import { Component } from "@angular/core";

@Component({
    selector: "app-process-monitoring",
    templateUrl: "./process-monitoring.component.html",
    styleUrls: ["./process-monitoring.component.scss"],
})
export class ProcessMonitoringComponent {
    constructor() {}

    ngOnInit(): void {}

    pageTitleArea: pageTitle[] = [
        {
            title: "Facto IQ",
            subTitle: "Process Monitoring Analytics",
        },
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: "assets/img/OptebizImage/ProcessMonitor.jpg",
        },
    ];
    viewDetailsDesc: DetailsDesc[] = [
        {
            subTitle: "Process Monitoring Analytics",
            title1: "About Product Category",
            paragraphText1:
                "This approach involves real-time monitoring, key performance indicator tracking, anomaly detection, root cause analysis, and predictive analytics. Visualization tools and dashboards are often employed to present insights clearly, fostering a culture of continuous improvement in organizational processes.",
            paragraphText2:
                "This process often includes anomaly detection, root cause analysis, and predictive analytics to drive continuous improvement.",
            title2: "Important Facts",
            title3: "Application Areas",
            title4: "Technologies That We Use",
            img1: "assets/img/OptebizImage/Process-Monitoring_Efficiency.jpg",
            img2: "assets/img/OptebizImage/process-monitoring.png",
        },
    ];
    viewDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: "Real-time Insights",
        },
        {
            title: "Operational Efficiency",
        },
        {
            title: "Key Performance Indicators (KPIs)",
        },
        {
            title: "Data Visualization",
        },
        {
            title: "Efficient Resource Allocation",
        },
        {
            title: "User Behavior Analysis",
        },
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: "MES Analytics",
            link: "mes-analytics",
        },
        {
            title: "Alarm Analytics",
            link: "alarm-analytics",
        },
        {
            title: "Quality Analytics",
            link: "quality-analytics",
        },
        {
            title: "Process control Analytics",
            link: "process-control-analytics",
        },
        {
            title: "Time Series Analytics",
            link: "timeSeries-analytics",
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: "PDF Download",
            icon: "bx bxs-file-pdf",
            link: "view-details",
        },
        {
            title: "view Details.txt",
            icon: "bx bxs-file-txt",
            link: "view-details",
        },
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: "bx bx-user-pin",
            title: "Phone",
            subTitle: "+14199133878",
        },
        {
            icon: "bx bx-map",
            title: "Location",
            subTitle: "LeanQubit Inc. 2600 Dorr Street Toledo, OH 43606",
        },
        {
            icon: "bx bx-envelope",
            title: "Email",
            subTitle: "sales@leanqubit.ai",
        },
    ];
    viewDetailsDescApplication: Application[] = [
        {
            title: "Historical Data Logging",
            icon: "flaticon-factory",
        },
        {
            title: "Alarm and Event History",
            icon: "flaticon-hospital",
        },
        {
            title: "Performance Monitoring",
            icon: "flaticon-tracking",
        },
        {
            title: "Quality Control",
            icon: "flaticon-investment",
        },
        {
            title: "Trend Analysis",
            icon: "flaticon-house",
        },
        {
            title: "Predictive Maintenance",
            icon: "flaticon-order",
        },
    ];
}
class pageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}
