<section class="process-section">
    <div class="section-triangle"></div>
    <div class="container">
        <div class="header-container">
            <div class="subtitle-badge">
                <img src="assets/img/star-icon.png" alt="Process icon" /> OUR
                PROCESS
            </div>
            <h2 class="section-title">
                THE
                <strong
                    ><span>Lean</span><span>Q</span><span>ubit</span></strong
                >
                Value to your Business
            </h2>
            <p class="section-description">
                our value proposition revolves around providing expertise,
                tailored solutions, measurable results, collaborative support,
                and agility to help drive the success and growth of your
                business
            </p>
        </div>

        <div class="content-row">
            <div class="image-column">
                <div class="image-wrapper">
                    <img
                        src="assets/img/OptebizImage/growthSuccess.png"
                        alt="LeanQubit Process"
                        class="feature-image"
                    />
                </div>
            </div>

            <div class="stats-column">
                <div class="stats-grid">
                    <div class="stat-card">
                        <div class="stat-value">8<sub>%</sub></div>
                        <div class="stat-label">
                            <span class="icon">📊</span>
                            Quality Improvement
                        </div>
                    </div>

                    <div class="stat-card">
                        <div class="stat-value">20<sub>%</sub></div>
                        <div class="stat-label">
                            <span class="icon">🚚</span>
                            Faster Delivery
                        </div>
                    </div>

                    <div class="stat-card">
                        <div class="stat-value">15<sub>%</sub></div>
                        <div class="stat-label">
                            <span class="icon">💰</span>
                            Costs Cutting
                        </div>
                    </div>

                    <div class="stat-card">
                        <div class="stat-value">15<sub>%</sub></div>
                        <div class="stat-label">
                            <span class="icon">⏱️</span>
                            Maximize Uptime
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
