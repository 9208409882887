<div class="banner-area">
    <div class="slider-container">
        <div
            class="slider-content"
            [ngStyle]="{
                'background-image':
                    'url(' + slides[currentSlide].backgroundImage + ')'
            }"
        >
            <div class="overlay"></div>
            <div class="content-wrapper">
                <span class="small-title">{{
                    slides[currentSlide].smallTitle
                }}</span>
                <h2 class="large-title">
                    {{ slides[currentSlide].largeTitle }}
                </h2>
                <p class="info">{{ slides[currentSlide].info }}</p>
                <div class="buttons">
                    <a
                        [routerLink]="slides[currentSlide].readMoreLink"
                        class="btn btn-primary"
                        >Read More</a
                    >
                    <a
                        [href]="slides[currentSlide].videoLink"
                        class="btn btn-play"
                    >
                        <span class="play-icon"></span>
                        <svg class="circle-animation" viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="48" />
                        </svg>
                    </a>
                </div>
            </div>
            <div class="masking-text">
                <h1>{{ slides[currentSlide].maskingTitle }}</h1>
            </div>
        </div>
        <button class="nav-btn prev" (click)="prevSlide()">&#10094;</button>
        <button class="nav-btn next" (click)="nextSlide()">&#10095;</button>
    </div>
</div>
