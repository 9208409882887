<section class="cs_hero cs_style_2 position-relative" id="home">
    <div class="container">
        <div class="cs_product_title">
            <h1><span>FactoVision</span> Quality Control</h1>
        </div>
        <div class="row align-items-center cs_gap_y_40 cs_reverse_md">
            <div class="col-lg-7">
                <div class="cs_hero_text">
                    <h1 class="cs_hero_title">
                        <!-- <div class="main-title">
                            Enhance Quality Control with FactoVision
                        </div> -->
                        <div class="subtitle">
                            Advanced AI-Powered Visual Inspection
                        </div>
                    </h1>
                    <p class="cs_hero_subtitle">
                        Enhance Quality, Ensure Compliance, and Boost Efficiency
                        with FactoVision.
                    </p>
                    <div
                        class="cs_btn_group cs_version_2 wow fadeIn"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.25s"
                    >
                        <a
                            href="/BookDemo"
                            class="cs_btn cs_bg_white cs_dark_hover"
                            >Product Demo</a
                        >
                        <a
                            href="/TryItNow"
                            class="cs_btn cs_bg_accent cs_dark_hover"
                            >Start Free Trial</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="cs_hero_thumb">
                    <video
                        id="videoPlayer"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="max-width: 100%; height: auto"
                    >
                        <source
                            src="assets/img/OptebizImage/ERP Systembg.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_hero_shape1"></div>
    <div class="cs_hero_shape2">
        <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
    </div>
    <div class="cs_hero_shape3"></div>
</section>
<!-- Second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="container">
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes wave {
                                0% {
                                    transform: translateX(-10px);
                                }
                                100% {
                                    transform: translateX(10px);
                                }
                            }
                            @keyframes fade {
                                0% {
                                    opacity: 0;
                                }
                                100% {
                                    opacity: 1;
                                }
                            }
                            .data-wave {
                                animation: wave 2s ease-in-out infinite
                                    alternate;
                            }
                            .capture-point {
                                animation: fade 1s infinite alternate;
                            }
                        </style>
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <g class="data-wave">
                            <path
                                d="M20 50 Q35 40 50 50 Q65 60 80 50"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                d="M20 60 Q35 50 50 60 Q65 70 80 60"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </g>
                        <circle
                            class="capture-point"
                            cx="50"
                            cy="50"
                            r="5"
                            fill="#1d9395"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Capture Real-Time Data</h3>
                    <p class="cs_iconbox_subtitle">
                        Use high-resolution cameras and sensors to capture
                        images and videos of your production process.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes process {
                                0% {
                                    stroke-dashoffset: 1000;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes rotate {
                                from {
                                    transform: rotate(0deg);
                                }
                                to {
                                    transform: rotate(360deg);
                                }
                            }
                            .neural-path {
                                stroke-dasharray: 1000;
                                animation: process 3s linear infinite;
                            }
                            .processing {
                                animation: rotate 8s linear infinite;
                            }
                        </style>
                        <g class="processing" transform-origin="50 50">
                            <circle
                                cx="50"
                                cy="50"
                                r="35"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                class="neural-path"
                                d="M30 30 L70 70 M30 70 L70 30 M50 20 L50 80 M20 50 L80 50"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </g>
                        <circle cx="50" cy="50" r="5" fill="#1d9395" />
                    </svg>
                    <h3 class="cs_iconbox_title">
                        Analyze with AI-Powered Precision
                    </h3>
                    <p class="cs_iconbox_subtitle">
                        Leverage FactoVision's AI algorithms to analyze data for
                        SOP compliance, defect detection, and accurate counting.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes alert {
                                0% {
                                    transform: scale(1);
                                    opacity: 1;
                                }
                                50% {
                                    transform: scale(1.2);
                                    opacity: 0.7;
                                }
                                100% {
                                    transform: scale(1);
                                    opacity: 1;
                                }
                            }
                            .alert-ring {
                                animation: alert 1.5s infinite;
                            }
                        </style>
                        <circle
                            cx="50"
                            cy="50"
                            r="35"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <g class="alert-ring">
                            <path
                                d="M50 25 L50 45 M50 55 L50 75"
                                stroke="#1d9395"
                                stroke-width="4"
                                stroke-linecap="round"
                            />
                            <path
                                d="M25 50 L45 50 M55 50 L75 50"
                                stroke="#1d9395"
                                stroke-width="4"
                                stroke-linecap="round"
                            />
                        </g>
                        <circle cx="50" cy="50" r="5" fill="#1d9395" />
                    </svg>
                    <h3 class="cs_iconbox_title">Receive Real-Time Alerts</h3>
                    <p class="cs_iconbox_subtitle">
                        Get instant notifications for SOP deviations, defects,
                        or counting errors, and take corrective actions
                        immediately.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <style>
                                @keyframes connect {
                                    0% {
                                        stroke-dashoffset: 1000;
                                    }
                                    100% {
                                        stroke-dashoffset: 0;
                                    }
                                }
                                @keyframes pulse {
                                    0% {
                                        transform: scale(1);
                                    }
                                    50% {
                                        transform: scale(1.1);
                                    }
                                    100% {
                                        transform: scale(1);
                                    }
                                }
                                .connection {
                                    stroke-dasharray: 1000;
                                    animation: connect 3s linear infinite;
                                }
                                .node {
                                    animation: pulse 2s infinite;
                                }
                            </style>
                            <g class="node">
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="25"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                />
                                <circle
                                    cx="20"
                                    cy="20"
                                    r="10"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                />
                                <circle
                                    cx="80"
                                    cy="20"
                                    r="10"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                />
                                <circle
                                    cx="20"
                                    cy="80"
                                    r="10"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                />
                                <circle
                                    cx="80"
                                    cy="80"
                                    r="10"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                />
                            </g>
                            <path
                                class="connection"
                                d="M20 20 L50 50 L80 20 M20 80 L50 50 L80 80"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <h3 class="cs_iconbox_title">
                        Integrate with Existing Systems
                    </h3>
                    <p class="cs_iconbox_subtitle">
                        Seamlessly connect FactoVision with your MES, ERP, and
                        quality management systems for end-to-end visibility.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>
<!-- Third section -->

<div class="banner-container">
    <div class="banner-area">
        <div class="slider-container">
            <div
                class="slider-content"
                style="
                    background-image: url('assets/img/OptebizImage/image1_4.jpg');
                "
            >
                <div class="overlay"></div>
                <div class="content-container">
                    <!-- Top Right Content -->
                    <div class="content-wrapper-top">
                        <!-- <span class="small-title"
                        >Quality Control Inefficiencies</span
                    > -->
                        <h2 class="large-title">
                            Quality Control Inefficiencies
                        </h2>
                        <p class="info">
                            Manual quality control processes can be
                            time-consuming, prone to errors, and compromise
                            product quality
                        </p>
                        <div class="masking-text">
                            <h1>Industry Challenge</h1>
                        </div>
                    </div>

                    <!-- Bottom Left Content -->
                    <div class="content-wrapper-bottom">
                        <!-- <span class="small-title">Intelligent Quality Control</span> -->
                        <h2 class="large-title">Intelligent Quality Control</h2>
                        <p class="info">
                            FactoVision's AI-powered visual inspection solution
                            automates quality control, detects defects, and
                            ensures SOP compliance
                        </p>
                        <div class="masking-text">
                            <h1>Our Solution</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="container">
    <div class="m2-afmc-bottom-section">
        <!-- First Row -->
        <div class="m2-afmc-item active" id="bottom-1">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes check {
                            0% {
                                stroke-dashoffset: 1000;
                            }
                            100% {
                                stroke-dashoffset: 0;
                            }
                        }
                        @keyframes scan {
                            0% {
                                transform: translateY(0);
                            }
                            100% {
                                transform: translateY(40px);
                            }
                        }
                        .checkmark {
                            stroke-dasharray: 1000;
                            animation: check 2s ease-in-out infinite;
                        }
                        .scanner {
                            animation: scan 2s ease-in-out infinite alternate;
                        }
                    </style>
                    <rect
                        x="20"
                        y="20"
                        width="60"
                        height="60"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                        rx="5"
                    />
                    <line
                        class="scanner"
                        x1="25"
                        y1="30"
                        x2="75"
                        y2="30"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path
                        class="checkmark"
                        d="M30 50 L45 65 L70 35"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="3"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>SOP Compliance Monitoring</strong>
                <p>
                    Automatically track and verify operator adherence to
                    Standard Operating Procedures.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-2">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes rotate {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }
                        @keyframes pulse {
                            0% {
                                r: 3;
                            }
                            50% {
                                r: 5;
                            }
                            100% {
                                r: 3;
                            }
                        }
                        .scanner {
                            animation: rotate 4s linear infinite;
                        }
                        .defect {
                            animation: pulse 1s infinite;
                        }
                    </style>
                    <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <g class="scanner" transform-origin="50 50">
                        <line
                            x1="50"
                            y1="10"
                            x2="50"
                            y2="90"
                            stroke="#1d9395"
                            stroke-width="1"
                            stroke-dasharray="2,2"
                        />
                        <line
                            x1="10"
                            y1="50"
                            x2="90"
                            y2="50"
                            stroke="#1d9395"
                            stroke-width="1"
                            stroke-dasharray="2,2"
                        />
                    </g>
                    <circle
                        class="defect"
                        cx="65"
                        cy="35"
                        r="4"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Defect Detection</strong>
                <p>
                    Identify defects in real-time with high accuracy using
                    AI-powered image recognition.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes count {
                            0%,
                            100% {
                                opacity: 0;
                            }
                            50% {
                                opacity: 1;
                            }
                        }
                        .number {
                            font-family: Arial, sans-serif;
                            font-size: 24px;
                            animation: count 2s infinite;
                        }
                        .number:nth-child(2) {
                            animation-delay: 0.5s;
                        }
                        .number:nth-child(3) {
                            animation-delay: 1s;
                        }
                    </style>
                    <circle
                        cx="50"
                        cy="50"
                        r="40"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <text class="number" x="35" y="60" fill="#1d9395">1</text>
                    <text class="number" x="50" y="60" fill="#1d9395">2</text>
                    <text class="number" x="65" y="60" fill="#1d9395">3</text>
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Accurate Counting</strong>
                <p>
                    Automate counting processes for parts, products, or
                    inventory with precision.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Second Row -->
<div class="m2-afmc-bottom-section">
    <div class="m2-afmc-item" id="bottom-4">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes ring {
                        0% {
                            transform: scale(1);
                            opacity: 1;
                        }
                        50% {
                            transform: scale(1.5);
                            opacity: 0;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    .alert-ring {
                        animation: ring 2s infinite;
                    }
                </style>
                <circle
                    cx="50"
                    cy="50"
                    r="35"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <g class="alert-ring">
                    <circle
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle
                        cx="50"
                        cy="50"
                        r="27"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <circle
                        cx="50"
                        cy="50"
                        r="15"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                </g>
                <circle cx="50" cy="50" r="5" fill="#1d9395" />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Real-Time Alerts</strong>
            <p>
                Receive instant notifications for SOP deviations, defects, or
                counting discrepancies.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-5">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes flow {
                        0% {
                            stroke-dashoffset: 1000;
                        }
                        100% {
                            stroke-dashoffset: 0;
                        }
                    }
                    @keyframes spin {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                    .data-flow {
                        stroke-dasharray: 1000;
                        animation: flow 3s linear infinite;
                    }
                    .gear {
                        animation: spin 4s linear infinite;
                    }
                </style>
                <g class="gear" transform-origin="50 50">
                    <path
                        d="M50 25 L55 25 L57 35 L43 35 L45 25 Z"
                        fill="#1d9395"
                    />
                    <path
                        d="M50 75 L55 75 L57 65 L43 65 L45 75 Z"
                        fill="#1d9395"
                    />
                    <path
                        d="M25 50 L25 55 L35 57 L35 43 L25 45 Z"
                        fill="#1d9395"
                    />
                    <path
                        d="M75 50 L75 55 L65 57 L65 43 L75 45 Z"
                        fill="#1d9395"
                    />
                </g>
                <path
                    class="data-flow"
                    d="M20 50 C40 20, 60 80, 80 50"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Seamless Integration</strong>
            <p>
                Integrate with existing MES, ERP, and quality management systems
                for end-to-end visibility.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes highlight {
                        0% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    @keyframes cursor {
                        0% {
                            transform: translate(0, 0);
                        }
                        50% {
                            transform: translate(20px, 15px);
                        }
                        100% {
                            transform: translate(0, 0);
                        }
                    }
                    .ui-element {
                        animation: highlight 1.5s infinite alternate;
                    }
                    .cursor {
                        animation: cursor 3s infinite;
                    }
                </style>
                <rect
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    rx="5"
                />
                <rect
                    class="ui-element"
                    x="20"
                    y="20"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    opacity="0.8"
                />
                <rect
                    class="ui-element"
                    x="55"
                    y="20"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    opacity="0.8"
                />
                <rect
                    class="ui-element"
                    x="20"
                    y="55"
                    width="60"
                    height="15"
                    fill="#1d9395"
                    opacity="0.8"
                />
                <path
                    class="cursor"
                    d="M25 25 L35 35 L25 35 Z"
                    fill="#1d9395"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>User-Friendly Interface</strong>
            <p>
                Intuitive dashboards and reports for easy monitoring and
                decision-making.
            </p>
        </div>
    </div>
</div>

<!-- Fifth Section -->
<!-- First Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-1">
        <img
            src="assets/img/OptebizImage/1st dashboard for FactoVision.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Ensure Operator Adherence</div>
        <div class="m2-afmc-copy-text">
            <p>
                Automatically track and verify operator adherence to Standard
                Operating Procedures (SOPs) with FactoVision.
            </p>
            <p>
                Our AI-powered computer vision technology monitors operator
                activities in real-time, ensuring they follow established
                protocols. This enables you to:
            </p>
            <ul>
                <li>Reduce errors and defects caused by non-compliance</li>
                <li>Improve product quality and consistency</li>
                <li>Enhance operator accountability and safety</li>
            </ul>
        </div>
    </div>
</div>

<!-- Second Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-4">
        <img
            src="assets/img/OptebizImage/FactoVision 3.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Automate Counting Processes</div>
        <div class="m2-afmc-copy-text">
            <p>
                Streamline inventory management and production tracking with
                automated counting processes.
            </p>
            <p>
                FactoVision uses computer vision to accurately count parts,
                products, or inventory, reducing manual errors and increasing
                efficiency. This feature enables you to:
            </p>
            <ul>
                <li>Eliminate manual counting errors and discrepancies</li>
                <li>Improve inventory accuracy and reduce stockouts</li>
                <li>Increase productivity and reduce labor costs</li>
            </ul>
        </div>
    </div>
</div>

<!-- Third Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/FactoVision 2.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Receive Timely Notifications</div>
        <div class="m2-afmc-copy-text">
            <p>
                Stay informed and take prompt action with instant notifications
                from FactoVision.
            </p>
            <p>
                Receive alerts for SOP deviations, defects, or counting
                discrepancies, enabling you to:
            </p>
            <ul>
                <li>
                    Quickly address quality control issues and minimize downtime
                </li>
                <li>Improve operator accountability and retrain as needed</li>
                <li>
                    Optimize production processes and improve overall efficiency
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- last section -->
<section class="mb-5 mt-5">
    <div class="container">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title cs_text_white">
                        Take Control of Your Quality Control Today!
                    </h2>
                    <p class="cs_section_subtitle mb-0">
                        Discover how FactoVision can help you automate quality
                        control and improve product quality. Contact us to learn
                        more.
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="/BookDemo" class="cs_btn cs_bg_white"
                        >Book A Demo</a
                    >
                    <a href="/TryItNow" class="cs_btn cs_bg_accent"
                        >Try It Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
