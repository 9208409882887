import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Frequently Asked Questions'
        }
    ]
    faqAccordion: Accordion[] = [
        {
            questions: 'Q1. Why do we need MES Solution if we already have an ERP Solution?',
            answer: "While ERP solutions excel in managing overall business processes, MES serves as a specialized layer designed to optimize and control manufacturing operations. MES provides real-time visibility into the production process, allowing for precise monitoring, tracking, and control of shop floor activities. It bridges the gap between planning and execution, enhancing efficiency, reducing lead times, and ensuring quality control in manufacturing. In essence, combining ERP with MES creates a comprehensive ecosystem that not only manages the entire shop floor environment but also fine-tunes and maximizes the efficiency of the manufacturing process."
        },
        {
            questions: 'Q2. Will FactoMES Solution be fully compatible with my ERP Solution',
            answer: "Yes, Facto Tools seamlessly integrates with a diverse range of ERP systems, including SAP, Oracle, JDE, Sage, and various others, ensuring a comprehensive collaboration within your existing technological ecosystem. This interoperability is designed to enhance efficiency and minimize disruptions, making Facto Tools a versatile solution for businesses with different ERP preferences. In the integration process, approximately 98% of the data exchange pertains to critical manufacturing components. This includes the synchronization of schedules, work orders, production and downtime values, and Bill of Materials (BOMs), ensuring that Facto Tools becomes a synchronized extension of your ERP, streamlining manufacturing processes and bolstering data accuracy for optimal operational performance."
        },
        {
            questions: 'Q3. What Measurable Results should I expect by partnering with Optebiz?',
            answer: "Choosing the ideal solution and a strategic implementation partner is pivotal in reaping significant rewards for your organization. This thoughtful selection process not only enhances customer satisfaction by ensuring on-time deliveries but also contributes to substantial reductions in downtime and wastage. The synergy between the right solution and a competent implementation partner goes beyond mere efficiency – it establishes a robust platform that grants your organization maximum control over manufacturing activities. Importantly, this is achieved without burdening your existing infrastructure with unnecessary resources, ensuring a seamless integration that elevates operational excellence while optimizing resource utilization."
        },
        {
            questions: 'Q4. What Skill Sets are needed to use Facto Tools?',
            answer: "The efficacy of any advanced solution is intricately tied to the competence of the individuals operating it. Recognizing this, OpteBiz goes beyond providing a cutting-edge solution; we offer comprehensive support through detailed training materials. These materials are designed to aid in the training of the right individuals within your organization. Moreover, our tools are designed with user-friendliness in mind, adhering to industry standards. This means that in many cases, your existing team can seamlessly integrate and navigate the solution without the need for specialized training."
        },
        {
            questions: 'Q5. What is the Implementation Cost for Facto MES?',
            answer: "Determining the software pricing and tailoring the implementation process is a customized journey at OpteBiz, shaped by your unique requirements. Our approach involves a comprehensive assessment, enabling us to gain a thorough understanding of your specific needs and the intricacies of your existing systems. This detailed evaluation serves as the foundation for presenting you with the most suitable solution set and pricing options that align with your budget constraints. At OpteBiz, transparency is key, and our commitment is to deliver a bespoke solution that not only addresses your current needs but also anticipates and accommodates your future growth. We prioritize a collaborative and consultative process, ensuring that the proposed pricing and implementation plan meets your organizational objectives and financial considerations."
        }
    ]

}
class pageTitle {
    title : string;
}
class Accordion {
    questions : string;
    answer : string;
}