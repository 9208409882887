import { Component } from "@angular/core";

@Component({
    selector: "app-production-montioring",
    templateUrl: "./production-montioring.component.html",
    styleUrls: ["./production-montioring.component.scss"],
})
export class ProductionMontioringComponent {
    constructor() {}

    ngOnInit(): void {}

    pageTitleArea: pageTitle[] = [
        {
            title: "Facto MES",
            subTitle: "Production Monitoring",
        },
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: "/assets/img/OptebizImage/ProdMonitor.jpg",
        },
    ];
    viewDetailsDesc: DetailsDesc[] = [
        {
            subTitle: "Production Monitoring",
            title1: "Production Monitoring",
            paragraphText1:
                "Production Monitoring refers to the systematic process of observing, analyzing, and managing the various aspects of manufacturing processes to ensure efficiency, quality, and overall operational excellence.",
            paragraphText2:
                "The goal of production monitoring is to track and optimize production activities in real-time, allowing organizations to make data-driven decisions, identify areas for improvement, and respond promptly to deviations from planned production targets.",
            title2: "Important Facts",
            title3: "Application Areas",
            title4: "Technologies That We Use",
            img1: "assets/img/OptebizImage/PM.jpg",
            img2: "assets/img/OptebizImage/dashboardpm.png",
        },
    ];
    viewDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: "Real-Time Data Collection",
        },
        {
            title: "Key Performance Indicators (KPIs)",
        },
        {
            title: "Production Dashboards",
        },
        {
            title: "Downtime Tracking",
        },
        {
            title: "Quality Control",
        },
        {
            title: "OEE (Overall Equipment Effectiveness)",
        },
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: "WorkOrder Management",
            link: "work-order-management",
        },

        {
            title: "Inventory Management",
            link: "inventory-management",
        },
        {
            title: "Quality Control",
            link: "quality-control",
        },
        {
            title: "Downtime Management",
            link: "downtime-management",
        },
        {
            title: "Reporting and Analytics",
            link: "reportand-analytics",
        },
        // {
        //     title: 'Paperless Manufacturing',
        //     link: 'paperless-manufacturing'
        // },
        //  {
        //     title: 'Machine Integration',
        //     link: 'machine-integration'
        // },
        // {
        //     title: 'Maintenance Management',
        //     link: 'maintenance-management'
        // }
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: "PDF Download",
            icon: "bx bxs-file-pdf",
            link: "view-details",
        },
        {
            title: "view Details.txt",
            icon: "bx bxs-file-txt",
            link: "view-details",
        },
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: "bx bx-user-pin",
            title: "Phone",
            subTitle: "+14199133878",
        },
        {
            icon: "bx bx-map",
            title: "Location",
            subTitle: "LeanQubit Inc. 2600 Dorr Street Toledo, OH 43606",
        },
        {
            icon: "bx bx-envelope",
            title: "Email",
            subTitle: "sales@leanqubit.ai",
        },
    ];
}
class pageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}
