import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'About Us'
        }
    ]


whyChooseUsContent: Content1[] = [
    {
        title: 'Our Mission',
        paragraphText1: 'Our expert team of consultants and proprietary tools enable your business to collect and analyze current and historical data. Our FactoTools break this data down, providing key insights of your business. Whether it’s on a small scale, or dealing with big-data on a multi-plant scale, we digest the data and present it through intuitive dashboards and reports built for you – giving you an in-depth oversight of your operations.',
        paragraphText2: 'Our solutions are flexible – we can design web based or mobile solutions to fit your needs – giving you access anytime, anywhere.',
        linkIcon: 'flaticon-right',
        linkText: 'More About Us',
        link: 'about-us'
    }
]
ourBrandPartnersList: List[] = [
    {
        title: 'Our Brand Partners',
        linkText: 'View All',
        link: 'partner'
    }
]
brandPartnersImg: Image[] = [
    {
        img: 'assets/img/partner/img1.png'
    },
    {
        img: 'assets/img/partner/img2.png'
    },
    {
        img: 'assets/img/partner/img3.png'
    },
    {
        img: 'assets/img/partner/img4.png'
    },
    {
        img: 'assets/img/partner/img5.png'
    },
    {
        img: 'assets/img/partner/img6.png'
    }
]


singleFunfactsItem: Funfacts[] = [
{
    icon: "assets/img/funfacts/icon1.png",
    title: '10%',
    subTitle: 'Improvement in processing throughout the facilities.',
    color:'red'
},
{
    icon: "assets/img/funfacts/icon2.png",
    title: '2%',
    subTitle: 'Reduction in total Downtime.',
    color:'Green'
},
// {
//     icon: "assets/img/funfacts/icon3.png",
//     title: '100%',
//     subTitle: 'Satisfaction rate'
// },
// {
//     icon: "assets/img/funfacts/icon4.png",
//     title: '90%',
//     subTitle: 'Senior scientist'
// }
]

}
class Funfacts {
icon : string;
title : string;
subTitle : string;
color:string;
}
class Content1 {
title : string;
paragraphText1 : string;
paragraphText2 : string;
linkIcon : string;
linkText : string;
link : string;
}
class List {
title : string;
linkText : string;
link : string;
}
class Image {
img : string;
}
class Content {
title : string;
paragraphText : string;
linkText : string;
link : string;
}
class Video {
img : string;
icon : string;
videoLink : string;
}


class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText : string;
linkText : string;
linkIcon : string;
link : string;
}
class pageTitle {
    title : string;
}