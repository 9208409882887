import { Component } from '@angular/core';

@Component({
  selector: 'app-alarm-analytics',
  templateUrl: './alarm-analytics.component.html',
  styleUrls: ['./alarm-analytics.component.scss']
})
export class AlarmAnalyticsComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto IQ',
          subTitle: 'Alarm Analytics',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: '/assets/img/OptebizImage/alarm.png'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Alarm Analytics',
          title1: 'Alarm Analytics',
          paragraphText1: 'Alarm analytics refers to the analysis of system alarms and notifications to identify patterns, anomalies, and insights for improved system reliability and performance',
          paragraphText2: 'This involves but is not limited to monitoring equipment performance, production rates, quality control, and other relevant metrics. The goal is to identify and address any issues that may arise in real-time, minimizing downtime and maximizing productivity.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/Pipeline Only.png',
          img2: 'assets/img/OptebizImage/Alarm Status with Notes.png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Alarm Rationalization'
      },
      {
          title: 'Root Cause Analysis'
      },
      {
          title: 'Prioritization of Alarms'
      },
      {
          title: 'False Alarm Analysis'
      },
      {
          title: 'Alarm Response Time Analysis'
      },
      {
          title: 'Alarm Trends and Patterns'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Time Series Analytics',
        link: 'time-series-analytics'
    },
    {
        title: 'MES Analytics',
        link: 'mes-analytics'
    },
    
    {
        title: 'Quality Analytics',
        link: 'quality-analytics'
    },
    {
        title: 'Processcontrol Analytics',
        link: 'process-control-analytics'
    }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}


