import { Component } from '@angular/core';

@Component({
  selector: 'app-transaction-progress',
  templateUrl: './transaction-progress.component.html',
  styleUrls: ['./transaction-progress.component.scss']
})
export class TransactionProgressComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto CloudConnect',
          subTitle: 'Transaction Progress Monitoring Dashboard',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/Trans.webp'
      }
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Transaction Progress Monitoring Dashboard',
          title1: 'Transaction Progress Monitoring Dashboard',
          paragraphText1: 'The chronological and procedural advancement of a transaction through various stages or steps within a system or process. A transaction, in this context, typically involves the exchange of information, goods, or services between parties, and its progress can be monitored to ensure its successful completion and to identify and address any issues that may arise during the process.',
          paragraphText2: 'It helps stakeholders and decision-makers monitor transactions in real time, identify bottlenecks, and take corrective actions as needed.The dashboard typically displays key metrics and indicators.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/transaction_monitoring.svg',
          img2: 'assets/img/OptebizImage/Transcation.jpg'
          
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Event-Driven Architecture'
      },
      {
          title: 'Real-Time Integration'
      },
      {
          title: 'Triggers Define Dispatch Points'
      },
      {
          title: 'Dependencies on External Systems'
      },
      {
          title: 'Data Transformation'
      },
      {
          title: 'Balancing Throughput and Resources'
      }
  ]
  servicesDetailsDescApplication: Application[] = [
      {
          title: 'Manufacturing',
          icon: 'flaticon-factory'
      },
      {
          title: 'Healthcare',
          icon: 'flaticon-hospital'
      },
      {
          title: 'Automobile',
          icon: 'flaticon-tracking'
      },
      {
          title: 'Banking',
          icon: 'flaticon-investment'
      },
      {
          title: 'Real Estate',
          icon: 'flaticon-house'
      },
      {
          title: 'Logistics',
          icon: 'flaticon-order'
      }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      {
          title: 'JavaScript'
      },
      {
          title: 'Python'
      },
      {
          title: 'Java'
      },
      {
          title: 'C/CPP'
      },
      {
          title: 'PHP'
      },
      {
          title: 'Swift'
      },
      {
          title: 'C# (C- Sharp)'
      },
      {
          title: 'Ruby'
      },
      {
          title: 'SQL'
      }
  ]

  sidebarServicesList: ServicesList[] = [
      {
          title: 'Data Integration ',
          link: 'data-integration'
      },
      {
          title: 'Integration Service',
          link: 'integration-service'
      },
      {
        title: 'Resilient Architecture',
        link: 'resilient-architecture'
    },
    {
        title: 'Trigger-Based',
        link: 'trigger-based'
    },
      {
          title: 'Dynamic Tag Selection',
          link: 'dynamic-tag-selection'
      }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'services-details'
      },
      {
          title: 'Services Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'services-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}



