import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-testing2',
  templateUrl: './testing2.component.html',
  styleUrls: ['./testing2.component.scss'],
  animations: [
    trigger('fadeAnimation', [
      transition('* => *', [
        style({ opacity: 0 }),
        animate('500ms', style({ opacity: 1 })),
      ]),
    ]),
  ],
})
export class Testing2Component implements OnInit {
  slides = [
    {
      backgroundImage: '../../assets/img/OptebizImage/slide1.jpg',
      smallTitle: 'We are Dedicated',
      largeTitle: 'Transforming Manufacturing with Industrial IoT and AI.',
      info: 'Your Path to Smart, Efficient, and Data-Driven Production.',
      readMoreLink: '/company/about',
      videoLink: 'https://www.youtube.com/watch?v=c1XNqw2gSbU',
      maskingTitle: 'AI Solution',
      animateTitle: true
    },
    {
      backgroundImage: '../../assets/img/OptebizImage/slide2.jpg',
      smallTitle: 'Driving Industrial Innovation',
      largeTitle: 'Unlocking the Future of Industrial Automation.',
      info: 'Empowering industries with seamless automation, real-time data insights, and scalable solutions that drive operational excellence. Transform your plant into a smart, efficient hub with cutting-edge technology.',
      readMoreLink: '/company/about',
      videoLink: 'https://www.youtube.com/watch?v=c1XNqw2gSbU',
      maskingTitle: 'Analytics Dashboard',
      animateTitle: false
    },
    {
      backgroundImage: '../../assets/img/OptebizImage/slide1.jpg',
      smallTitle: 'Cloud-Powered Innovation', 
  largeTitle: 'Accelerating Business Transformation with AWS', 
  info: 'Leverage the power of AWS to scale your business, enhance agility, and drive innovation. From cloud computing to AI solutions, AWS provides the tools to transform your digital landscape.', 
      readMoreLink: '/services',
      videoLink: 'https://www.youtube.com/watch?v=example3',
      maskingTitle: 'Cloud Solutions ',
      animateTitle: true
    },
    {
      backgroundImage: '../../assets/img/OptebizImage/slide2.jpg',
      smallTitle: 'FactoIQ Team',
      largeTitle: 'Delivering Excellence in Time Series Analytics.',
      info: 'Our expert team at FactoIQ ensures that every project is driven by precise and data-backed insights. With a focus on Time Series Analytics, we turn raw data into actionable intelligence.',
      readMoreLink: '/team',
      videoLink: 'https://www.youtube.com/watch?v=example4',
      maskingTitle: 'Time Series Expertise',
      animateTitle: false
    }
    
  ];

  currentSlide = 0;
  private autoSlideInterval: any;

  ngOnInit() {
    this.startAutoSlide();
  }

  ngOnDestroy() {
    this.stopAutoSlide();
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    this.resetAutoSlide();
  }

  prevSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    this.resetAutoSlide();
  }

  private startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  private stopAutoSlide() {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  private resetAutoSlide() {
    this.stopAutoSlide();
    this.startAutoSlide();
  }
}