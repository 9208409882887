<div id="main" class="site-main- clearfix">
    <svg style="display: none"></svg>
    <div class="hero-section yb-section">
        <div class="headline text-center">
            <div class="container">
                <div class="left-side">
                    <h1>
                        <span class="gradient-word">FactoCloud Connect</span>
                        Streamlined Cross-System data flow
                    </h1>
                    <div class="subheading">
                        FactoCloudConnect is advanced data capture, processing,
                        and data streaming application capable of pushing the
                        data between different data storage systems like Kafka,
                        traditional databases, Event Hub, ElasticSearch, and
                        InfluxDb.
                    </div>
                </div>
                <div class="right-side">
                    <img
                        src="assets\img\OptebizImage\facto cloud connect latest1.svg"
                        alt="FactoCloudConnect"
                        title="FactoCloudConnect"
                        class="wow animate__animated animate__fadeInDown"
                        loading="lazy"
                        style="top: 25px"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="pdf-download-section yb-section dark-bg">
        <div class="container">
            <div
                class="page-title-content"
                *ngFor="let pageTitle of pageTitleArea"
            >
                <!-- <h2>{{pageTitle.title}}</h2> -->
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{ pageTitle.title }}</li>
                    <li>{{ pageTitle.subTitle }}</li>
                </ul>
            </div>
        </div>
        <div class="c-v2-yugabyte-db-section-triangle"></div>
        <div class="container" style="max-width: 1200px">
            <section class="section solutions-importance">
                <div class="c-row">
                    <h2>
                        Power of Big Data With Facto <span>CloudConnect</span>
                    </h2>
                    <p>
                        FactoCloudConnect is advanced data capture, processing,
                        and data streaming application capable of pushing the
                        data between different data storage systems like Kafka,
                        traditional databases, Event Hub, ElasticSearch, and
                        InfluxDb.
                    </p>
                    <div class="importance-boxes">
                        <div>
                            <img
                                src="assets\img\OptebizImage\data connector Icon.png"
                                data-src="assets\img\OptebizImage\data connector Icon.png"
                                alt="data-integration"
                                title="data-integration"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Data Integration</span> & Connector
                                Configuration
                            </h3>
                            <p>
                                Facto Cloudconnect streamlines the
                                configuration, customization, and management of
                                data connectors and interfaces, enabling a
                                smooth and integrated exchange of data across
                                diverse systems and platforms.
                            </p>
                            <a
                                routerLink="/data-integration"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\integration service icon.png"
                                data-src="assets\img\OptebizImage\integration service icon.png"
                                alt="integration-service"
                                title="integration-service"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Integration Service</span> Utilizing
                                Apache Camel
                            </h3>
                            <p>
                                Utilizing Apache Camel, it's about building an
                                integration service that effortlessly connects
                                and exchanges data between diverse systems and
                                applications, thanks to Camel's extensive
                                connectors and routing capabilities.
                            </p>
                            <a
                                routerLink="/integration-service"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\data stroe and forward icon.png"
                                data-src="assets\img\OptebizImage\data stroe and forward icon.png"
                                alt="data store and forward "
                                title="data store and forward"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Resilient Data Store</span> & Forward
                                Architecture
                            </h3>
                            <p>
                                Resilient Architecture for Data Store and
                                Forward refers to the design of a system
                                architecture that ensures data integrity and
                                continuity by reliably storing and forwarding
                                data, even in the presence of disruptions or
                                disconnections.
                            </p>
                            <a
                                routerLink="/resilient-architecture"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\data dispatch icon.png"
                                data-src="assets\img\OptebizImage\data dispatch icon.png"
                                alt="data dispatched"
                                title="data dispatched"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Trigger-Based</span> Data Dispatch
                                Mechanism
                            </h3>
                            <p>
                                A Trigger-Based Data Dispatch Mechanism" refers
                                to a system or process in which data is sent or
                                dispatched in response to specific triggers or
                                events, enabling precise and automated data
                                transmission based on predefined conditions or
                                occurrences.
                            </p>
                            <a routerLink="/trigger-based" class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\monitoring dashboard icon.png"
                                data-src="assets\img\OptebizImage\monitoring dashboard icon.png"
                                alt="monitoring dashboard "
                                title="monitoring dashboard "
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Transaction Progress </span> Monitoring
                                Dashboard
                            </h3>
                            <p>
                                A Transaction Progress Monitoring Dashboard is a
                                graphical user interface that offers real-time
                                or near-real-time visibility into the status,
                                progress, and key metrics of ongoing
                                transactions or processes, enabling effective
                                monitoring and informed decision-making.
                            </p>
                            <a
                                routerLink="/transaction-progress"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\data scaling icon.png"
                                data-src="assets\img\OptebizImage\data scaling icon.png"
                                alt="data scaling"
                                title="data scaling"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3>
                                <span>Dynamic Tag</span> Aggregation for
                                Scalable Data
                            </h3>
                            <p>
                                The process where tags, or labels, are chosen
                                dynamically based on changing criteria to
                                efficiently collect and organize data in a
                                scalable manner. This approach allows for
                                flexible and adaptive data aggregation,
                                accommodating varying needs and evolving
                                datasets.
                            </p>
                            <a
                                routerLink="/dynamic-tag-selection"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
