import { Component } from '@angular/core';

@Component({
  selector: 'app-inductive-details',
  templateUrl: './inductive-details.component.html',
  styleUrls: ['./inductive-details.component.scss']
})
export class InductiveDetailsComponent {

}
