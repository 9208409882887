<section class="page-title-area">
    <div class="container">
        <div class="page-title-content" *ngFor="let pageTitle of pageTitleArea">
            <!-- <h2>{{pageTitle.title}}</h2> -->
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>
                    <a routerLink="/FactoCloud">{{ pageTitle.title }}</a>
                </li>
                <li>{{ pageTitle.subTitle }}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.png" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
</section>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div
                    class="services-details-image"
                    *ngFor="let DetailsImage of servicesDetailsImage"
                >
                    <img [src]="DetailsImage.img" alt="image" />
                </div>

                <div
                    class="services-details-desc"
                    *ngFor="let DetailsDesc of servicesDetailsDesc"
                >
                    <h3 class="titlecolor">{{ DetailsDesc.title1 }}</h3>
                    <p>{{ DetailsDesc.paragraphText1 }}</p>

                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-6">
                            <div class="image">
                                <img [src]="DetailsDesc.img1" alt="image" />
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="content">
                                <h3>{{ DetailsDesc.title2 }}</h3>

                                <ul>
                                    <li
                                        *ngFor="
                                            let ImportantFacts of servicesDetailsDescImportantFacts
                                        "
                                    >
                                        {{ ImportantFacts.title }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p>{{ DetailsDesc.paragraphText2 }}</p>

                    <div class="charts-image">
                        <img [src]="DetailsDesc.img2" alt="image" />
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-details-info">
                    <ul class="services-list">
                        <li *ngFor="let ServicesList of sidebarServicesList">
                            <a routerLink="/{{ ServicesList.link }}">{{
                                ServicesList.title
                            }}</a>
                        </li>
                    </ul>

                    <!-- <div class="download-file">
                        <h3>Brochures</h3>

                        <ul>
                            <li *ngFor="let DownloadFile of sidebarDownloadFile;"><a routerLink="/{{DownloadFile.link}}">{{DownloadFile.title}} <i class='{{DownloadFile.icon}}'></i></a></li>
                        </ul>
                    </div> -->

                    <div class="services-contact-info">
                        <h3>Contact Info</h3>

                        <ul>
                            <li *ngFor="let ContactInfo of sidebarContactInfo">
                                <div class="icon">
                                    <i class="{{ ContactInfo.icon }}"></i>
                                </div>
                                <span>{{ ContactInfo.title }}:</span>
                                {{ ContactInfo.subTitle }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
