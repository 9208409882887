import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-partner',
    templateUrl: './homeone-partner.component.html',
    styleUrls: ['./homeone-partner.component.scss']
})
export class HomeonePartnerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Our Products",
        title: 'Business with Excellence',
        paragraphText: 'Our Facto Tools and expert team delivers integrated solutions to seamlessly collect, analyze, and present data—eliminating guesswork and meeting your unique business need'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
    {
      icon: 'assets/img/OptebizImage/facto MESLogo.png',
      title: 'FactoMES',
      paragraphText: [
        'All-in-one shop floor monitoring solution.',
        'Optimizes operations & automates workflows.',
        'Manages orders, scheduling & product flow.',
        'Improves production efficiency with real-time tracking.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoMES'
    },
    {
      icon: 'assets/img/OptebizImage/facto SPC.png',
      title: 'FactoVision',
      paragraphText: [
        'AI cameras monitor SOP compliance.',
        'Detects defects and ensures accurate counting in real-time.',
        'Integrates with MES, ERP, and quality systems.',
        'Provides instant alerts and quality control reports.'
      ],   
    
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoVision'
    },
    {
      icon: 'assets/img/OptebizImage/factoLake 2.png',
      title: 'FactoLake',
      paragraphText: [
        'Tracks and assesses key performance indicators (KPIs).',
        'Identifies inefficiencies and optimizes resource allocation.',
        'Enhances supply chain visibility and reduces waste.',
        'Provides actionable insights through advanced analytics.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoLake'
    },
    {
      icon: 'assets/img/OptebizImage/facto SPC.png',
      title: 'FactoPlan',
      paragraphText: [
        'Optimizes resource allocation for better efficiency.',
        'Ensures projects are delivered on time and within budget.',
        'Provides clear insights into resource availability.',
        'Supports strategic decision-making with real-time data.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoPlan'
    },
    {
      icon: 'assets/img/OptebizImage/facto IQlogo.png',
      title: 'FactoIQ',
      paragraphText: [
        'Collects real-time timeseries data for analysis.',
        'Analyzes shop floor processes, maintenance, and operations data.',
        'Uses statistical and AI methodologies for insights.',
        'Enhances predictive maintenance to minimize downtime.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoIQ'
    }
];

  

}
class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText: string[];
linkText : string;
linkIcon : string;
link : string;
}