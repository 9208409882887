<div class="cs_content">
    <section class="app-solutions pt-40 pb-50">
        <app-testing2></app-testing2>
    </section>
</div>

<!-- second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="cs_height_143 cs_height_lg_75"></div>
    <div class="container" style="max-width: 1400px">
        <div class="cs_section_heading cs_style_1 text-center">
            <p
                class="cs_section_subtitle cs_text_accent wow fadeInUp"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                style="
                    font-size: 68px;
                    margin-bottom: 0;
                    position: relative;
                    display: block;
                    color: #444444;
                    font-family: sans-serif;

                    font-weight: 800;
                    line-height: 1em;
                    margin-top: 0px;
                    text-align: center;
                    text-transform: uppercase;
                "
            >
                Optimize Operations
                <br />
                <span style="color: #1d9395">Reduce Downtime </span>
                <span class="cut-costs">& CUT COSTS</span>
            </p>
            <h2
                class="cs_hero_subtitle mb-0 wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                style="
                    display: block;
                    max-width: 1000px;
                    margin: 7px auto 32px;
                    font-size: 25px;
                    font-family: sans-serif;
                    color: #444444;
                    font-weight: 700;
                    line-height: 30px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 12px;
                "
            >
                Empower your operations with scalable, cost-effective automation
                and real-time insights
            </h2>
        </div>
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/shop.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Scalable Solutions</h3>
                    <p class="cs_iconbox_subtitle">
                        A comprehensive suite of tools tailored to meet the
                        unique demands of diverse manufacturing environments,
                        enabling enhanced operational efficiency and seamless
                        automation.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/price_bar.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Cost-Effective Automation</h3>
                    <p class="cs_iconbox_subtitle">
                        Streamline workflows, reduce downtime, and cut
                        operational costs with innovative solutions that drive
                        productivity and deliver long-term value to your
                        business.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img src="assets/img/OptebizImage/ui.svg" alt="Icon" />
                    </div>
                    <h3 class="cs_iconbox_title">Seamless Integration</h3>
                    <p class="cs_iconbox_subtitle">
                        Experience effortless deployment with advanced
                        automation tools designed for quick setup and minimal IT
                        complexity, allowing for a smooth transition.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <img
                            src="assets/img/OptebizImage/setup.svg"
                            alt="Icon"
                        />
                    </div>
                    <h3 class="cs_iconbox_title">Real-Time Insights</h3>
                    <p class="cs_iconbox_subtitle">
                        Leverage intuitive dashboards to gain unparalleled
                        visibility into operations, empowering informed
                        decisions and improving overall performance.
                    </p>
                </div>
            </div>
        </div>
        <!-- <div class="cs_featured_shape">
            <img src="assets/img/OptebizImage/Vector.svg" alt="Image" />
        </div> -->
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>

<div class="m2-fixed-container" style="max-width: 1400px">
    <div class="carousel-section">
        <div class="carousel-section-header">
            <h2>
                Unlock Operational Excellence with
                <strong
                    ><span>Lean</span><span>Q</span><span>ubit</span></strong
                >
            </h2>
        </div>
        <div class="slides-container">
            <div
                *ngFor="let slide of slides; let i = index"
                class="slide"
                [class.active]="currentSlideIndex === i"
                [id]="slide.id"
            >
                <div class="content">
                    <!-- <span class="step-counter">{{ slide.stepCounter }}</span> -->
                    <h5 [innerHTML]="slide.title"></h5>
                    <p>{{ slide.description }}</p>
                </div>
                <div class="lottie-container" [id]="'animation-' + (i + 1)">
                    <div class="image-container">
                        <ng-container *ngIf="slide.mediaType === 'video'">
                            <video
                                #videoPlayer
                                [src]="slide.mediaSource"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                                [muted]="true"
                                preload="auto"
                                loop
                            >
                                Your browser does not support the video tag.
                            </video>
                        </ng-container>
                        <ng-container *ngIf="slide.mediaType === 'image'">
                            <img
                                [src]="slide.mediaSource"
                                alt="Description"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                            />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation-arrows progress-bars">
            <button
                class="nav-arrow prev"
                [disabled]="currentSlideIndex === 0"
                (click)="prevSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M14.5 17L9.5 12L14.5 7"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
            <div class="progress-bar-inner">
                <div
                    *ngFor="let slide of slides; let i = index"
                    class="progress-bar"
                >
                    <div
                        class="progress-bar-fill"
                        [style.width.%]="getProgressWidth(i)"
                    ></div>
                </div>
            </div>
            <button
                class="nav-arrow next"
                [disabled]="currentSlideIndex === slides.length - 1"
                (click)="nextSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M9.5 7L14.5 12L9.5 17"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
        </div>
    </div>
</div>

<!-- new section -->
<div class="m4-features-main-container noribbon">
    <div class="m2-fixed-containernew">
        <div class="m4-features-items-cont">
            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Gold Certified Ignition <span>Partner</span></h3>
                    <div class="m4-fet-text">
                        <p>
                            Empowering manufacturing innovation, LeanQubit
                            delivers seamless digitization as a Gold Certified
                            Ignition Partner.
                        </p>
                        <p>
                            LeanQubit enhances operational value through the
                            strategic implementation of Ignition® by Inductive
                            Automation.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="inductive-details"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Learn More</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        class="gold-badge"
                        src="assets/img/OptebizImage/GoldCertified.svg"
                        alt="integrations-header-devices"
                        loading="lazy"
                        style="
                            max-width: 100%;
                            filter: drop-shadow(
                                0px 10px 20px rgba(239, 191, 4, 0.2)
                            );
                        "
                    />
                </div>
            </div>

            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Automate with <span>Expertise</span></h3>
                    <div class="m4-fet-text">
                        <p>
                            LeanQubit brings over 25+ years of expertise to
                            provide customized MES solutions.
                        </p>
                        <p>
                            Discover how our cutting-edge dashboard empowers
                            businesses to make data-driven decisions.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="inductive-details"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Explore Expertise</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        src="assets/img/OptebizImage/Downloads.png"
                        alt="mm21_integrations_iso_R1 Copy 5"
                        loading="lazy"
                        style="
                            max-width: 100%;
                            height: auto;
                            filter: drop-shadow(0 0 0.95rem #044f67);
                        "
                    />
                    <span
                        ><img
                            src="assets\img\OptebizImage\inductive-automation-llc-logo2.png"
                            data-src="assets\img\OptebizImage\inductive-automation-llc-logo2.png"
                            alt="Inductive Automation"
                            title="Inductive Automation"
                            style="
                                height: 100px;
                                margin-top: -24px;
                                margin-left: 388px;
                            "
                    /></span>
                </div>
            </div>

            <div class="m4-features-item">
                <div class="m4-features-item-text">
                    <h3>Unlocking Potential with <span>AWS</span></h3>
                    <div class="m4-fet-text">
                        <p>
                            As a certified Ignition integrator and an accredited
                            AWS partner, we guarantee seamless, scalable, and
                            secure automation for your business.
                        </p>
                        <p>
                            Discover how our cutting-edge dashboard empowers
                            businesses to make data-driven decisions.
                        </p>
                    </div>
                    <div class="m4-fet-cta">
                        <a
                            href="aws"
                            contenteditable="false"
                            style="cursor: pointer"
                            >Unlocking AWS</a
                        >
                    </div>
                </div>
                <div class="m4-features-image-box">
                    <img
                        src="assets/img/OptebizImage/cloud-partner-aws-1024x811_Update1.png"
                        alt="mm20-laptop_0004_Extensibility-Utilization_dropshadow"
                        loading="lazy"
                        style="
                            max-width: 100%;
                            height: auto;
                            filter: drop-shadow(0 0 0.75rem #e65f32);
                        "
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<!-- new section cardsanimation -->
<section class="services-area">
    <div class="container" style="max-width: 1400px">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                {{ sectionTitleContent.title }}
                <span> Best in Class </span> Solutions
            </h2>
        </div>

        <div class="slider-container">
            <div class="cards-wrapper">
                <div class="cards-container">
                    <div
                        [class]="getColumnClass()"
                        *ngFor="let ServicesBoxContent of visibleCards"
                        [@cardsBatch]="visibleCards.length"
                    >
                        <div
                            class="single-services-box-item"
                            style="
                                height: 550px;
                                display: flex;
                                flex-direction: column;
                            "
                        >
                            <div class="icon">
                                <img
                                    [src]="ServicesBoxContent.icon"
                                    alt="image"
                                />
                            </div>
                            <h3>
                                <a>{{ ServicesBoxContent.title }}</a>
                            </h3>
                            <div style="flex-grow: 1; overflow-y: auto">
                                <ul>
                                    <li
                                        *ngFor="
                                            let point of ServicesBoxContent.paragraphText
                                        "
                                    >
                                        {{ point }}
                                    </li>
                                </ul>
                            </div>
                            <a
                                [routerLink]="['/', ServicesBoxContent.link]"
                                class="learn-more-btn"
                                style="margin-top: auto"
                            >
                                <i></i
                                >{{ ServicesBoxContent.linkText }}&nbsp;&nbsp;
                                <i
                                    class="{{ ServicesBoxContent.linkIcon }}"
                                ></i>
                            </a>
                            <div class="shape">
                                <img
                                    src="assets/img/services/shape4.png"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- New dot navigation -->
            <div class="dot-navigation">
                <div
                    class="dot"
                    *ngFor="let batch of batchSizes; let i = index"
                    [class.active]="currentBatchIndex === i"
                    (click)="goToBatch(i)"
                ></div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>

<!-- fourth section -->

<section
    id="pricing"
    class="cs_section_shape_wrap_1 cs_gradient_bg_1 position-relative overflow-hidden"
>
    <div class="cs_section_shape_1 position-absolute">
        <img src="assets/img/OptebizImage/Vector3.svg" alt="Shape" />
    </div>
    <div class="cs_section_shape_2 position-absolute">
        <img src="assets/img/OptebizImage/dot.svg" alt="Shape" />
    </div>
    <div class="cs_height_143 cs_height_lg_75"></div>
    <div class="container" style="max-width: 1400px">
        <div class="cs_section_heading cs_style_1 cs_style_1 text-center">
            <div
                class="d-flex align-items-center justify-content-center gap-3"
                style="margin-bottom: 10px"
            >
                <img
                    src="assets/img/OptebizImage/Inductive-Automation-Ignition-Designer-Logo.png"
                    alt="Medium Logo"
                    style="width: 210px; height: 80px; object-fit: contain"
                />
            </div>
            <h2
                class="cs_section_subtitle mb-0 wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
                style="
                    color: #444;
                    font-family: sans-serif;
                    font-size: 68px;
                    font-weight: 600;
                    line-height: 1em;
                    margin-top: 0;
                    text-align: center;
                    text-transform: uppercase;
                "
            >
                Find the Perfect Fit for<br />
                <span style="color: #1d9395"> Your Needs</span>
            </h2>
        </div>
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Basic</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For small business
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-star"></i>Ignition
                                Installation & Licensing Assistance
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Basic System
                                Architecture Design
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Connecting to
                                Devices
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Ignition Starter
                                Training
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Basic
                                Troubleshooting & Support
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Access to
                                Resources
                            </li>
                        </ul>
                        <a
                            href="BookDemo"
                            class="cs_pricing_btn cs_up_3_hover"
                            style="margin-top: 188px"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol1.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Intermediate</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For sophisticated industrial
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-star"></i>All Basic
                                services plus
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Custom SCADA
                                Dashboards
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>IIoT Device
                                Integration
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Database
                                Integration & Historian Setup
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Custom Scripting
                                & Automation
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Industrial
                                Protocol Integration
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Security
                                Hardening
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Team Training
                            </li>
                        </ul>
                        <a
                            href="BookDemo"
                            class="cs_pricing_btn cs_up_3_hover"
                            style="margin-top: 100px"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="cs_pricing_table cs_style_1">
                    <div class="cs_pricing_head">
                        <div class="cs_pricing_icon">
                            <img
                                src="assets/img/OptebizImage/symbol3.svg"
                                alt="Image"
                            />
                        </div>
                        <div class="cs_pricing_head_text">
                            <h3 class="cs_pricing_head_title">Advanced</h3>
                            <p class="cs_pricing_head_subtitle mb-0">
                                For Enterprise-grade solutions
                            </p>
                        </div>
                    </div>
                    <div class="cs_pricing_seperator"></div>
                    <div class="cs_pricing_plan"></div>
                    <div class="cs_pricing_feature">
                        <ul class="cs_pricing_feature_list cs_mp0">
                            <li>
                                <i class="fa-solid fa-star"></i>All Intermediate
                                services plus
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>End-to-End
                                Ignition Solution Design
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Advanced IIoT
                                Implementation
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Complex SCADA
                                Development
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Advanced
                                Historian Setup & Data Analytics
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Redundancy
                                Gateways & High Availability
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Custom IIoT
                                Applications
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>Training &
                                Support for Advanced Teams
                            </li>
                            <li>
                                <i class="fa-solid fa-star"></i>System
                                Optimization & Maintenance
                            </li>
                        </ul>
                        <a href="BookDemo" class="cs_pricing_btn cs_up_3_hover"
                            >Book Now</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>

<section>
    <div class="container" style="max-width: 1400px; margin-bottom: 50px">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2
                        class="cs_section_title cs_text_white"
                        style="
                            font-size: 50px;
                            line-height: 1.3em;
                            position: relative;
                            z-index: 2;
                            font-family: Inter, sans-serif;
                        "
                    >
                        Simplify Automated Workflow Solutions <br />
                        for Teams and Industries
                    </h2>
                    <p
                        class="cs_section_subtitles mb-0"
                        style="
                            font-weight: 600;
                            margin-bottom: 10px;
                            font-size: 20px;
                            line-height: 1.6em;
                            font-family: Inter, sans-serif;
                        "
                    >
                        Transform your operations with IoT Automation and
                        Business Software Systems delivering actionable <br />
                        insights and streamlining processes across teams and
                        industries.
                    </p>
                    <style>
                        @media screen and (max-width: 1200px) {
                            .cs_section_title {
                                font-size: 45px !important;
                            }
                            .cs_section_subtitles {
                                font-size: 16px !important;
                            }
                        }

                        @media screen and (max-width: 992px) {
                            .cs_section_title {
                                font-size: 38px !important;
                            }
                            .cs_section_subtitles {
                                font-size: 15px !important;
                            }
                        }

                        @media screen and (max-width: 768px) {
                            .cs_section_title {
                                font-size: 30px !important;
                                line-height: 1.4em !important;
                            }
                            .cs_section_subtitles {
                                font-size: 14px !important;
                                line-height: 1.5em !important;
                            }
                        }

                        @media screen and (max-width: 480px) {
                            .cs_section_title {
                                font-size: 24px !important;
                            }
                            .cs_section_subtitles {
                                font-size: 13px !important;
                            }
                        }
                    </style>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="BookDemo" class="cs_btn cs_bg_accent"
                        >Product Demo</a
                    >
                    <a href="TryItNow" class="cs_btn cs_bg_white"
                        >Try Free Trial</a
                    >
                </div>
                <div
                    class="cs_cta_image text-center"
                    style="
                        margin-top: 40px;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        position: relative;
                        z-index: 2;
                    "
                >
                    <img
                        src="assets/img/OptebizImage/animation4 (1).svg"
                        alt="Optebiz Animation"
                        style="
                            display: block;
                            max-width: 100%;
                            width: 100%;
                            height: auto;
                            border-radius: 10px;
                            position: relative;
                            z-index: 2;
                        "
                    />
                </div>
            </div>
            <div class="cs_cta_shape1" style="z-index: 1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2" style="z-index: 1">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
