import { Component } from '@angular/core';

@Component({
  selector: 'app-work-order-management',
  templateUrl: './work-order-management.component.html',
  styleUrls: ['./work-order-management.component.scss']
})
export class WorkOrderManagementComponent {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto MES',
          subTitle: 'Workorder Management',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: '/assets/img/OptebizImage/work-order-management-process.svg'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Workorder Management',
          title1: 'Workorder Management',
          paragraphText1: 'Work Order Management refers to the systematic process of creating, tracking, and managing work orders throughout their lifecycle within an organization.',
          paragraphText2: 'Work orders are directives that specify tasks, services, or maintenance activities that need to be performed. Effective work order management is crucial for organizations to ensure tasks are executed efficiently, resources are allocated appropriately, and overall operations run smoothly.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/work-order-management-hero.png',
          img2: 'assets/img/OptebizImage/order-workflow.png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Creation of Work Orders'
      },
      {
          title: 'Task Specification'
      },
      {
          title: 'Prioritization'
      },
      {
          title: 'Assignment of Resources'
      },
      {
          title: 'Workflow and Approval Processes'
      },
      {
          title: 'Scheduling'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
      {
          title: 'Production Monitoring',
          link: 'production-montioring'
      },
      
    {
        title: 'Inventory Management',
        link: 'inventory-management'
    },
    {
        title: 'Quality Control',
        link: 'quality-control'
    },
    {
        title: 'Downtime Management',
        link: 'downtime-management'
    },
    {
        title: 'Reporting and Analytics',
        link: 'reportand-analytics'
    },
    // {
    //     title: 'Paperless Manufacturing',
    //     link: 'paperless-manufacturing'
    // },
    //  {
    //     title: 'Machine Integration',
    //     link: 'machine-integration'
    // },
    // {
    //     title: 'Maintenance Management',
    //     link: 'maintenance-management'
    // }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
        icon: 'bx bx-user-pin',
        title: 'Phone',
        subTitle: '+1 (419) 476-4520'
    },
    {
        icon: 'bx bx-map',
        title: 'Location',
        subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
    },
    {
        icon: 'bx bx-envelope',
        title: 'Email',
        subTitle: 'bizops@OpteBiz.com'
    }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}
