<div class="services-container">
    <div class="service-level-tabs">
        <button [routerLink]="'/Basic'" class="tab-button">Basic</button>
        <button [routerLink]="'/intermediate'" class="tab-button active">
            Intermediate
        </button>
        <button [routerLink]="'/Advanced'" class="tab-button">Advanced</button>
        <button [routerLink]="'/Add-on-service'" class="tab-button">
            Add-On-Service
        </button>
    </div>

    <header class="services-header">
        <h1>Our Intermediate Services</h1>
        <p class="subtitle">
            Advanced solutions for sophisticated industrial automation
            requirements
        </p>
        <!-- Added Key Information Section -->
        <div class="key-info">
            <div class="info-group">
                <h4>Target Audience</h4>
                <p>
                    Companies looking to integrate Ignition into existing
                    systems
                </p>
            </div>

            <div class="services-features-container">
                <!-- Services Offered Section -->
                <div class="services-section">
                    <div class="info-group">
                        <h4>Services Offered</h4>
                        <ul class="info-list">
                            <li>
                                Custom Project Design: SCADA dashboards, tags,
                                scripting & alarms
                            </li>
                            <li>
                                Integrations: Connecting Ignition to PLCs, ERP
                                systems, or databases
                            </li>
                            <li>
                                Development Guidance: Code review and
                                optimization for in-house teams
                            </li>
                            <li>
                                Basic Training: Live sessions on core Ignition
                                modules
                            </li>
                            <li>
                                Service Packages: Flexible packages based on
                                time or milestones
                            </li>
                        </ul>
                    </div>
                </div>

                <!-- Key App Features Section -->
                <div class="features-section">
                    <div class="info-group">
                        <h4>Key App Features</h4>
                        <ul class="info-list">
                            <li>
                                Booking system for live development or
                                consultation slots
                            </li>
                            <li>
                                Resource allocation and project tracking tools
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="services-grid">
        <div *ngFor="let service of services" class="service-card">
            <div class="icon-container">
                <i [class]="service.icon"></i>
            </div>
            <div class="card-content">
                <h3>{{ service.title }}</h3>
                <p>{{ service.description }}</p>

                <ul *ngIf="service.bulletPoints" class="bullet-points">
                    <li *ngFor="let point of service.bulletPoints">
                        <span class="bullet-icon">
                            <i class="fas fa-check-circle"></i>
                        </span>
                        <span class="bullet-text">{{ point }}</span>
                    </li>
                </ul>
            </div>
            <div class="card-overlay"></div>
        </div>
    </div>
</div>
