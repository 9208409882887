<div class="footer-wrapper">
    <div class="footer-container">
        <div class="footer-left">
            <img
                src="assets/img/LeanQbit_logo.png"
                alt="LeanQubit"
                class="logo"
            />
            <p class="description">
                LeanQubit is a dynamic company at the forefront of the
                industrial revolution 4.0 in manufacturing, specializing in
                MES-based solutions, CloudConnect, AWS, and Inductive Automation
            </p>
            <div class="social-links">
                <span class="follow-text">Follow Us:</span>
                <a
                    href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A105597157&keywords=leanqubit&origin=RICH_QUERY_TYPEAHEAD_HISTORY&position=0&searchId=7a7de46e-c283-4bf2-9e32-22663b9d431c&sid=Qge&spellCorrectionEnabled=true"
                    target="_blank"
                    class="social-icon Linkedin"
                    ><i class="fab fa-linkedin-in"></i
                ></a>
                <!-- <a href="#" class="social-icon twitter"
                    ><i class="fab fa-twitter"></i
                ></a>
                <a href="#" class="social-icon instagram"
                    ><i class="fab fa-instagram"></i
                ></a>
                <a href="#" class="social-icon youtube"
                    ><i class="fab fa-youtube"></i
                ></a>
                <a href="#" class="social-icon dribbble"
                    ><i class="fab fa-dribbble"></i
                ></a> -->
            </div>
        </div>

        <div class="footer-links">
            <h3>USEFUL LINKS</h3>
            <div class="links-grid">
                <a href="#" class="active">Home</a>
                <a href="/Solution">Solution</a>
                <a href="/Overview">About us</a>

                <a href="/inductive-details">Inductive </a>

                <a href="/faq">FAQ</a>
                <a href="/aws">AWS</a>
                <a href="/BookDemo">Product Demo</a>

                <a href="/Client-Testimonials">Testimonials</a>
            </div>
        </div>

        <div class="contact-info">
            <h3>CONTACT INFO</h3>
            <div class="contact-details">
                <div class="contact-item">
                    <i class="fas fa-phone contact-icon"></i>
                    <p>
                        <a href="tel:+14199133878" target="_blank"
                            >+14199133878</a
                        >
                    </p>
                </div>

                <div class="contact-item">
                    <i class="fas fa-envelope contact-icon"></i>
                    <p>
                        <a href="mailto:sales@leanqubit.ai" target="_blank"
                            >sales@leanqubit.ai</a
                        >
                    </p>
                </div>
                <div class="contact-item">
                    <i class="fas fa-building contact-icon"></i>
                    <div class="contact-text">
                        <p class="company">LeanQubit Inc</p>
                        <p>2600 Dorr Street Toledo, OH 43606, United States</p>
                    </div>
                </div>

                <div class="contact-item">
                    <i class="fas fa-map-marker-alt contact-icon"></i>
                    <div class="contact-text">
                        <p>
                            B-205, Mastermind III, Royal Palms IT Park Aarey
                            Colony, Goregaon(East), Mumbai, MH, India
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-subscribe">
            <h3>SUBSCRIBE</h3>
            <p>Get subscriber only insights & news delivered by LeanQubit</p>
            <input
                type="email"
                placeholder="Email Address"
                class="email-input"
                [(ngModel)]="email"
                (input)="onEmailChange($event)"
            />
            <button class="subscribe-btn" (click)="subscribe()">Go</button>
            <p
                *ngIf="subscriptionStatus"
                [ngClass]="{
                    success: subscriptionStatus.includes('Successfully'),
                    error: !subscriptionStatus.includes('Successfully')
                }"
            >
                {{ subscriptionStatus }}
            </p>
        </div>
    </div>
</div>
<!-- Copyright Section -->
<div class="copyright-area">
    <div class="container">
        <div class="row">
            <div
                class="col-xl-6 col-lg-6 text-center text-lg-left d-flex align-items-center"
            >
                <div class="copyright-text">
                    <p>
                        Copyright &copy; 2024, All Right Reserved
                        <a href="/">LeanQubit Private Limited</a>
                    </p>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                <div class="footer-menu">
                    <ul style="margin-left: 149px">
                        <li><a href="/Terms&Condition">Terms</a></li>
                        <li><a href="/privacy&policy">Privacy</a></li>
                        <!-- <li><a href="/BookDemo">Contact</a></li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <a href="BookDemo" class="ask-us-button">
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 16 16"
        class="button-icon"
    >
        <path d="m0 0h16v16h-16z" fill="none" opacity="0"></path>
        <path
            fill="#ffffff"
            d="m15.2 13c-.22 0-.43-.09-.58-.25l-2.96-3.15h-4.85c-1.54 0-2.8-1.26-2.8-2.8v-4c0-1.54 1.26-2.8 2.8-2.8h6.4c1.54 0 2.8 1.26 2.8 2.8v9.4c0 .33-.2.62-.51.74-.1.04-.2.06-.29.06zm-8.4-11.4c-.67 0-1.2.53-1.2 1.2v4c0 .66.54 1.2 1.2 1.2h5.2c.22 0 .43.09.58.25l1.82 1.93v-7.38c0-.66-.54-1.2-1.2-1.2z"
        ></path>
        <path
            fill="#ffffff"
            d="m.8 16c-.1 0-.2-.02-.29-.06-.3-.12-.51-.42-.51-.74v-9.4c0-1.54 1.26-2.8 2.8-2.8h2c.44 0 .8.36.8.8s-.36.8-.8.8h-2c-.67 0-1.2.53-1.2 1.2v7.38l1.82-1.93c.15-.16.36-.25.58-.25h5.2c.66 0 1.2-.54 1.2-1.2v-1c0-.44.36-.8.8-.8s.8.36.8.8v1c0 1.54-1.26 2.8-2.8 2.8h-4.85l-2.96 3.15c-.15.16-.37.25-.58.25z"
        ></path>
    </svg>
    <span class="button-text">Ask Us</span>
</a> -->
<div class="go-top"><i class="flaticon-up"></i></div>
