import { Component } from '@angular/core';

@Component({
  selector: 'app-mes-analytics',
  templateUrl: './mes-analytics.component.html',
  styleUrls: ['./mes-analytics.component.scss']
})
export class MesAnalyticsComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto IQ',
          subTitle: 'MES Analytics',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/Mes1.png'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'MES Analytics',
          title1: 'MES Analytics',
          paragraphText1: 'MES is an Application Suite  that manages and controls manufacturing operations on the shop floor. When analytics is applied to MES, it involves utilizing data produced during manufacturing processes to extract valuable insights, enhance performance, and guide well-informed decision-making',
          paragraphText2: 'MES solutions are the flashlight that light the way on the path to continuous improvement. MES tools bring efficiency to your organization by streamlining data flow, which will increase your production capacity without the capital expense. And, rather than embarking on usual improvement initiatives that may or may not benefit your organization, you can use MES tools to show where your efforts will be best spent.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/MES.png',
          img2: 'assets/img/OptebizImage/MES (OEE).png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Overall Equipment Effectiveness '
      },
      {
          title: 'Downtime Tracking'
      },
      {
          title: 'Data Entry Automation'
      },
      {
          title: 'Real-time Monitoring'
      },
      {
          title: 'Production Planning and Scheduling'
      },
      {
          title: 'Statistical Process Control'
      },
      {
        title: 'Recipe Management'
    }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Time Series Analytics',
        link: 'time-series-analytics'
    },
    {
        title: 'Alarm Analytics',
        link: 'alarm-analytics'
    },
    {
        title: 'Quality Analytics',
        link: 'quality-analytics'
    },
    {
        title: 'Processcontrol Analytics',
        link: 'process-control-analytics'
    }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}

