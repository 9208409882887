import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-partner',
    templateUrl: './homeone-partner.component.html',
    styleUrls: ['./homeone-partner.component.scss']
})
export class HomeonePartnerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Our Products",
        title: 'Business with Best in Class Solutions',
        paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
    {
        icon: 'assets/img/OptebizImage/Asset 6@36x.png',
        title: 'FactoIQ',
        paragraphText: ' Collect Realtime timeseries data in a timeseries database and analyze the shop floor process, maintenance, manufacturing and operations data using statistical and AI methodologies',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoIQ'
    },
    {
        icon: 'assets/img/OptebizImage/FactoMES.svg',
        title: 'FactoMES',
        paragraphText: 'The FactoMES suite provides an all-in-one solution for monitoring every shop floor activity from start to finish. It helps businesses optimize operations and automate workflows, from receiving factory orders and scheduling production runs to managing the movement of finished products.',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoMES'
    },
    {
        icon: 'assets/img/OptebizImage/Asset 4@36x.png',
        title: 'FactoCloudConnect',
        paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shop floor and machine control system into various cloud environments and Big Data Platforms',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/factopredictive 2.png',
        title: 'FactoPredict',
        paragraphText: 'FactoPredict AI analyzes manufacturing data to forecast issues, optimize production, and improve resource planning - helping you reduce downtime and deliver better quality products',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/FactoOEE 1.png',
        title: 'FactoOEE',
        paragraphText: 'FactoOEE provides manufacturers insights into production processes, helping pinpoint inefficiencies and areas for improvement. Leveraging these insights, manufacturers can reduce waste, enhance productivity, and optimize operational performance.',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/factoLake 2.png',
        title: 'FactoLake',
        paragraphText: 'The FactoLake enable organization to track and assess key performance indicators (KPIs), identify inefficiencies, and optimize resource allocation, the tool empowers manufacturers to streamline operations, reduce waste, and enhance overall supply chain visibility.',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/FatocEye 2.png',
        title: 'FactoEye',
        paragraphText: 'Automated vision-based SOP compliance monitoring that provides real-time feedback to operators and quality insights to managers - empowering better supervision and reporting.',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/factoQual 1.png',
        title: 'FactoQual',
        paragraphText: 'FactoQual is IoT-powered quality control systems and a real-time monitoring tool, this transformation streamlines inspection processes, enables earlier defect detection, and elevates overall product quality. ',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    {
        icon: 'assets/img/OptebizImage/factoMPCP.png',
        title: 'FactoMPCP',
        paragraphText: 'FactoMPCP monitors and controls production to ensure efficient operations, consistent quality, and regulatory compliance - keeping your manufacturing process on track.',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    
]

}
class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText : string;
linkText : string;
linkIcon : string;
link : string;
}