import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state, query, stagger, group } from '@angular/animations';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-testing2',
  templateUrl: './testing2.component.html',
  styleUrls: ['./testing2.component.scss'],
  animations: [
      trigger('zoomTransition', [
        transition(':increment', [
          style({ opacity: 0, transform: 'scale(1)' }),
          animate(
            '1500ms ease-out',
            style({ opacity: 1, transform: 'scale(1.1)' })
          ),
        ]),
        transition(':decrement', [
          style({ opacity: 0, transform: 'scale(1)' }),
          animate(
            '1500ms ease-out',
            style({ opacity: 1, transform: 'scale(1.1)' })
          ),
        ]),
      ]),
    
      trigger('fadeIn', [
        transition(':enter', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
        ])
      ]),
      
      trigger('cardsBatch', [
        transition(':increment', [
          query(
            ':enter',
            [
              style({
                transform: 'translateX(-100%)',
                opacity: 0,
              }),
              animate(
                '300ms ease-out',
                style({
                  transform: 'translateX(0)',
                  opacity: 1,
                })
              ),
            ],
            { optional: true }
          ),
          query(
            ':leave',
            [
              animate(
                '300ms ease-in',
                style({
                  transform: 'translateX(100%)',
                  opacity: 0,
                })
              ),
            ],
            { optional: true }
          ),
        ]),
        transition(':decrement', [
          query(
            ':enter',
            [
              style({
                transform: 'translateX(100%)',
                opacity: 0,
              }),
              animate(
                '300ms ease-out',
                style({
                  transform: 'translateX(0)',
                  opacity: 1,
                })
              ),
            ],
            { optional: true }
          ),
          query(
            ':leave',
            [
              animate(
                '300ms ease-in',
                style({
                  transform: 'translateX(-100%)',
                  opacity: 0,
                })
              ),
            ],
            { optional: true }
          ),
        ]),
      ])
      
      
      
      ],
      
    
})
export class Testing2Component implements OnInit {
  slides = [
    {
      backgroundImage: 'assets/img/OptebizImage/image1_4.jpg',
      smallTitle: 'Advanced Control Systems',
      largeTitle: 'Ignition SCADA Solutions for Modern Industry',
      info: 'Leverage powerful Ignition platform for seamless control, monitoring, and data visualization across your operations.',
      Demo: '/BookDemo',
      Freetrail: '/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=scada-demo',
      maskingTitle: 'SCADA Systems',
      animateTitle: true,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      backgroundImage: 'assets/img/OptebizImage/image1_5.jpg',
      smallTitle: 'Machine Vision Excellence',
      largeTitle: 'Advanced Vision Systems for Quality Control',
      info: 'Implement cutting-edge machine vision solutions for automated inspection, defect detection, and quality assurance.',
      Demo: '/BookDemo',
      Freetrail: '/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=vision-systems',
      maskingTitle: 'Vision Systems',
      animateTitle: false,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      backgroundImage: 'assets/img/OptebizImage/image1_6.jpg',
      smallTitle: 'Smart Manufacturing Planning',
      largeTitle: 'Optimize Production with MES & SPC Solutions',
      info: 'Streamline operations with integrated Manufacturing Execution Systems and Manufacturing Control Platforms.',
      Demo: '/BookDemo',
      Freetrail: '/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=mes-solutions',
      maskingTitle: 'Production Planning',
      animateTitle: true,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      backgroundImage: 'assets/img/OptebizImage/LandingImg.jpg',
      smallTitle: 'We are Dedicated',
      largeTitle: 'Transforming Manufacturing with Industrial IoT and AI.',
      info: 'Your Path to Smart, Efficient, and Data-Driven Production.',
      Demo: '/BookDemo',
      Freetrail :'/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=c1XNqw2gSbU',
      maskingTitle: 'AI Solution',
      animateTitle: true,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      backgroundImage: 'assets/img/OptebizImage/LandingImg2.jpg',
      smallTitle: 'Driving Industrial Innovation',
      largeTitle: 'Unlocking the Future of Industrial Automation.',
      info: 'Empower industries with automation, real-time insights, and scalable solutions for efficiency',
      Demo: '/BookDemo',
      Freetrail :'/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=c1XNqw2gSbU',
      maskingTitle: 'Analytics Dashboard',
      animateTitle: false,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      backgroundImage: 'assets/img/OptebizImage/Landingimg3.jpg',
      smallTitle: 'Cloud-Powered Innovation', 
  largeTitle: 'Accelerating Business Transformation with AWS', 
  info: 'Leverage AWS for scalability, agility, and innovation with cloud and AI solutions.', 
  Demo: '/BookDemo',
      Freetrail :'/TryItNow',
      videoLink: 'https://www.youtube.com/watch?v=example3',
      maskingTitle: 'Cloud Solutions ',
      animateTitle: true,
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
  
    
  ];
 

  currentSlide = 0;
  private autoSlideInterval: any;

  ngOnInit() {
    this.startAutoSlide();
  }

  ngOnDestroy() {
    this.stopAutoSlide();
  }

  nextSlide() {
    this.currentSlide = (this.currentSlide + 1) % this.slides.length;
    this.resetAutoSlide();
  }

  prevSlide() {
    this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
    this.resetAutoSlide();
  }

  private startAutoSlide() {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  private stopAutoSlide() {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  private resetAutoSlide() {
    this.stopAutoSlide();
    this.startAutoSlide();
  }
}