import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-case-studies',
    templateUrl: './homeone-case-studies.component.html',
    styleUrls: ['./homeone-case-studies.component.scss']
})
export class HomeoneCaseStudiesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    singleCaseStudyItem: singleCaseStudyItemContent[] = [
        {
            subTitle: 'OUR EXPERTISE #1',
            title: 'We Are the Future of Operations',
            paragraphText1: 'Optebiz is here to modernize your business.Simply put,we Collect,Understand & Utilize Data From your industrial process to optimize and maximize Your Operation ',
            // paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img1.jpg'
        },
        {
            subTitle: 'OUR EXPERTISE #2',
            title: 'Real Results',
            paragraphText1: 'Our tools pair the best technology with experts who understand your business. We help achieve results that directly impact your bottom line.',
            // paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img2.jpg'
        },
        {
            subTitle: 'OUR EXPERTISE #3',
            title: 'A Thorough Process',
            paragraphText1: 'Finding the right solution is difficult - we work with you to assess your operation and fill in any gaps present with current technology & management. Schedule a free consultation today!',
            // paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.',
            link: 'case-studies-details',
            linkText: 'Details More',
            linkIcon: 'flaticon-view',
            img: 'assets/img/case-study/img2.jpg'
        }
    ]

}
class singleCaseStudyItemContent {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    // paragraphText2 : string;
    link : string;
    linkText : string;
    linkIcon : string;
    img : string;
}