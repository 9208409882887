<section class="testimonials-area bg-white">
    <div class="container">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title" style="font-size: 30px"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subTitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
                style="color: #30307f"
            >
                <span>What Our </span
                ><span
                    style="
                        background: -webkit-linear-gradient(
                            rgb(4, 79, 103),
                            rgb(230, 95, 50)
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    "
                >
                    Clients </span
                >{{ sectionTitleContent.title }}
            </h2>
            <p
                style="
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #4e5f6d;
                    max-width: 935px;
                    margin: 7px auto 32px;
                    font-family: Condensed;
                "
            >
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div
            class="testimonials-view-btn text-center"
            *ngFor="let Btn of testimonialsBtn"
        >
            <a routerLink="/{{ Btn.link }}" class="default-btn"
                ><i class="{{ Btn.icon }}"></i>{{ Btn.text }}<span></span
            ></a>
        </div>
    </div>
</section>
