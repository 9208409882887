import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss']
})
export class HomeoneBannerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    mainBannerContent: Content[] = [
        {
            title: 'Manufacturing with Industrial IoT and AI',
            paragraphText: 'Your Path to Smart, Efficient, and Data-Driven Production',
            paragraphText1: 'We help manufacturers maximize equipment efficiency, reduce downtime, improve product quality, and optimize resource allocation, leading to significant cost savings and a competitive edge in the market.',
            defaultBtnIcon: 'flaticon-structure',
            defaultBtnText: 'About Us',
            defaultBtnLink: 'about-us',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'Watch Video',
            videoBtnLink: 'https://www.youtube.com/watch?v=7RWmfIVDkN8'
        }
    ]

}
class Content {
    title : string;
    paragraphText : string;
    paragraphText1: string;
    defaultBtnIcon : string;
    defaultBtnText: string;
    defaultBtnLink : string;
    videoBtnIcon : string;
    videoBtnText: string;
    videoBtnLink : string;
}