<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets\img\LeanQbit_logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img
                        src="assets\img\LeanQbit_logo.png"
                        alt="logo"
                        style="width: 150px; height: 80px"
                    />
                </a>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>
                        <ul class="dropdown-menu"></ul>

                        <li class="nav-item">
                            <a
                                routerLink="/about-us"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About Us</a
                            >
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Products <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        routerLink="/Facto-Tool-Kit"
                                        class="nav-link"
                                        >Facto ToolKit<i
                                            class="bx bx-chevron-right"
                                        ></i
                                    ></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoIQ"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoIQ</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoMES"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoMES</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoCloud"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoCloudConnect</a
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Services <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="nav-link"
                                        >Basic
                                        <i class="bx bx-chevron-right"></i
                                    ></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                                >Ignition Installation &
                                                Licensing Assistance
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i
                                            ></a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ignition-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Help with downloading,
                                                        installing, and setting
                                                        up the Ignition platform
                                                        (Maker, Standard, or
                                                        Edge)</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ignition-platform-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Download, Install, and
                                                        Set Up Ignition Platform
                                                        (Maker, Standard, or
                                                        Edge)</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ignition-licensing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Guidance on selecting
                                                        the appropriate
                                                        licensing model (modules
                                                        like Perspective,
                                                        Vision, or Edge)</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Basic System Architecture Design
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/architecture-guidance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >High-level guidance on
                                                        designing an
                                                        architecture (single
                                                        server vs. distributed
                                                        system)</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/hardware-network-config"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Recommendations for
                                                        hardware and network
                                                        configurations</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Connecting to Devices
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/device-connection-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Step-by-step help
                                                        connecting PLCs, RTUs,
                                                        and edge devices
                                                        (OPC-UA, MQTT,
                                                        Modbus)</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/gateway-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Setting up a Gateway to
                                                        collect and process
                                                        data</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Ignition Basics Training
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/designer-interface"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Overview of Ignition's
                                                        Designer interface</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/basic-configuration"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Creating simple tags,
                                                        building a basic
                                                        dashboard, and
                                                        configuring alarms</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Basic Troubleshooting & Support
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/support-channels"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Live chat, email, or
                                                        call support for
                                                        setup-related issues</a
                                                    >
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/error-resolution"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >Assistance with common
                                                        error resolutions and
                                                        log analysis</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Access to Resources
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/learning-resources"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                        >User manuals, video
                                                        tutorials, and webinars
                                                        covering IIoT and SCADA
                                                        fundamentals</a
                                                    >
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <!-- interimediate -->

                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="nav-link"
                                    >
                                        Intermediate
                                        <i class="bx bx-chevron-right"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Custom SCADA Dashboards
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-dashboard-design"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Developing
                                                        process-specific
                                                        dashboards with
                                                        real-time visualization
                                                        for KPIs like OEE,
                                                        machine states, or
                                                        production rates
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/scripting-best-practices"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Building user-friendly
                                                        HMIs (Human-Machine
                                                        Interfaces) using
                                                        Ignition’s Vision or
                                                        Perspective modules
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                IIoT Device Integration
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-protocol-integration"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Configuring Ignition
                                                        Edge for edge computing
                                                        and data collection from
                                                        IoT sensors.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/edge-computing-strategies"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Enabling data streams
                                                        over MQTT Sparkplug B
                                                        for efficient and
                                                        lightweight
                                                        communication.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Database Integration & Historian
                                                Setup
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-historian-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Connecting Ignition to
                                                        SQL databases (MySQL,
                                                        PostgreSQL, etc.) for
                                                        historical data logging
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-reporting"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Implementing Ignition’s
                                                        Tag Historian module for
                                                        seamless data storage
                                                        and retrieval.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <!-- <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Intermediate Services (Project
                                                Development Support)
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                        </li> -->

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Custom Scripting & Automation
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-historian-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Python scripting for
                                                        advanced data
                                                        manipulation, event
                                                        handling, and automating
                                                        workflows within
                                                        Ignition
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-reporting"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Configuring automated
                                                        report generation using
                                                        the Reporting module.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Industrial Protocol Integration
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-historian-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Advanced configuration
                                                        for protocols like
                                                        BACnet, DNP3, and IEC
                                                        60870 for specific
                                                        industries
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Security Hardening
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-historian-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Implementing role-based
                                                        security and encrypted
                                                        communication between
                                                        Ignition servers,
                                                        clients, and devices.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Team Training
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-historian-setup"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Intermediate training
                                                        sessions on Ignition’s
                                                        Designer, Gateway, and
                                                        Tag systems
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-reporting"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Hands-on exercises for
                                                        developing custom SCADA
                                                        or IIoT projects.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <!-- Advanced Services Dropdown -->
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        class="nav-link"
                                    >
                                        Advanced
                                        <i class="bx bx-chevron-right"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Services Offered
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/enterprise-integration"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Turnkey Solutions:
                                                        Complete Ignition
                                                        project development and
                                                        deployment.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/custom-module-development"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Advanced Training:
                                                        Tailored programs for
                                                        Ignition Edge,
                                                        Perspective, and
                                                        advanced scripting.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-security"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        24/7 Support: Priority
                                                        support for
                                                        mission-critical
                                                        systems.
                                                    </a>
                                                </li>

                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/advanced-security"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Post-Implementation
                                                        Maintenance: Continuous
                                                        monitoring, updates, and
                                                        upgrades.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Key App Features
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Real-time collaboration
                                                        (video calls, shared
                                                        dashboards).
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        SLA-based support
                                                        escalation.
                                                    </a>
                                                </li>

                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Custom analytics to
                                                        measure system
                                                        performance and ROI.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <!-- <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Advanced Services (End-to-End
                                                Implementation)
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                        </li> -->

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                End-to-End Ignition Solution
                                                Design
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Comprehensive
                                                        architecture for
                                                        distributed or redundant
                                                        Ignition Gateways.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Multi-site deployment
                                                        with centralized
                                                        monitoring and control.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Advanced IIoT Implementation
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Deploying large-scale
                                                        MQTT infrastructure with
                                                        Ignition Edge for edge
                                                        device management.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Real-time, bidirectional
                                                        data exchange between
                                                        Ignition and enterprise
                                                        systems like ERP, MES,
                                                        or CMMS.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Complex SCADA Development
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Designing and deploying
                                                        custom SCADA projects
                                                        for process industries
                                                        (e.g., oil & gas, food &
                                                        beverage,
                                                        pharmaceuticals).
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Integration of advanced
                                                        analytics and machine
                                                        learning for predictive
                                                        maintenance or process
                                                        optimization.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Advanced Historian Setup & Data
                                                Analytics
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Configuring Ignition
                                                        with cloud services
                                                        (AWS, Azure, Google
                                                        Cloud) for big data
                                                        storage and analytics.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Creating custom
                                                        analytics dashboards and
                                                        reports using scripting
                                                        and external tools
                                                        (e.g., Python or Power
                                                        BI).
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Redundancy & High Availability
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Setting up redundant
                                                        Gateways to ensure
                                                        uninterrupted
                                                        operations.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Disaster recovery
                                                        planning and testing.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Custom IIoT Applications
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Developing bespoke IIoT
                                                        solutions using
                                                        Ignition’s Perspective
                                                        module for mobile and
                                                        web-friendly apps.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Integration of AI-driven
                                                        insights using external
                                                        APIs.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Training & Support for Advanced
                                                Teams
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Training engineering
                                                        teams on Ignition’s
                                                        advanced modules (e.g.,
                                                        Perspective, WebDev, and
                                                        MQTT Transmission).
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        On-demand workshops and
                                                        certifications for
                                                        in-house developers.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                href="javascript:void(0)"
                                                class="nav-link"
                                            >
                                                Ongoing System Optimization &
                                                Maintenance
                                                <i
                                                    class="bx bx-chevron-right"
                                                ></i>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/predictive-maintenance"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        SLA-based 24/7 technical
                                                        support for
                                                        enterprise-grade
                                                        solutions.
                                                    </a>
                                                </li>
                                                <li class="nav-item">
                                                    <a
                                                        routerLink="/ml-data-processing"
                                                        class="nav-link"
                                                        routerLinkActive="active"
                                                        [routerLinkActiveOptions]="{
                                                            exact: true
                                                        }"
                                                    >
                                                        Proactive system
                                                        monitoring, updates, and
                                                        scalability
                                                        enhancements.
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/inductive-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Inductive Automation (Ignition)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/aws"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Amazon Web Service (Saas)</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Company <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/Client-Testimonials"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Testimonials</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Contact</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"
                                ><i class="flaticon-right"></i>Get Started<span
                                ></span
                            ></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"
                                ><i class="flaticon-right"></i>Get Started<span
                                ></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
