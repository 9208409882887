<div class="container">
    <div class="left-section">
        <h1 class="headline">
            POWER UP<br />
            YOUR FACTORY<br />
            WITH <span class="highlight">Lean</span
            ><span class="highlight-orange">Q</span
            ><span class="highlight">ubit</span>
        </h1>

        <h2 class="subtitle">Try LeanQubit Free for 30 Days</h2>

        <p class="description">
            Experience seamless industrial automation, real-time monitoring and
            predictive maintenance for maximum efficiency
        </p>

        <h3 class="features-title">What's Included in Your Free Trial:</h3>

        <ul class="features-list">
            <li class="feature-item">
                <span class="check-icon">✓</span>
                Full access to LeanQubit's SCADA platform
            </li>
            <li class="feature-item">
                <span class="check-icon">✓</span>
                Real-time production insights and efficiency metrics
            </li>
            <li class="feature-item">
                <span class="check-icon">✓</span>
                Automated alerts for downtime and performance tracking
            </li>
            <li class="feature-item">
                <span class="check-icon">✓</span>
                Seamless integration with existing manufacturing systems
            </li>
        </ul>
    </div>

    <div class="right-section">
        <div class="form-container">
            <h1 class="form-title">Start Your Free Trial</h1>

            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="name-row">
                    <div class="form-group half-width">
                        <label class="required">First Name</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="firstName"
                            [ngClass]="{
                                'is-invalid': isFieldInvalid('firstName')
                            }"
                        />
                        <div
                            *ngIf="isFieldInvalid('firstName')"
                            class="invalid-feedback"
                        >
                            <div *ngIf="f.firstName.errors?.required">
                                First name is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group half-width">
                        <label class="required">Last Name</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="lastName"
                            [ngClass]="{
                                'is-invalid': isFieldInvalid('lastName')
                            }"
                        />
                        <div
                            *ngIf="isFieldInvalid('lastName')"
                            class="invalid-feedback"
                        >
                            <div *ngIf="f.lastName.errors?.required">
                                Last name is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        formControlName="email"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('email') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('email')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.email.errors?.required">
                            Email is required
                        </div>
                        <div
                            *ngIf="
                                f.email.errors?.email || f.email.errors?.pattern
                            "
                        >
                            Please enter a valid email address
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Phone</label>
                    <input
                        type="tel"
                        class="form-control"
                        formControlName="phone"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('phone') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('phone')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.phone.errors?.required">
                            Phone number is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Company</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="company"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('company') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('company')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.company.errors?.required">
                            Company is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Additional Notes</label>
                    <textarea
                        class="form-control"
                        formControlName="additionalNotes"
                        rows="4"
                    ></textarea>
                </div>

                <div *ngIf="showFormError" class="alert alert-danger">
                    Please correct the errors in the form before submitting.
                </div>

                <button
                    type="submit"
                    class="submit-btn"
                    [disabled]="submitted && contactForm.invalid"
                >
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div>

<!-- Second section -->

<div class="success-stories-container">
    <h1 class="success-title">SUCCESS STORIES</h1>
    <div class="title-underline"></div>

    <div class="success-subtitle">
        See why <span class="highlight">Lean</span
        ><span class="highlight-orange">Q</span
        ><span class="highlight">ubit</span> is trusted by organizations around
        the globe
    </div>

    <div class="client-cards-container">
        <div class="client-card">
            <h2 class="client-heading">Client 1: Solar Cell Manufacturing</h2>
            <p class="client-description">
                Increased processing efficiency by 10% while reducing total
                downtime by 2%.
            </p>
            <a href="testimonialcase1" class="read-more-link">Read More</a>
        </div>

        <div class="client-card">
            <h2 class="client-heading">Client 2: Water Treatment</h2>
            <p class="client-description">
                Reduced labor costs by 25% and provided 24-hour real-time
                monitoring.
            </p>
            <a href="testimonialcase2" class="read-more-link">Read More</a>
        </div>

        <div class="client-card">
            <h2 class="client-heading">Client 3: Paper Production</h2>
            <p class="client-description">
                Achieved a 5% reduction in downtime and a 30% reduction in labor
                costs due to data entry.
            </p>
            <a href="testimonialcase4" class="read-more-link">Read More</a>
        </div>
    </div>
</div>

<!-- Third content -->
<div class="hero-section">
    <div class="title-container">
        <h1 class="headlines">
            <span class="black-text">UNLOCK YOUR</span><br />
            <span class="black-text">OPERATIONAL</span
            ><span class="teal-text"> POTENTIAL</span>
        </h1>
    </div>

    <!-- First row (standard) -->
    <div class="content-wrapper">
        <div class="text-content">
            <div class="tracking-section">
                <h2 class="subheadline">Real-Time<br />Performance Tracking</h2>

                <p class="description">
                    Track shift target vs. actual performance in real time.
                    Identify deviations early, ensuring process efficiency
                </p>
            </div>
        </div>

        <div class="image-content">
            <img
                src="assets/img/OptebizImage/First section image.png"
                alt="Dashboard on laptop"
                class="laptop-image"
            />
        </div>
    </div>

    <!-- Second row (reversed) -->
    <div class="content-wrapper row-reverse">
        <div class="text-content">
            <div class="tracking-section">
                <h2 class="subheadline">Seamless<br />Process Monitoring</h2>

                <p class="description">
                    provide clear visualization of process sequences, cycle
                    times, OEE performance, and ensure smooth operations from
                    ​assembly to final output
                </p>
            </div>
        </div>

        <div class="image-content">
            <img
                src="assets/img/OptebizImage/secondimage.png"
                alt="Data analysis dashboard"
                class="laptop-image"
            />
        </div>
    </div>

    <!-- Third row (standard) -->
    <div class="content-wrapper">
        <div class="text-content">
            <div class="tracking-section">
                <h2 class="subheadline">
                    Minimize Downtime,<br />Maximize Efficiency
                </h2>

                <p class="description">
                    Identify top downtime causes, analyze shiftwise occurrences,
                    and reduce costly disruptions with predictive maintenance
                </p>
            </div>
        </div>

        <div class="image-content">
            <img
                src="assets/img/OptebizImage/Third section.png"
                alt="Alert system interface"
                class="laptop-image"
            />
        </div>
    </div>

    <!-- Fourth row (reversed) -->
    <div class="content-wrapper row-reverse">
        <div class="text-content">
            <div class="tracking-section">
                <h2 class="subheadline">Optimize<br />Production Efficiency</h2>

                <p class="description">
                    Monitor performance, detect failures in real time, and
                    automate maintenance to reduce downtime and costs
                </p>
            </div>
        </div>

        <div class="image-content">
            <img
                src="assets/img/OptebizImage/Fourth section.png"
                alt="Reporting dashboard"
                class="laptop-image"
            />
        </div>
    </div>
</div>

<!-- section How its work -->
<div class="how-it-works">
    <div class="how-it-works-container">
        <div class="steps-content">
            <h2 class="section-title">HOW IT WORKS</h2>
            <ul class="steps-list">
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e3f2fd"
                                stroke="#2196f3"
                                stroke-width="2"
                            />
                            <path
                                d="M30 40 L50 30 L70 40 L70 60 L50 70 L30 60Z"
                                fill="#2196f3"
                                stroke="#1565c0"
                                stroke-width="2"
                            />
                            <circle cx="50" cy="50" r="10" fill="#1565c0" />
                            <path
                                d="M40 35 L60 35 M40 65 L60 65"
                                stroke="#1565c0"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>RESOURCE MANAGEMENT</h3>
                        <p>
                            Connect your equipment, materials, and personnel
                            within a unified system that monitors all your
                            manufacturing resources in real-time.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e8f5e9"
                                stroke="#4caf50"
                                stroke-width="2"
                            />
                            <path
                                d="M25 50 L45 30 M45 30 L65 30 M65 30 L75 50 M75 50 L65 70 M65 70 L45 70 M45 70 L25 50"
                                fill="none"
                                stroke="#4caf50"
                                stroke-width="3"
                            />
                            <circle cx="25" cy="50" r="8" fill="#2e7d32" />
                            <circle cx="45" cy="30" r="8" fill="#2e7d32" />
                            <circle cx="65" cy="30" r="8" fill="#2e7d32" />
                            <circle cx="75" cy="50" r="8" fill="#2e7d32" />
                            <circle cx="65" cy="70" r="8" fill="#2e7d32" />
                            <circle cx="45" cy="70" r="8" fill="#2e7d32" />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>PROCESS INTEGRATION</h3>
                        <p>
                            how your production lines, processes, and
                            capabilities interact through comprehensive
                            monitoring and data collection.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#fff3e0"
                                stroke="#ff9800"
                                stroke-width="2"
                            />
                            <path
                                d="M30 50 L45 35 L60 50 L45 65Z"
                                fill="#ff9800"
                            />
                            <path
                                d="M50 20 L70 40 L50 60 L30 40Z"
                                fill="none"
                                stroke="#ef6c00"
                                stroke-width="3"
                            />
                            <circle cx="50" cy="40" r="5" fill="#ef6c00" />
                            <path
                                d="M40 70 L60 70"
                                stroke="#ef6c00"
                                stroke-width="3"
                                stroke-linecap="round"
                            />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>OPERATIONAL EXCELLENCE</h3>
                        <p>
                            Take action by managing operations efficiently,
                            tracking downtime, and ensuring production quality
                            through sequential operational steps.
                        </p>
                    </div>
                </li>
                <li class="step-item">
                    <div class="step-icon">
                        <svg
                            viewBox="0 0 100 100"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <circle
                                cx="50"
                                cy="50"
                                r="45"
                                fill="#e1f5fe"
                                stroke="#03a9f4"
                                stroke-width="2"
                            />
                            <path
                                d="M30 70 L30 40 L45 40 L45 70 Z"
                                fill="#03a9f4"
                            />
                            <path
                                d="M50 70 L50 30 L65 30 L65 70 Z"
                                fill="#03a9f4"
                            />
                            <path
                                d="M25 75 L75 75"
                                stroke="#0288d1"
                                stroke-width="3"
                            />
                            <circle cx="70" cy="35" r="8" fill="#0288d1" />
                        </svg>
                    </div>
                    <div class="step-content">
                        <h3>REPORTING & INSIGHTS</h3>
                        <p>
                            Scale your understanding through detailed reports
                            that combine material quality metrics, production
                            data, and operational performance.
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="device-image">
            <video
                id="videoPlayer"
                autoplay
                loop
                muted
                playsinline
                style="
                    display: block;
                    max-width: 100%;
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                    position: relative;
                    z-index: 2;
                "
            >
                <source
                    src="assets/img/OptebizImage/processflow1.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
        </div>
    </div>
</div>

<!-- New Faq  -->
<section class="page-title">
    <div class="containers mb-5">
        <div class="mt-5">
            <h1
                class="h1cl"
                style="
                    color: #1d9395;
                    font-size: 68px;
                    font-family: Inter, sans-serif;
                "
            >
                Frequently Asked
            </h1>
        </div>
        <div class="faq-subtitle" data-aos="fade-up">
            <a href="/faq">
                <span style="color: #333">Visit our</span> FAQ
                <span style="color: #333">for more info</span></a
            >
        </div>
        <h2
            data-aos="zoom-in"
            class="mt-5 aos-init aos-animate"
            data-v-116f5dc2=""
            style="color: var(--mainColor)"
        >
            General Questions
        </h2>
        <details
            data-aos="zoom-in"
            data-v-116f5dc2=""
            data-v-bcf7a0d4=""
            class="aos-init aos-animate"
        >
            <summary data-v-bcf7a0d4="">
                <span
                    aria-hidden="true"
                    class="material-design-icon help-circle-outline-icon"
                    role="img"
                    data-v-bcf7a0d4=""
                >
                    <svg
                        fill="currentColor"
                        class="material-design-icon__svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                        ></path>
                    </svg>
                </span>
                <h3 data-v-bcf7a0d4="">
                    Q1. Why do we need MES Solution if we already have an ERP
                    Solution?
                </h3>
            </summary>
            <div data-v-bcf7a0d4="">
                <p data-v-116f5dc2="">
                    <strong
                        >While ERP solutions excel in managing overall business
                        processes,</strong
                    >
                    MES serves as a specialized layer designed to optimize and
                    control manufacturing operations. MES provides real-time
                    visibility into the production process, allowing for precise
                    monitoring, tracking, and control of shop floor activities.
                    It bridges the gap between planning and execution, enhancing
                    efficiency, reducing lead times, and ensuring quality
                    control in manufacturing. In essence, combining ERP with MES
                    creates a comprehensive ecosystem that not only manages the
                    entire shop floor environment but also fine-tunes and
                    maximizes the efficiency of the manufacturing process.
                </p>
            </div>
            <style>
                p {
                    font-family: Inter, sans-serif;
                    font-size: 20px;
                    font-weight: 400;
                    color: #333; /* Dark gray for readability */
                    line-height: 1.6;
                }

                p strong {
                    font-weight: 700;
                    color: #1d9395; /* Highlighted text */
                }

                /* Responsive Adjustments */

                /* Tablet Devices (Up to 1024px) */
                @media (max-width: 1024px) {
                    p {
                        font-size: 18px !important;
                        text-align: center !important;
                        max-width: 90% !important;
                    }
                }

                /* Mobile Devices (Up to 768px) */
                @media (max-width: 768px) {
                    p {
                        font-size: 16px !important;
                        text-align: center !important;
                        line-height: 1.5 !important;
                    }
                }

                /* Small Mobile Devices (Up to 480px) */
                @media (max-width: 480px) {
                    p {
                        font-size: 14px !important;
                        text-align: left !important;
                        padding: 0 10px;
                    }
                }
            </style>
        </details>
        <details
            data-aos="zoom-in"
            data-v-116f5dc2=""
            data-v-bcf7a0d4=""
            class="aos-init aos-animate"
        >
            <summary data-v-bcf7a0d4="">
                <span
                    aria-hidden="true"
                    class="material-design-icon help-circle-outline-icon"
                    role="img"
                    data-v-bcf7a0d4=""
                >
                    <svg
                        fill="currentColor"
                        class="material-design-icon__svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                        >
                            <!---->
                        </path>
                    </svg>
                </span>
                <h3 data-v-bcf7a0d4="">
                    Q2. Will FactoMES Solution be fully compatible with my ERP
                    Solution
                </h3>
            </summary>
            <div data-v-bcf7a0d4="">
                <p data-v-116f5dc2="">
                    <strong
                        >Yes, Facto Tools seamlessly integrates with a diverse
                        range of ERP systems,</strong
                    >
                    including SAP, Oracle, JDE, Sage, and various others,
                    ensuring a comprehensive collaboration within your existing
                    technological ecosystem This interoperability is designed to
                    enhance efficiency and minimize disruptions, making Facto
                    Tools a versatile solution for businesses with different ERP
                    preferences. In the integration process, approximately 98%
                    of the data exchange pertains to critical manufacturing
                    components. This includes the synchronization of schedules,
                    work orders, production and downtime values, and Bill of
                    Materials (BOMs), ensuring that Facto Tools becomes a
                    synchronized extension of your ERP, streamlining
                    manufacturing processes and bolstering data accuracy for
                    optimal operational performance.
                </p>
            </div>
        </details>
        <details
            data-aos="zoom-in"
            data-v-116f5dc2=""
            data-v-bcf7a0d4=""
            class="aos-init aos-animate"
        >
            <summary data-v-bcf7a0d4="">
                <span
                    aria-hidden="true"
                    class="material-design-icon help-circle-outline-icon"
                    role="img"
                    data-v-bcf7a0d4=""
                >
                    <svg
                        fill="currentColor"
                        class="material-design-icon__svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                        >
                            <!---->
                        </path>
                    </svg>
                </span>
                <h3 data-v-bcf7a0d4="">
                    Q3. What Measurable Results should I expect by partnering
                    with LeanQubit?
                </h3>
            </summary>
            <div data-v-bcf7a0d4="">
                <p data-v-116f5dc2="">
                    <strong
                        >Choosing the ideal solution and a strategic
                        implementation partner is pivotal in reaping significant
                        rewards for your organization.</strong
                    >
                    This thoughtful selection process not only enhances customer
                    satisfaction by ensuring on-time deliveries but also
                    contributes to substantial reductions in downtime and
                    wastage. The synergy between the right solution and a
                    competent implementation partner goes beyond mere efficiency
                    – it establishes a robust platform that grants your
                    organization maximum control over manufacturing activities.
                    Importantly, this is achieved without burdening your
                    existing infrastructure with unnecessary resources, ensuring
                    a seamless integration that elevates operational excellence
                    while optimizing resource utilization.
                </p>
            </div>
        </details>
        <details
            data-aos="zoom-in"
            data-v-116f5dc2=""
            data-v-bcf7a0d4=""
            class="aos-init aos-animate"
        >
            <summary data-v-bcf7a0d4="">
                <span
                    aria-hidden="true"
                    class="material-design-icon help-circle-outline-icon"
                    role="img"
                    data-v-bcf7a0d4=""
                >
                    <svg
                        fill="currentColor"
                        class="material-design-icon__svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                        ></path>
                    </svg>
                </span>
                <h3 data-v-bcf7a0d4="">
                    Q4. What Skill Sets are needed to use Facto Tools?
                </h3>
            </summary>
            <div data-v-bcf7a0d4="">
                <p data-v-116f5dc2="">
                    <strong
                        >The efficiency of any advanced solution is intricately
                        tied to the competence of the individuals operating
                        it.</strong
                    >
                    Recognizing this, LeanQubit goes beyond providing a
                    cutting-edge solution; we offer comprehensive support
                    through detailed training materials. These materials are
                    designed to aid in the training of the right individuals
                    within your organization. Moreover, our tools are designed
                    with user-friendliness in mind, adhering to industry
                    standards. This means that in many cases, your existing team
                    can seamlessly integrate and navigate the solution without
                    the need for specialized training.
                </p>
            </div>
        </details>
        <details
            data-aos="zoom-in"
            data-v-116f5dc2=""
            data-v-bcf7a0d4=""
            class="aos-init aos-animate"
        >
            <summary data-v-bcf7a0d4="">
                <span
                    aria-hidden="true"
                    class="material-design-icon help-circle-outline-icon"
                    role="img"
                    data-v-bcf7a0d4=""
                >
                    <svg
                        fill="currentColor"
                        class="material-design-icon__svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path
                            d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z"
                        ></path>
                    </svg>
                </span>
                <h3 data-v-bcf7a0d4="">
                    Q5. What is the Implementation Cost for Facto MES?
                </h3>
            </summary>
            <div data-v-bcf7a0d4="">
                <p data-v-116f5dc2="">
                    <strong
                        >Determining the software pricing and tailoring the
                        implementation process is a customized journey at
                        LeanQubit,</strong
                    >
                    shaped by your unique requirements. Our approach involves a
                    comprehensive assessment, enabling us to gain a thorough
                    understanding of your specific needs and the intricacies of
                    your existing systems. This detailed evaluation serves as
                    the foundation for presenting you with the most suitable
                    solution set and pricing options that align with your budget
                    constraints. At LeanQubit, transparency is key, and our
                    commitment is to deliver a bespoke solution that not only
                    addresses your current needs but also anticipates and
                    accommodates your future growth. We prioritize a
                    collaborative and consultative process, ensuring that the
                    proposed pricing and implementation plan meets your
                    organizational objectives and financial considerations.
                </p>
            </div>
        </details>
    </div>
</section>
