import { Component } from "@angular/core";

@Component({
    selector: "app-quality-control",
    templateUrl: "./quality-control.component.html",
    styleUrls: ["./quality-control.component.scss"],
})
export class QualityControlComponent {
    constructor() {}

    ngOnInit(): void {}

    pageTitleArea: pageTitle[] = [
        {
            title: "Facto MES",
            subTitle: "Quality Control",
        },
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: "/assets/img/OptebizImage/Quality.png",
        },
    ];
    viewDetailsDesc: DetailsDesc[] = [
        {
            subTitle: "Quality Control",
            title1: "Quality Control",
            paragraphText1:
                "Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.",
            paragraphText2:
                "This could involve monitoring equipment performance, production rates, quality control, and other relevant metrics. The goal is to identify and address any issues that may arise in real-time, minimizing downtime and maximizing productivity. Its like having a watchful eye over the entire production process to keep things on track.",
            title2: "Important Facts",
            title3: "Application Areas",
            title4: "Technologies That We Use",
            img1: "assets/img/OptebizImage/quality_policy.webp",

            img2: "assets/img/OptebizImage/Spccolor.jpg",
        },
    ];
    viewDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: "Quality Standards",
        },
        {
            title: "Inspection and Testing",
        },
        {
            title: "Process Control",
        },
        {
            title: "Statistical Process Control (SPC)",
        },
        {
            title: "Quality Control Charts",
        },
        {
            title: "Quality Control Plan",
        },
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: "Production Monitoring",
            link: "production-montioring",
        },
        {
            title: "Workorder Management",
            link: "work-order-management",
        },
        {
            title: "Inventory Management",
            link: "inventory-management",
        },
        {
            title: "Downtime Management",
            link: "downtime-management",
        },
        {
            title: "Reporting and Analytics",
            link: "reportand-analytics",
        },
        //   {
        //       title: 'Paperless Manufacturing',
        //       link: 'paperless-manufacturing'
        //   },
        //    {
        //       title: 'Machine Integration',
        //       link: 'machine-integration'
        //   },
        //   {
        //       title: 'Maintenance Management',
        //       link: 'maintenance-management'
        //   }
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: "PDF Download",
            icon: "bx bxs-file-pdf",
            link: "view-details",
        },
        {
            title: "view Details.txt",
            icon: "bx bxs-file-txt",
            link: "view-details",
        },
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: "bx bx-user-pin",
            title: "Phone",
            subTitle: "+14199133878",
        },
        {
            icon: "bx bx-map",
            title: "Location",
            subTitle: "LeanQubit Inc. 2600 Dorr Street Toledo, OH 43606",
        },
        {
            icon: "bx bx-envelope",
            title: "Email",
            subTitle: "sales@leanqubit.ai",
        },
    ];
}
class pageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}
