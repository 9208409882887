<section class="cs_hero cs_style_2 position-relative" id="home">
    <div class="container">
        <div class="cs_product_title">
            <h1><span>FactoPlan</span> Operations Revolution</h1>
        </div>
        <div class="row align-items-center cs_gap_y_40 cs_reverse_md">
            <div class="col-lg-7">
                <div class="cs_hero_text">
                    <h1 class="cs_hero_title">
                        <!-- <div class="main-title">
                            Revolutionize Your Operations with FactoPlan
                        </div> -->
                        <div class="subtitle">
                            Finite Capacity Planning Made Simple
                        </div>
                    </h1>
                    <p class="cs_hero_subtitle">
                        Optimize Resources, Reduce Bottlenecks, and Maximize
                        Efficiency with FactoPlan.
                    </p>
                    <div
                        class="cs_btn_group cs_version_2 wow fadeIn"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.25s"
                    >
                        <a
                            href="/BookDemo"
                            class="cs_btn cs_bg_white cs_dark_hover"
                            >Product Demo</a
                        >
                        <a
                            href="/TryItNow"
                            class="cs_btn cs_bg_accent cs_dark_hover"
                            >Start Free Trial</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="cs_hero_thumb">
                    <video
                        id="videoPlayer"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="max-width: 100%; height: auto"
                    >
                        <source
                            src="assets/img/OptebizImage/black background factoplan.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_hero_shape1"></div>
    <div class="cs_hero_shape2">
        <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
    </div>
    <div class="cs_hero_shape3"></div>
</section>
<!-- Second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="container">
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes dataFlow {
                                0% {
                                    stroke-dashoffset: 1000;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes pulse {
                                0% {
                                    transform: scale(1);
                                }
                                50% {
                                    transform: scale(1.1);
                                }
                                100% {
                                    transform: scale(1);
                                }
                            }
                            .flow-line {
                                stroke-dasharray: 1000;
                                animation: dataFlow 3s linear infinite;
                            }
                            .system {
                                animation: pulse 2s infinite ease-in-out;
                            }
                        </style>

                        <!-- MES System (Left) -->
                        <g class="system" transform="translate(0,0)">
                            <rect
                                x="5"
                                y="30"
                                width="30"
                                height="40"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                                rx="2"
                            />
                            <text
                                x="20"
                                y="55"
                                font-size="10"
                                fill="#1d9395"
                                text-anchor="middle"
                            >
                                MES
                            </text>
                        </g>

                        <!-- ERP System (Right) -->
                        <g class="system" transform="translate(0,0)">
                            <rect
                                x="65"
                                y="30"
                                width="30"
                                height="40"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                                rx="2"
                            />
                            <text
                                x="80"
                                y="55"
                                font-size="10"
                                fill="#1d9395"
                                text-anchor="middle"
                            >
                                ERP
                            </text>
                        </g>

                        <!-- Connection Lines -->
                        <path
                            class="flow-line"
                            d="M35 40 C45 40, 55 40, 65 40 
                                 M65 60 C55 60, 45 60, 35 60"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                            stroke-linecap="round"
                        />

                        <!-- Data Points -->
                        <circle cx="45" cy="40" r="2" fill="#1d9395">
                            <animate
                                attributeName="opacity"
                                values="0;1;0"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </circle>
                        <circle cx="55" cy="60" r="2" fill="#1d9395">
                            <animate
                                attributeName="opacity"
                                values="0;1;0"
                                dur="2s"
                                repeatCount="indefinite"
                                begin="1s"
                            />
                        </circle>
                    </svg>
                    <h3 class="cs_iconbox_title">Integrate with MES and ERP</h3>
                    <p class="cs_iconbox_subtitle">
                        Seamlessly connect FactoPlan with your existing
                        Manufacturing Execution System and Enterprise Resource
                        Planning system
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <!-- Calendar Base -->
                        <rect
                            x="20"
                            y="25"
                            width="60"
                            height="55"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="3"
                            rx="4"
                        >
                            <animate
                                attributeName="stroke-dasharray"
                                values="0,230;230,0"
                                dur="3s"
                                repeatCount="indefinite"
                            />
                        </rect>

                        <!-- Calendar Top -->
                        <path
                            d="M30 15 L30 30 M70 15 L70 30"
                            stroke="#1d9395"
                            stroke-width="3"
                            stroke-linecap="round"
                        />

                        <!-- Check Mark -->
                        <path
                            d="M35 50 L45 60 L65 40"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="3"
                            stroke-linecap="round"
                        >
                            <animate
                                attributeName="stroke-dashoffset"
                                values="50;0"
                                dur="1.5s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="stroke-dasharray"
                                values="50"
                                dur="1.5s"
                                repeatCount="indefinite"
                            />
                        </path>

                        <!-- Circular Progress -->
                        <circle
                            cx="50"
                            cy="50"
                            r="35"
                            fill="none"
                            stroke="#e6f3f3"
                            stroke-width="2"
                        />

                        <circle
                            cx="50"
                            cy="50"
                            r="35"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        >
                            <animate
                                attributeName="stroke-dasharray"
                                values="0 220;220 220"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                            <animate
                                attributeName="stroke-dashoffset"
                                values="0;-220"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </circle>

                        <!-- Small Dots -->
                        <circle cx="50" cy="85" r="2" fill="#1d9395">
                            <animate
                                attributeName="opacity"
                                values="0;1;0"
                                dur="1.5s"
                                repeatCount="indefinite"
                            />
                        </circle>
                        <circle cx="60" cy="85" r="2" fill="#1d9395">
                            <animate
                                attributeName="opacity"
                                values="0;1;0"
                                dur="1.5s"
                                begin="0.5s"
                                repeatCount="indefinite"
                            />
                        </circle>
                        <circle cx="40" cy="85" r="2" fill="#1d9395">
                            <animate
                                attributeName="opacity"
                                values="0;1;0"
                                dur="1.5s"
                                begin="1s"
                                repeatCount="indefinite"
                            />
                        </circle>
                    </svg>
                    <h3 class="cs_iconbox_title">Streamline Scheduling</h3>
                    <p class="cs_iconbox_subtitle">
                        Use FactoPlan's finite capacity planning algorithm to
                        create optimized production schedules that minimize
                        bottlenecks and downtime.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <!-- Outer Circle Container -->
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                            opacity="0.2"
                        />

                        <!-- Expanding/Contracting Arrows -->
                        <g>
                            <!-- Top Right Arrow -->
                            <path
                                d="M50 30 L70 30 L70 50"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="3"
                                stroke-linecap="round"
                            >
                                <animate
                                    attributeName="stroke-dasharray"
                                    values="0,40;40,0"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>

                            <!-- Bottom Left Arrow -->
                            <path
                                d="M50 70 L30 70 L30 50"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="3"
                                stroke-linecap="round"
                            >
                                <animate
                                    attributeName="stroke-dasharray"
                                    values="0,40;40,0"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </path>
                        </g>

                        <!-- Central Resource Elements -->
                        <g>
                            <!-- Rotating Inner Circle -->
                            <circle
                                cx="50"
                                cy="50"
                                r="20"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            >
                                <animate
                                    attributeName="stroke-dasharray"
                                    values="0,126;126,0"
                                    dur="3s"
                                    repeatCount="indefinite"
                                />
                                <animateTransform
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 50 50"
                                    to="360 50 50"
                                    dur="8s"
                                    repeatCount="indefinite"
                                />
                            </circle>

                            <!-- Pulsing Center -->
                            <circle cx="50" cy="50" r="10" fill="#1d9395">
                                <animate
                                    attributeName="r"
                                    values="8;12;8"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                                <animate
                                    attributeName="fill-opacity"
                                    values="1;0.6;1"
                                    dur="2s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                        </g>

                        <!-- Efficiency Indicators -->
                        <g>
                            <circle cx="50" cy="20" r="3" fill="#1d9395">
                                <animate
                                    attributeName="opacity"
                                    values="0.3;1;0.3"
                                    dur="1.5s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                            <circle cx="50" cy="80" r="3" fill="#1d9395">
                                <animate
                                    attributeName="opacity"
                                    values="0.3;1;0.3"
                                    dur="1.5s"
                                    begin="0.5s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                            <circle cx="20" cy="50" r="3" fill="#1d9395">
                                <animate
                                    attributeName="opacity"
                                    values="0.3;1;0.3"
                                    dur="1.5s"
                                    begin="1s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                            <circle cx="80" cy="50" r="3" fill="#1d9395">
                                <animate
                                    attributeName="opacity"
                                    values="0.3;1;0.3"
                                    dur="1.5s"
                                    begin="0.75s"
                                    repeatCount="indefinite"
                                />
                            </circle>
                        </g>
                    </svg>
                    <h3 class="cs_iconbox_title">Maximize Resources</h3>
                    <p class="cs_iconbox_subtitle">
                        Make the most of your resources by allocating manpower,
                        machinery, and materials effectively.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <!-- Base Grid -->
                            <path
                                d="M20 80 H80 V20"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                                opacity="0.3"
                            />

                            <!-- Animated Growth Line -->
                            <path
                                d="M20 60 Q35 55, 45 45 T80 25"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="3"
                                stroke-linecap="round"
                            >
                                <animate
                                    attributeName="stroke-dasharray"
                                    values="0,200;200,0"
                                    dur="3s"
                                    repeatCount="indefinite"
                                />
                            </path>

                            <!-- Analysis Points -->
                            <g>
                                <!-- Point 1 -->
                                <circle cx="35" cy="55" r="3" fill="#1d9395">
                                    <animate
                                        attributeName="r"
                                        values="2;4;2"
                                        dur="2s"
                                        repeatCount="indefinite"
                                    />
                                </circle>

                                <!-- Point 2 -->
                                <circle cx="55" cy="40" r="3" fill="#1d9395">
                                    <animate
                                        attributeName="r"
                                        values="2;4;2"
                                        dur="2s"
                                        begin="0.5s"
                                        repeatCount="indefinite"
                                    />
                                </circle>

                                <!-- Point 3 -->
                                <circle cx="75" cy="28" r="3" fill="#1d9395">
                                    <animate
                                        attributeName="r"
                                        values="2;4;2"
                                        dur="2s"
                                        begin="1s"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                            </g>

                            <!-- Scanning Line -->
                            <line
                                x1="20"
                                y1="20"
                                x2="20"
                                y2="80"
                                stroke="#1d9395"
                                stroke-width="2"
                                opacity="0.5"
                            >
                                <animateTransform
                                    attributeName="transform"
                                    type="translate"
                                    values="0 0; 60 0; 0 0"
                                    dur="4s"
                                    repeatCount="indefinite"
                                />
                            </line>

                            <!-- Improvement Arrows -->
                            <g>
                                <path
                                    d="M70 35 L80 25 L85 40"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                >
                                    <animate
                                        attributeName="opacity"
                                        values="0;1;0"
                                        dur="2s"
                                        repeatCount="indefinite"
                                    />
                                </path>
                            </g>

                            <!-- Analysis Circles -->
                            <g>
                                <circle
                                    cx="50"
                                    cy="50"
                                    r="30"
                                    fill="none"
                                    stroke="#1d9395"
                                    stroke-width="1"
                                    opacity="0.2"
                                >
                                    <animate
                                        attributeName="r"
                                        values="25;35;25"
                                        dur="4s"
                                        repeatCount="indefinite"
                                    />
                                </circle>
                            </g>
                        </svg>
                    </div>
                    <h3 class="cs_iconbox_title">Analyze and Improve</h3>
                    <p class="cs_iconbox_subtitle">
                        Use FactoPlan's scenario analysis to test "what-if"
                        scenarios and make informed decisions.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>
<!-- Third section -->

<div class="banner-container">
    <div class="banner-area">
        <div class="slider-container">
            <div
                class="slider-content"
                style="
                    background-image: url('assets/img/OptebizImage/Landingimg3.jpg');
                "
            >
                <div class="overlay"></div>
                <div class="content-container">
                    <!-- Top Right Content -->
                    <div class="content-wrapper-top">
                        <h2 class="large-title">Operational Inefficiency</h2>
                        <p class="info">
                            Many manufacturing businesses struggle with
                            inefficient production planning, leading to
                            bottlenecks, downtime, and wasted resources
                        </p>
                        <div class="masking-text">
                            <h1>Industry Challenge</h1>
                        </div>
                    </div>

                    <!-- Bottom Left Content -->
                    <div class="content-wrapper-bottom">
                        <h2 class="large-title">Intelligent Optimization</h2>
                        <p class="info">
                            FactoPlan's finite capacity planning solution
                            optimizes production schedules, allocates resources
                            efficiently, and provides real-time visibility into
                            operations
                        </p>
                        <div class="masking-text">
                            <h1>Our Solution</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="container">
    <div class="m2-afmc-bottom-section">
        <!-- First Row -->
        <div class="m2-afmc-item active" id="bottom-1">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes pulse {
                            0% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(1.1);
                            }
                            100% {
                                transform: scale(1);
                            }
                        }
                        .pulse {
                            animation: pulse 2s infinite;
                        }
                    </style>
                    <circle
                        cx="50"
                        cy="50"
                        r="45"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <g class="pulse">
                        <path
                            d="M25 50 L40 50 L50 30 L60 70 L70 50 L85 50"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="3"
                            stroke-linecap="round"
                        />
                    </g>
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Real-Time Capacity Planning</strong>
                <p>
                    Optimize resource utilization with real-time capacity
                    planning and scheduling.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-2">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes rotate {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }
                        .rotating {
                            animation: rotate 8s linear infinite;
                        }
                    </style>
                    <rect
                        x="20"
                        y="20"
                        width="60"
                        height="60"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                        rx="5"
                    />
                    <g class="rotating" transform-origin="50 50">
                        <circle cx="50" cy="30" r="3" fill="#1d9395" />
                        <line
                            x1="50"
                            y1="30"
                            x2="50"
                            y2="50"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="50"
                            y1="50"
                            x2="65"
                            y2="50"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                    </g>
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Dynamic Scheduling</strong>
                <p>
                    Respond quickly to changing demands with flexible scheduling
                    tools.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes rise {
                            0% {
                                transform: translateY(20px);
                                opacity: 0;
                            }
                            100% {
                                transform: translateY(0);
                                opacity: 1;
                            }
                        }
                        .bar {
                            animation: rise 1s ease-out infinite;
                        }
                        .bar:nth-child(2) {
                            animation-delay: 0.2s;
                        }
                        .bar:nth-child(3) {
                            animation-delay: 0.4s;
                        }
                    </style>
                    <rect
                        class="bar"
                        x="25"
                        y="40"
                        width="15"
                        height="40"
                        fill="#1d9395"
                    />
                    <rect
                        class="bar"
                        x="45"
                        y="30"
                        width="15"
                        height="50"
                        fill="#1d9395"
                    />
                    <rect
                        class="bar"
                        x="65"
                        y="20"
                        width="15"
                        height="60"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Resource Optimization</strong>
                <p>
                    Efficiently allocate manpower, machinery, and materials to
                    maximize productivity.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Second Row -->
<div class="m2-afmc-bottom-section">
    <div class="m2-afmc-item" id="bottom-4">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes branch {
                        0% {
                            stroke-dashoffset: 1000;
                        }
                        100% {
                            stroke-dashoffset: 0;
                        }
                    }
                    .path {
                        stroke-dasharray: 1000;
                        animation: branch 3s linear infinite;
                    }
                </style>
                <path
                    class="path"
                    d="M50 20 L50 40 L30 60 M50 40 L50 60 M50 40 L70 60"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="3"
                    stroke-linecap="round"
                />
                <circle cx="30" cy="60" r="5" fill="#1d9395" />
                <circle cx="50" cy="60" r="5" fill="#1d9395" />
                <circle cx="70" cy="60" r="5" fill="#1d9395" />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Scenario Analysis</strong>
            <p>
                Make informed decisions with data-driven scenario analysis and
                modeling.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-5">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes fade {
                        0% {
                            opacity: 0.3;
                        }
                        100% {
                            opacity: 1;
                        }
                    }
                    .gauge {
                        animation: fade 1.5s ease-in-out infinite alternate;
                    }
                </style>
                <rect
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    rx="5"
                />
                <circle
                    class="gauge"
                    cx="30"
                    cy="40"
                    r="15"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="3"
                />
                <rect
                    class="gauge"
                    x="55"
                    y="25"
                    width="25"
                    height="30"
                    fill="#1d9395"
                    opacity="0.8"
                />
                <path
                    class="gauge"
                    d="M20 70 L35 60 L50 65 L65 55 L80 70"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Performance Dashboards</strong>
            <p>
                Track KPI's in real-time with customizable, color-coded
                dashboards.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes blink {
                        0%,
                        50% {
                            opacity: 1;
                        }
                        25%,
                        75% {
                            opacity: 0.3;
                        }
                    }
                    .alert {
                        animation: blink 2s infinite;
                    }
                </style>
                <path
                    d="M50 20 L80 70 L20 70 Z"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <g class="alert">
                    <line
                        x1="50"
                        y1="35"
                        x2="50"
                        y2="55"
                        stroke="#1d9395"
                        stroke-width="3"
                        stroke-linecap="round"
                    />
                    <circle cx="50" cy="62" r="2" fill="#1d9395" />
                </g>
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Intelligent Alerts</strong>
            <p>
                Automate notifications and incident tracking for improved
                responsiveness.
            </p>
        </div>
    </div>
</div>

<!-- Fifth Section -->
<!-- First Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-1">
        <img
            src="assets/img/OptebizImage/FactoPlan dashboard 1.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Track KPI's in Real-Time</div>
        <div class="m2-afmc-copy-text">
            <p>
                This enables you to identify trends, detect anomalies, and make
                data-driven decisions to optimize your operations.
            </p>
            <p>Monitor and visualize key performance indicators such as:</p>
            <ul>
                <li>Production throughput</li>
                <li>Quality metrics</li>
                <li>Equipment uptime</li>
                <li>Overall equipment effectiveness (OEE) in real-time</li>
            </ul>
        </div>
    </div>
</div>

<!-- Second Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-4">
        <img
            src="assets/img/OptebizImage/FactoPlan dashboard 2.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Optimize Resource Utilization</div>
        <div class="m2-afmc-copy-text">
            <p>
                Efficiently allocate resources such as machines, labor, and
                materials with real-time capacity planning.
            </p>
            <p>
                This helps minimize bottlenecks, reduce waste, and maximize
                productivity. With FactoPlan, you can:
            </p>
            <ul>
                <li>Visualize resource utilization in real-time</li>
                <li>
                    Identify potential bottlenecks and take proactive measures
                </li>
                <li>Equipment uptime</li>
                <li>
                    Optimize production schedules to maximize resource
                    utilization
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Third Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/FactoPlan dashboard 3.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Automate Notifications</div>
        <div class="m2-afmc-copy-text">
            <p>
                Efficiently allocate resources such as machines, labor, and
                materials with real-time capacity planning.Receive timely
                notifications and quickly respond to changes in your operations
                to minimize downtime and ensure smooth production.
            </p>
            <p>
                Stay ahead of production disruptions with automated
                notifications. Set up custom alerts for events such as:
            </p>
            <ul>
                <li>Equipment downtime or maintenance needs</li>
                <li>Quality control issues or defects</li>
                <li>Production delays or changes in schedules</li>
            </ul>
        </div>
    </div>
</div>

<!-- last section -->
<section class="mb-5 mt-5">
    <div class="container">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title cs_text_white">
                        Take Control of Your Operations Today!
                    </h2>
                    <p class="cs_section_subtitle mb-0">
                        Discover how FactoPlan can help you optimize your
                        production planning and scheduling. Contact us to learn
                        more
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="/BookDemo" class="cs_btn cs_bg_white"
                        >Book A Demo</a
                    >
                    <a href="/TryItNow" class="cs_btn cs_bg_accent"
                        >Try It Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
