<div class="Process-section come-out come-in loaded">
    <div class="section-triangle"></div>
    <div class="container">
        <div class="section-title">
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" /> What We
                Do</span
            >
            <p
                style="
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #fff;
                    max-width: 935px;
                    margin: 7px auto 32px;
                    font-family: Condensed;
                "
            >
                Our solution design is based on and compliant with
                industry-leading ISA-95 Guidelines with enough flexibility to
                tailor the models to meet your business needs seamlessly
            </p>
        </div>
        <div class="head-content">
            <div class="image-area">
                <img
                    src="assets\img\OptebizImage\ProcessFLOW.png"
                    data-src="assets\img\OptebizImage\ProcessFLOW.png"
                    alt="Requirement Process"
                    title="Requirement Process"
                    width="1000"
                    height="900"
                    class="loaded come-in"
                    class="wow animate__animated animate__fadeInRight"
                />
            </div>
        </div>
        <div class="transactional-db">
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Requirement Gathering.png"
                        data-src="assets\img\OptebizImage\Requirement Gathering.png"
                        alt="Requirement Gatheringe"
                        title="Requirement Gathering"
                        width="53"
                        height="57"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Requirement Gathering</div>
                <div class="detail">
                    Requirements are gathered by engaging with stakeholders,
                    analyzing sample data for the current state and identifying
                    gaps and pain points through mapping existing business
                    processes.
                </div>
            </div>
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Structure Requirements.png"
                        data-src="assets\img\OptebizImage\Structure Requirements.png"
                        alt="Structure Requirements"
                        title="Structure Requirements"
                        width="64"
                        height="56"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Structure Requirements</div>
                <div class="detail">
                    This involves setting capacity and efficiency goals, quality
                    standards, addressing inventory and fulfillment issues, and
                    aligning technology with customer needs and growth
                    strategies for long-term success.
                </div>
            </div>
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Documenting Requirements.png"
                        data-src="assets\img\OptebizImage\Documenting Requirements.png"
                        alt="Documenting Requirements"
                        title="Documenting Requirements"
                        width="64"
                        height="64"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Documenting Requirements</div>
                <div class="detail">
                    Using our detailed templates ensures clear project
                    requirements, reducing ambiguity, and facilitating efficient
                    decision-making for all stakeholders, promoting project
                    success.
                </div>
            </div>
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Requirement Prioritization.png"
                        data-src="assets\img\OptebizImage\Requirement Prioritization.png"
                        alt="Requirement Prioritization"
                        title="Requirement Prioritization"
                        width="68"
                        height="68"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Requirement Prioritization</div>
                <div class="detail">
                    Requirement prioritization ranks needs by importance,
                    allocating resources to address high-impact requirements for
                    maximum value within constraints.
                </div>
            </div>
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Solution Design.png"
                        data-src="assets\img\OptebizImage\Solution Design.png"
                        alt="Solution Design"
                        title="Solution Design"
                        width="57"
                        height="56"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Solution Design</div>
                <div class="detail">
                    Create a blueprint for a cohesive and efficient solution,
                    considering scalability and usability, translating
                    requirements into an actionable plan that aligns with
                    project goals.
                </div>
            </div>
            <div class="single-db">
                <div class="icon">
                    <img
                        src="assets\img\OptebizImage\Solution Demo and Sign Off.png"
                        data-src="assets\img\OptebizImage\Solution Demo and Sign Off.png"
                        alt="Solution Demo and Sign Off"
                        title="Solution Demo and Sign Off"
                        width="57"
                        height="50"
                        class="loaded come-in"
                    />
                </div>
                <div class="heading">Solution Demo</div>
                <div class="detail">
                    Final design is formally proposed based on the identified
                    requirements highlighting the key features and
                    functionalities of the point of arrival system comparing it
                    with the existing solution.
                </div>
            </div>
        </div>
    </div>
</div>
