import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details-facto-vision',
  templateUrl: './service-details-facto-vision.component.html',
  styleUrls: ['./service-details-facto-vision.component.scss']
})
export class ServiceDetailsFactoVisionComponent {

}
