import { Component } from '@angular/core';

@Component({
  selector: 'app-inventory-management',
  templateUrl: './inventory-management.component.html',
  styleUrls: ['./inventory-management.component.scss']
})
export class InventoryManagementComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto MES',
          subTitle: 'Inventory Management',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/inventory_reporting_dashboard.jpg'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Inventory Management',
          title1: 'Inventory Management',
          paragraphText1: "Inventory management involves the efficient control and supervision of a company's stock of goods and materials.",
          paragraphText2: 'It encompasses various processes, strategies, and technologies to ensure that the right products are available in the right quantities at the right time. Effective inventory management is crucial for optimizing costs, reducing stockouts, and maximizing overall operational efficiency.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/InventoryManagement.png',
          img2: '/assets/img/OptebizImage/inventory.png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Stock Level Optimization'
      },
      {
          title: 'Ordering and Reordering'
      },
      {
          title: 'Just-In-Time (JIT) Inventory'
      },
      {
          title: 'Technology Integration'
      },
      {
          title: 'Serialized Inventory Tracking'
      },
      {
          title: 'Dead Stock Management'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Production Monitoring',
        link: 'production-montioring'
    },
    {
      title: 'Workorder Management',
      link: 'work-order-management'
  },
   {
      title: 'Quality Control',
      link: 'quality-control'
  },
  {
      title: 'Downtime Management',
      link: 'downtime-management'
  },
  {
      title: 'Reporting and Analytics',
      link: 'reportand-analytics'
  },
//   {
//       title: 'Paperless Manufacturing',
//       link: 'paperless-manufacturing'
//   },
//    {
//       title: 'Machine Integration',
//       link: 'machine-integration'
//   },
//   {
//       title: 'Maintenance Management',
//       link: 'maintenance-management'
//   }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
        icon: 'bx bx-user-pin',
        title: 'Phone',
        subTitle: '+1 (419) 476-4520'
    },
    {
        icon: 'bx bx-map',
        title: 'Location',
        subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
    },
    {
        icon: 'bx bx-envelope',
        title: 'Email',
        subTitle: 'bizops@OpteBiz.com'
    }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}

