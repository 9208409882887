import { Component } from '@angular/core';

@Component({
  selector: 'app-services-details-factocloud',
  templateUrl: './services-details-factocloud.component.html',
  styleUrls: ['./services-details-factocloud.component.scss']
})
export class ServicesDetailsFactocloudComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Product Features',
          subTitle: 'FactoCloudConnect',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      // {
      //     img: 'img\OptebizImage\Process_Cycle.jpg'
      // }
  ]
  singleSolutionsBox: solutionsBoxContent[] = [
    {
      icon: 'flaticon-analytics',
      title: 'Data Integration & Connector Configuration',
      paragraphText: 'encompasses the processes related to configuring, customizing, and managing data connectors and interfaces, facilitating the seamless exchange and integration of data across heterogeneous systems and platforms',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Integration Service Utilizing Apache Camel',
      paragraphText: "Utilizing Apache Camel, it's about building an integration service that effortlessly connects and exchanges data between diverse systems and applications, thanks to Camel's extensive connectors and routing capabilities",
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Resilient Architecture for Data Store and Forward',
      paragraphText: 'Resilient Architecture for Data Store and Forward refers to the design of a system architecture that ensures data integrity and continuity by reliably storing and forwarding data, even in the presence of disruptions or disconnections',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Trigger-Based Data Dispatch Mechanism',
      paragraphText: 'A Trigger-Based Data Dispatch Mechanism" refers to a system or process in which data is sent or dispatched in response to specific triggers or events, enabling precise and automated data transmission based on predefined conditions or occurrences.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Transaction Progress Monitoring Dashboard',
      paragraphText: 'A Transaction Progress Monitoring Dashboard is a graphical user interface that offers real-time or near-real-time visibility into the status, progress, and key metrics of ongoing transactions or processes, enabling effective monitoring and informed decision-making.',
      link: 'services-details',
      linkText: 'View More'
  },
  {
    icon: 'flaticon-analytics',
    title: 'Dynamic Tag Selection and Aggregation for Data Scaling',
    paragraphText: 'the process of dynamically choosing data tags and performing aggregations to efficiently manage and analyze large datasets, allowing for scalability and improved data processing',
    link: 'services-details',
    linkText: 'View More'
},
      
      
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'FactoCloudConnect',
          // title1: 'About this Product',
          paragraphText1: 'FactoCloudConnect is advanced data capture, processing, and data streaming application capable of pushing the data between different data storage systems like Kafka, traditional databases, Event Hub, ElasticSearch, and InfluxDb.',
          // paragraphText2: 'Ensure product quality through quality checks and inspections, and track product defects and rework.',
          title2: 'Important Facts',
          title3: 'Products Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/projects/img2.jpg',
          img2: 'assets/img/services/charts.jpg'
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      // {
      //     title: 'The Field of Data Science'
      // },
      // {
      //     title: 'The Problem'
      // },
      // {
      //     title: 'The Solution'
      // },
      // {
      //     title: 'The Skills'
      // },
      // {
      //     title: 'Statistics'
      // },
      // {
      //     title: 'Mathematics'
      // }
  ]
  servicesDetailsDescApplication: Application[] = [
      // {
      //     title: 'Manufacturing',
      //     icon: 'flaticon-factory'
      // },
      // {
      //     title: 'Healthcare',
      //     icon: 'flaticon-hospital'
      // },
      // {
      //     title: 'Automobile',
      //     icon: 'flaticon-tracking'
      // },
      // {
      //     title: 'Banking',
      //     icon: 'flaticon-investment'
      // },
      // {
      //     title: 'Real Estate',
      //     icon: 'flaticon-house'
      // },
      // {
      //     title: 'Logistics',
      //     icon: 'flaticon-order'
      // }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      // {
      //     title: 'JavaScript'
      // },
      // {
      //     title: 'Python'
      // },
      // {
      //     title: 'Java'
      // },
      // {
      //     title: 'C/CPP'
      // },
      // {
      //     title: 'PHP'
      // },
      // {
      //     title: 'Swift'
      // },
      // {
      //     title: 'C# (C- Sharp)'
      // },
      // {
      //     title: 'Ruby'
      // },
      // {
      //     title: 'SQL'
      // }
  ]

  sidebarServicesList: ServicesList[] = [
      // {
      //     title: 'AI & ML Development',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Analytics',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Science',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Artificial Intelligence',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Visualization',
      //     link: 'services-details'
      // }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      // {
      //     title: 'PDF Download',
      //     icon: 'bx bxs-file-pdf',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Services Details.txt',
      //     icon: 'bx bxs-file-txt',
      //     link: 'services-details'
      // }
  ]
  sidebarContactInfo: ContactInfo[] = [
      // {
      //     icon: 'bx bx-user-pin',
      //     title: 'Phone',
      //     subTitle: '+2145 354 5413'
      // },
      // {
      //     icon: 'bx bx-map',
      //     title: 'Location',
      //     subTitle: 'New York, USA'
      // },
      // {
      //     icon: 'bx bx-envelope',
      //     title: 'Email',
      //     subTitle: 'hello@wilo.com'
      // }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  // title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  // paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}
class solutionsBoxContent {
  icon : string;
  title : string;
  paragraphText : string;
  link : string;
  linkText : string;
}
