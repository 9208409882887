import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-solutions',
    templateUrl: './solutions.component.html',
    styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Highlights',
            title: 'Optebiz',
            paragraphText: 'Finding the right solution is difficult - we work with you to assess your operation and fill in any gaps present with current technology & management. Schedule a free consultation today!'
        }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'assets/img/OptebizImage/AnalticsFront.png',
            title: 'Analytics',
            paragraphText: 'Analytics is the systematic examination of data to uncover patterns, gain insights, and make informed decisions, aiding businesses and individuals across various fields',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/OptebizImage/Monitor.jpg',
            title: 'Monitoring',
            paragraphText: 'Monitoring involves regular observation and assessment of a system, process, or situation to track its performance, detect issues, and ensure it operates as intended',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'assets/img/OptebizImage/Execution1.jpg',
            title: 'Execution',
            paragraphText: 'To automate the seamless transfer of order processing and capacity management information from the ERP system to the manufacturing plant',
            link: 'services-details',
            linkText: 'View Details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}