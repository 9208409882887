import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-case-studies-three-columns-page',
    templateUrl: './case-studies-three-columns-page.component.html',
    styleUrls: ['./case-studies-three-columns-page.component.scss']
})
export class CaseStudiesThreeColumnsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    pageTitleArea: pageTitle[] = [
        {
            title: 'Client  Testimonials'
        }
    ]



    // ]
    singleProjectsBox: singleProjectsContent[] = [
        {
            icon: 'bx bx-plus',
            img: ' assets/img/OptebizImage/IMG_0929.jpg',
            title: 'A Data & Tracking Solution for Large-Scale Operation.',
            // subTitle: 'System Project',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/OptebizImage/ivan-bandura-Ac97OqAWDvg-unsplash.jpg',
            title: 'Executed on saving their bottom line – through more effectively utilizing their machines.',
            // subTitle: 'Machine Learning',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/OptebizImage/jared-brashier-OR3FqpLyPdI-unsplash.jpg',
            title: 'Procuring a cost-effective, result driven data-solution.',
            // subTitle: 'Web Projects',
            link: 'case-studies-details'
        },
        {
            icon: 'bx bx-plus',
            img: 'assets/img/OptebizImage/jj-ying-WmnsGyaFnCQ-unsplash.jpg',
            title: 'Implementing a large-scale, multi-plant solution; and managing big-data sets.',
            // subTitle: 'Programming',
            link: 'case-studies-details'
        },
        
    ]

}
class pageTitle {
    title : string;
}
class singleProjectsContent {
    icon : string;
    img : string;
    title : string;
    // subTitle : string;
    link : string;
}
class imagecontent{
    title : string;
    Header:string;
    Paragraph1:string;
    Paragraph2:string;
    Paragraph3:string;
}