import { Component } from "@angular/core";

@Component({
    selector: "app-dynamic-tag-selection",
    templateUrl: "./dynamic-tag-selection.component.html",
    styleUrls: ["./dynamic-tag-selection.component.scss"],
})
export class DynamicTagSelectionComponent {
    constructor() {}

    ngOnInit(): void {}

    pageTitleArea: pageTitle[] = [
        {
            title: "Facto CloudConnect",
            subTitle: "Dynamic Tag Aggregation for Scalable Data",
        },
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: "assets/img/OptebizImage/tag.png",
        },
    ];
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: "Dynamic Tag Selection & Aggregation for Data Scaling",
            title1: "Dynamic Tag Aggregation for Scalable Data",
            paragraphText1:
                "Dynamic tag selection in the cloud provides a flexible and adaptable way to manage and organize resources, enabling users to optimize costs, automate processes, enforce security policies, and enhance overall operational efficiency in cloud environments.",
            paragraphText2:
                "The process of summarizing, condensing, or combining large volumes of data to derive meaningful insights or reduce the complexity of the data set. This becomes particularly important as the volume of data grows, and it's crucial to efficiently process and analyze information at scale.",
            title2: "Important Facts",
            title3: "Application Areas",
            title4: "Technologies That We Use",
            img1: "assets/img/OptebizImage/DA.webp",
            img2: "assets/img/OptebizImage/dataAggr.avif",
        },
    ];
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: "Event-Driven Architecture",
        },
        {
            title: "Real-Time Integration",
        },
        {
            title: "Triggers Define Dispatch Points",
        },
        {
            title: "Dependencies on External Systems",
        },
        {
            title: "Data Transformation",
        },
        {
            title: "Balancing Throughput and Resources",
        },
    ];
    servicesDetailsDescApplication: Application[] = [
        {
            title: "Manufacturing",
            icon: "flaticon-factory",
        },
        {
            title: "Healthcare",
            icon: "flaticon-hospital",
        },
        {
            title: "Automobile",
            icon: "flaticon-tracking",
        },
        {
            title: "Banking",
            icon: "flaticon-investment",
        },
        {
            title: "Real Estate",
            icon: "flaticon-house",
        },
        {
            title: "Logistics",
            icon: "flaticon-order",
        },
    ];
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: "JavaScript",
        },
        {
            title: "Python",
        },
        {
            title: "Java",
        },
        {
            title: "C/CPP",
        },
        {
            title: "PHP",
        },
        {
            title: "Swift",
        },
        {
            title: "C# (C- Sharp)",
        },
        {
            title: "Ruby",
        },
        {
            title: "SQL",
        },
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: "Data Integration ",
            link: "data-integration",
        },
        {
            title: "Integration Service",
            link: "integration-service",
        },
        {
            title: "Resilient Architecture",
            link: "resilient-architecture",
        },
        {
            title: "Transaction Progress",
            link: "transaction-progress",
        },
        {
            title: "Trigger-Based",
            link: "trigger-based",
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: "PDF Download",
            icon: "bx bxs-file-pdf",
            link: "services-details",
        },
        {
            title: "Services Details.txt",
            icon: "bx bxs-file-txt",
            link: "services-details",
        },
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: "bx bx-user-pin",
            title: "Phone",
            subTitle: "+14199133878",
        },
        {
            icon: "bx bx-map",
            title: "Location",
            subTitle: "LeanQubit Inc. 2600 Dorr Street Toledo, OH 43606",
        },
        {
            icon: "bx bx-envelope",
            title: "Email",
            subTitle: "sales@leanqubit.ai",
        },
    ];
}
class pageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}
