import { Component } from '@angular/core';

@Component({
  selector: 'app-downtime-management',
  templateUrl: './downtime-management.component.html',
  styleUrls: ['./downtime-management.component.scss']
})
export class DowntimeManagementComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto MES',
          subTitle: 'Downtime Management',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: '/assets/img/OptebizImage/downtime4.png'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Downtime Management',
          title1: 'Downtime Management',
          paragraphText1: 'Downtime management involves the systematic efforts to minimize, control, and optimize periods during which equipment, machinery, or systems are not operational.',
          paragraphText2: ' Downtime can be categorized into planned and unplanned events, and effective downtime management involves measures to address both types. The primary goals of downtime management are to enhance operational efficiency, reduce production interruptions, and mitigate the negative impact on productivity and profitability.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: '/assets/img/OptebizImage/downtime3.avif',
          img2: '/assets/img/OptebizImage/Downtime.png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Planned Downtime'
      },
      {
          title: 'Unplanned Downtime'
      },
      {
          title: 'Downtime Tracking and Analysis'
      },
      {
          title: 'Predictive Maintenance'
      },
      {
          title: 'Real-time Monitoring'
      },
      {
          title: 'Technology Integration (Automation and IoT)'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Production Monitoring',
        link: 'production-montioring'
    },
    {
      title: 'Workorder Management',
      link: 'work-order-management'
  },
  {
      title: 'Inventory Management',
      link: 'inventory-management'
  },
  {
      title: 'Quality Control',
      link: 'quality-control'
  },
    {
      title: 'Reporting and Analytics',
      link: 'reportand-analytics'
  },
//   {
//       title: 'Paperless Manufacturing',
//       link: 'paperless-manufacturing'
//   },
//    {
//       title: 'Machine Integration',
//       link: 'machine-integration'
//   },
//   {
//       title: 'Maintenance Management',
//       link: 'maintenance-management'
//   }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
        icon: 'bx bx-user-pin',
        title: 'Phone',
        subTitle: '+1 (419) 476-4520'
    },
    {
        icon: 'bx bx-map',
        title: 'Location',
        subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
    },
    {
        icon: 'bx bx-envelope',
        title: 'Email',
        subTitle: 'bizops@OpteBiz.com'
    }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}
