<div class="main" data-v-bc4c6fa8="">
    <div
        class="hero container text-center"
        data-v-bc4c6fa8=""
        style="padding-top: 3rem; padding-bottom: 15px"
    >
        <img
            src="assets\img\LeanQbit_logo.png"
            style="width: 150px; height: 80px"
        />
        <h1 class="wow animate__animated animate__fadeInLeft">
            Building the Future of IOT and
            <span>Automation</span>
        </h1>
    </div>
</div>
<div class="container" data-v-bc4c6fa8="">
    <section class="pt-3" data-v-bc4c6fa8="" data-v-27bd6564="">
        <div
            class="subtitle aos-init aos-animate"
            data-aos="fade-right"
            data-v-27bd6564=""
        >
            <p data-v-27bd6564="">Our story</p>
        </div>
        <h2
            data-aos="fade-left"
            data-v-27bd6564=""
            class="aos-init aos-animate"
        >
            <img src="assets/img/star-icon.png" alt="image" /> About
            <strong><span>Lean</span><span>Q</span><span>ubit</span></strong>
        </h2>
        <div class="maincon" data-v-27bd6564="">
            <div
                class="container text-center baseline-max-width aos-init aos-animate"
                data-aos="fade-left"
                data-v-bc4c6fa8=""
            >
                <p data-v-bc4c6fa8="">
                    LeanQubit is a Certified Integrator of Ignition. Our status
                    ensures you are working with experts who can understand the
                    fundamentals of your business, and effectively apply
                    Ignition-based solutions to fit what you need.
                </p>
                <p data-v-bc4c6fa8="">
                    We have worked in the manufacturing, energy, and utility
                    industries, giving us the experience to build custom
                    solutions for your operation, big or small. We maintain our
                    certification for the latest software of Ignition.
                </p>
                <p data-v-bc4c6fa8="">
                    Whether your business is new to automation or looking to
                    take it to the next level, we integrate flexible,
                    cost-effective hardware with our FactoTools to create a
                    packaged solution that drives results you need for your
                    business.
                </p>
            </div>
        </div>
    </section>
</div>
<div class="container-fluid" data-v-8a7cad3c="">
    <div class="hero container" data-v-8a7cad3c="" style="max-width: 1200px">
        <div class="row" data-v-8a7cad3c="">
            <div class="col-md-6 d-flex align-items-center" data-v-8a7cad3c="">
                <div data-v-8a7cad3c="">
                    <h2
                        data-aos="fade-left"
                        data-v-8a7cad3c=""
                        class="aos-init aos-animate"
                        style="
                            margin: 0 0 32px;
                            font-family: Inter, sans-serif;
                            font-weight: 900;
                            font-size: 42px;
                            color: #ffff;
                            text-transform: uppercase;
                            line-height: 1.2;
                            white-space: nowrap;
                        "
                    >
                        <img src="assets/img/star-icon.png" alt="image" />
                        Journey to Success
                    </h2>
                    <p
                        class="mt-5 aos-init aos-animate"
                        data-aos="fade-right"
                        data-v-8a7cad3c=""
                        style="
                            font-family: Inter, sans-serif;
                            color: #fff4f4;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 1.5;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                    >
                        Today, our platform is operational, in production, and
                        performing beyond expectations. Hundreds of internal
                        users have experienced significant productivity gains
                        since adopting LeanQubit, validating our efforts to
                        create a truly revolutionary solution.
                    </p>
                    <p
                        data-aos="fade-right"
                        data-v-8a7cad3c=""
                        class="aos-init aos-animate"
                        style="
                            font-family: Inter, sans-serif;
                            color: #fff4f4;
                            font-weight: 400;
                            font-size: 22px;
                            line-height: 1.5;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                    >
                        We are incredibly proud of the impact that our platform
                        has made, and we are inspired to continue our mission to
                        redefine the FactoTools and automation landscape.
                    </p>
                    <style>
                        /* Inline media query */
                        @media (max-width: 1024px) {
                            .hero h2 {
                                font-size: 36px !important;
                                white-space: normal !important;
                                text-align: center !important;
                            }
                            .hero p {
                                font-size: 20px !important;
                                text-align: center !important;
                            }
                            .hero .row {
                                display: flex;
                                flex-direction: column;
                                text-align: center !important;
                            }
                            .hero .col-md-6 {
                                width: 100% !important;
                            }
                        }

                        @media (max-width: 768px) {
                            .hero h2 {
                                font-size: 28px !important;
                            }
                            .hero p {
                                font-size: 18px !important;
                                max-width: 90% !important;
                            }
                        }

                        @media (max-width: 480px) {
                            .hero {
                                padding: 30px 0 !important;
                            }
                            .hero h2 {
                                font-size: 40px !important;
                            }
                            .hero p {
                                font-size: 20px !important;
                            }
                        }
                    </style>
                </div>
            </div>
            <div class="col-md-6" data-v-8a7cad3c="">
                <img
                    class="img-fluid"
                    src="assets/img/OptebizImage/CraftingPath.png"
                    alt="Picture of the user interface of OpteBiz on light mode"
                    data-aos="zoom-in"
                    class="wow animate__animated animate__fadeInRight"
                />
            </div>
        </div>
    </div>
</div>
<div data-v-56ea8e9b="" class="container-fluid bg-body-tertiary">
    <div data-v-56ea8e9b="" class="container" style="max-width: 1200px">
        <div data-v-56ea8e9b="" class="row">
            <div
                data-v-56ea8e9b=""
                class="col-lg-6 aos-init aos-animate"
                data-aos="zoom-in"
            >
                <img
                    data-v-56ea8e9b=""
                    class="img-fluid mt-5 mt-kg-0"
                    src="assets\img\OptebizImage\Technologyused.png"
                    alt="Logos of tools"
                />
            </div>
            <div data-v-56ea8e9b="" class="col-lg-6 mb-5">
                <h2
                    data-v-56ea8e9b=""
                    data-aos="fade-left"
                    class="aos-init aos-animate"
                    style="
                        margin: 0 0 32px;
                        font-family: Inter, sans-serif;
                        font-weight: 900;
                        font-size: 42px;
                        color: #444444;
                        text-transform: uppercase;
                        line-height: 1.2;
                        white-space: nowrap;
                    "
                >
                    <img src="assets/img/star-icon.png" alt="image" />
                    Technology Insights
                </h2>
                <p
                    data-v-56ea8e9b=""
                    class="mb-5 aos-init aos-animate"
                    data-aos="fade-right"
                    style="
                        font-family: Inter, sans-serif;
                        color: #666555;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 1.5;
                        max-width: 935px;
                        margin: 0 auto 32px;
                    "
                >
                    <strong
                        data-v-56ea8e9b=""
                        style="
                            font-weight: 400;
                            font-size: 22px;
                            font-family: Inter, sans-serif;
                            line-height: 30px;
                            color: #1d9395;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                        >Our Solutions</strong
                    >
                    cover a broad variety of technology, allowing you to
                    integrate and build systems to specifically fit your needs.
                </p>
                <p
                    data-v-56ea8e9b=""
                    class="mb-5 aos-init aos-animate"
                    data-aos="fade-right"
                    style="
                        font-family: Inter, sans-serif;
                        color: #666555;
                        font-weight: 400;
                        font-size: 22px;
                        line-height: 1.5;
                        max-width: 935px;
                        margin: 0 auto 32px;
                    "
                >
                    <strong
                        data-v-56ea8e9b=""
                        style="
                            font-weight: 400;
                            font-size: 22px;
                            font-family: Inter, sans-serif;
                            line-height: 30px;
                            color: #1d9395;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                        >Integrated, Dynamic Solutions</strong
                    >
                    We’re here to engineer a custom solution for you – or if you
                    have existing MES/Data-Tracking, address the gaps present to
                    optimize your operations.
                </p>
            </div>
        </div>
        <style>
            /* Tablet Devices (Up to 1024px) */
            @media (max-width: 1024px) {
                .col-lg-6 h2 {
                    font-size: 36px !important;
                    white-space: normal !important;
                    text-align: center !important;
                }

                .col-lg-6 h2 img {
                    width: 35px !important;
                }

                .col-lg-6 p {
                    font-size: 20px !important;
                    text-align: center !important;
                }

                .row {
                    display: flex;
                    flex-direction: column;
                    text-align: center !important;
                }

                .col-lg-6 {
                    width: 100% !important;
                }

                .img-fluid {
                    margin: 0 auto;
                    display: block;
                }
            }

            /* Mobile Devices (Up to 768px) */
            @media (max-width: 768px) {
                .col-lg-6 h2 {
                    font-size: 28px !important;
                }

                .col-lg-6 p {
                    font-size: 18px !important;
                    max-width: 90% !important;
                }

                .img-fluid {
                    width: 90% !important;
                }
            }

            /* Small Mobile Devices (Up to 480px) */
            @media (max-width: 480px) {
                .container {
                    padding: 30px 10px !important;
                }

                .col-lg-6 h2 {
                    font-size: 40px !important;
                }

                .col-lg-6 p {
                    font-size: 20px !important;
                }

                .col-lg-6 h2 img {
                    width: 25px !important;
                }
            }
        </style>
    </div>
</div>
<div data-v-d5c9078a="" class="container">
    <section data-v-27bd6564="" data-v-d5c9078a="">
        <h2
            data-v-27bd6564=""
            data-aos="fade-left"
            class="aos-init aos-animate"
        >
            <img src="assets/img/star-icon.png" alt="image" /> Getting started
            with
            <strong
                ><span style="color: #1d9395">Lean</span
                ><span style="color: #da5c30">Q</span
                ><span style="color: #1d9395">ubit</span></strong
            >
        </h2>
        <div data-v-27bd6564="" class="main">
            <div data-v-d5c9078a="" class="d-flex justify-content-center">
                <p
                    data-v-d5c9078a=""
                    class="text-center mb-5 baseline-max-width aos-init aos-animate"
                    data-aos="fade-right"
                    style="
                        display: block;
                        max-width: 1000px;
                        font-size: 25px;
                        font-family: sans-serif;
                        color: #444444;
                        font-weight: 700;
                        line-height: 30px;
                        margin-left: auto;
                        margin-right: auto;
                    "
                >
                    We are always on the lookout for passionate and talented
                    individuals. If you are excited about the prospect of
                    working on cutting-edge technology and shaping the future of
                    orchestration, we would love to hear from you
                </p>
            </div>
            <a routerLink="/BookDemo" class="default-btn"
                ><i class="bx bx-phone-call"></i>Book Demo<span></span
            ></a>
        </div>
    </section>
</div>
<section class="why-choose-us-area bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div
                    class="why-choose-us-content"
                    *ngFor="let Content1 of whyChooseUsContent"
                >
                    <h2>{{ Content1.title }}</h2>
                    <p>{{ Content1.paragraphText1 }}</p>
                    <p>{{ Content1.paragraphText2 }}</p>
                    <!-- <a routerLink="/{{Content1.link}}" class="default-btn"><i class="{{Content1.linkIcon}}"></i>{{Content1.linkText}}<span></span></a> -->
                    <div class="map-shape2">
                        <img
                            src="assets/img/shape/map-shape2.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="our-brand-partners-list">
                    <h2
                        style="
                            color: rgb(68, 68, 68);
                            margin: 0px 0px 32px;
                            font-size: 35px;
                            font-family: sans-serif;
                            font-weight: 600;
                        "
                    >
                        A Trusted and Reliable Partner to Support your Ops
                    </h2>
                    <p
                        style="
                            font-family: sans-serif;
                            font-size: 20px;
                            color: #666555;
                            font-weight: 400;
                        "
                    >
                        <strong
                            ><span style="color: #1d9395; font-size: 18px"
                                >Lean</span
                            ><span style="color: #da5c30; font-size: 18px"
                                >Q</span
                            ><span style="color: #1d9395; font-size: 18px"
                                >ubit</span
                            ></strong
                        >
                        is an ISA-95 compliant solution provider for enterprise
                        automation. We are a preferred partner of Inductive
                        Automation for consulting and implementation of Ignition
                        based solutions across diverse manufacturing industries.
                        We are also a Validated AWS Partner for our SaaS
                        offerings on AWS. With a comprehensive implementation
                        and support experience of over 20 years, we build
                        customized, reliable solutions for all of your MES
                        tracking and reporting needs.
                    </p>
                    <p
                        style="
                            font-family: sans-serif;
                            font-size: 20px;
                            color: #666555;
                            font-weight: 400;
                        "
                    >
                        We’re here for you every step of the way. Contact us to
                        get started!
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
