<section class="testimonials-area bg-white">
    <div class="container" style="max-width: 1400px">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title" style="font-size: 30px"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subTitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                <span>What Our </span
                ><span style="color: #1d9395"> Clients </span
                >{{ sectionTitleContent.title }}
            </h2>
            <p>
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div
            class="testimonials-view-btn text-center"
            *ngFor="let Btn of testimonialsBtn"
        >
            <a routerLink="/{{ Btn.link }}" class="default-btn"
                ><i class="{{ Btn.icon }}"></i>{{ Btn.text }}<span></span
            ></a>
        </div>
    </div>
</section>
