<section class="services-area">
    <div class="container" style="max-width: 1200px">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subtitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                <span style="color: #1d9395"> Transforming </span
                >{{ sectionTitleContent.title }}
            </h2>
            <p>
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div class="container">
            <div class="row first-row">
                <!-- First row with 3 cards -->
                <div
                    class="col-lg-4 col-md-4 col-sm-12"
                    *ngFor="
                        let ServicesBoxContent of singleServicesBoxItem.slice(
                            0,
                            3
                        )
                    "
                >
                    <div class="single-services-box-item" style="height: 520px">
                        <div class="icon">
                            <img [src]="ServicesBoxContent.icon" alt="image" />
                        </div>
                        <h3>
                            <a>{{ ServicesBoxContent.title }}</a>
                        </h3>
                        <div style="flex-grow: 1; overflow-y: auto">
                            <ul>
                                <li
                                    *ngFor="
                                        let point of ServicesBoxContent.paragraphText
                                    "
                                >
                                    {{ point }}
                                </li>
                            </ul>
                        </div>
                        <a
                            routerLink="/{{ ServicesBoxContent.link }}"
                            class="learn-more-btn"
                            style="margin-top: auto"
                        >
                            <i></i>{{ ServicesBoxContent.linkText }}&nbsp;&nbsp;
                            <i class="{{ ServicesBoxContent.linkIcon }}"></i>
                        </a>
                        <div class="shape">
                            <img
                                src="assets/img/services/shape4.png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="row second-row">
                <!-- Second row with 2 cards centered -->
                <div class="col-lg-2 col-md-0 col-sm-0 d-none d-lg-block">
                    <!-- Empty column for centering on large screens -->
                </div>
                <div
                    class="col-lg-4 col-md-6 col-sm-12"
                    *ngFor="
                        let ServicesBoxContent of singleServicesBoxItem.slice(
                            3,
                            5
                        )
                    "
                >
                    <div class="single-services-box-item" style="height: 520px">
                        <div class="icon">
                            <img [src]="ServicesBoxContent.icon" alt="image" />
                        </div>
                        <h3>
                            <a>{{ ServicesBoxContent.title }}</a>
                        </h3>
                        <div style="flex-grow: 1; overflow-y: auto">
                            <ul>
                                <li
                                    *ngFor="
                                        let point of ServicesBoxContent.paragraphText
                                    "
                                >
                                    {{ point }}
                                </li>
                            </ul>
                        </div>
                        <a
                            routerLink="/{{ ServicesBoxContent.link }}"
                            class="learn-more-btn"
                            style="margin-top: auto"
                        >
                            <i></i>{{ ServicesBoxContent.linkText }}&nbsp;&nbsp;
                            <i class="{{ ServicesBoxContent.linkIcon }}"></i>
                        </a>
                        <div class="shape">
                            <img
                                src="assets/img/services/shape4.png"
                                alt="image"
                            />
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-0 col-sm-0 d-none d-lg-block">
                    <!-- Empty column for centering on large screens -->
                </div>
            </div>
        </div>
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>
