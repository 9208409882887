<div class="dashboard-section">
    <div class="section-header">
        <div class="star-title" data-aos="fade-right">
            <img src="assets/img/star-icon.png" alt="star icon" />
            <span>ALL-INCLUSIVE DASHBOARD</span>
        </div>
        <h2 class="main-title wow animate__animated animate__fadeInRight">
            SMART & INSIGHTFUL DASHBOARD FOR
            <span class="highlight">DECISIONS</span>
        </h2>
    </div>

    <div class="dashboard-viewer">
        <!-- Main display area with current active dashboard - full cover image -->
        <div class="display-area">
            <img
                [src]="activeDashboard.image"
                [alt]="activeDashboard.title"
                class="active-dashboard-image"
                [ngClass]="{
                    'fade-in': fadeState === 'in',
                    'fade-out': fadeState === 'out'
                }"
            />

            <!-- Transparent navigation arrows -->
            <div class="nav-arrows">
                <button
                    class="nav-arrow prev"
                    (click)="showPreviousDashboard()"
                >
                    &lt;
                </button>
                <button class="nav-arrow next" (click)="showNextDashboard()">
                    &gt;
                </button>
            </div>
        </div>

        <!-- Dashboard info panels (non-clickable) -->
        <div class="dashboard-info-panels">
            <div
                *ngFor="let dashboard of dashboards; let i = index"
                [ngClass]="{
                    'dashboard-info': true,
                    active: isDashboardActive(i)
                }"
            >
                <div class="icon-container">
                    <svg class="dashboard-icon" viewBox="0 0 24 24">
                        <path [attr.d]="dashboard.icon"></path>
                    </svg>
                </div>
                <div class="info-content">
                    <h3>{{ dashboard.title }}</h3>
                    <p>{{ dashboard.description }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="cta-container">
        <a routerLink="/BookDemo" class="default-btn">
            <i class="bx bxs-calendar"></i>Book a demo<span></span>
        </a>
    </div> -->
</div>
