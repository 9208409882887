import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonialcase2',
  templateUrl: './testimonialcase2.component.html',
  styleUrls: ['./testimonialcase2.component.scss']
})
export class Testimonialcase2Component {
    constructor() { }

    ngOnInit(): void {
    }


  sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Facto-Tools We Implemented",
        title: 'Business with Best in Class Solutions',
        paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach.'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
    {
        icon: 'assets/img/OptebizImage/facto%20MESLogo.png',
        title: 'FactoMes',
        paragraphText: [
            'All-in-one shop floor monitoring solution.',
            'Optimizes operations & automates workflows.',
            'Manages orders, scheduling & product flow.',
            'Improves production efficiency with real-time tracking.'
          ],            linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoMES'
    },
    {
        icon: 'assets/img/OptebizImage/facto%20IQlogo.png',
        title: 'FactoIQ',
        paragraphText: [
            "Collects real-time time-series data.",
            "Stores data in a time-series database.",
            "Analyzes shop floor & operations data.",
            "Uses AI & statistical methods for insights."
        ],
                    linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: ''
    },
]
exploreLearningContent: Content[] = [
    {
        title: 'Old Processes & Issues They Faced',
        paragraphText: 'LeanQubit was entrusted to automate a small area in the clean water tank pumping station on a pilot basis to evaluate the benefits of automation.',
        linkText: 'Explore Learing',
        link: 'courses'
    }
]
exploreLearningVideo: Video[] = [
    {
        img: 'assets/img/OptebizImage/BUSINESS LAYER.svg',
        icon: 'flaticon-google-play',
        videoLink: 'https://www.youtube.com/watch?v=Y5KCDWi7h9o'
    }
]
whyChooseUsContent: Content1[] = [
    {
        title: 'The LeanQubit Advantage',
        paragraphText1: 'Our expert team of consultants and proprietary tools enable your business to collect and analyze current and historical data. Our FactoTools break this data down, providing key insights of your business. Whether it’s on a small scale, or dealing with big-data on a multi-plant scale, we digest the data and present it through intuitive dashboards and reports built for you – giving you an in-depth oversight of your operations.',
        paragraphText2: 'Our solutions are flexible – we can design web based or mobile solutions to fit your needs – giving you access anytime, anywhere.',
        linkIcon: 'flaticon-right',
        linkText: 'More About Us',
        link: 'about-us'
    }
]
ourBrandPartnersList: List[] = [
    {
        title: 'Our Brand Partners',
        linkText: 'View All',
        link: 'partner'
    }
]
singleFunfactsItem: Funfacts[] = [
    {
        icon: "assets/img/funfacts/icon1.png",
        title: '25%',
        subTitle: 'Reduction in labor cost related to starting and stopping of pumps in remote areas, real-time information about performance, and rapid response.',
        color:'red'
    },
    {
        icon: "assets/img/funfacts/icon2.png",
        title: '24 HR',
        subTitle: 'Hour Monitoring, Around the Clock to Keep this Plant in Check.',
        color:'Green'
    },
    // {
    //     icon: "assets/img/funfacts/icon3.png",
    //     title: '100%',
    //     subTitle: 'Satisfaction rate'
    // },
    // {
    //     icon: "assets/img/funfacts/icon4.png",
    //     title: '90%',
    //     subTitle: 'Senior scientist'
    // }
]

}
class Funfacts {
icon : string;
title : string;
subTitle : string;
color:string;
}

class Content1 {
title : string;
paragraphText1 : string;
paragraphText2 : string;
linkIcon : string;
linkText : string;
link : string;
}
class List {
title : string;
linkText : string;
link : string;
}

class Content {
title : string;
paragraphText : string;
linkText : string;
link : string;
}
class Video {
img : string;
icon : string;
videoLink : string;
}


class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText : string[];
linkText : string;
linkIcon : string;
link : string;
}