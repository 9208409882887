import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-inductive-details',
  templateUrl: './inductive-details.component.html',
  styleUrls: ['./inductive-details.component.scss']
})
export class InductiveDetailsComponent implements OnInit, AfterViewInit {
  @ViewChild('sliderContainer') sliderContainer: ElementRef;
  
  testimonials = [
    {
      name: 'Web-Based Deployment',
      // company: 'Almas Market',
      image: 'assets/img/OptebizImage/introIcon_webBasedDeployment.png',
      text: 'Web-Based Deployment in Ignition by Inductive Automation allows users to effortlessly monitor and control industrial projects through standard web browsers, ensuring convenience and efficiency across various devices for a user-friendly experience.',
      rating: 5
    },
    {
      name: 'Rapid Development',
      // company: 'Voila Caffe',
      image: 'assets/img/OptebizImage/introIcon_rapidDevelopment.png',
      text: "In Inductive Automation's Ignition platform enables swift creation and deployment of industrial automation solutions, streamlining the process for designing and implementing projects like HMI, SCADA, and MES.",
      rating: 5
    },
    {
      name: 'Built on Trusted IT standards',
      // company: 'Green Lodge',
      image: 'assets/img/OptebizImage/introIcon_ITStandards.png',
      text: 'FactoTools is built upon information technology standards that are dependable and widely accepted, guaranteeing compatibility, security, and reliability.',
      rating: 5
    },
    {
      name: 'Data Collection & Analytics',
      // company: 'Almas Market',
      image: 'assets/img/OptebizImage/introicon-data-collection-analytics.png',
      text: 'Our applications efficiently gather data from various sources, employing analytical tools to derive meaningful insights, thereby supporting informed decision-making, optimizing performance, and uncovering patterns or trends within the data.',
      rating: 5
    },
    {
      name: 'Automation',
      // company: 'Voila Caffe',
      image: 'assets/img/OptebizImage/introicon-automation.png',
      text: 'Leveraging cutting-edge automation technology to execute tasks autonomously, improving efficiency, and minimizing manual intervention in repetitive or labor-intensive processes across diverse industries.',
      rating: 5
    },
    {
      name: 'Reduce Development Time',
      // company: 'Tech Solutions',
      image: 'assets/img/OptebizImage/introIcon_rapidDevelopment.png',
      text: 'Reducing development time in Inductive Automation with Ignition involves streamlining processes to swiftly design and deploy industrial automation projects such as HMI, SCADA, and MES.',
      rating: 5
    },
    // {
    //   name: 'Analyze & Increase Performance',
    //   // company: 'Digital Marketing',
    //   image: 'assets/img/OptebizImage/IntroIcon-Sepasoft-BetterAnalyze.svg',
    //   text: 'Being an AWS SaaS Partner, LeanQubit provides cloud-based analytics solutions that afford you a comprehensive overview of your processes, coupled with actionable insights to enhance productivity and mitigate wastage.',
    //   rating: 5
    // },
    // {
    //   name: 'Scalable Architecture',
    //   // company: 'Cloud Solutions',
    //   image: 'assets/img/OptebizImage/introicon-scalable-architecture.png',
    //   text: 'This ensures efficient scaling without the need for a complete overhaul, enabling seamless addition of devices, data points, or features as needed for optimal performance as the system evolves.',
    //   rating: 5
    // }
  ];

  currentIndex = 0;
  cardWidth = 0;
  cardsPerView = 3;
  autoSlideInterval: any;
  touchStartX = 0;
  touchEndX = 0;
  totalPages = 0;
  
  constructor() { }

  ngOnInit(): void {
    // Initialize
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCardsPerView();
      this.startAutoSlide();
    }, 0);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardsPerView();
  }

  updateCardsPerView(): void {
    if (window.innerWidth < 768) {
      this.cardsPerView = 1;
    } else if (window.innerWidth < 1024) {
      this.cardsPerView = 2;
    } else {
      this.cardsPerView = 3;
    }
    
    const sliderElement = this.sliderContainer.nativeElement;
    const cards = sliderElement.querySelectorAll('.testimonial-card');
    if (cards.length > 0) {
      this.cardWidth = cards[0].offsetWidth + 20; // card width + margin
    }
    
    this.totalPages = Math.ceil(this.testimonials.length / this.cardsPerView);
    this.updateSliderPosition();
  }

  updateSliderPosition(): void {
    const sliderElement = this.sliderContainer.nativeElement;
    sliderElement.style.transform = `translateX(-${this.currentIndex * this.cardWidth}px)`;
  }

  nextSlide(): void {
    const maxIndex = this.testimonials.length - this.cardsPerView;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
    this.updateSliderPosition();
  }

  prevSlide(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.testimonials.length - this.cardsPerView;
    }
    this.updateSliderPosition();
  }

  goToPage(pageIndex: number): void {
    this.currentIndex = pageIndex * this.cardsPerView;
    if (this.currentIndex > this.testimonials.length - this.cardsPerView) {
      this.currentIndex = this.testimonials.length - this.cardsPerView;
    }
    this.updateSliderPosition();
  }

  getCurrentPage(): number {
    return Math.floor(this.currentIndex / this.cardsPerView);
  }

  startAutoSlide(): void {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  stopAutoSlide(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
    this.stopAutoSlide();
  }

  onTouchEnd(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].clientX;
    this.handleSwipe();
    this.startAutoSlide();
  }

  handleSwipe(): void {
    if (this.touchEndX < this.touchStartX - 50) {
      this.nextSlide();
    }
    if (this.touchEndX > this.touchStartX + 50) {
      this.prevSlide();
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  // Generate array for star rating display
  getStarArray(rating: number): number[] {
    return Array(5).fill(0).map((_, i) => i < rating ? 1 : 0);
  }

  // For scroll to top functionality
  // scrollToTop(): void {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }

  // Cleanup
  ngOnDestroy(): void {
    this.stopAutoSlide();
  }
}