import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, animate, transition, query, stagger } from '@angular/animations';

@Component({
  selector: 'app-testingfrontpage3',
  templateUrl: './testingfrontpage3.component.html',
  styleUrls: ['./testingfrontpage3.component.scss'],
  animations: [
    trigger('textColorChange', [
      state('color1', style({ color: '#0064fb' })),
      state('color2', style({ color: '#ff414b' })),
      state('color3', style({ color: '#00d084' })),
      transition('* => *', animate('1s ease-in-out'))
    ]),
    trigger('contentChange', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0, transform: 'translateY(20px)' }),
          stagger(100, [
            animate('500ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
          ])
        ], { optional: true }),
        query(':leave', [
          stagger(100, [
            animate('500ms ease-out', style({ opacity: 0, transform: 'translateY(-20px)' }))
          ])
        ], { optional: true })
      ])
    ]),
    trigger('imageChange', [
      transition('* => *', [
        query('.banner-grid1, .banner-grid2, .banner3-big', [
          style({ opacity: 0, transform: 'scale(0.9)' }),
          stagger(100, [
            animate('500ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
          ])
        ])
      ])
    ])
  ]
})
export class Testingfrontpage3Component implements OnInit, OnDestroy {
  showVideo = false;
  videoUrl = 'assets/videos/sample-video.mp4';
  colorState = 'color1';
  currentIndex = 0;
  content = [
    {
      heading: 'Transforming Manufacturing with Industrial IoT and AI',
      subheading: 'Your Path to Smart, Efficient, and Data-Driven Production',
      images: {
        grid1: 'assets/img/OptebizImage/banner-grid1.e5aa7272.jpg',
        grid2: 'assets/img/OptebizImage/banner-grid2.508c1159.jpg',
        grid3: 'assets/img/OptebizImage/banner-grid3.73e4b091.jpg'
      }
    },
    {
      heading: 'Innovative Solutions for Your Business',
      subheading: 'Transforming ideas into powerful applications',
      images: {
        grid1: 'assets/img/OptebizImage/banner-grid1.e5aa7272.jpg',
        grid2: 'assets/img/OptebizImage/banner-grid2.508c1159.jpg',
        grid3: 'assets/img/OptebizImage/banner-grid3.73e4b091.jpg'
      }
    },
    {
      heading: 'Secure, Scalable, and Smart Technology',
      subheading: 'Building the foundation for your digital success',
      images: {
        grid1: 'assets/img/OptebizImage/banner-grid1.e5aa7272.jpg',
        grid2: 'assets/img/OptebizImage/banner-grid2.508c1159.jpg',
        grid3: 'assets/img/OptebizImage/banner-grid3.73e4b091.jpg'
      }
    }
  ];
  private intervalId: any;

  ngOnInit() {
    this.startTransitions();
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startTransitions() {
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.content.length;
      this.colorState = `color${(this.currentIndex + 1)}`;
    }, 5000); // Change every 5 seconds
  }

  playVideo() {
    this.showVideo = true;
  }

  closeVideo() {
    this.showVideo = false;
  }
}