<section class="page-title-areas">
    <div class="container">
        <div
            class="page-title-content text-center"
            *ngFor="let pageTitle of pageTitleArea"
        >
            <h2>{{ pageTitle.title }}</h2>
        </div>
    </div>

    <div class="shape-img1">
        <img src="assets/img/shape/shape1.svg" alt="image" />
    </div>
    <div class="shape-img2">
        <img src="assets/img/shape/shape2.png" alt="image" />
    </div>
    <div class="shape-img3">
        <img src="assets/img/shape/shape3.png" alt="image" />
    </div>
</section>

<section class="projects-area pt-70 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="single-projects-box">
                    <div class="image">
                        <img
                            src="assets/img/OptebizImage/IMG_0929.jpg"
                            alt="image"
                        />
                        <div class="overlay-text">
                            <span class="tag-line">#CASE STUDY 1</span>
                            <h1 class="main-title">
                                A Data & Tracking Solution for Large-Scale
                                Operation.
                            </h1>
                            <p class="description">
                                Solar Cell Manufacturing Plant
                            </p>
                            <div class="features">
                                <div class="feature">
                                    <span class="number">01.</span>
                                    Management & Organization of Billions of
                                    Data Sets
                                </div>
                                <div class="feature">
                                    <span class="number">02.</span>
                                    Collection of Intuitive Dashboards to Stay
                                    Updated
                                </div>
                                <div class="feature">
                                    <span class="number">03.</span>
                                    In-Depth Analysis to Optimize their
                                    Operations
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <h3>
                            <a routerLink="/testimonialcase1"
                                >A Data & Tracking Solution for Large-Scale
                                Operation.</a
                            >
                        </h3>
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img
                            src="assets/img/OptebizImage/ivan-bandura-Ac97OqAWDvg-unsplash.jpg"
                            alt="image"
                        />
                        <div class="overlay-text">
                            <span class="tag-line">#CASE STUDY 2</span>
                            <h1 class="main-title">
                                Executed on saving their bottom line – through
                                more effectively utilizing their machines.
                            </h1>
                            <p class="description">Water Treatment Plant</p>

                            <div class="features">
                                <div class="feature">
                                    <span class="number">01.</span>
                                    Management & Organization of Billions of
                                    Data Sets
                                </div>
                                <div class="feature">
                                    <span class="number">02.</span>
                                    Collection of Intuitive Dashboards to Stay
                                    Updated
                                </div>
                                <div class="feature">
                                    <span class="number">03.</span>
                                    In-Depth Analysis to Optimize their
                                    Operations
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <h3>
                            <a routerLink="/testimonialcase2"
                                >Executed on saving their bottom line – through
                                more effectively utilizing their machines.</a
                            >
                        </h3>
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img
                            src="assets/img/OptebizImage/jared-brashier-OR3FqpLyPdI-unsplash.jpg"
                            alt="image"
                        />
                        <div class="overlay-text">
                            <span class="tag-line">#CASE STUDY 3</span>
                            <h1 class="main-title">
                                Procuring a cost-effective, result driven
                                data-solution.
                            </h1>
                            <p class="description">Door Production</p>
                            <div class="features">
                                <div class="feature">
                                    <span class="number">01.</span>
                                    Management & Organization of Billions of
                                    Data Sets
                                </div>
                                <div class="feature">
                                    <span class="number">02.</span>
                                    Collection of Intuitive Dashboards to Stay
                                    Updated
                                </div>
                                <div class="feature">
                                    <span class="number">03.</span>
                                    In-Depth Analysis to Optimize their
                                    Operations
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <h3>
                            <a routerLink="/testimonialcase3"
                                >Procuring a cost-effective, result driven
                                data-solution.</a
                            >
                        </h3>
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img
                            src="assets/img/OptebizImage/jj-ying-WmnsGyaFnCQ-unsplash.jpg"
                            alt="image"
                        />
                        <div class="overlay-text">
                            <span class="tag-line">#CASE STUDY 4</span>
                            <h1 class="main-title">
                                Implementing a large-scale, multi-plant
                                solution, and managing big-data sets.
                            </h1>
                            <p class="description">Paper Production Plant</p>
                            <div class="features">
                                <div class="feature">
                                    <span class="number">01.</span>
                                    Management & Organization of Billions of
                                    Data Sets
                                </div>
                                <div class="feature">
                                    <span class="number">02.</span>
                                    Collection of Intuitive Dashboards to Stay
                                    Updated
                                </div>
                                <div class="feature">
                                    <span class="number">03.</span>
                                    In-Depth Analysis to Optimize their
                                    Operations
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="content">
                        <h3>
                            <a routerLink="/testimonialcase4"
                                >Implementing a large-scale, multi-plant
                                solution, and managing big-data sets.</a
                            >
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
