<section class="cs_hero cs_style_2 position-relative" id="home">
    <div class="container">
        <div class="cs_product_title">
            <h1><span>FactoIQ</span> Predictive Manufacturing</h1>
        </div>
        <div class="row align-items-center cs_gap_y_40 cs_reverse_md">
            <div class="col-lg-7">
                <div class="cs_hero_text">
                    <h1 class="cs_hero_title">
                        <!-- <div class="main-title">
                            Optimize Shop Floor Processes with FactoIQ
                        </div> -->
                        <div class="subtitle">
                            Unlocking Insights through Advanced Analytics
                        </div>
                    </h1>
                    <p class="cs_hero_subtitle">
                        Harnessing the Power of Advanced Analytics for
                        Industrial Operations.
                    </p>
                    <div
                        class="cs_btn_group cs_version_2 wow fadeIn"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.25s"
                    >
                        <a
                            href="/BookDemo"
                            class="cs_btn cs_bg_white cs_dark_hover"
                            >Product Demo</a
                        >
                        <a
                            href="/TryItNow"
                            class="cs_btn cs_bg_accent cs_dark_hover"
                            >Start Free Trial</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="cs_hero_thumb">
                    <video
                        id="videoPlayer"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="max-width: 100%; height: auto"
                    >
                        <source
                            src="assets/img/OptebizImage/ERP SystemIQ.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_hero_shape1"></div>
    <div class="cs_hero_shape2">
        <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
    </div>
    <div class="cs_hero_shape3"></div>
</section>
<!-- Second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="container">
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes flow {
                                0% {
                                    transform: translateY(-30px);
                                    opacity: 0;
                                }
                                70%,
                                100% {
                                    transform: translateY(30px);
                                    opacity: 1;
                                }
                            }
                            @keyframes expand {
                                0%,
                                100% {
                                    transform: scale(1);
                                }
                                50% {
                                    transform: scale(1.1);
                                }
                            }
                            .data-flow {
                                animation: flow 2s infinite;
                            }
                            .data-flow-1 {
                                animation-delay: 0s;
                            }
                            .data-flow-2 {
                                animation-delay: 0.5s;
                            }
                            .data-flow-3 {
                                animation-delay: 1s;
                            }
                            .database {
                                animation: expand 3s infinite;
                            }
                        </style>
                        <path
                            d="M30,20 L70,20 L70,35 L30,35 Z"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="50"
                            y1="35"
                            x2="50"
                            y2="45"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            class="data-flow data-flow-1"
                            d="M45,30 L55,30 L55,40 L45,40 Z"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <path
                            class="data-flow data-flow-2"
                            d="M42,25 L58,25 L58,35 L42,35 Z"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <path
                            class="data-flow data-flow-3"
                            d="M48,28 L52,28 L52,38 L48,38 Z"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <ellipse
                            class="database"
                            cx="50"
                            cy="55"
                            rx="20"
                            ry="5"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            d="M30,55 L30,75 C30,78 39,85 50,85 C61,85 70,78 70,75 L70,55"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            d="M30,65 C30,68 39,75 50,75 C61,75 70,68 70,65"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="1"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Data Collection</h3>
                    <p class="cs_iconbox_subtitle">
                        Collect and integrate historical data on process,
                        downtime, breakdown, and metrics.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes draw {
                                0% {
                                    stroke-dashoffset: 300;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes float {
                                0%,
                                100% {
                                    transform: translateY(0);
                                }
                                50% {
                                    transform: translateY(-3px);
                                }
                            }
                            .stat-line {
                                stroke-dasharray: 300;
                                animation: draw 3s linear forwards;
                            }
                            .data-point {
                                animation: float 2s infinite alternate;
                            }
                            .data-point:nth-child(3) {
                                animation-delay: 0.2s;
                            }
                            .data-point:nth-child(4) {
                                animation-delay: 0.4s;
                            }
                            .data-point:nth-child(5) {
                                animation-delay: 0.6s;
                            }
                            .data-point:nth-child(6) {
                                animation-delay: 0.8s;
                            }
                        </style>
                        <rect
                            x="15"
                            y="15"
                            width="70"
                            height="70"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="1"
                        />
                        <line
                            x1="15"
                            y1="85"
                            x2="85"
                            y2="85"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="15"
                            y1="85"
                            x2="15"
                            y2="15"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            class="stat-line"
                            d="M15,60 Q30,40 45,50 Q60,60 75,30"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <circle
                            class="data-point"
                            cx="15"
                            cy="60"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="data-point"
                            cx="30"
                            cy="40"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="data-point"
                            cx="45"
                            cy="50"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="data-point"
                            cx="60"
                            cy="60"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="data-point"
                            cx="75"
                            cy="30"
                            r="3"
                            fill="#1d9395"
                        />
                        <path
                            class="stat-line"
                            d="M15,70 C25,71 45,65 55,72 C65,79 80,73 85,69"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="1"
                            stroke-dasharray="3"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Predictive Analytics</h3>
                    <p class="cs_iconbox_subtitle">
                        Apply AI-powered predictive analytics to forecast
                        real-time outcomes.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes glow {
                                0%,
                                100% {
                                    filter: drop-shadow(0 0 2px #1d9395);
                                }
                                50% {
                                    filter: drop-shadow(0 0 8px #1d9395);
                                }
                            }
                            @keyframes appear {
                                0% {
                                    opacity: 0;
                                    transform: scale(0.5);
                                }
                                100% {
                                    opacity: 1;
                                    transform: scale(1);
                                }
                            }
                            .bulb {
                                animation: glow 3s infinite;
                            }
                            .idea {
                                animation: appear 2s forwards;
                            }
                            .idea-1 {
                                animation-delay: 0.5s;
                            }
                            .idea-2 {
                                animation-delay: 1s;
                            }
                            .idea-3 {
                                animation-delay: 1.5s;
                            }
                        </style>
                        <path
                            class="bulb"
                            d="M50,20 Q65,20 65,40 Q65,50 55,60 L45,60 Q35,50 35,40 Q35,20 50,20 Z"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <rect
                            x="45"
                            y="60"
                            width="10"
                            height="5"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            d="M45,65 Q45,75 50,75 Q55,75 55,65"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <circle
                            class="idea idea-1"
                            cx="40"
                            cy="30"
                            r="3"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <circle
                            class="idea idea-2"
                            cx="50"
                            cy="35"
                            r="4"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <circle
                            class="idea idea-3"
                            cx="60"
                            cy="30"
                            r="3"
                            fill="#1d9395"
                            opacity="0"
                        />
                        <path
                            class="idea"
                            d="M42,85 L58,85 L55,75 L45,75 Z"
                            fill="#1d9395"
                            opacity="0"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Real-Time Insights</h3>
                    <p class="cs_iconbox_subtitle">
                        Gain real-time insights into process performance,
                        quality, and alarm metrics.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <style>
                                @keyframes pulse {
                                    0%,
                                    100% {
                                        transform: scale(0.95);
                                        opacity: 0.7;
                                    }
                                    50% {
                                        transform: scale(1.05);
                                        opacity: 1;
                                    }
                                }
                                @keyframes checkmark {
                                    0% {
                                        stroke-dashoffset: 100;
                                    }
                                    100% {
                                        stroke-dashoffset: 0;
                                    }
                                }
                                .action-pulse {
                                    animation: pulse 2s infinite;
                                }
                                .check {
                                    stroke-dasharray: 100;
                                    animation: checkmark 1.5s ease-out forwards;
                                }
                            </style>
                            <circle
                                cx="50"
                                cy="35"
                                r="20"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                class="check"
                                d="M40,35 L48,43 L60,28"
                                stroke="#1d9395"
                                stroke-width="3"
                                fill="none"
                            />
                            <rect
                                class="action-pulse"
                                x="25"
                                y="60"
                                width="50"
                                height="25"
                                rx="5"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <line
                                x1="35"
                                y1="70"
                                x2="65"
                                y2="70"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <line
                                x1="35"
                                y1="75"
                                x2="60"
                                y2="75"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                d="M30,60 L30,45 L40,35 M60,35 L70,45 L70,60"
                                stroke="#1d9395"
                                stroke-width="1"
                                stroke-dasharray="3"
                            />
                        </svg>
                    </div>
                    <h3 class="cs_iconbox_title">Optimization</h3>
                    <p class="cs_iconbox_subtitle">
                        Optimize process parameters and maintenance schedules
                        for maximum efficiency.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>
<!-- Third section -->

<div class="banner-container">
    <div class="banner-area">
        <div class="slider-container">
            <div
                class="slider-content"
                style="
                    background-image: url('assets/img/OptebizImage/LandingImg.jpg');
                "
            >
                <div class="overlay"></div>
                <div class="content-container">
                    <!-- Top Right Content -->
                    <div class="content-wrapper-top">
                        <!-- <span class="small-title">Shop Floor Inefficiencies</span> -->
                        <h2 class="large-title">Shop Floor Inefficiencies</h2>
                        <p class="info">
                            Manufacturing operations are often plagued by
                            inefficiency, leading to reduced productivity,
                            quality issues, and increased costs.
                        </p>
                        <div class="masking-text">
                            <h1>Industry Challenge</h1>
                        </div>
                    </div>

                    <!-- Bottom Left Content -->
                    <div class="content-wrapper-bottom">
                        <!-- <span class="small-title">Unlock Analytical Insights</span> -->
                        <h2 class="large-title">Unlock Analytical Insights</h2>
                        <p class="info">
                            FactoIQ provides AI-powered insights to predict and
                            prevent inefficiencies, enabling data-driven
                            decision making and optimized operations
                        </p>
                        <div class="masking-text">
                            <h1>Our Solution</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="container">
    <div class="m2-afmc-bottom-section">
        <!-- First Row -->
        <div class="m2-afmc-item active" id="bottom-1">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes flow {
                            0% {
                                transform: translateY(-25px);
                                opacity: 0;
                            }
                            70%,
                            100% {
                                transform: translateY(25px);
                                opacity: 1;
                            }
                        }
                        @keyframes pulse {
                            0%,
                            100% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(1.1);
                            }
                        }
                        .data-flow {
                            animation: flow 2s infinite;
                        }
                        .data-flow-1 {
                            animation-delay: 0s;
                        }
                        .data-flow-2 {
                            animation-delay: 0.5s;
                        }
                        .data-flow-3 {
                            animation-delay: 1s;
                        }
                        .database {
                            animation: pulse 3s infinite;
                        }
                    </style>
                    <circle
                        cx="50"
                        cy="20"
                        r="10"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <line
                        x1="43"
                        y1="20"
                        x2="57"
                        y2="20"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <line
                        x1="50"
                        y1="13"
                        x2="50"
                        y2="27"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path d="M50,30 L50,40" stroke="#1d9395" stroke-width="2" />
                    <path
                        class="data-flow data-flow-1"
                        d="M48,25 L52,25 L50,35 Z"
                        fill="#1d9395"
                        opacity="0"
                    />
                    <path
                        class="data-flow data-flow-2"
                        d="M46,22 L54,22 L50,32 Z"
                        fill="#1d9395"
                        opacity="0"
                    />
                    <path
                        class="data-flow data-flow-3"
                        d="M44,20 L56,20 L50,30 Z"
                        fill="#1d9395"
                        opacity="0"
                    />
                    <ellipse
                        class="database"
                        cx="50"
                        cy="55"
                        rx="20"
                        ry="5"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path
                        d="M30,55 L30,75 C30,78 39,85 50,85 C61,85 70,78 70,75 L70,55"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path
                        d="M30,65 C30,68 39,75 50,75 C61,75 70,68 70,65"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Real-Time Predictive Analytics </strong>
                <p>
                    Leverage historical data to predict real-time outcomes,
                    including equipment failures, process disruptions, and
                    quality issues.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-2">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes rotate {
                            0% {
                                transform: rotate(0deg);
                            }
                            100% {
                                transform: rotate(360deg);
                            }
                        }
                        @keyframes draw {
                            0% {
                                stroke-dashoffset: 300;
                            }
                            100% {
                                stroke-dashoffset: 0;
                            }
                        }
                        @keyframes blink {
                            0%,
                            100% {
                                opacity: 0.3;
                            }
                            50% {
                                opacity: 1;
                            }
                        }
                        .gear {
                            transform-origin: 50px 50px;
                            animation: rotate 20s linear infinite;
                        }
                        .chart-line {
                            stroke-dasharray: 300;
                            animation: draw 3s linear forwards;
                        }
                        .data-point {
                            animation: blink 2s infinite alternate;
                        }
                    </style>
                    <circle
                        class="gear"
                        cx="50"
                        cy="50"
                        r="35"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <path
                        class="gear"
                        d="M50,15 L53,20 L58,17 L60,23 L65,22 L66,28 L71,28 L70,34 L75,36 L72,41 L77,45 L72,49 L75,54 L69,56 L70,62 L64,62 L62,68 L57,66 L54,71 L50,68 L46,71 L43,66 L38,68 L36,62 L30,62 L31,56 L25,54 L28,49 L23,45 L28,41 L25,36 L30,34 L29,28 L34,28 L35,22 L40,23 L42,17 L47,20 Z"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle cx="50" cy="50" r="5" fill="#1d9395" />
                    <path
                        class="chart-line"
                        d="M25,65 L35,55 L45,60 L55,40 L65,35 L75,45"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle
                        class="data-point"
                        cx="35"
                        cy="55"
                        r="3"
                        fill="#1d9395"
                    />
                    <circle
                        class="data-point"
                        cx="55"
                        cy="40"
                        r="3"
                        fill="#1d9395"
                    />
                    <circle
                        class="data-point"
                        cx="75"
                        cy="45"
                        r="3"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>AI-Powered Alarm Management </strong>
                <p>
                    Reduce alarm fatigue with AI-driven alarm analytics,
                    prioritization, and filtering.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes appear {
                            0% {
                                opacity: 0;
                                transform: translateY(5px);
                            }
                            100% {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                        @keyframes slide {
                            0% {
                                transform: translateX(-5px);
                            }
                            100% {
                                transform: translateX(5px);
                            }
                        }
                        .widget {
                            animation: appear 0.5s ease-out forwards;
                        }
                        .widget-1 {
                            animation-delay: 0.1s;
                        }
                        .widget-2 {
                            animation-delay: 0.3s;
                        }
                        .widget-3 {
                            animation-delay: 0.5s;
                        }
                        .widget-4 {
                            animation-delay: 0.7s;
                        }
                        .drag {
                            animation: slide 2s ease-in-out infinite alternate;
                        }
                    </style>
                    <rect
                        x="10"
                        y="10"
                        width="80"
                        height="80"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                        rx="3"
                    />
                    <line
                        x1="10"
                        y1="25"
                        x2="90"
                        y2="25"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle cx="20" cy="17" r="3" fill="#1d9395" />
                    <circle cx="30" cy="17" r="3" fill="#1d9395" />
                    <rect
                        class="widget widget-1"
                        x="15"
                        y="30"
                        width="30"
                        height="20"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                        rx="2"
                    />
                    <path
                        class="widget widget-1"
                        d="M20,40 L25,35 L30,45 L35,38"
                        stroke="#1d9395"
                        stroke-width="1"
                        fill="none"
                    />
                    <rect
                        class="widget widget-2"
                        x="55"
                        y="30"
                        width="30"
                        height="20"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                        rx="2"
                    />
                    <circle
                        class="widget widget-2"
                        cx="70"
                        cy="40"
                        r="8"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <path
                        class="widget widget-2"
                        d="M65,36 L75,44"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <rect
                        class="widget widget-3"
                        x="15"
                        y="60"
                        width="30"
                        height="20"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                        rx="2"
                    />
                    <line
                        class="widget widget-3"
                        x1="20"
                        y1="65"
                        x2="40"
                        y2="65"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <line
                        class="widget widget-3"
                        x1="20"
                        y1="70"
                        x2="35"
                        y2="70"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <line
                        class="widget widget-3"
                        x1="20"
                        y1="75"
                        x2="38"
                        y2="75"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                    <rect
                        class="widget widget-4 drag"
                        x="55"
                        y="60"
                        width="30"
                        height="20"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="1"
                        rx="2"
                        stroke-dasharray="3"
                    />
                    <path
                        class="widget widget-4 drag"
                        d="M65,70 L75,70 M70,65 L70,75"
                        stroke="#1d9395"
                        stroke-width="1"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Customizable Dashboards </strong>
                <p>
                    Visualize key performance indicators (KPIs) and metrics in
                    real-time, including OEE, throughput, quality, and
                    availability.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Second Row -->
<div class="m2-afmc-bottom-section">
    <div class="m2-afmc-item" id="bottom-4">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes pulse {
                        0%,
                        100% {
                            transform: scale(0.95);
                            opacity: 0.7;
                        }
                        50% {
                            transform: scale(1.05);
                            opacity: 1;
                        }
                    }
                    @keyframes checkmark {
                        0% {
                            stroke-dashoffset: 100;
                        }
                        100% {
                            stroke-dashoffset: 0;
                        }
                    }
                    .action-pulse {
                        animation: pulse 2s infinite;
                    }
                    .check {
                        stroke-dasharray: 100;
                        animation: checkmark 1.5s ease-out forwards;
                    }
                </style>
                <circle
                    cx="50"
                    cy="35"
                    r="20"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <path
                    class="check"
                    d="M40,35 L48,43 L60,28"
                    stroke="#1d9395"
                    stroke-width="3"
                    fill="none"
                />
                <rect
                    class="action-pulse"
                    x="25"
                    y="60"
                    width="50"
                    height="25"
                    rx="5"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="35"
                    y1="70"
                    x2="65"
                    y2="70"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="35"
                    y1="75"
                    x2="60"
                    y2="75"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <path
                    d="M30,60 L30,45 L40,35 M60,35 L70,45 L70,60"
                    stroke="#1d9395"
                    stroke-width="1"
                    stroke-dasharray="3"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Seamless Integration </strong>
            <p>
                Integrate with existing SCADA, DCS, and process control systems,
                as well as ERP and MES systems.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-5">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes connect {
                        0%,
                        100% {
                            stroke-dashoffset: 0;
                        }
                        50% {
                            stroke-dashoffset: 20;
                        }
                    }
                    @keyframes highlight {
                        0%,
                        100% {
                            fill-opacity: 0.4;
                            stroke-width: 1;
                        }
                        50% {
                            fill-opacity: 0.8;
                            stroke-width: 2;
                        }
                    }
                    .connection {
                        stroke-dasharray: 5;
                        animation: connect 3s linear infinite;
                    }
                    .system {
                        animation: highlight 2s infinite alternate;
                    }
                </style>
                <rect
                    class="system"
                    x="10"
                    y="20"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    fill-opacity="0.4"
                    stroke="#1d9395"
                    stroke-width="1"
                    rx="3"
                />
                <rect
                    class="system"
                    x="65"
                    y="20"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    fill-opacity="0.4"
                    stroke="#1d9395"
                    stroke-width="1"
                    rx="3"
                />
                <rect
                    class="system"
                    x="10"
                    y="55"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    fill-opacity="0.4"
                    stroke="#1d9395"
                    stroke-width="1"
                    rx="3"
                />
                <rect
                    class="system"
                    x="65"
                    y="55"
                    width="25"
                    height="25"
                    fill="#1d9395"
                    fill-opacity="0.4"
                    stroke="#1d9395"
                    stroke-width="1"
                    rx="3"
                />
                <circle
                    cx="50"
                    cy="50"
                    r="15"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <circle cx="50" cy="50" r="7" fill="#1d9395" opacity="0.7" />
                <line
                    class="connection"
                    x1="35"
                    y1="32"
                    x2="50"
                    y2="43"
                    stroke="#1d9395"
                    stroke-width="1.5"
                />
                <line
                    class="connection"
                    x1="65"
                    y1="32"
                    x2="50"
                    y2="43"
                    stroke="#1d9395"
                    stroke-width="1.5"
                />
                <line
                    class="connection"
                    x1="35"
                    y1="68"
                    x2="50"
                    y2="57"
                    stroke="#1d9395"
                    stroke-width="1.5"
                />
                <line
                    class="connection"
                    x1="65"
                    y1="68"
                    x2="50"
                    y2="57"
                    stroke="#1d9395"
                    stroke-width="1.5"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Continuous Learning </strong>
            <p>
                Improve predictive accuracy over time with machine learning
                algorithms that adapt to changing process conditions.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes scale {
                        0% {
                            transform: scale(0.7);
                            opacity: 0.3;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    @keyframes rotate {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                    .scale-up {
                        animation: scale 2s infinite alternate;
                    }
                    .scale-up-1 {
                        animation-delay: 0s;
                    }
                    .scale-up-2 {
                        animation-delay: 0.5s;
                    }
                    .scale-up-3 {
                        animation-delay: 1s;
                    }
                    .orbit {
                        transform-origin: 50px 50px;
                        animation: rotate 10s linear infinite;
                    }
                </style>
                <circle
                    class="scale-up scale-up-1"
                    cx="50"
                    cy="50"
                    r="10"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <circle
                    class="scale-up scale-up-2"
                    cx="50"
                    cy="50"
                    r="20"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="1.5"
                />
                <circle
                    class="scale-up scale-up-3"
                    cx="50"
                    cy="50"
                    r="30"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="1"
                />
                <path
                    class="orbit"
                    d="M50,20 L55,25 M50,80 L45,75 M20,50 L25,45 M80,50 L75,55"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <circle class="orbit" cx="50" cy="20" r="3" fill="#1d9395" />
                <circle class="orbit" cx="50" cy="80" r="3" fill="#1d9395" />
                <circle class="orbit" cx="20" cy="50" r="3" fill="#1d9395" />
                <circle class="orbit" cx="80" cy="50" r="3" fill="#1d9395" />
                <path
                    d="M40,45 L60,55 M40,55 L60,45"
                    stroke="#1d9395"
                    stroke-width="1"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Alerts and Notifications </strong>
            <p>
                Receive real-time alerts and notifications for critical events,
                including equipment failures, process disruptions, and quality
                issues.
            </p>
        </div>
    </div>
</div>

<!-- Fifth Section -->
<!-- First Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-1">
        <img
            src="assets/img/OptebizImage/Facto IQ Dashboard 1.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Predictive Maintenance</div>
        <div class="m2-afmc-copy-text">
            <p>
                Predict equipment failures and schedule maintenance proactively,
                reducing downtime and increasing overall equipment effectiveness
                (OEE).
            </p>
            <p>With FactoIQ, you can:</p>
            <ul>
                <li>Identify potential equipment failures before they occur</li>
                <li>Schedule maintenance during planned downtime</li>
                <li>Reduce maintenance costs and extend equipment lifespan</li>
            </ul>
        </div>
    </div>
</div>

<!-- Second Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-4">
        <img
            src="assets/img/OptebizImage/quality optimization_factoiq.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Quality Optimization</div>
        <div class="m2-afmc-copy-text">
            <p>
                Optimize process parameters in real-time to improve product
                quality and reduce waste.
            </p>
            <p>With FactoIQ, you can:</p>
            <ul>
                <li>Monitor product quality in real-time</li>
                <li>Identify process parameters that impact quality</li>
                <li>Adjust process parameters to optimize quality</li>
            </ul>
        </div>
    </div>
</div>

<!-- Third Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/Facto IQ Cycle.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Real-Time Insights</div>
        <div class="m2-afmc-copy-text">
            <p>
                Gain real-time insights into process performance, quality, and
                alarm metrics, enabling prompt corrective action and optimized
                operations.
            </p>
            <p>With FactoIQ, you can:</p>
            <ul>
                <li>Visualize process performance in real-time</li>
                <li>Identify areas for improvement</li>
                <li>Make data-driven decisions to optimize operations</li>
            </ul>
        </div>
    </div>
</div>

<!-- last section -->
<section class="mb-5 mt-5">
    <div class="container">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title cs_text_white">
                        Unlock the Power of Predictive Manufacturing
                    </h2>
                    <p class="cs_section_subtitle mb-0">
                        Discover how FactoIQ can transform your manufacturing
                        operations. Contact our team today for a consultation
                        and demo!
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="/BookDemo" class="cs_btn cs_bg_white"
                        >Start Free Trial</a
                    >
                    <a href="/TryItNow" class="cs_btn cs_bg_accent"
                        >Purchase Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
