import { Component } from '@angular/core';

@Component({
  selector: 'app-time-series-analytics',
  templateUrl: './time-series-analytics.component.html',
  styleUrls: ['./time-series-analytics.component.scss']
})
export class TimeSeriesAnalyticsComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto IQ',
          subTitle: 'Timeseries Analytics',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/Time-series-analysis-using-graph-and-bars.jpg'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Time series Analytics',
          title1: 'Time series Analytics',
          paragraphText1: 'Time series data is information collected at regular time intervals, representing variables like stock prices, temperature, or sales figures that change over time',
          paragraphText2: 'This could involve monitoring equipment performance, production rates, quality control, and other relevant metrics. The goal is to identify and address any issues that may arise in real-time, minimizing downtime and maximizing productivity. Its like having a watchful eye over the entire production process to keep things on track.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/time-series.png',
          img2: 'assets/img/OptebizImage/features-banner-v1.webp'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Data Logging and Storage'
      },
      {
          title: 'Tag Historian Module'
      },
      {
          title: 'Trend Analysis'
      },
      {
          title: 'Alarming and Event History'
      },
      {
          title: 'Real-Time and Historical Overlays'
      },
      {
          title: 'Data Export and Reporting'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'MES Analytics',
        link: 'mes-analytics'
    },
    {
      title: 'Alarm Analytics',
      link: 'alarm-analytics'
  },
  {
      title: 'Quality Analytics',
      link: 'quality-analytics'
  },
  {
      title: 'Process control Analytics',
      link: 'process-control-analytics'
  }

  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
      {
          icon: 'bx bx-user-pin',
          title: 'Phone',
          subTitle: '+1 (419) 476-4520'
      },
      {
          icon: 'bx bx-map',
          title: 'Location',
          subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
      },
      {
          icon: 'bx bx-envelope',
          title: 'Email',
          subTitle: 'bizops@OpteBiz.com'
      }
  ]
  viewDetailsDescApplication: Application[] = [
    {
        title: 'Historical Data Logging',
        icon: 'flaticon-factory'
    },
    {
        title: 'Alarm and Event History',
        icon: 'flaticon-hospital'
    },
    {
        title: 'Performance Monitoring',
        icon: 'flaticon-tracking'
    },
    {
        title: 'Quality Control',
        icon: 'flaticon-investment'
    },
    {
        title: 'Trend Analysis',
        icon: 'flaticon-house'
    },
    {
        title: 'Predictive Maintenance',
        icon: 'flaticon-order'
    }
]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}




