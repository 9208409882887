import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state, query, stagger, group } from '@angular/animations';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

interface ImageConfig {
  src: string;
  animationIn: any[];
  animationOut: any[];
}

interface Slide {
  id: string;
  stepCounter: string;
  title: string| SafeHtml;
  description: string;
  mediaSource: string;
  mediaType: 'image' | 'video';
}
class sectionTitleContent {
  subtitle:string;
  title : string;
  paragraphText : string;
  }
  class ServicesBoxContent {
  icon : string;
  title : string;
  paragraphText : string[];
  linkText : string;
  linkIcon : string;
  link : string;
  }

@Component({
  selector: 'app-landing-page-new',
  templateUrl: './landing-page-new.component.html',
  styleUrls: ['./landing-page-new.component.scss'],
  animations: [
    trigger('zoomTransition', [
      transition(':increment', [
        style({ opacity: 0, transform: 'scale(1)' }),
        animate(
          '1500ms ease-out',
          style({ opacity: 1, transform: 'scale(1.1)' })
        ),
      ]),
      transition(':decrement', [
        style({ opacity: 0, transform: 'scale(1)' }),
        animate(
          '1500ms ease-out',
          style({ opacity: 1, transform: 'scale(1.1)' })
        ),
      ]),
    ]),
  
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    
    trigger('cardsBatch', [
      transition(':increment', [
        query(
          ':enter',
          [
            style({
              transform: 'translateX(-100%)',
              opacity: 0,
            }),
            animate(
              '300ms ease-out',
              style({
                transform: 'translateX(0)',
                opacity: 1,
              })
            ),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            animate(
              '300ms ease-in',
              style({
                transform: 'translateX(100%)',
                opacity: 0,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
      transition(':decrement', [
        query(
          ':enter',
          [
            style({
              transform: 'translateX(100%)',
              opacity: 0,
            }),
            animate(
              '300ms ease-out',
              style({
                transform: 'translateX(0)',
                opacity: 1,
              })
            ),
          ],
          { optional: true }
        ),
        query(
          ':leave',
          [
            animate(
              '300ms ease-in',
              style({
                transform: 'translateX(-100%)',
                opacity: 0,
              })
            ),
          ],
          { optional: true }
        ),
      ]),
    ])
    
    
    
    ],
    
  
  
  
})
export class LandingPageNewComponent implements OnInit,AfterViewInit, OnDestroy {


  @ViewChildren('videoPlayer') videoPlayers: QueryList<ElementRef<HTMLVideoElement>>;
  ngOnInit() {
    this.initializeSlides();
  this.startImageCycle();
    this.initializeCardDisplay();
    this.startAutoSlide();
  }
  images: ImageConfig[] = [
    {
      src: 'assets/img/OptebizImage/LandingImg.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateX(-90deg) scale(0.5)' }),
        animate('2000ms cubic-bezier(0.25, 0.1, 0.25, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateX(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(-100%) rotate(20deg)' })
        )
      ]
    },
    {
      src: 'assets/img/OptebizImage/LandingImg2.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'translateY(50px) scale(0.8)' }),
        animate('2000ms ease-out', 
          style({ opacity: 1, transform: 'translateY(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'translateX(100%) rotate(-20deg)' })
        )
      ]
    },
    {
      src: 'assets/img/OptebizImage/Landingimg3.jpg',
      animationIn: [
        style({ opacity: 0, transform: 'perspective(1000px) rotateY(-90deg) scale(0.7)' }),
        animate('2000ms cubic-bezier(0.45, 0, 0.55, 1)', 
          style({ opacity: 1, transform: 'perspective(1000px) rotateY(0) scale(1)' })
        )
      ],
      animationOut: [
        animate('1000ms ease-in', 
          style({ opacity: 0, transform: 'scale(1.2) rotate(20deg)' })
        )
      ]
    }
  ];
  slides: Slide[] = [];
  private initializeSlides() {
    this.slides = [
      {
        id: 'slide-1',
        stepCounter: '01',
        title: this.sanitizer.bypassSecurityTrustHtml('Real-Time Visibility & <strong>Control</strong>'),
        description: 'Achieve end-to-end transparency and centralized monitoring of your production processes, enabling data-driven decision making.',
       
         mediaSource: 'assets/img/OptebizImage/Delivery on Time (4).mp4',
        mediaType: 'video'
      },
      {
        id: 'slide-2',
        stepCounter: '02',
        title: this.sanitizer.bypassSecurityTrustHtml('Predictive Maintenance & <strong>Optimization</strong>'),
        description: 'Leverage AI-powered analytics to forecast equipment failures, optimize asset performance, and maximize Overall Equipment Effectiveness (OEE).',
        mediaSource: 'assets/img/OptebizImage/Delivery on Time (2) (1).mp4',
        mediaType: 'video'
      },
      {
        id: 'slide-3',
        stepCounter: '03',
        title: this.sanitizer.bypassSecurityTrustHtml('Intelligent Insights for Informed <strong>Decision Making</strong>'),
        description: 'Unlock hidden capacity, identify bottlenecks, and drive continuous improvement with comparative analytics and performance benchmarking.',
        mediaSource: 'assets/img/OptebizImage/Delivery on Time (3).mp4',
        mediaType: 'video'
      }
    ];
  }
  sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Our Products",
        title: 'Transforming Business with',
        paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
  {
    icon: 'assets/img/OptebizImage/facto MESLogo.png',
    title: 'FactoMES',
    paragraphText: [
      'All-in-one shop floor monitoring solution.',
      'Optimizes operations & automates workflows.',
      'Manages orders, scheduling & product flow.',
      'Improves production efficiency with real-time tracking.'
    ],
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoMES'
  },
  {
    icon: 'assets/img/OptebizImage/facto SPC.png',
    title: 'FactoVision',
    paragraphText: [
      'AI cameras monitor SOP compliance.',
      'Detects defects and ensures accurate counting in real-time.',
      'Integrates with MES, ERP, and quality systems.',
      'Provides instant alerts and quality control reports.'
    ],   
  
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoVision'
  },
  {
    icon: 'assets/img/OptebizImage/factoLake 2.png',
    title: 'FactoLake',
    paragraphText: [
      'Tracks and assesses key performance indicators (KPIs).',
      'Identifies inefficiencies and optimizes resource allocation.',
      'Enhances supply chain visibility and reduces waste.',
      'Provides actionable insights through advanced analytics.'
    ],
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoLake'
  },
  {
    icon: 'assets/img/OptebizImage/facto SPC.png',
    title: 'FactoPlan',
    paragraphText: [
      'Optimizes resource allocation for better efficiency.',
      'Ensures projects are delivered on time and within budget.',
      'Provides clear insights into resource availability.',
      'Supports strategic decision-making with real-time data.'
    ],
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoPlan'
  },
  {
    icon: 'assets/img/OptebizImage/facto IQlogo.png',
    title: 'FactoIQ',
    paragraphText: [
      'Collects real-time timeseries data for analysis.',
      'Analyzes shop floor processes, maintenance, and operations data.',
      'Uses statistical and AI methodologies for insights.',
      'Enhances predictive maintenance to minimize downtime.'
    ],
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoIQ'
  },
  {
    icon: 'assets/img/OptebizImage/facto MESLogo.png',
    title: 'FactoMES',
    paragraphText: [
      'All-in-one shop floor monitoring solution.',
      'Optimizes operations & automates workflows.',
      'Manages orders, scheduling & product flow.',
      'Improves production efficiency with real-time tracking.'
    ],
    linkText: 'Learn More',
    linkIcon: 'flaticon-right',
    link: 'FactoMES'
  },
];
  currentImageIndex = 0;
  currentSlideIndex = 0;
  private imageInterval: any;
  private currentVideo: HTMLVideoElement | null = null;
  visibleCards: any[] = [];
  cardsPerView = 3;
  private slideInterval: Subscription;
  readonly batchSizes = [3, 3];
  currentBatchIndex = 0;
  private totalBatches = this.batchSizes.length;
  private autoSlideSubscription: Subscription;
  private readonly AUTO_SLIDE_INTERVAL = 5000;

  constructor(private sanitizer: DomSanitizer) {
    this.slideInterval = new Subscription();
    this.autoSlideSubscription = new Subscription();
  }
  isDotActive(index: number): boolean {
    return this.currentBatchIndex === index;
  }

 
  private initializeCardDisplay() {
    this.updateVisibleCards();
    // Initialize with first batch
    this.currentBatchIndex = 0;
    this.visibleCards = this.getVisibleCardsForCurrentBatch();
  }
  private startAutoSlide() {
    this.autoSlideSubscription = interval(this.AUTO_SLIDE_INTERVAL).subscribe(() => {
      this.slideNext();
    });
  }

  private getVisibleCardsForCurrentBatch(): any[] {
    let startIndex = 0;
    for (let i = 0; i < this.currentBatchIndex; i++) {
      startIndex += this.batchSizes[i];
    }
    
    const batchSize = this.batchSizes[this.currentBatchIndex];
    return this.singleServicesBoxItem.slice(startIndex, startIndex + batchSize);
  }
  

    ngAfterViewInit() {
    // Initialize video players
    this.initializeVideos();
    
    // Subscribe to changes in video players (when slides change)
    this.videoPlayers.changes.subscribe(() => {
      this.initializeVideos();
    });
  }
  ngOnDestroy() {
    if (this.imageInterval) {
      clearInterval(this.imageInterval);
    }
    else{
// Clean up any playing videos
this.stopCurrentVideo();
    }
    if (this.slideInterval) {
      this.slideInterval.unsubscribe();
    }
    
  }


  updateVisibleCards() {
    const newVisibleCards = this.getVisibleCardsForCurrentBatch();
    
    // Only update if the cards have actually changed
    if (JSON.stringify(this.visibleCards) !== JSON.stringify(newVisibleCards)) {
      this.visibleCards = newVisibleCards;
    }
  }


  slideNext() {
    this.currentBatchIndex = (this.currentBatchIndex + 1) % this.totalBatches;
    this.updateVisibleCards();
  }

  slidePrev() {
    this.currentBatchIndex = (this.currentBatchIndex - 1 + this.totalBatches) % this.totalBatches;
    this.updateVisibleCards();
  
  }
    getColumnClass(): string {
      const currentBatchSize = this.batchSizes[this.currentBatchIndex];
      const colSize = Math.floor(12 / currentBatchSize);
      return `col-lg-${colSize} col-md-6 col-sm-12`;
    }
    pauseAutoSlide() {
      this.autoSlideSubscription.unsubscribe();
    }
    goToBatch(index: number) {
      this.currentBatchIndex = index;
      this.updateVisibleCards();
      // Reset auto-slide timer when manually navigating
      if (this.autoSlideSubscription) {
        this.autoSlideSubscription.unsubscribe();
        // this.startAutoSlide();
      }
    }
    private initializeVideos() {
      const currentPlayer = this.videoPlayers.toArray()[this.currentSlideIndex]?.nativeElement;
    
      if (currentPlayer) {
        // Configure video attributes
        currentPlayer.playsInline = true;
        currentPlayer.muted = true;
        currentPlayer.preload = 'auto';
    
        // Add event listeners
        currentPlayer.addEventListener('loadedmetadata', () => this.playVideo(currentPlayer));
        currentPlayer.addEventListener('error', (e) => this.onVideoError(e));
    
        // Play video
        this.playVideo(currentPlayer);
      }
    }
    
  private onVideoLoaded(video: HTMLVideoElement) {
    if (this.slides[this.currentSlideIndex].mediaType === 'video') {
      this.playVideo(video);
    }
  }
  private onVideoError(event: ErrorEvent) {
    console.error('Video loading error:', event);
  }

  private playVideo(video: HTMLVideoElement) {
    this.stopCurrentVideo();
    this.currentVideo = video;
    
    video.play().catch(error => {
      console.warn('Autoplay failed:', error);
      
      // Fallback for autoplay restrictions
      const playOnInteraction = () => {
        video.play().catch(console.error);
        document.removeEventListener('click', playOnInteraction);
      };
      document.addEventListener('click', playOnInteraction);
    });
  }
  private stopCurrentVideo() {
    if (this.currentVideo) {
      this.currentVideo.pause();
      this.currentVideo.currentTime = 0;
    }
  }
  nextSlide() {
    if (this.currentSlideIndex < this.slides.length - 1) {
      this.stopCurrentVideo();
      this.currentSlideIndex++;
      // Initialize video for new slide if it's a video
      if (this.slides[this.currentSlideIndex].mediaType === 'video') {
        this.initializeVideos();
      }
    }
  }

  prevSlide() {
    if (this.currentSlideIndex > 0) {
      this.stopCurrentVideo();
      this.currentSlideIndex--;
      // Initialize video for new slide if it's a video
      if (this.slides[this.currentSlideIndex].mediaType === 'video') {
        this.initializeVideos();
      }
    }
  }
  getProgressWidth(index: number): number {
    return index === this.currentSlideIndex ? 100 : 0;
  }

  startImageCycle() {
    setInterval(() => {
      this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
    }, 3500);
  }

  nextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }

  prevImage() {
    this.currentImageIndex = (this.currentImageIndex - 1 + this.images.length) % this.images.length;
  }




}