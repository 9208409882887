<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img
                            src="assets/img/OptebizImage/favicon-leanqubitbr.png"
                            alt="favicon"
                            class="favicon"
                        />
                        <img
                            src="assets/img/LeanQbit_logo.png"
                            alt="logo"
                            class="leanqbit-logo"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img
                        src="assets\img\OptebizImage\favicon-leanqubitbr.png"
                        alt="favicon"
                        style="
                            width: 80px;
                            height: 80px;
                            margin-right: -10px;
                            top: 10px;
                        "
                    />
                    <img
                        src="assets\img\LeanQbit_logo.png"
                        alt="logo"
                        style="width: 150px; height: 80px"
                    />
                </a>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>
                        <ul class="dropdown-menu"></ul>
                        <!-- product -->
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Products <i class="bx bx-chevron-right"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/FactoMES"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FactoMES</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/FactoVision"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FactoVision</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/FactoLake"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FactoLake</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/FactoPlan"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FactoPlan</a
                                    >
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/FactoIQ"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FactoIQ</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <!-- service -->

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                Services <i class="bx bx-chevron-right"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <!-- Ignition Services with nested dropdown -->
                                <li class="nav-item">
                                    <a
                                        routerLink="/inductive-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Inductive Automation
                                        <i class="bx bx-chevron-right"></i>
                                    </a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/Basic"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                            >
                                                Basic
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/intermediate"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                            >
                                                Intermediate
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/Advanced"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                            >
                                                Advanced
                                            </a>
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/Add-on-service"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                            >
                                                Add-On Services - Available at
                                                all levels
                                            </a>
                                        </li>
                                    </ul>
                                </li>

                                <!-- AWS Service -->
                                <li class="nav-item">
                                    <a
                                        routerLink="/aws"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Amazon Web Service (Saas)
                                    </a>
                                </li>
                                <!-- Ignition -->
                                <li class="nav-item">
                                    <a
                                        routerLink="/Scada"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Ignition SCADA
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <!-- solution -->
                        <li class="nav-item">
                            <a
                                routerLink="/Solution"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Solution</a
                            >
                        </li>
                        <!-- About Us -->
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">
                                About Us
                                <i class="bx bx-chevron-right"></i>
                            </a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        routerLink="/Overview"
                                        class="nav-link"
                                    >
                                        Overview
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        FAQ
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/Client-Testimonials"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Testimonials
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a
                                        routerLink="/BookDemo"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                    >
                                        Contact
                                    </a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a
                                routerLink="/pricing"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Pricing</a
                            >
                        </li> -->
                        <!-- <li class="nav-item">
                            <a
                                routerLink="/TestingMainBanner"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >TestingMainBanner(WIP)</a
                            >
                        </li> -->
                        <!-- <li class="nav-item">
                            <a
                                routerLink="/TryItNow"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >TryItNow(WIP)</a
                            >
                        </li> -->
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="/BookDemo" class="default-btn"
                                >Book A Demo<span></span
                            ></a>
                            <a routerLink="/TryItNow" class="default-btn"
                                >Try Free Now<span></span
                            ></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive" #menuContainer>
        <div class="container">
            <!-- Dot menu button -->
            <!-- <div class="dot-menu" (click)="toggleMenu($event)">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div> -->
            <!-- <div class="container" [class.active]="isMenuOpen">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <a
                                class="default-btn"
                                (click)="navigateAndClose('/BookDemo')"
                            >
                                Book A Demo<span></span>
                            </a>
                            <a
                                class="default-btn"
                                (click)="navigateAndClose('/TryItNow')"
                            >
                                Try Free Now<span></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
