<!-- !-- homeone-banner.component.html -- -->
<div class="main-banner">
    <div class="container-fluid">
        <div class="row" *ngIf="isMainBannerVisible">
            <!-- Content Section -->
            <div class="col-lg-4 col-md-12">
                <div
                    class="main-banner-content"
                    [@slideAnimation]="currentIndex"
                >
                    <!-- Title Section -->
                    <div
                        class="c-v2-intro-title c-v2-intro-title-fade-words animation-element fade-in-up in-view"
                    >
                        <!-- <h3>{{ currentIndex }}</h3> -->
                        <h1>
                            <ng-container
                                *ngIf="mainBannerContent[currentIndex].title"
                            >
                                <!-- Split title and animate first word -->
                                <span class="animate-charcter">{{
                                    getFirstWord(
                                        mainBannerContent[currentIndex].title
                                    )
                                }}</span>
                                {{
                                    getRemainingWords(
                                        mainBannerContent[currentIndex].title
                                    )
                                }}
                            </ng-container>
                        </h1>
                        <div class="c-v2-intro-title-hidden"></div>
                    </div>
                    <!-- Description -->
                    <p
                        class="wow animate__animated animate__fadeInLeft"
                        data-wow-delay="100ms"
                        data-wow-duration="1000ms"
                    >
                        {{ mainBannerContent[currentIndex].paragraphText }}
                    </p>

                    <!-- Buttons -->
                    <div class="btn-box">
                        <a
                            [routerLink]="[
                                '/',
                                mainBannerContent[currentIndex].defaultBtnLink
                            ]"
                            class="default-btn wow animate__animated animate__fadeInRight"
                            data-wow-delay="200ms"
                            data-wow-duration="1000ms"
                        >
                            <i
                                [class]="
                                    mainBannerContent[currentIndex]
                                        .defaultBtnIcon
                                "
                            ></i>
                            {{ mainBannerContent[currentIndex].defaultBtnText }}
                            <span></span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12">
                <div class="row g-4">
                    <!-- Featured Service Cards -->
                    <div
                        class="col-md-6"
                        *ngFor="
                            let service of mainBannerContent[
                                currentIndex
                            ].featuredServices.slice(0, 2);
                            let i = index
                        "
                    >
                        <div
                            class="single-services-box-item 
                        wow animate__animated animate__fadeInRight 
                        {{ currentIndex === 0 ? 'custom-style-index-1' : '' }}
                        {{
                                currentIndex === 0 && i === 1
                                    ? 'second-card'
                                    : ''
                            }}"
                            data-wow-delay="100ms"
                            data-wow-duration="1000ms"
                        >
                            <div class="icon">
                                <img
                                    [src]="service.icon"
                                    [alt]="service.title"
                                    class="img-fluid"
                                    responsiveness
                                />
                            </div>
                            <h3>
                                {{ service.title }}
                            </h3>
                            <!-- <p>
                                {{ service.paragraphText }}
                            </p> -->
                            <div style="flex-grow: 1; overflow-y: auto">
                                <ul>
                                    <li
                                        *ngFor="
                                            let point of service.paragraphText
                                        "
                                    >
                                        {{ point }}
                                    </li>
                                </ul>
                            </div>
                            <a
                                [routerLink]="['/', service.link]"
                                class="learn-more-btn"
                            >
                                {{ service.linkText }}
                                <i [class]="service.linkIcon"></i>
                            </a>
                            <div class="shape">
                                <img
                                    src="assets/img/services/shape4.png"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Navigation Arrows -->
        <div class="carousel-controls">
            <button
                class="prev-btn"
                (click)="prevSlide()"
                [disabled]="animating"
            >
                <i class="fas fa-chevron-left"></i>
            </button>
            <button
                class="next-btn"
                (click)="nextSlide()"
                [disabled]="animating"
            >
                <i class="fas fa-chevron-right"></i>
            </button>
        </div>

        <div class="additional-content" *ngIf="!isMainBannerVisible">
            <!-- Content to be shown after every 2 slides -->
            <div class="content-section">
                <div class="content">
                    <div class="heading">
                        <span class="gradient-word">Partnering With </span>
                        <span>
                            <img
                                src="assets/img/OptebizImage/Inductive-Automation-Ignition-Designer-Logo.png"
                                alt="Ignition"
                                title="Ignition"
                                width="150"
                                height="150"
                                style="margin-top: -26px"
                            />
                        </span>
                    </div>
                    <div class="detail">
                        Our collaboration with Inductive Automation brings you
                        state-of-the-art Ignition-based solutions that enhance
                        operational efficiency and drive innovation in your
                        manufacturing processes.
                    </div>
                    <div class="button-section">
                        <a
                            routerLink="/inductive-details"
                            class="learn-more-btn"
                        >
                            <i class="flaticon-right"></i> Learn More
                        </a>
                    </div>
                </div>
                <div class="image">
                    <img
                        src="assets/img/OptebizImage/Downloads.png"
                        alt="Inductive Automation"
                        title="Inductive Automation"
                        width="450"
                        height="500"
                    />
                    <span>
                        <img
                            src="assets/img/OptebizImage/inductive-automation-llc-logo2.png"
                            data-src="assets/img/OptebizImage/inductive-automation-llc-logo2.png"
                            alt="Inductive Automation"
                            title="Inductive Automation"
                            style="
                                height: 100px;
                                margin-top: -21px;
                                margin-left: 245px;
                            "
                        />
                    </span>
                </div>
            </div>
        </div>
        <!-- Toggle Button -->
        <!-- <button (click)="toggleBannerVisibility()" class="toggle-btn">
            Toggle Banner
        </button> -->
    </div>
</div>
