<div class="m4-thank-you">
    <div class="m2-fixed-container">
        <div class="m4-ty-container">
            <div class="left">
                <div
                    class="m2-heading-title"
                    style="white-space: nowrap; display: inline-block"
                >
                    <h1>
                        <span>THANK YOU!</span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </h1>
                </div>
                <div class="text">
                    <p>
                        We received your submission and our team will be in
                        touch soon!
                    </p>
                </div>
            </div>
            <div class="right">
                <div class="cover"></div>
            </div>
        </div>
    </div>
</div>

<div class="m4-suggested-content">
    <div class="m2-fixed-container">
        <div class="m4-suggested-content-cont">
            <h2>Suggested Content:</h2>
            <div class="m4-scc-boxes">
                <div class="m4-scc-box">
                    <a
                        href="BookDemo"
                        target="_blank"
                        rel="noopener"
                        contenteditable="false"
                        style="cursor: pointer"
                    >
                        <div
                            class="m4-scc-img"
                            style="
                                background: url(assets/img/OptebizImage/productiondataset.png)
                                    no-repeat;
                                background-size: cover;
                                background-position: center;
                            "
                        ></div>
                        <div class="content">
                            <h3>Book a Demo of LeanQubit</h3>
                            <div class="text">
                                The leading platform to collect, monitor,
                                analyze, and drive action with machine data. Set
                                up time with a product specialist to learn how
                                we can help your operation.
                            </div>
                        </div>
                    </a>
                </div>

                <div class="m4-scc-box">
                    <a
                        href="FactoMES"
                        target="_blank"
                        rel="noopener"
                        contenteditable="false"
                        style="cursor: pointer"
                    >
                        <div
                            class="m4-scc-img"
                            style="
                                background: url(assets/img/OptebizImage/facto%20mes%20Latest.svg)
                                    no-repeat;
                                background-size: cover;
                                background-position: center;
                            "
                        ></div>
                        <div class="content">
                            <h3>Explore MES Technology in Action</h3>
                            <div class="text">
                                Our MES solution seamlessly integrates
                                production tracking, real-time analytics,
                                quality management, and operational efficiency
                                into a powerful platform
                            </div>
                        </div>
                    </a>
                </div>

                <div class="m4-scc-box">
                    <a
                        href="Solution"
                        target="_blank"
                        rel="noopener"
                        contenteditable="false"
                        style="cursor: pointer"
                    >
                        <div
                            class="m4-scc-img"
                            style="
                                background: url(assets/img/OptebizImage/DowntimeDashboard.png)
                                    no-repeat;
                                background-size: cover;
                                background-position: center;
                            "
                        ></div>
                        <div class="content">
                            <h3>Learn How to Run a Downtime Analysis</h3>
                            <div class="text">
                                Our comprehensive solution provides deep
                                insights, root cause detection, and actionable
                                recommendations to minimize downtime and
                                maximize manufacturing performance.
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
