import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details-factoplan',
  templateUrl: './service-details-factoplan.component.html',
  styleUrls: ['./service-details-factoplan.component.scss']
})
export class ServiceDetailsFactoplanComponent {

}
