<section class="history-area ptb-100">
    <div class="container" style="max-width: 1400px">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subTitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
            >
                {{ sectionTitleContent.title
                }}<span style="color: #1d9395"> operational </span
                ><span>excellence</span>
            </h2>
            <p>
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div class="private-dbaas come-out loaded come-in">
            <div class="container">
                <div class="dbaas-options">
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\Controlstep1.png"
                                data-src="assets\img\OptebizImage\Controlstep1.png"
                                alt="Machine Control System and Network Transformation Journey"
                                title="Machine Control System and Network Transformation Journey"
                                width="298"
                                height="376"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Control</span>
                                    & Network Upgrade Journey
                                </div>
                                <div class="detail">
                                    Upgrade your SCADA system with our Control
                                    System & Network Transformation services,
                                    ensuring seamless integration, enhanced
                                    efficiency, and superior connectivity
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\database transform.png"
                                data-src="assets\img\OptebizImage\database transform.png"
                                alt="Database Transformation Journey"
                                title="Database Transformation Journey"
                                width="336"
                                height="272"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Database </span>
                                    Upgrade Journey
                                </div>
                                <div class="detail">
                                    Optimize your database with our DB
                                    Transformation services, including
                                    migration, schema restructuring, and modern
                                    DBMS adoption for improved performance and
                                    resilience.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\materiallatest1.png"
                                data-src="assets\img\OptebizImage\materiallatest1.png"
                                alt="Material Transformation Journey"
                                title="Material Transformation Journey"
                                width="387"
                                height="491"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word"
                                        >Production</span
                                    >
                                    Evolution & Transformation
                                </div>
                                <div class="detail">
                                    Enhance inventory tracking with advanced
                                    technology, real-time visibility, and
                                    automation, optimizing procurement and
                                    reducing lead times.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\paperless.png"
                                data-src="assets\img\OptebizImage\paperless.png"
                                alt="Paperless Transformation"
                                title="Paperless Transformation"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Paperless</span>
                                    business Transformation
                                </div>
                                <div class="detail">
                                    Go paperless with digital solutions, IoT,
                                    and cloud systems to streamline operations,
                                    reduce errors, and enhance compliance and
                                    agility.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\maintainence.png"
                                data-src="assets\img\OptebizImage\maintainence.png"
                                alt="Process Control"
                                title="Process Control"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word"
                                        >Maintenance</span
                                    >
                                    Management
                                </div>
                                <div class="detail">
                                    Transform maintenance with predictive
                                    analytics, IoT sensors, and condition
                                    monitoring to prevent failures, optimize
                                    scheduling, and minimize downtime.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\Quality Latest1.png"
                                data-src="assets\img\OptebizImage\Quality Latest1.png"
                                alt="Quality"
                                title="Quality"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Quality</span>
                                    data management
                                </div>
                                <div class="detail">
                                    Enhance quality control with IoT and
                                    real-time monitoring, enabling early defect
                                    detection, predictive maintenance, and
                                    improved efficiency.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background: radial-gradient(
                                circle,
                                rgba(29, 147, 149, 0.15) 0%,
                                rgba(255, 255, 255, 1) 70%
                            );
                            box-shadow: none;
                            border: none;
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\process control updt.png"
                                data-src="assets\img\OptebizImage\process control updt.png"
                                alt="Maintenance"
                                title="Maintenance"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Process</span>
                                    Control
                                </div>
                                <div class="detail">
                                    Automate backups and recovery while applying
                                    consistent security measures across a
                                    globally distributed database with advanced
                                    features like end-to-end encryption, RBAC,
                                    and external KMS support.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
