import { Component } from '@angular/core';

@Component({
  selector: 'app-quality-analytics',
  templateUrl: './quality-analytics.component.html',
  styleUrls: ['./quality-analytics.component.scss']
})
export class QualityAnalyticsComponent {
 
    constructor() { }
  
    ngOnInit(): void {
    }
  
    pageTitleArea: pageTitle[] = [
        {
            title: 'Facto IQ',
            subTitle: 'Quality Analytics',
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        {
            img: 'assets/img/OptebizImage/QA-01.jpg'
        }
    ]
    viewDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'Quality Analytics',
            title1: 'Quality Analytics',
            paragraphText1: 'Our Quality Analytics feature enhances efficiency, reliability, and customer satisfaction by identifying trends, patterns, and anomalies in products or services, enabling proactive quality control measures and reducing defects',
            paragraphText2: 'This includes monitoring equipment performance, production rates, quality control, and other relevant metrics. The goal is to identify and address any issues that may arise in real-time, minimizing downtime and maximizing productivity. Its like having a watchful eye over the entire production process to keep things on track.',
            title2: 'Important Facts',
            title3: 'Application Areas',
            title4: 'Technologies That We Use',
            img1: 'assets/img/OptebizImage/quality-assurance-process-main.jpg',
            img2: 'assets/img/OptebizImage/dataQual.png'
        }
    ]
    viewDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'Cost of Quality Analysis'
        },
        {
            title: 'Continuous Improvement'
        },
        {
            title: 'Data Visualization'
        },
        {
            title: 'First-Time Yield (FTY) Analysis'
        },
        {
            title: 'Statistical Process Control (SPC)'
        },
        {
            title: 'Defect Analysis'
        }
    ]
    
    sidebarServicesList: ServicesList[] = [
        {
            title: 'Time Series Analytics',
            link: 'time-series-analytics'
        },
        {
            title: 'MES Analytics',
            link: 'mes-analytics'
        },
        {
            title: 'Alarm Analytics',
            link: 'alarm-analytics'
        },
        {
            title: 'Process control Analytics',
            link: 'process-control-analytics'
        }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'view-details'
        },
        {
            title: 'view Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'view-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+1 (419) 476-4520'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'bizops@OpteBiz.com'
        }
    ]
    
  }
  class pageTitle {
    title : string;
    subTitle : string;
  }
  class DetailsImage {
    img : string;
  }
  class DetailsDesc {
    subTitle : string;
    title1 : string;
    title2 : string;
    title3 : string;
    title4 : string;
    paragraphText1 : string;
    paragraphText2 : string;
    img1 : string;
    img2 : string;
  }
  class ImportantFacts {
    title : string;
  }
  class Application {
    title : string;
    icon: string;
  }
  class TechnologiesFeatures {
    title : string;
  }
  
  class ServicesList {
    title : string;
    link : string;
  }
  class DownloadFile {
    title : string;
    icon : string;
    link : string;
  }
  class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
  }
  
  

