<section class="page-title-area">
    <div class="container">
        <div class="page-title-content text-center" *ngFor="let pageTitle of pageTitleArea;">
            <h2>{{pageTitle.title}}</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>{{pageTitle.title}}</li>
            </ul>
        </div>
    </div>

    <div class="shape-img1"><img src="assets/img/shape/shape1.svg" alt="image"></div>
    <div class="shape-img2"><img src="assets/img/shape/shape2.png" alt="image"></div>
    <div class="shape-img3"><img src="assets/img/shape/shape3.png" alt="image"></div>
</section>

<section class="projects-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-6">
                <div class="single-projects-box">
                    <div class="image">
                        <img  src="assets/img/OptebizImage/IMG_0929.jpg" alt="image">
                        <div class="overlay-text"  > 
                            <p class="header">#Case study 1</p> 
                            <h1>A Data & Tracking Solution for Large-Scale Operation.</h1>  
                            <h4 >Solar Cell Manufacturing Plant</h4>  
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">01. Management & Organization of Billions of Data Sets.</p>
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">02. Collection of Intuitive Dashboards to Stay Updated.</p> 
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">03. In-Depth Analysis to Optimize their Operations.</p> 
                            </div>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/case-studies-details">A Data & Tracking Solution for Large-Scale Operation.</a></h3>
                        <!-- <span>{{singleProjectsContent.subTitle}}</span> -->
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img  src="assets/img/OptebizImage/ivan-bandura-Ac97OqAWDvg-unsplash.jpg" alt="image">
                        <div class="overlay-text"> 
                            <p class="header">#Case study 2</p>  
                            <h1>Executed on saving their bottom line – through more effectively utilizing their machines.</h1> 
                            <h4>Water Treatment Plant</h4>  
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">01. Management & Organization of Billions of Data Sets.</p>
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">02. Collection of Intuitive Dashboards to Stay Updated.</p> 
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">03. In-Depth Analysis to Optimize their Operations.</p> 
                            </div>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/testimonialcase2">Executed on saving their bottom line – through more effectively utilizing their machines.</a></h3>
                        <!-- <span>{{singleProjectsContent.subTitle}}</span> -->
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img  src="assets/img/OptebizImage/jared-brashier-OR3FqpLyPdI-unsplash.jpg" alt="image">
                        <div class="overlay-text">
                            <p class="header">#Case study 3</p> 
                            <h1>Procuring a cost-effective, result driven data-solution.</h1> 
                            <h4>Door Production</h4>   
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">01. Management & Organization of Billions of Data Sets.</p>
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">02. Collection of Intuitive Dashboards to Stay Updated.</p> 
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">03. In-Depth Analysis to Optimize their Operations.</p>  
                            </div>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/testimonialcase3">Procuring a cost-effective, result driven data-solution.</a></h3>
                        <!-- <span>{{singleProjectsContent.subTitle}}</span> -->
                    </div>
                </div>
                <div class="single-projects-box">
                    <div class="image">
                        <img  src="assets/img/OptebizImage/jj-ying-WmnsGyaFnCQ-unsplash.jpg" alt="image">
                        <div class="overlay-text"  > 
                            <p class="header">#Case study 4</p>    
                            <h1>Implementing a large-scale, multi-plant solution,    and managing big-data sets.</h1>
                            <h4>Paper Production Plant</h4>
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">01. Management & Organization of Billions of Data Sets.</p>
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">02. Collection of Intuitive Dashboards to Stay Updated.</p> 
                            <p style="color: #884A39;font-size: 16px;font-weight: 700;">03. In-Depth Analysis to Optimize their Operations.</p>  
                            </div>
                    </div>

                    <div class="content">
                        <h3><a routerLink="/testimonialcase4">Implementing a large-scale, multi-plant solution, and managing big-data sets.</a></h3>
                        <!-- <span>{{singleProjectsContent.subTitle}}</span> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>