<div id="main" class="site-main- clearfix">
    <svg style="display: none">
        <style>
            .yb-section {
                --OrangeColor: #ff6e42;
                --lightColor: #fff;
                --primary-blue: var(--lightColor);
                --primary-text-color: var(--lightColor);
            }
            .yb-section:not(.dark-bg) {
                --DarkColor: #000041;
                --OrangeColor: #ff6e42;
                --primary-blue: var(--DarkColor);
                --primary-orange: var(--OrangeColor);
                --primary-text-color: #4e5f6d;
            }
            * {
                box-sizing: border-box;
            }
            .page-template-cloud-solutions {
                position: relative;
            }
            .page-template-cloud-solutions:before {
                content: "";
                width: 100%;
                background: url(/wp-content/uploads/2023/03/hero-bg.svg) left
                    center no-repeat;
                background-size: 100%;
                display: block;
                position: absolute;
                top: -80px;
                height: 1116px;
            }
            div#page {
                overflow: hidden;
            }
            .container {
                width: 100%;
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }
            .gradient-word {
                color: #ff6e42;
                background: linear-gradient(
                    90deg,
                    #ff6e42 14.06%,
                    #d847c0 55.73%,
                    #9867f9 92.71%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                -webkit-box-decoration-break: clone;
                font-weight: 700;
            }
            .yb-section .short-detail {
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
                color: var(--primary-text-color);
                max-width: 935px;
                margin: 0 auto 32px;
            }
            .yb-section:not(.hero-section) {
                margin: 150px 0 200px;
                position: relative;
            }
            .yb-section:last-child {
                margin-bottom: 0;
            }
            .yb-section:not(.hero-section):before {
                content: "";
                width: 1px;
                height: 70px;
                background-image: linear-gradient(0deg, #fff 50%, #000041 50%);
                background-size: 1px 5px;
                position: absolute;
                top: -113px;
                z-index: 4;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            .yb-section:not(.hero-section):after {
                width: 11px;
                height: 11px;
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -113px;
                border: 1px solid #000041;
                border-radius: 100%;
                background: #fff;
                z-index: 5;
                transition: top 0.3s 0.5s linear;
            }
            .yb-section.dark-bg:before {
                background-image: linear-gradient(0deg, #fff 50%, #000041 50%);
                background-size: 1px 5px;
                z-index: 1;
                border: 0;
                top: -58px;
                height: 200px;
            }
            .yb-section.dark-bg:after {
                top: 138px;
                background: #090043;
                border: 2px solid rgb(255 255 255/62%);
            }
            .section-heading {
                font-weight: 700;
                font-size: 38px;
                line-height: 48px;
                color: var(--primary-blue);
                margin-bottom: 16px;
            }
            @media (min-width: 576px) {
                .container {
                    max-width: 100%;
                }
            }
            @media (min-width: 768px) {
                .container {
                    max-width: 100%;
                    box-sizing: border-box;
                }
            }
            @media (min-width: 992px) {
                .container {
                    max-width: 1160px;
                    box-sizing: border-box;
                }
            }
            @media (min-width: 1200px) {
                .container {
                    max-width: 1140px;
                }
                #content .container {
                    max-width: 1248px;
                    padding: 60px;
                    gap: 72px;
                }
            }
            @media (max-width: 1279px) {
                #content {
                    padding: 0 20px;
                }
                #content .container {
                    max-width: 1160px;
                    padding: 60px;
                }
            }
            @media (max-width: 1199px) {
                .yb-section:not(.hero-section):not(:last-child) {
                    margin: 50px 0 150px;
                }
            }
            img {
                max-width: 100%;
                height: auto;
            }
            .c-v2-yugabyte-db-section-triangle {
                padding-bottom: 85px;
                width: 100%;
                position: absolute;
                top: -1px;
                right: 0;
                left: 0;
                overflow: hidden;
                z-index: 0;
            }
            .c-v2-yugabyte-db-section-triangle:after {
                content: "";
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                background: #fff;
                -webkit-transform: skewY(-3deg);
                -ms-transform: skewY(-3deg);
                transform: skewY(-3deg);
                -webkit-transform-origin: left top;
                -ms-transform-origin: left top;
                transform-origin: left top;
                z-index: 999;
            }
            .header-inner {
                background: 0 0;
                backdrop-filter: none;
            }
            .page-scroll:not(.mobile_nav_open) .header-inner {
                background: rgba(242, 246, 255, 0.6);
                backdrop-filter: blur(50px);
            }
            .hero-section {
                padding-top: 50px;
                padding-bottom: 50px;
                position: relative;
                overflow: hidden;
            }
            .headline.text-center {
                position: relative;
                z-index: 1;
            }
            .hero-section h1 {
                color: var(--primary-blue);
                font-weight: 700;
                font-size: 52px;
                line-height: 62px;
                margin-top: 50px;
                margin-bottom: 24px;
            }
            .hero-section .subheading {
                color: var(--primary-text-color);
                margin: 0 auto 20px;
                font-weight: 400;
                font-size: 18px;
                line-height: 30px;
            }
            .hero-section .button-section {
                margin-top: 25px;
                display: flex;
                gap: 23px;
            }
            .hero-section .right-side {
                width: calc(100% - 552px);
                display: flex;
                justify-content: center;
                position: relative;
            }
            .hero-section .right-side&gt;
            img {
                position: absolute;
                width: 664px;
                height: auto;
                margin: 0;
                left: 50px;
                top: 10px;
                max-width: none;
            }
            .subheading a:hover {
                color: #ff6e42;
            }
            .hero-section .left-side {
                width: 552px;
                position: relative;
                z-index: 1;
                min-height: 378px;
            }
            .hero-section .container {
                display: flex;
            }
            .right-side img {
                margin-left: 50px;
                margin-top: 10px;
                width: auto;
            }
            .hero-section .cards {
                display: flex;
                gap: 24px;
                position: relative;
                z-index: 2;
                max-width: 100%;
            }
            .hero-section .cards .card&gt;
            .button-section {
                margin-top: auto;
            }
            .hero-section .cards .card&gt;
            .button-section a {
                background: #ff6e42;
                border-radius: 6px;
                padding: 13.5px 30px;
                font-weight: 500;
                font-size: 14px;
                line-height: 13px;
                text-align: center;
                color: #fff;
                display: inline-block;
                height: auto;
                position: relative;
                z-index: 1;
                overflow: hidden;
            }
            .hero-section .cards .card&gt;
            .button-section a:after {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                z-index: -1;
                top: 0;
                right: 0;
                transition: all 0.25s ease;
                background: linear-gradient(
                    90deg,
                    rgb(4, 79, 103),
                    rgb(230, 95, 50)
                );
            }
            .hero-section .cards .card&gt;
            .button-section a:hover:after {
                width: 100%;
            }
            .hero-section .cards .card h3 {
                background: linear-gradient(
                    90deg,
                    #ff6e42 14.06%,
                    #d847c0 55.73%,
                    #9867f9 92.71%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                margin-bottom: 16px;
                max-width: 400px;
                font-weight: 700;
                font-size: 38px;
                line-height: 48px;
            }
            .hero-section .cards .card {
                display: flex;
                flex-flow: column;
                width: 360px;
                padding: 40px;
                max-width: 100%;
                background: #fff;
                box-shadow: 0 10px 30px rgba(0, 0, 65, 0.15);
                border-radius: 16px;
            }
            .hero-section .cards .card .short-detail {
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 40px;
            }
            @media (max-width: 1380px) {
                .hero-section .right-side&gt;
                img {
                    position: absolute;
                    width: 584px;
                    height: auto;
                    margin: 0;
                    left: 0;
                    top: 0;
                    max-width: none;
                }
            }
            @media (max-width: 1199px) {
                .hero-section {
                    overflow: hidden;
                    padding-bottom: 150px;
                }
                .hero-section .right-side&gt;
                img {
                    position: static;
                    width: 100%;
                }
            }
            @media (max-width: 991px) {
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section h1 {
                    font-size: 39px;
                    line-height: 50px;
                    max-width: 100%;
                }
                .subheading {
                    font-size: 16px;
                }
                .hero-section .right-side&gt;
                img {
                    width: 500px;
                    max-width: 100%;
                }
                .hero-section .right-side {
                    margin-bottom: 40px;
                }
                .hero-section .right-side,
                .hero-section .left-side {
                    width: 100%;
                    min-height: 10px;
                }
                .button-section a {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                div#main {
                    padding-top: inherit !important;
                }
            }
            @media (min-width: 768px) {
                div#main {
                    padding-top: 0 !important;
                }
            }
            @media (max-width: 767px) {
                .hero-section h1 {
                    font-size: 29px;
                    line-height: 40px;
                    max-width: 100%;
                }
                .subheading p {
                    margin: 15px 0;
                }
                .hero-section .subheading {
                    font-size: 16px;
                    line-height: normal;
                    margin-bottom: 40px;
                }
                .hero-section .right-side {
                    height: auto;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 50px;
                }
                .hero-section .right-side&gt;
                img {
                    position: static;
                    max-width: 100%;
                }
                .hero-section .left-side {
                    width: 100%;
                }
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section .button-section a {
                    min-width: 144px;
                }
                .hero-section .button-section {
                    gap: 10px;
                }
            }
            @media (max-width: 600px) {
                .hero-section .cards {
                    flex-flow: column;
                    align-items: center;
                }
                .hero-section {
                    max-height: inherit;
                }
                .hero-section .container {
                    flex-flow: column;
                }
                .hero-section h1 {
                    margin-top: 50px;
                }
                .hero-section .subheading {
                    margin-bottom: 30px;
                }
                .hero-section .left-side {
                    width: 100%;
                }
                .hero-section .button-section {
                    flex-wrap: wrap;
                }
                body {
                    background-size: auto 500px;
                }
            }
            @media (max-width: 767px) {
                div#main {
                    padding-top: 80px;
                }
            }
            @media (max-width: 480px) {
                body:not(.js-lazyloaded) .hero-section .right-side&gt,
                img {
                    height: 315px;
                }
            }
        </style>
    </svg>
    <div class="hero-section yb-section">
        <div class="headline text-center">
            <div class="container">
                <div class="left-side">
                    <h2 style="color: #1d9395">
                        Case 1: Solar Cell Manufacturing
                    </h2>
                    <h1>
                        <span style="color: #1d9395">Scalable </span>
                        Data Tracking & Integration
                    </h1>
                    <div class="subheading">
                        A leading global solar cell manufacturer needed a
                        solution to track, manage, and organize data across
                        multiple facilities and machines. Our Facto-Tools
                        provided seamless data tracking, management, and
                        accessibility at both granular and high levels.
                    </div>
                </div>
                <div class="right-side">
                    <img
                        src="assets\img\OptebizImage\solar.jpg"
                        alt="AWS"
                        title="AWS"
                        width="1024"
                        height="711"
                        class="loaded come-in"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subtitle }}</span
            >
        </div>
        <div class="row">
            <div
                class="col-lg-4 col-md-6 col-sm-12"
                *ngFor="let ServicesBoxContent of singleServicesBoxItem"
            >
                <div class="single-services-box-item">
                    <div class="icon">
                        <img [src]="ServicesBoxContent.icon" alt="image" />
                    </div>
                    <h3>
                        <a>{{ ServicesBoxContent.title }}</a>
                    </h3>
                    <div style="flex-grow: 1; overflow-y: auto">
                        <ul>
                            <li
                                *ngFor="
                                    let point of ServicesBoxContent.paragraphText
                                "
                            >
                                {{ point }}
                            </li>
                        </ul>
                    </div>
                    <a
                        routerLink="/{{ ServicesBoxContent.link }}"
                        class="learn-more-btn"
                        ><i class="{{ ServicesBoxContent.linkIcon }}"></i
                        >{{ ServicesBoxContent.linkText }}</a
                    >
                    <div class="shape">
                        <img src="assets/img/services/shape4.png" alt="image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="now-available-section yb-section">
        <section class="section side-content">
            <div class="c-row">
                <h2 class="primary-features-title">
                    Primary Features of Our <span>Solution</span>
                </h2>
                <p class="primary-features-text">
                    A legacy system was already in place, but it was not
                    efficient enough to meet the requirements. A lot of manual
                    effort was needed to maintain constant output. Also, machine
                    data was supposed to be captured in real-time to have
                    correct production charts, but produced materials were too
                    numerous to use bar codes.
                </p>
                <h2 class="main-issues-title">
                    <span>Main Issues We Addressed</span>
                </h2>
                <div class="side-content-wrapper come-out come-in">
                    <div class="side-box">
                        <div class="side-txt-row">
                            <img
                                src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step1.svg"
                                data-src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step1.svg"
                                alt="CloudNativeApps Define Cluster Layout"
                                title="CloudNativeApps Define Cluster Layout"
                                width="65"
                                height="65"
                                class="lazy-loaded"
                            />
                            <span>Step 1</span>
                            <h3><span>Efficient</span> Tracking</h3>
                            <p>
                                In their current process, performance tracking
                                of individual machines from a single location
                                was cumbersome. Parameters such as individual
                                machine runtime, downtime, the reason for
                                downtime etc. were not being captured
                                efficiently.
                            </p>
                        </div>
                        <div class="side-txt-row">
                            <img
                                src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step2.svg"
                                data-src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step2.svg"
                                alt="CloudNativeApps Configure YugabyteDB with Kubernetes"
                                title="CloudNativeApps Configure YugabyteDB with Kubernetes"
                                width="65"
                                height="65"
                                class="lazy-loaded"
                            />
                            <span>Step 2</span>
                            <h3><span>Real-Time </span> Monitoring</h3>
                            <p>
                                An MES application was designed & developed
                                using Ignition for real-time production
                                monitoring. Ignition was used as the SCADA to
                                take care of all the above requirements and work
                                in sync with the individual, real-time
                                production processes.
                            </p>
                        </div>
                        <div class="side-txt-row">
                            <img
                                src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step3.svg"
                                data-src="https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-step3.svg"
                                alt="CloudNativeApps Establish Backup and Recovery"
                                title="CloudNativeApps Establish Backup and Recovery"
                                width="65"
                                height="65"
                                class="lazy-loaded"
                            />
                            <span>Step 3</span>
                            <h3><span>Big-Data</span> Management</h3>
                            <p>
                                With such a large operation, there were billions
                                of data points and millions of datasets to
                                organize & display – through our Facto-Tools, we
                                were able to both capture this data and present
                                it in clean, intuitive dashboards.
                            </p>
                        </div>
                    </div>
                    <div
                        class="side-image"
                        style="
                            background-image: url(https://www.yugabyte.com/wp-content/uploads/2022/09/cloudnativeapps-diagram-gradient.svg);
                        "
                    >
                        <img
                            src="assets\img\OptebizImage\solarman.jpg"
                            data-src="assets\img\OptebizImage\solarman.jpg"
                            alt="CloudNativeApps YugabyteDB with VMware Tanzu"
                            title="CloudNativeApps YugabyteDB with VMware Tanzu"
                            width="681"
                            height="396"
                            class="lazy-loaded"
                        />
                    </div>
                </div>
            </div>
        </section>
        <div class="core-pricing come-out come-in loaded">
            <div class="c-v2-yugabyte-db-section-triangle"></div>
            <div class="container">
                <h2>
                    <span>Data-Driven Solution</span> to Impact their Bottom
                    Line
                </h2>
                <div class="detail-sec">
                    <p>
                        Ignition was used as the SCADA to take care of all the
                        above requirements and work in sync with the individual,
                        real-time production processes. An MES application was
                        designed & developed using Ignition for real-time
                        production monitoring.
                    </p>
                    <p>
                        LeanQubit achieved a 2% reduction in downtime, 10%
                        improvement in processing throughput, and helped the
                        client achieve a paperless environment, which results in
                        large cost savings.
                    </p>
                </div>
                <div class="container" style="padding-top: 5%">
                    <div class="row">
                        <div
                            class="col-lg-6 col-sm-6 col-6 col-md-6"
                            *ngFor="let Funfacts of singleFunfactsItem"
                        >
                            <div class="single-funfacts-item">
                                <h3>{{ Funfacts.title }}</h3>
                                <p>
                                    {{ Funfacts.subTitle }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <section class="explore-learning-area ptb-100">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div
                            class="explore-learning-content"
                            *ngFor="let Content of exploreLearningContent"
                        >
                            <h2>
                                <span>Old Processes</span>
                                & Issues They Faced
                            </h2>
                            <p>
                                {{ Content.paragraphText }}
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div
                            class="explore-learning-video"
                            *ngFor="let Video of exploreLearningVideo"
                        >
                            <img [src]="Video.img" alt="image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="why-choose-us-area bg-color">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div
                            class="why-choose-us-content"
                            *ngFor="let Content1 of whyChooseUsContent"
                        >
                            <h2>{{ Content1.title }}</h2>
                            <p>{{ Content1.paragraphText1 }}</p>
                            <p>{{ Content1.paragraphText2 }}</p>
                            <div class="map-shape2">
                                <img
                                    src="assets/img/shape/map-shape2.png"
                                    alt="image"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="our-brand-partners-list">
                            <h2>A Trusted, Reliable Partner to Support Ops</h2>
                            <p>
                                <strong
                                    ><span style="color: #1d9395">Lean</span
                                    ><span style="color: #da5c30">Q</span
                                    ><span style="color: #1d9395"
                                        >ubit</span
                                    ></strong
                                >
                                is an ISA-95 certified company for enterprise
                                automation. We are also a preferred partner of
                                Inductive Automation for consulting and
                                implementation of Ignition based solutions
                                across diverse manufacturing industries. With a
                                comprehensive implementation and support
                                experience of over 20 years, we build
                                customized, reliable solutions for all of your
                                MES tracking and reporting needs.
                            </p>
                            <p>
                                We’re here for you every step of the way.
                                Contact us to get started!
                            </p>

                            <a routerLink="/BookDemo" class="default-btn"
                                ><i class="flaticon-right"></i>Book Demo<span
                                ></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
