<div class="Process-section ">
    <div class="section-triangle">
    </div>
     <div class="container" >
        <div class="section-title" *ngFor="let Content of aboutContent;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{Content.subTitle}}</span>
            <h2  class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms" style="color: #fff;;"><span>The </span><span style="background: -webkit-linear-gradient(rgb(4, 79, 103),rgb(230, 95, 50));-webkit-background-clip: text;-webkit-text-fill-color: transparent;">  LeanQubit </span>{{Content.title}}</h2>
            <p style=" font-weight: 500;
           font-family: Condensed; 
            font-size: 18px;
            line-height: 30px;
            color: #fff;
            max-width: 935px;
            margin: 7px auto 32px;">{{Content.paragraphText1}}</p>
        </div>
     </div>
     <div class="c-row">
        <div class="c-columns c-v2-links-stats-item">
         <div class="hero-img" > <img src="assets\img\OptebizImage\growthSuccess.png"  class="wow animate__animated animate__fadeInLeft" alt="Impact" title="Impact" width="500" height="703"></div>
           <div class="c-columns c-v2-links-stats-item-right">
              <ul class="wow animate__animated animate__fadeInRight">
                 <li class="animation-element staggered fade-in-up in-view" >
                    <div class="c-v2-links-stats-item-right-title"> 8<sub>%</sub></div>
                    <div class="c-v2-links-stats-item-right-text"> <img src="assets\img\OptebizImage\quality-control.png" data-src="assets\img\OptebizImage\quality-control.png" alt="Quality Boost" width="20" height="20" class="loaded come-in" > Quality Boost</div>
                 </li>
                 <li class="animation-element staggered fade-in-up in-view">
                    <div class="c-v2-links-stats-item-right-title"> 20<sub>%</sub></div>
                    <div class="c-v2-links-stats-item-right-text"> <img src="assets\img\OptebizImage\fast-delivery.png" data-src="assets\img\OptebizImage\fast-delivery.png" alt="Faster Delivery" width="24" height="24" class="loaded come-in"> Faster Delivery </div>
                 </li>
                 <li class="animation-element staggered fade-in-up in-view">
                    <div class="c-v2-links-stats-item-right-title"> 15<sub>%</sub></div>
                    <div class="c-v2-links-stats-item-right-text"> <img src="assets\img\OptebizImage\cutting.png" data-src="assets\img\OptebizImage\cutting.png" alt="Cut Costs" width="25" height="24" class="loaded come-in"> Cut Costs</div>
                 </li>
                 <li class="animation-element staggered fade-in-up in-view">
                    <div class="c-v2-links-stats-item-right-title"> 15<sub>%</sub></div>
                    <div class="c-v2-links-stats-item-right-text"> <img src="assets\img\OptebizImage\down-time.png" data-src="assets\img\OptebizImage\down-time.png" alt="Minimize Downtime" width="24" height="24" class="loaded come-in"> Minimize Downtime</div>
                 </li>
              </ul>
           </div>
        </div>
     </div>
 </div>