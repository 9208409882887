import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details-facto-iq',
  templateUrl: './service-details-facto-iq.component.html',
  styleUrls: ['./service-details-facto-iq.component.scss']
})
export class ServiceDetailsFactoIQComponent {

}
