<div class="navbar-area">
    <div class="wilo-responsive-nav">
        <div class="container">
            <div class="wilo-responsive-menu">
                <div class="logo">
                    <a routerLink="/">
                        <img src="assets\img\LeanQbit_logo.png" alt="logo" />
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="wilo-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/">
                    <img
                        src="assets\img\LeanQbit_logo.png"
                        alt="logo"
                        style="width: 150px; height: 80px"
                    />
                </a>
                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a
                                routerLink="/"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Home</a
                            >
                        </li>
                        <!-- <li class="nav-item">
                            <a
                                routerLink="/Testing"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Test Front Page</a
                            >
                        </li>

                        <li class="nav-item">
                            <a
                                routerLink="/Testing2"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Test Front Page2</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/Testing3"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Test Front Page3</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                routerLink="/Testing4"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >Test Front Page4</a
                            >
                        </li> -->
                        <ul class="dropdown-menu"></ul>

                        <li class="nav-item">
                            <a
                                routerLink="/about-us"
                                class="nav-link"
                                routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }"
                                >About Us</a
                            >
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Products <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        href="javascript:void(0)"
                                        routerLink="/Facto-Tool-Kit"
                                        class="nav-link"
                                        >Facto ToolKit<i
                                            class="bx bx-chevron-right"
                                        ></i
                                    ></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoIQ"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoIQ</a
                                            >
                                        </li>

                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoMES"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoMES</a
                                            >
                                        </li>
                                        <li class="nav-item">
                                            <a
                                                routerLink="/FactoCloud"
                                                class="nav-link"
                                                routerLinkActive="active"
                                                [routerLinkActiveOptions]="{
                                                    exact: true
                                                }"
                                                >FactoCloudConnect</a
                                            >
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Services <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/inductive-details"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Inductive Automation (Ignition)</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/aws"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Amazon Web Service (Saas)</a
                                    >
                                </li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link"
                                >Company <i class="bx bx-chevron-down"></i
                            ></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item">
                                    <a
                                        routerLink="/faq"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >FAQ</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/Client-Testimonials"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Testimonials</a
                                    >
                                </li>

                                <li class="nav-item">
                                    <a
                                        routerLink="/contact"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        >Contact</a
                                    >
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"
                                ><i class="flaticon-right"></i>Get Started<span
                                ></span
                            ></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <a routerLink="/contact" class="default-btn"
                                ><i class="flaticon-right"></i>Get Started<span
                                ></span
                            ></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
