import { Component } from '@angular/core';

@Component({
  selector: 'app-testingfrontpage4',
  templateUrl: './testingfrontpage4.component.html',
  styleUrls: ['./testingfrontpage4.component.scss']
})
export class Testingfrontpage4Component {

}
