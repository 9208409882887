import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-process',
    templateUrl: './homeone-process.component.html',
    styleUrls: ['./homeone-process.component.scss']
})
export class HomeoneProcessComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
        // console.log(this.processContent[0])
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our Process",
            title: 'What We Do',
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.'
        }
    ]
    singleServicesItem: ServicesItem[] = [
        {
            icon: 'assets/img/services/icon1.png',
            title: 'Gather Requirement',
            paragraphText: 'Requirements are gathered by engaging with stakeholders, analyzing sample data for the current state and identifying gaps and pain points through mapping existing business processes',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon2.png',
            title: 'Structure Requirement',
            paragraphText: "To ensure sustained success, specify output goals, quality standards, inventory needs, and tech alignment for the customer's operations",
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon3.png',
            title: 'Documenting Requirements & Ensuring Consistency',
            paragraphText: 'Our detailed documentation templates ensure precise project requirements, reducing ambiguity and aiding efficient decision-making for project success',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon4.png',
            title: 'Requirements Prioritization',
            paragraphText: 'Requirement prioritization means ranking needs and features based on their importance to the project goals and stakeholder value. This ensures resources are allocated to address high-impact requirements, delivering maximum value within project constraints',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon5.png',
            title: 'Solution Design',
            paragraphText: 'Design a blueprint to turn requirements into an actionable plan, emphasizing scalability, usability, and efficiency. It outlines the architecture, technology, and component arrangement for a cohesive solution aligned with project objectives.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        },
        {
            icon: 'assets/img/services/icon6.png',
            title: 'Solution Demo & Sign Off',
            paragraphText: 'Final design is formally proposed based on the identified requirements highlighting the key features and functionalities of the point of arrival system comparing it with the existing solution.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services-details'
        }
    ]

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class ServicesItem {
    icon : string;
    title : string;
    paragraphText : string;
    btnIcon : string;
    btnText : string;
    link : string;
}