<section class="history-area ptb-100">
    <div class="container">
        <div
            class="section-title"
            *ngFor="let sectionTitleContent of sectionTitle"
        >
            <span class="sub-title"
                ><img src="assets/img/star-icon.png" alt="image" />
                {{ sectionTitleContent.subTitle }}</span
            >
            <h2
                class="wow animate__animated animate__fadeInRight"
                data-wow-delay="00ms"
                data-wow-duration="1000ms"
                style="color: #30307f"
            >
                {{ sectionTitleContent.title
                }}<span
                    style="
                        background: -webkit-linear-gradient(
                            rgb(4, 79, 103),
                            rgb(230, 95, 50)
                        );
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                    "
                >
                    operational </span
                ><span>excellence.</span>
            </h2>
            <p
                style="
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 30px;
                    color: #4e5f6d;
                    max-width: 935px;
                    font-family: Condensed;
                    margin: 7px auto 32px;
                "
            >
                {{ sectionTitleContent.paragraphText }}
            </p>
        </div>
        <div class="private-dbaas come-out loaded come-in">
            <div class="container">
                <div class="dbaas-options">
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\Controlstep1.png"
                                data-src="assets\img\OptebizImage\Controlstep1.png"
                                alt="Machine Control System and Network Transformation Journey"
                                title="Machine Control System and Network Transformation Journey"
                                width="298"
                                height="376"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Control</span>
                                    System & Network Transformation Journey
                                </div>
                                <div class="detail">
                                    Embark on a transformative journey with our
                                    Control System & Network Transformation
                                    services. We can guide you through a
                                    seamless integration of your existing SCADA
                                    monitoring and control tools with the best
                                    in class hardware and network upgrades,
                                    enhancing efficiency and connectivity.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\database transform.png"
                                data-src="assets\img\OptebizImage\database transform.png"
                                alt="Database Transformation Journey"
                                title="Database Transformation Journey"
                                width="336"
                                height="272"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Database </span>
                                    Transformation Journey
                                </div>
                                <div class="detail">
                                    Leveraging advanced technologies and
                                    methodologies, DB Transformation journey
                                    encompasses data migration, schema
                                    restructuring, and the adoption of
                                    cutting-edge DBMS systems. By redefining the
                                    architecture and optimizing data structures,
                                    organizations can achieve higher throughput,
                                    improved query response times, and greater
                                    resilience while ensuring that databases
                                    evolve to meet the evolving demands of
                                    modern applications.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\materiallatest1.png"
                                data-src="assets\img\OptebizImage\materiallatest1.png"
                                alt="Material Transformation Journey"
                                title="Material Transformation Journey"
                                width="387"
                                height="491"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word"
                                        >Production</span
                                    >
                                    Evolution & Transformation
                                </div>
                                <div class="detail">
                                    This multifaceted endeavor involves the
                                    integration of latest technologies and
                                    exhaustive solutions to enhance real-time
                                    visibility and tracking of inventory at all
                                    levels of production. Additionally, the
                                    implementation of sophisticated analytics
                                    and automation tools optimizes procurement,
                                    reducing lead times and minimizing excess
                                    stock.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\paperless.png"
                                data-src="assets\img\OptebizImage\paperless.png"
                                alt="Paperless Transformation"
                                title="Paperless Transformation"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Paperless</span>
                                    business Transformation
                                </div>
                                <div class="detail">
                                    The paperless transformation focuses on
                                    eliminating traditional paperwork and
                                    embracing digital solutions. Leveraging
                                    technologies like IoT devices, digital work
                                    instructions, and cloud-based systems, it
                                    streamlines operations, enhances
                                    collaboration, and reduces manual errors.
                                    Real-time data capture and analysis enable
                                    agile decision-making, while digital
                                    documentation ensures compliance and
                                    traceability.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\maintainence.png"
                                data-src="assets\img\OptebizImage\maintainence.png"
                                alt="Process Control"
                                title="Process Control"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word"
                                        >Maintenance</span
                                    >
                                </div>
                                <div class="detail">
                                    By adopting predictive maintenance
                                    algorithms, IoT sensors, and condition
                                    monitoring systems, this transformation
                                    journey shifts maintenance practices from
                                    reactive to proactive. Real-time data
                                    analytics enable organizations to predict
                                    equipment failures, schedule maintenance
                                    activities efficiently, and minimize
                                    downtime.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\Quality Latest1.png"
                                data-src="assets\img\OptebizImage\Quality Latest1.png"
                                alt="Quality"
                                title="Quality"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Quality</span>
                                </div>
                                <div class="detail">
                                    Leveraging IoT-driven quality control
                                    systems and real-time monitoring tools, this
                                    transformation optimizes inspection
                                    processes, identifies defects earlier, and
                                    improves overall product quality. The
                                    integration of data analytics facilitates
                                    predictive maintenance, reducing downtime
                                    and enhancing efficiency.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="single-dbaas"
                        style="
                            background-image: url(assets/img/OptebizImage/diagram-gradient.svg);
                        "
                    >
                        <div class="icon">
                            <img
                                src="assets\img\OptebizImage\process control updt.png"
                                data-src="assets\img\OptebizImage\process control updt.png"
                                alt="Maintenance"
                                title="Maintenance"
                                width="232"
                                height="273"
                                class="loaded come-in"
                            />
                        </div>
                        <div class="content-area">
                            <div class="content-inner">
                                <div class="heading">
                                    <span class="gradient-word">Process</span>
                                    Control
                                </div>
                                <div class="detail">
                                    Automate backups and recovery while applying
                                    consistent security measures across a
                                    globally distributed database with advanced
                                    features like end-to-end encryption, RBAC,
                                    and external KMS support.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
