<div id="main" class="site-main- clearfix" style="margin-top: 95px">
    <div id="hero_alt" class="content_section">
        <div class="content_section_inner nopadding">
            <div class="inner">
                <div class="inner_content">
                    <h1>Privacy Notice</h1>
                </div>
            </div>
        </div>
    </div>
    <div id="primary" class="content-area full">
        <div id="content" class="site-content" role="main">
            <article
                id="post-27932"
                class="come-out post-27932 page type-page status-publish has-post-thumbnail hentry come-in"
            >
                <div
                    id="narrow_wys-block_98c15c5f7243a90f80bc08e127ada20b"
                    class="content_section narrow_wys"
                >
                    <div class="content_section_inner">
                        <div class="inner">
                            <div class="inner_content wysiwyg">
                                <p
                                    style="
                                        text-align: center;
                                        font-weight: 500;
                                        font-size: 20px;
                                        color: #ff6e42;
                                    "
                                >
                                    <strong>LEANQUBIT PRIVACY NOTICE</strong>
                                </p>
                                <p style="text-align: justify">
                                    <strong>Last Updated</strong>: March 18,
                                    2023
                                </p>
                                <p style="text-align: justify">
                                    This Privacy Notice explains how we collect,
                                    use, disclose, and otherwise process
                                    personal information in connection with our
                                    services. This Privacy Notice is not,
                                    however, a contract and does not create any
                                    legal rights or obligations not otherwise
                                    provided by law.
                                </p>
                                <p style="text-align: justify">
                                    <strong>Who We Are</strong>:
                                    <b><i>&nbsp;</i></b
                                    ><span style="font-weight: 400"
                                        >LeanQubit Pvt. Ltd</span
                                    >
                                    and its group companies and affiliates
                                    (hereinafter referred to as “OpteBiz”) is a
                                    strong believer in Business Ethics,
                                    Transparency and Privacy of data and these
                                    are our pillars for the growth of all our
                                    businesses in this digital era. We believe
                                    in efficient ways of collecting, using and
                                    transferring information/ data, and handle
                                    all means of electronic data in a fair and
                                    responsible manner.
                                </p>
                                <p style="text-align: justify">
                                    <b><i></i></b> This website is operated by
                                    OpteBiz. Information about our customers is
                                    an important part of our business and we
                                    respect your privacy. We will use the
                                    information we collect from you in
                                    accordance with this policy. A list of our
                                    group and affiliated companies is available
                                    on request. We assure you of our privacy
                                    policies, which are stringent enough and
                                    always updated with new trends to make sure
                                    that the information you give us is secure.
                                </p>

                                <p style="text-align: center">
                                    <strong>Scope</strong>
                                </p>
                                <p style="text-align: justify">
                                    OpteBiz’s Privacy Statement applies to all
                                    websites of LeanQubit Group and affiliated
                                    Companies and applications that comprise a
                                    link or reference to this Privacy Statement
                                    and also information about you collected
                                    offline. It does not apply to websites,
                                    services, and products that do not refer,
                                    display or link to this privacy statement,
                                    or which have their own privacy statements.
                                    We may update this privacy policy from time
                                    to time in order to reflect, for example,
                                    changes to our practices or for other
                                    operational, legal or regulatory reasons.
                                </p>
                                <p><strong>Collection of Data</strong></p>
                                <p>
                                    We only collect information that you provide
                                    to us with your consent to collect, use or
                                    transfer your information in conformity with
                                    the terms and policies. We obtain the
                                    personal data from you when you register on
                                    our websites or through cookies, provide our
                                    representatives with personal data on your
                                    business card, or when we obtain your data
                                    from representatives of your company. We may
                                    use your information to contact you by post,
                                    telephone, email or otherwise to let you
                                    know about other products and services we
                                    offer that may be of interest to you, to
                                    seek your views on our products and services
                                    and to notify you occasionally about
                                    important changes or developments to them or
                                    to the site.
                                </p>
                                <p>
                                    Also can disclose your information to our
                                    staff, agents and service providers for
                                    these purposes. Providing personal data to
                                    us during the website visit through any
                                    direct and indirect medium is voluntary. If
                                    you do not endow with your personal data to
                                    us, then you will not be able to benefit
                                    from the relevant service offerings and
                                    other information given by our websites,
                                    such as direct communication with you,
                                    downloading of documents or recruitment,
                                    marketing and promotional offers, events,
                                    subscriptions to newsletters, & social media
                                    functions on our site, webinars or forum
                                    discussion and exhibition or trade shows.
                                </p>
                                <p style="text-align: center">
                                    <strong
                                        >Our Collection of Personal
                                        Information</strong
                                    >
                                </p>
                                <p>
                                    From the first moment you interact with us,
                                    we are collecting personal information about
                                    you. Sometimes we collect personal
                                    information automatically when you interact
                                    with our services and sometimes we collect
                                    the personal information directly from you.
                                    At times, we may collect personal
                                    information about you from other sources and
                                    third parties, even before our first direct
                                    interaction.
                                </p>
                                <p><b>Personal Information You Provide</b></p>
                                <p style="text-align: justify">
                                    We collect the following personal
                                    information you provide in connection with
                                    our services:
                                </p>
                                <ul>
                                    <li style="text-align: justify">
                                        <strong>Contact Information</strong>,
                                        including your full name, email address,
                                        mailing address, phone number and state
                                        and country of residence
                                    </li>
                                    <li style="text-align: justify">
                                        <strong>Professional Information</strong
                                        >, including your title, company name,
                                        professional background and the nature
                                        of your relationship with us
                                    </li>
                                    <li style="text-align: justify">
                                        <strong>Service Information</strong>,
                                        including product or service interests
                                        and orders
                                    </li>
                                    <!-- <li style="text-align: justify;"><strong>Inquiry Information</strong>, such as the content of your email, text or chat with us and, where applicable, your voice from calls with us</li> -->
                                </ul>
                                <p>
                                    If you choose to contact us, we may need
                                    additional information to fulfill your
                                    request or respond to your inquiry. We may
                                    provide additional privacy disclosures where
                                    the scope of the inquiry/request and/or
                                    personal information we require fall outside
                                    the scope of this Privacy Notice. In that
                                    case, the additional privacy disclosures
                                    will govern how we may process the
                                    information you provide at that time.
                                </p>
                                <p style="text-align: justify">
                                    <strong
                                        >Cookie based collection of data usage
                                        through website</strong
                                    >
                                </p>
                                <p style="text-align: justify">
                                    This website uses Google Analytics, a web
                                    analytics service by Google Inc. and it uses
                                    “cookies”. The cookies are text files
                                    located on your computer, which help the
                                    website, analyze how users navigate through
                                    the whole website. Cookies help to generate
                                    information about users including IP address
                                    and such technical information is stored by
                                    Google on their servers. These cookies help
                                    make your visit more personalized to offer a
                                    better online experience.
                                </p>
                                <p>
                                    By using our website, you give your consent
                                    for the processing of your data to Google
                                    for the purposes mentioned above. Please
                                    refer to the Google Analytics support site
                                    for details related to the various data
                                    gathered by Google Analytics and the exact
                                    nature of ongoing changes in this regard.
                                </p>

                                <p style="text-align: justify">
                                    <strong
                                        >Sharing the data or information that we
                                        collect</strong
                                    >
                                </p>
                                <p style="text-align: justify">
                                    We do not sell or share information we
                                    collect to everyone and anyone, i.e. other
                                    than our service providers, professional
                                    advisors, government authorities or third
                                    parties in connection with a professional or
                                    commercial relation related to our business.
                                    As we are a global organization, these
                                    mentioned parties can be located in other
                                    countries too including those located
                                    outside the European Union. In such cases,
                                    before sharing the data we ensure all the
                                    data security measures and process your
                                    personal data in accordance with the
                                    applicable law and shall implement apt
                                    international data transfer methods and
                                    standards.
                                </p>

                                <p style="text-align: center">
                                    <strong id="use-personal-info"
                                        >Sensitive Personal Data</strong
                                    >
                                </p>
                                <p style="text-align: justify">
                                    In general, LeanQubit does not collect or
                                    use Sensitive Personal Data through our
                                    website. In very limited cases we seek to
                                    collect such data and we perform this in
                                    accordance with data privacy law and/or
                                    request for individual consent on the same.
                                </p>
                                <p style="text-align: justify">
                                    The term “Sensitive Personal Data” means
                                    Personal Data concerning the racial or
                                    ethnic origin, political opinions, religious
                                    or philosophical beliefs, trade-union
                                    membership, or concerning health or sex life
                                    or orientation, criminal conviction or
                                    offenses. By volunteering to share sensitive
                                    personal data to us (e.g., by submitting
                                    your resume or a job application online),
                                    you explicitly provide consent to your
                                    personal data being used as described in
                                    this Policy.
                                </p>

                                <p style="text-align: center">
                                    <strong>Business Reorganization</strong>
                                </p>
                                <p style="text-align: justify">
                                    With ever-changing waves in the business
                                    world like many organizations, LeanQubit may
                                    restructure its business models and
                                    operations around the globe when required,
                                    whether by buying new entities/business or
                                    selling or merging existing businesses. This
                                    may require sharing of personal data to the
                                    potential or actual buyer of parts of our
                                    business, or receive personal data from
                                    potential sellers. It is our responsibility
                                    to seek proper privacy protection for
                                    personal data disclosed involved in such
                                    dealings.
                                </p>

                                <p style="text-align: center">
                                    <strong>Legal Disclosures of Data</strong>
                                </p>
                                <p style="text-align: justify">
                                    LeanQubit can share or disclose your
                                    personal data due to some urgent state of
                                    affairs to protect personal safety, the
                                    public or LeanQubit website. It may also be
                                    possible that we may need to disclose your
                                    personal data to the law for any judicial or
                                    other government summons, warrants, or
                                    orders, in any country where we do business.
                                </p>

                                <p style="text-align: center">
                                    <strong
                                        >How long can we retain your
                                        data?</strong
                                    >
                                </p>
                                <p style="text-align: justify">
                                    We can retain your personal data as long as
                                    it is necessary to us or until you revoke
                                    your consent on the same. We have our own
                                    record management and retention policies as
                                    per that we decide to keep or delete the
                                    data after a reasonable time, following the
                                    below mentioned criteria.
                                </p>
                                <p style="text-align: justify">
                                    We preserve your data till we have an
                                    enduring business association with you
                                    (specifically, if you have an account with
                                    us). We will only retain your data until
                                    your account is active or as long as we are
                                    providing our service to you. We will retain
                                    your data until we needed it, in order to
                                    comply with our global legal and contractual
                                    agreement with you. We will retain your data
                                    for as long as your employment with your
                                    company subsists. Requests regarding your
                                    Personal Data: Access, Correction, Update
                                    and Deletion: Subject to the applicable law,
                                    you have the right to inquire about your
                                    data anytime, whether you want to access,
                                    rectify or delete your data.
                                </p>

                                <p style="text-align: center">
                                    <strong>Contact Us</strong>
                                </p>
                                <p>
                                    If you have any questions or requests in
                                    connection with this Privacy Notice or other
                                    privacy-related matters, please send an
                                    email to
                                    <a
                                        href="mailto:sales@leanqubit.ai"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >sales@leanqubit.ai</a
                                    >
                                    <span style="font-weight: 400">and/or</span
                                    >&nbsp;inquiries may be addressed to:<br />
                                    LeanQubit Pvt Ltd,<br />
                                    <span style="font-weight: 400"
                                        >LeanQubit Inc. Research & Technology
                                        Complex 2600 Dorr Street Toledo, OH
                                        43606</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </div>
    </div>
</div>
