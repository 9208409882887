import { Component, OnInit, AfterViewInit, ElementRef, ViewChild, HostListener } from '@angular/core';

@Component({
  selector: 'app-aws',
  templateUrl: './aws.component.html',
  styleUrls: ['./aws.component.scss']
})
export class AWSComponent {
 @ViewChild('sliderContainer') sliderContainer: ElementRef;
  
  testimonials = [
    {
      name: 'On-Premises Data Monitoring',
      // company: 'Almas Market',
      image: 'assets/img/OptebizImage/introIcon_webBasedDeployment.png',
      text: 'AWS IoT SiteWise, including SiteWise Edge, collects, processes, and monitors equipment data locally on on-premises hardware before securely sending it to AWS. This facilitates efficient data management for industrial applications.',
      rating: 5
    },
    {
      name: 'Rapid Development',
      // company: 'Voila Caffe',
      image: 'assets/img/OptebizImage/introIcon_rapidDevelopment.png',
      text: "Utilize a comprehensive set of deployment options for geo-distributed and hybrid environments. LeanQubit can be deployed across multiple AWS availability zones and regions (or even across clouds) using sync and async replication topologies.",
      rating: 5
    },
    {
      name: 'Security and Compliance',
      // company: 'Green Lodge',
      image: 'assets/img/OptebizImage/introIcon_ITStandards.png',
      text: 'AWS Cloud is that it allows you to scale and innovate, while maintaining a secure environment and paying only for the services you use. This means that you can have the security you need at a lower cost than in an on-premises environment.',
      rating: 5
    },
    {
      name: 'Serverless Cloud & Functions',
      // company: 'Almas Market',
      image: 'assets/img/OptebizImage/introicon-data-collection-analytics.png',
      text: 'Get started on AWS in minutes with no software installation, configuration, or management with LeanQubit Managed. Or, if you prefer, we can provide you with all the tools you need to run a self-managed AWS yourself.',
      rating: 5
    },
    {
      name: 'Flexible schema management',
      // company: 'Voila Caffe',
      image: 'assets/img/OptebizImage/introicon-automation.png',
      text: 'Amazon Cloud Directory allows flexible schema management for easily extending directory attributes and relationships. This supports multiple independent applications, promoting shared directories and eliminating data duplication.',
      rating: 5
    },
    {
      name: 'Reduce Development Time',
      // company: 'Tech Solutions',
      image: 'assets/img/OptebizImage/introIcon_rapidDevelopment.png',
      text: 'LeanQubit FactoVision on AWS accelerates deployment, leveraging the scalability and efficiency of AWS for a swift and optimized implementation. Experience the benefits of our solutions quickly and seamlessly.',
      rating: 5
    },
    // {
    //   name: 'Analyze & Increase Performance',
    //   // company: 'Digital Marketing',
    //   image: 'assets/img/OptebizImage/IntroIcon-Sepasoft-BetterAnalyze.svg',
    //   text: 'Being an AWS SaaS Partner, LeanQubit provides cloud-based analytics solutions that afford you a comprehensive overview of your processes, coupled with actionable insights to enhance productivity and mitigate wastage.',
    //   rating: 5
    // },
    // {
    //   name: 'Scalable Architecture',
    //   // company: 'Cloud Solutions',
    //   image: 'assets/img/OptebizImage/introicon-scalable-architecture.png',
    //   text: 'This ensures efficient scaling without the need for a complete overhaul, enabling seamless addition of devices, data points, or features as needed for optimal performance as the system evolves.',
    //   rating: 5
    // }
  ];

  currentIndex = 0;
  cardWidth = 0;
  cardsPerView = 3;
  autoSlideInterval: any;
  touchStartX = 0;
  touchEndX = 0;
  totalPages = 0;
  
  constructor() { }

  ngOnInit(): void {
    // Initialize
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.updateCardsPerView();
      this.startAutoSlide();
    }, 0);
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateCardsPerView();
  }

  updateCardsPerView(): void {
    if (window.innerWidth < 768) {
      this.cardsPerView = 1;
    } else if (window.innerWidth < 1024) {
      this.cardsPerView = 2;
    } else {
      this.cardsPerView = 3;
    }
    
    const sliderElement = this.sliderContainer.nativeElement;
    const cards = sliderElement.querySelectorAll('.testimonial-card');
    if (cards.length > 0) {
      this.cardWidth = cards[0].offsetWidth + 20; // card width + margin
    }
    
    this.totalPages = Math.ceil(this.testimonials.length / this.cardsPerView);
    this.updateSliderPosition();
  }

  updateSliderPosition(): void {
    const sliderElement = this.sliderContainer.nativeElement;
    sliderElement.style.transform = `translateX(-${this.currentIndex * this.cardWidth}px)`;
  }

  nextSlide(): void {
    const maxIndex = this.testimonials.length - this.cardsPerView;
    if (this.currentIndex < maxIndex) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
    this.updateSliderPosition();
  }

  prevSlide(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.testimonials.length - this.cardsPerView;
    }
    this.updateSliderPosition();
  }

  goToPage(pageIndex: number): void {
    this.currentIndex = pageIndex * this.cardsPerView;
    if (this.currentIndex > this.testimonials.length - this.cardsPerView) {
      this.currentIndex = this.testimonials.length - this.cardsPerView;
    }
    this.updateSliderPosition();
  }

  getCurrentPage(): number {
    return Math.floor(this.currentIndex / this.cardsPerView);
  }

  startAutoSlide(): void {
    this.autoSlideInterval = setInterval(() => {
      this.nextSlide();
    }, 5000);
  }

  stopAutoSlide(): void {
    if (this.autoSlideInterval) {
      clearInterval(this.autoSlideInterval);
    }
  }

  onTouchStart(event: TouchEvent): void {
    this.touchStartX = event.touches[0].clientX;
    this.stopAutoSlide();
  }

  onTouchEnd(event: TouchEvent): void {
    this.touchEndX = event.changedTouches[0].clientX;
    this.handleSwipe();
    this.startAutoSlide();
  }

  handleSwipe(): void {
    if (this.touchEndX < this.touchStartX - 50) {
      this.nextSlide();
    }
    if (this.touchEndX > this.touchStartX + 50) {
      this.prevSlide();
    }
  }

  trackByFn(index: number): number {
    return index;
  }

  // Generate array for star rating display
  getStarArray(rating: number): number[] {
    return Array(5).fill(0).map((_, i) => i < rating ? 1 : 0);
  }

  // For scroll to top functionality
  // scrollToTop(): void {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   });
  // }

  // Cleanup
  ngOnDestroy(): void {
    this.stopAutoSlide();
  }
}

