import { Component } from '@angular/core';

@Component({
  selector: 'app-services-details-factomes',
  templateUrl: './services-details-factomes.component.html',
  styleUrls: ['./services-details-factomes.component.scss']
})
export class ServicesDetailsFactomesComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Product Features',
          subTitle: 'FactoMES',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      // {
      //     img: 'img\OptebizImage\Process_Cycle.jpg'
      // }
  ]
  singleSolutionsBox: solutionsBoxContent[] = [
    {
      icon: 'flaticon-analytics',
      title: 'Production Monitoring',
      paragraphText: 'Time series data is information collected at regular time intervals, representing variables like stock prices, temperature, or sales figures that change over time.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Work Order Management',
      paragraphText: 'Tremendous un-utilized Knowledge base that can uncover critical information through analytical techniques.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Inventory Management',
      paragraphText: ' This involves visualizing time series data through line charts, bar charts, and other graphical methods to identify trends and patterns.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Quality Control',
      paragraphText: 'Tremendous un-utilized Knowledge base that can uncover critical information through analytical techniques.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
      icon: 'flaticon-analytics',
      title: 'Downtime Management',
      paragraphText: 'Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.',
      link: 'services-details',
      linkText: 'View Details'
  },
  {
    icon: 'flaticon-analytics',
    title: 'Reporting and Analytics',
    paragraphText: 'Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.',
    link: 'services-details',
    linkText: 'View Details'
},
{
  icon: 'flaticon-analytics',
  title: 'Paperless Manufacturing',
  paragraphText: 'Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.',
  link: 'services-details',
  linkText: 'View Details'
},
{
  icon: 'flaticon-analytics',
  title: 'Machine Integration',
  paragraphText: 'Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.',
  link: 'services-details',
  linkText: 'View Details'
},
{
  icon: 'flaticon-analytics',
  title: 'Maintenance Management',
  paragraphText: 'Detecting anomalies in time series data is critical for spotting fraud, network breaches, or equipment failures.',
  link: 'services-details',
  linkText: 'View Details'
},
 
      
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'FactoMES',
          // title1: 'About this Product',
          paragraphText1: 'FactoMES suite is a comprehensive solution to keep track of all activities on the shop floor from start to finish. Whether it is receiving factory orders, scheduling production runs, or managing the movement of finished goods, FactoMES can help the companies streamline their operations and automate their processes.',
          // paragraphText2: 'Ensure product quality through quality checks and inspections, and track product defects and rework.',
          title2: 'Important Facts',
          title3: 'Products Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/projects/img2.jpg',
          img2: 'assets/img/services/charts.jpg'
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      // {
      //     title: 'The Field of Data Science'
      // },
      // {
      //     title: 'The Problem'
      // },
      // {
      //     title: 'The Solution'
      // },
      // {
      //     title: 'The Skills'
      // },
      // {
      //     title: 'Statistics'
      // },
      // {
      //     title: 'Mathematics'
      // }
  ]
  servicesDetailsDescApplication: Application[] = [
      // {
      //     title: 'Manufacturing',
      //     icon: 'flaticon-factory'
      // },
      // {
      //     title: 'Healthcare',
      //     icon: 'flaticon-hospital'
      // },
      // {
      //     title: 'Automobile',
      //     icon: 'flaticon-tracking'
      // },
      // {
      //     title: 'Banking',
      //     icon: 'flaticon-investment'
      // },
      // {
      //     title: 'Real Estate',
      //     icon: 'flaticon-house'
      // },
      // {
      //     title: 'Logistics',
      //     icon: 'flaticon-order'
      // }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      // {
      //     title: 'JavaScript'
      // },
      // {
      //     title: 'Python'
      // },
      // {
      //     title: 'Java'
      // },
      // {
      //     title: 'C/CPP'
      // },
      // {
      //     title: 'PHP'
      // },
      // {
      //     title: 'Swift'
      // },
      // {
      //     title: 'C# (C- Sharp)'
      // },
      // {
      //     title: 'Ruby'
      // },
      // {
      //     title: 'SQL'
      // }
  ]

  sidebarServicesList: ServicesList[] = [
      // {
      //     title: 'AI & ML Development',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Analytics',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Science',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Artificial Intelligence',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Data Visualization',
      //     link: 'services-details'
      // }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      // {
      //     title: 'PDF Download',
      //     icon: 'bx bxs-file-pdf',
      //     link: 'services-details'
      // },
      // {
      //     title: 'Services Details.txt',
      //     icon: 'bx bxs-file-txt',
      //     link: 'services-details'
      // }
  ]
  sidebarContactInfo: ContactInfo[] = [
      // {
      //     icon: 'bx bx-user-pin',
      //     title: 'Phone',
      //     subTitle: '+2145 354 5413'
      // },
      // {
      //     icon: 'bx bx-map',
      //     title: 'Location',
      //     subTitle: 'New York, USA'
      // },
      // {
      //     icon: 'bx bx-envelope',
      //     title: 'Email',
      //     subTitle: 'hello@wilo.com'
      // }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  // title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  // paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}
class solutionsBoxContent {
  icon : string;
  title : string;
  paragraphText : string;
  link : string;
  linkText : string;
}
