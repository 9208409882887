<!-- HTML -->
<section class="hero-section">
    <div class="hero-content">
        <h1 class="hero-title">
            Empowering You<br />with Data in Inductive Automation
        </h1>
        <p class="hero-text">
            Unsure what you need? Not getting results? LeanQubit delivers
            tailored automation solutions to put data in your hands.
        </p>
        <div class="button-group">
            <a href="#" class="btn btn-primary">TRY IT NOW</a>
            <a href="#" class="btn btn-secondary">SCHEDULE DEMO</a>
        </div>
    </div>
</section>

<!-- HTML -->
<section class="services-section">
    <div class="services-container">
        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path
                        d="M8 9h8M8 13h5M21 12a9 9 0 1 1-9-9 9 9 0 0 1 9 9Z"
                    />
                    <path d="M12 21v-3.5a2.5 2.5 0 0 1 5 0V21" />
                </svg>
            </div>
            <h3 class="service-title">Consultation</h3>
            <p class="service-text">
                We analyze your current operations and systems involved and any
                previous data-related work.
            </p>
        </div>

        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <circle cx="12" cy="12" r="10" />
                    <path d="M16 16 12 12 8 16 10 10 16 8z" />
                </svg>
            </div>
            <h3 class="service-title">Exploration</h3>
            <p class="service-text">
                Find the areas that need help the most – filling gaps present
                from previous solutions, or assessing a solution from scratch.
            </p>
        </div>

        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M12 2v4" />
                    <path d="M12 22v-4" />
                    <path d="M4.93 4.93l2.83 2.83" />
                    <path d="M16.24 16.24l2.83 2.83" />
                    <path d="M2 12h4" />
                    <path d="M22 12h-4" />
                    <path d="M4.93 19.07l2.83-2.83" />
                    <path d="M16.24 7.76l2.83-2.83" />
                    <circle cx="12" cy="12" r="3" />
                </svg>
            </div>
            <h3 class="service-title">Integration</h3>
            <p class="service-text">
                Work with your team to implement our solutions, and guide you
                through the system – ensuring the product is built around you,
                for you.
            </p>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row align-items-center cs_gap_y_40">
            <div
                class="col-xl-6 wow fadeInLeft"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                <div class="cs_pr_45 text-center">
                    <img
                        src="assets\img\OptebizImage\inductive main pagelatest1.svg"
                        alt="Image"
                        style="margin-top: 120px"
                    />
                </div>
            </div>
            <div
                class="col-xl-6 wow fadeIn"
                data-wow-duration="0.8s"
                data-wow-delay="0.2s"
            >
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title mb-0">
                        <span
                            style="
                                color: #1d9395;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                                text-transform: none;
                            "
                            >Lean</span
                        ><span
                            style="
                                color: #da5c30;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                                text-transform: none;
                            "
                            >Q</span
                        ><span
                            style="
                                color: #1d9395;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                                text-transform: none;
                            "
                            >ubit</span
                        >
                        Creates a Direct Impact on Your Bottom Line
                    </h2>
                </div>
                <div class="cs_height_60 cs_height_lg_40"></div>
                <div class="row cs_gap_y_40">
                    <div class="col-lg-6">
                        <div class="cs_iconbox cs_style_2">
                            <div
                                class="cs_number_box cs_bg_accent cs_text_white"
                            >
                                01
                            </div>
                            <h3 class="cs_iconbox_title">Save Time</h3>
                            <p class="cs_iconbox_subtitle">
                                LeanQubit optimizes delivery operations and
                                reduces production lead time with automated DAQ
                                devices, saving time in data collection and
                                analysis.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="cs_iconbox cs_style_2">
                            <div
                                class="cs_number_box cs_bg_accent cs_text_white"
                            >
                                02
                            </div>
                            <h3 class="cs_iconbox_title">Reduce Costs</h3>
                            <p class="cs_iconbox_subtitle">
                                LeanQubit offers low-cost MES solutions and
                                Facto-Tools to cut expenses and boost NPV,
                                ensuring profit protection with data-driven
                                decisions.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="cs_iconbox cs_style_2">
                            <div
                                class="cs_number_box cs_bg_accent cs_text_white"
                            >
                                03
                            </div>
                            <h3 class="cs_iconbox_title">
                                Boost Uptime Performance
                            </h3>
                            <p class="cs_iconbox_subtitle">
                                LeanQubit Facto Solutions minimize downtime,
                                enhance machinery longevity, and optimize cycle
                                time with advanced analytics.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="cs_iconbox cs_style_2">
                            <div
                                class="cs_number_box cs_bg_accent cs_text_white"
                            >
                                04
                            </div>
                            <h3 class="cs_iconbox_title">
                                Improve Product Quality
                            </h3>
                            <p class="cs_iconbox_subtitle">
                                Facto Solutions offers real-time tracking with
                                alerts,offers real-time tracking with alerts,
                                ensuring quick issue resolution and process
                                optimization.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_75"></div>
</section>
<section class="testimonial-section">
    <div class="section-header">
        <span class="section-subtitle"
            >Be Future ready with
            <span
                style="
                    color: #1d9395;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >Lean</span
            >
            <span
                style="
                    color: #da5c30;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >Q</span
            >
            <span
                style="
                    color: #1d9395;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >ubit</span
            >
        </span>
        <h2 class="section-title">
            LeanQubit provides a development environment equipped with
            everything you need to create a wide range of industrial
            applications, including SCADA, IIoT, MES, and more—all on one
            platform.
        </h2>
    </div>

    <div class="testimonial-grid">
        <div
            class="testimonial-card"
            *ngFor="let testimonial of testimonials; trackBy: trackByFn"
        >
            <div class="client-info">
                <div class="client-image">
                    <img
                        [src]="testimonial.image"
                        [alt]="testimonial.name"
                        (error)="
                            $event.target.src = 'assets/img/fallback-image.png'
                        "
                    />
                </div>
                <div class="client-details">
                    <h4>{{ testimonial.name }}</h4>
                    <p>{{ testimonial.company }}</p>
                </div>
            </div>
            <p class="testimonial-text">{{ testimonial.text }}</p>
        </div>
    </div>
</section>

<section class="industry-section">
    <div class="content-wrapper">
        <div class="text-content">
            <div class="header-with-logo">
                <p class="subtitle">Certified by Inductive Automation</p>
                <img
                    src="assets\img\OptebizImage\igni_partner_4.png"
                    alt="Ignition Logo"
                    class="ignition-logo"
                />
            </div>
            <h2 class="title">
                Implementing Standard &
                <span class="highlight">Custom Solutions</span>
            </h2>
            <p class="description">
                LeanQubit, a Certified Ignition Integrator, specializes in
                delivering tailored Ignition-based solutions for manufacturing.
            </p>
            <ul class="features">
                <li>Certified Experts in Ignition software</li>
                <li>
                    Industry Experience in manufacturing, energy, and utilities
                </li>
                <li>Custom Solutions for businesses of any size</li>
                <li>Scalable & Flexible to grow with your needs</li>
                <li>
                    Optimized Operations for better efficiency and performance
                </li>
            </ul>
        </div>
        <div class="image-content">
            <img
                src="assets\img\OptebizImage\mobile-responsive.png"
                alt="Dashboard on devices"
                class="dashboard-image"
            />
        </div>
    </div>
</section>

<section class="methodology-section">
    <div class="container">
        <div class="methodology-grid">
            <!-- Left Image Side (Swapped position) -->
            <div class="image-side">
                <div class="image-wrapper">
                    <img
                        src="assets\img\OptebizImage\IA-CSIA-Show-diagram-WEB-1.png"
                        alt="Ignition Methodology Diagram"
                        class="main-image"
                    />
                </div>
            </div>

            <!-- Right Content Side (Swapped position) -->
            <div class="content-side">
                <div class="header-content">
                    <div class="badge-wrapper">
                        <span class="badge">Ignition Certified</span>
                        <div class="badge-line"></div>
                    </div>
                    <h1>
                        <span
                            style="
                                color: #1d9395;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                            "
                            >Lean</span
                        ><span
                            style="
                                color: #da5c30;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                            "
                            >Q</span
                        ><span
                            style="
                                color: #1d9395;
                                font-family: 'Bahnschrift Condensed', sans-serif;
                            "
                            >ubit</span
                        >
                        METHODOLOGY IN IGNITION
                    </h1>
                    <p class="intro-text">
                        LeanQubit is a Certified Integrator of Ignition,
                        delivering expert solutions perfectly aligned with your
                        business requirements.
                    </p>
                </div>

                <div class="features-list">
                    <ul>
                        <li>
                            Deep expertise in manufacturing, energy, and utility
                            industries
                        </li>
                        <li>
                            Certified Professionals maintaining the latest
                            Ignition solutions
                        </li>
                        <li>Rigorous Testing and validation processes</li>
                        <li>
                            24/7 Support with technical assistance and
                            maintenance services
                        </li>
                        <li>
                            Expert Project Management ensuring smooth delivery
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
