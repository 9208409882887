import { Component } from '@angular/core';

@Component({
  selector: 'app-trigger-based',
  templateUrl: './trigger-based.component.html',
  styleUrls: ['./trigger-based.component.scss']
})
export class TriggerBasedComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto CloudConnect',
          subTitle: 'Trigger-Based Data Dispatch Mechanism',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/trigger.png'
      }
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Trigger-Based Data Dispatch Mechanism',
          title1: 'Trigger-Based Data Dispatch Mechanism',
          paragraphText1: 'A trigger-based data dispatch mechanism is a method for initiating the transfer or dispatch of data based on predefined conditions or events, commonly referred to as triggers. This mechanism is often used in the context of data integration and event-driven architectures to ensure that data is sent or processed in response to specific events or changes in the system.',
          paragraphText2: 'A trigger-based data dispatch mechanism is a powerful approach for orchestrating the timely and efficient transfer of data in response to specific events or conditions, providing a flexible and scalable solution for various integration scenarios.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/dispatch.webp',
          img2: 'assets/img/OptebizImage/queued-trigger.png'
          
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Event-Driven Architecture'
      },
      {
          title: 'Real-Time Integration'
      },
      {
          title: 'Triggers Define Dispatch Points'
      },
      {
          title: 'Dependencies on External Systems'
      },
      {
          title: 'Data Transformation'
      },
      {
          title: 'Balancing Throughput and Resources'
      }
  ]
  servicesDetailsDescApplication: Application[] = [
      {
          title: 'Manufacturing',
          icon: 'flaticon-factory'
      },
      {
          title: 'Healthcare',
          icon: 'flaticon-hospital'
      },
      {
          title: 'Automobile',
          icon: 'flaticon-tracking'
      },
      {
          title: 'Banking',
          icon: 'flaticon-investment'
      },
      {
          title: 'Real Estate',
          icon: 'flaticon-house'
      },
      {
          title: 'Logistics',
          icon: 'flaticon-order'
      }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      {
          title: 'JavaScript'
      },
      {
          title: 'Python'
      },
      {
          title: 'Java'
      },
      {
          title: 'C/CPP'
      },
      {
          title: 'PHP'
      },
      {
          title: 'Swift'
      },
      {
          title: 'C# (C- Sharp)'
      },
      {
          title: 'Ruby'
      },
      {
          title: 'SQL'
      }
  ]

  sidebarServicesList: ServicesList[] = [
      {
          title: 'Data Integration ',
          link: 'data-integration'
      },
      {
          title: 'Integration Service',
          link: 'integration-service'
      },
      {
        title: 'Resilient Architecture',
        link: 'resilient-architecture'
    },
      {
          title: 'Transaction Progress',
          link: 'transaction-progress'
      },
      {
          title: 'Dynamic Tag Selection',
          link: 'dynamic-tag-selection'
      }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'services-details'
      },
      {
          title: 'Services Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'services-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}



