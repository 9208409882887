import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { Router } from '@angular/router';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent  {
    isMenuOpen = false;

  @ViewChild('menuContainer') menuContainer!: ElementRef;

  constructor(private router: Router) {}

  // Close menu when clicking outside
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if (!this.menuContainer.nativeElement.contains(event.target)) {
      this.isMenuOpen = false;
    }
  }

  toggleMenu(event: Event) {
    event.stopPropagation();  // Prevent document click from immediately closing menu
    this.isMenuOpen = !this.isMenuOpen;
  }

  navigateAndClose(path: string) {
    
    this.isMenuOpen = false;  // Force close the menu
    this.router.navigate([path]);
  }
}