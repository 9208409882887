import { Component,OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';


@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.scss'],
  animations: [
    trigger('fadeInUp', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('{{delay}} 0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ])
  ]
})
export class TestingComponent implements OnInit {

  texts: string[] = [
    '03+ Variations',
    'Built with Sass',
    'Built on Bootstrap',
    'W3 Valid',
    'Easily Customizable',
    'Fully Responsive Layout',
    'Developer Friendly',
    'Well Documented'
  ];
  currentText: string = '';
  currentIndex: number = 0;
  isDeleting: boolean = false;
  typingSpeed: number = 100;
  deletingSpeed: number = 50;
  pauseDuration: number = 1000;


  mainBannerContent: Content = {
    title: 'Manufacturing with Industrial IoT and AI',
    paragraphText: 'Your Path to Smart, Efficient, and Data-Driven Production',
    paragraphText1: 'We help manufacturers maximize equipment efficiency, reduce downtime, improve product quality, and optimize resource allocation, leading to significant cost savings and a competitive edge in the market.',
    defaultBtnIcon: 'flaticon-structure',
    defaultBtnText: 'About Us',
    defaultBtnLink: 'about-us',
    videoBtnIcon: 'flaticon-google-play',
    videoBtnText: 'Watch Video',
    videoBtnLink: 'https://www.youtube.com/watch?v=7RWmfIVDkN8'
  };


  ngOnInit() {
    this.animateText();
  }
  animateText() {
    const current = this.currentIndex % this.texts.length;
    const fullText = this.texts[current];

    if (this.isDeleting) {
      this.currentText = fullText.substring(0, this.currentText.length - 1);
    } else {
      this.currentText = fullText.substring(0, this.currentText.length + 1);
    }

    let typingSpeed = this.isDeleting ? this.deletingSpeed : this.typingSpeed;

    if (!this.isDeleting && this.currentText === fullText) {
      typingSpeed = this.pauseDuration;
      this.isDeleting = true;
    } else if (this.isDeleting && this.currentText === '') {
      this.isDeleting = false;
      this.currentIndex++;
      typingSpeed = 500;
    }

    setTimeout(() => this.animateText(), typingSpeed);
  }




}

class Content {
  title : string;
  paragraphText : string;
  paragraphText1: string;
  defaultBtnIcon : string;
  defaultBtnText: string;
  defaultBtnLink : string;
  videoBtnIcon : string;
  videoBtnText: string;
  videoBtnLink : string;
}