import { Component } from '@angular/core';

@Component({
  selector: 'app-reportand-analytics',
  templateUrl: './reportand-analytics.component.html',
  styleUrls: ['./reportand-analytics.component.scss']
})
export class ReportandAnalyticsComponent {

  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto MES',
          subTitle: 'Report and Analytics',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: '/assets/img/OptebizImage/AnalticsFront.png'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Report and Analytics',
          title1: 'Report & Analytics',
          paragraphText1: 'Reporting and analytics refer to the processes and tools used by organizations to gather, analyze, and present data in a meaningful way to support decision-making and improve business performance. These practices involve the systematic exploration of data to uncover trends, patterns, and insights that can inform strategic and operational decisions.',
          paragraphText2: 'Reporting and analytics encompass the processes of collecting, organizing, analyzing, and presenting data to gain valuable insights for informed decision-making. Reporting involves summarizing data into structured formats like tables and charts, providing a snapshot of key metrics. Analytics, on the other hand, involves a deeper exploration of data using statistical and computational techniques to uncover patterns, trends, and predictions.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: '/assets/img/OptebizImage/img-report.png',
          img2: '/assets/img/OptebizImage/statusReport.png'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Data Collection'
      },
      {
          title: 'Data Storage and Management'
      },
      {
          title: 'Data Processing'
      },
      {
          title: 'Data Analysis'
      },
      {
          title: 'Key Performance Indicators (KPIs)'
      },
      {
          title: 'Ad Hoc Analysis'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Production Monitoring',
        link: 'view-details'
    },
    {
      title: 'Workorder Management',
      link: 'work-order-management'
  },
  {
      title: 'Inventory Management',
      link: 'inventory-management'
  },
  {
      title: 'Quality Control',
      link: 'quality-control'
  },
  {
      title: 'Downtime Management',
      link: 'downtime-management'
  },
  
//   {
//       title: 'Paperless Manufacturing',
//       link: 'paperless-manufacturing'
//   },
//    {
//       title: 'Machine Integration',
//       link: 'machine-integration'
//   },
//   {
//       title: 'Maintenance Management',
//       link: 'maintenance-management'
//   }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
        icon: 'bx bx-user-pin',
        title: 'Phone',
        subTitle: '+1 (419) 476-4520'
    },
    {
        icon: 'bx bx-map',
        title: 'Location',
        subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
    },
    {
        icon: 'bx bx-envelope',
        title: 'Email',
        subTitle: 'bizops@OpteBiz.com'
    }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}
