<div class="banner-area">
    <div class="slider-container" [@zoomTransition]>
        <div
            class="slider-content"
            [ngStyle]="{
                'background-image':
                    'url(' + slides[currentSlide].backgroundImage + ')'
            }"
        >
            <div class="overlay"></div>
            <div class="content-wrapper">
                <span class="small-title">{{
                    slides[currentSlide].smallTitle
                }}</span>
                <h2 class="large-title">
                    {{ slides[currentSlide].largeTitle }}
                </h2>
                <p class="info">{{ slides[currentSlide].info }}</p>
                <div class="buttons">
                    <a
                        [routerLink]="slides[currentSlide].Freetrail"
                        class="btn btn-primary"
                        >Try Free Trial</a
                    >
                    <a
                        [routerLink]="slides[currentSlide].Demo"
                        class="btn btn-primary"
                        >Product Demo</a
                    >
                </div>
            </div>
            <div class="masking-text">
                <h1>{{ slides[currentSlide].maskingTitle }}</h1>
            </div>
        </div>
        <button class="nav-btn prev" (click)="prevSlide()">&#10094;</button>
        <button class="nav-btn next" (click)="nextSlide()">&#10095;</button>
    </div>
</div>
