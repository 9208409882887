import { Component } from '@angular/core';

@Component({
  selector: 'app-resilient-architecture',
  templateUrl: './resilient-architecture.component.html',
  styleUrls: ['./resilient-architecture.component.scss']
})
export class ResilientArchitectureComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto CloudConnect',
          subTitle: 'Resilient Data Store and Forward Architecture',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/image.png'
      }
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Resilient Architecture for Data Store and Forward',
          title1: 'Resilient Data Store & Forward Architecture',
          paragraphText1: 'A resilient architecture for data store and forward is crucial for ensuring the reliability and fault-tolerance of systems that handle asynchronous communication, particularly in scenarios where data needs to be stored temporarily before being forwarded to its destination.',
          paragraphText2: 'A resilient architecture for data store and forward is essential for ensuring the reliability and fault tolerance of systems that handle asynchronous communication. By choosing a resilient data store, implementing store-and-forward logic, managing transactions effectively, monitoring the system, considering fault tolerance, addressing security concerns, and ensuring scalability, you can build a robust and resilient store-and-forward architecture that meets the requirements of your application.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/images.png',
          img2: 'assets/img/OptebizImage/2020022703I.png'
          
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Message Producer'
      },
      {
          title: 'Data Store or Message Queue'
      },
      {
          title: 'Store-and-Forward Logic'
      },
      {
          title: 'Transaction Manager'
      },
      {
          title: 'Fault Tolerance Mechanisms'
      },
      {
          title: 'Monitoring and Logging'
      }
  ]
  servicesDetailsDescApplication: Application[] = [
      {
          title: 'Manufacturing',
          icon: 'flaticon-factory'
      },
      {
          title: 'Healthcare',
          icon: 'flaticon-hospital'
      },
      {
          title: 'Automobile',
          icon: 'flaticon-tracking'
      },
      {
          title: 'Banking',
          icon: 'flaticon-investment'
      },
      {
          title: 'Real Estate',
          icon: 'flaticon-house'
      },
      {
          title: 'Logistics',
          icon: 'flaticon-order'
      }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      {
          title: 'JavaScript'
      },
      {
          title: 'Python'
      },
      {
          title: 'Java'
      },
      {
          title: 'C/CPP'
      },
      {
          title: 'PHP'
      },
      {
          title: 'Swift'
      },
      {
          title: 'C# (C- Sharp)'
      },
      {
          title: 'Ruby'
      },
      {
          title: 'SQL'
      }
  ]

  sidebarServicesList: ServicesList[] = [
      {
          title: 'Data Integration ',
          link: 'data-integration'
      },
      {
          title: 'Integration Service',
          link: 'integration-service'
      },
      {
          title: 'Trigger-Based',
          link: 'trigger-based'
      },
      {
          title: 'Transaction Progress',
          link: 'transaction-progress'
      },
      {
          title: 'Dynamic Tag Selection',
          link: 'dynamic-tag-selection'
      }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'services-details'
      },
      {
          title: 'Services Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'services-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}


