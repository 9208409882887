import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class FooterComponent implements OnInit {

    location: any;
    footerClass: any;
    email: string = '';
    subscribeUrl = 'https://condor3676.startdedicated.com:8005/subscribe/';
    subscriptionStatus: string = '';

    constructor(
        private router: Router,
        location: Location,
        private http: HttpClient
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/home-two' || this.location == '/home-four' || this.location == '/services-details' || this.location == '/events-details' || this.location == '/courses' || this.location == '/courses-details'){
                    this.footerClass = 'footer-area';
                } else if(this.location == '/about-us') {
                    this.footerClass = 'footer-area bg-fffaf3';
                } else {
                    this.footerClass = 'footer-area bg-color';
                }
            }
        });
    }

    ngOnInit(): void { }
    
    onEmailChange(event: any): void {
        this.email = event.target.value;
    }

    subscribe(): void {
        if (!this.email || !this.validateEmail(this.email)) {
            this.subscriptionStatus = 'Please enter a valid email address';
            return;
        }

        this.http.post(this.subscribeUrl, { email: this.email })
            .subscribe({
                next: (response) => {
                    this.subscriptionStatus = 'Successfully subscribed!';
                    this.email = '';
                },
                error: (error) => {
                    this.subscriptionStatus = 'Error subscribing. Please try again.';
                    console.error('Subscription error:', error);
                }
            });
    }

    validateEmail(email: string): boolean {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    }
}