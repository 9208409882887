// homeone-banner.component.ts
import { Component, OnInit } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

interface BannerContent {
    title: string;
    paragraphText: string;
    paragraphText1: string;
    defaultBtnIcon: string;
    defaultBtnText: string;
    defaultBtnLink: string;
    videoBtnIcon: string;
    videoBtnText: string;
    videoBtnLink: string;
    featuredServices: {
        icon?: string;
        title?: string;
        paragraphText?: string | string[]; 
        linkText?: string;
        linkIcon?: string;
        link?: string;
    }[];
    
    contentSections?: ContentSection[];
}


interface ContentSection {
    imageSrc: string;
    imageAlt: string;
    imageTitle: string;
    contentHeading: string;
    contentDetail: string;
    buttonLink: string;
    buttonText: string;
    alignment: 'left' | 'right';
  }

@Component({
    selector: 'app-homeone-banner',
    templateUrl: './homeone-banner.component.html',
    styleUrls: ['./homeone-banner.component.scss'],
    animations: [
        trigger('slideAnimation', [
            transition(':enter', [
                style({ transform: 'translateX(100%)', opacity: 0 }),
                animate('800ms ease-in-out', style({ transform: 'translateX(0)', opacity: 1 }))
            ]),
            transition(':leave', [
                animate('800ms ease-in-out', style({ transform: 'translateX(-100%)', opacity: 0 }))
            ])
        ])
    ]
})
export class HomeoneBannerComponent implements OnInit {
    isMainBannerVisible: boolean = true; // Initially show main banner
    currentIndex: number = 0;
    animating: boolean = false;
    slideCount: number = 0;  // Track the number of slides transitioned

    mainBannerContent: BannerContent[] = [


        {
            title: 'Powering Innovation with Ignition',
            paragraphText: "Driving Innovation with Inductive Automation",
            paragraphText1: 'Integrate cutting-edge IoT sensors and AI-powered analytics to transform your factory operations.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Our Solutions',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'View Demo',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo2',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/Inductive-Automation-Ignition-Designer-Logo.png',
                    title: 'Partnering With Ignition',
                    paragraphText: [
                        "Collaboration with Inductive Automation.",
                        "State-of-the-art Ignition-based solutions.",
                        "Enhances operational efficiency.",
                        "Drives innovation in manufacturing.",
                        "Optimized for seamless integration."
                    ],
                                      // linkText: 'Learn More',
                    // linkIcon: 'flaticon-right',
                    // link: 'FactoIQ'
                },
                {
                    icon: 'assets/img/OptebizImage/Downloads.png',
                    // linkText: 'Learn More',
                    // linkIcon: 'flaticon-right',
                    // link: 'FactoMES'
                }
            ]
        },
        

        
        {
            title: 'Data Science Transforms Production',
            paragraphText: 'Transform Your Production with Intelligent Solutions',
            paragraphText1: 'Leverage advanced analytics and machine learning to optimize your manufacturing processes.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Explore Solutions',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'Watch Demo',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo4',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/facto MESLogo.png',
                    title: 'FactoMES',
                    paragraphText: [
                        'All-in-one shop floor monitoring solution.',
                        'Optimizes operations & automates workflows.',
                        'Manages orders, scheduling & product flow.',
                        'Improves production efficiency with real-time tracking.'
                      ],                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoMES'
                },
                {
                    icon: 'assets/img/OptebizImage/facto SPC.png',
                    title: 'FactoPlan',
                    paragraphText: [
                        'Optimizes resource allocation for better efficiency.',
                        'Ensures projects are delivered on time and within budget.',
                        'Provides clear insights into resource availability.',
                        'Supports strategic decision-making with real-time data.'
                      ],
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoPlan'
                  },
            ]
        },
        {
            title: 'AI Powers Smart Production',
            paragraphText: 'Advanced Manufacturing Intelligence at Your Fingertips',
            paragraphText1: 'Drive operational excellence with AI-powered insights and predictive analytics.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Get Started',
            defaultBtnLink: 'BookDemo',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'See it in Action',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo5',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/facto IQlogo.png',
                    title: 'FactoIQ',
                    paragraphText: [
                        'Collects real-time timeseries data for analysis.',
                        'Analyzes shop floor processes, maintenance, and operations data.',
                        'Uses statistical and AI methodologies for insights.',
                        'Enhances predictive maintenance to minimize downtime.'
                      ],
                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoIQ'
                },
                {
                    icon: 'assets/img/OptebizImage/factoLake 2.png',
                    title: 'FactoLake',
                    paragraphText: [
                        'Tracks and assesses key performance indicators (KPIs).',
                        'Identifies inefficiencies and optimizes resource allocation.',
                        'Enhances supply chain visibility and reduces waste.',
                        'Provides actionable insights through advanced analytics.'
                      ],                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoLake'
                },
            ]
        },
       
        {
            title: 'Smart AI Transforms Production',
            paragraphText: 'Intelligent Manufacturing for the Digital Age',
            paragraphText1: 'Enhance your manufacturing capabilities with smart, data-driven solutions.',
            defaultBtnIcon: 'flaticon-analytics',
            defaultBtnText: 'Learn More',
            defaultBtnLink: 'about-us',
            videoBtnIcon: 'flaticon-google-play',
            videoBtnText: 'View Features',
            videoBtnLink: 'https://www.youtube.com/watch?v=demo7',
            featuredServices: [
                {
                    icon: 'assets/img/OptebizImage/facto SPC.png',
                    title: 'FactoVision',
                    paragraphText: [
                        'AI cameras monitor SOP compliance.',
                        'Detects defects and ensures accurate counting in real-time.',
                        'Integrates with MES, ERP, and quality systems.',
                        'Provides instant alerts and quality control reports.'
                      ],  
                                     linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoVision'
                  },
                  {
                    icon: 'assets/img/OptebizImage/facto MESLogo.png',
                    title: 'FactoMES',
                    paragraphText: [
                        'All-in-one shop floor monitoring solution.',
                        'Optimizes operations & automates workflows.',
                        'Manages orders, scheduling & product flow.',
                        'Improves production efficiency with real-time tracking.'
                      ],                    linkText: 'Learn More',
                    linkIcon: 'flaticon-right',
                    link: 'FactoMES'
                },
            ]
        }
    ];

    constructor() { }
    private slideInterval: any;

    ngOnInit(): void {
        this.startAutoSlide();
    }

    ngOnDestroy() {
        if (this.slideInterval) {
          clearInterval(this.slideInterval);  // Clear the interval when the component is destroyed
        }
      }

      startAutoSlide() {
        this.slideInterval = setInterval(() => {
          if (!this.animating) {
            // Perform the slide transition
            this.nextSlide();
            this.slideCount++;
      
            // // Hide the main banner after 2 slides
            // if (this.slideCount >= 2) {
            //   this.isMainBannerVisible = false;  // Hide the main banner
      
            //   // Set a timeout to make the banner visible again after 5 seconds
            //   setTimeout(() => {
            //     this.isMainBannerVisible = true;  // Make the banner visible again
            //   }, 8000); // 5 seconds delay before making the banner visible again
            // }
          }
        }, 8000); // 5000ms = 5 seconds interval between slides
      }
      
    
      // Method to go to the previous slide
      prevSlide() {
        if (this.animating) return;
        this.animating = true;
        this.currentIndex = (this.currentIndex - 1 + this.mainBannerContent.length) % this.mainBannerContent.length;
        setTimeout(() => this.animating = false, 1000);  // Reset animating state after 1 second
      }
    
      // Method to go to the next slide
      nextSlide() {
        if (this.animating) return;
        this.animating = true;
        this.currentIndex = (this.currentIndex + 1) % this.mainBannerContent.length;
        setTimeout(() => this.animating = false, 1000);  // Reset animating state after 1 second
      }
    
      // Method to toggle visibility of the additional content
      toggleBannerVisibility() {
        this.isMainBannerVisible = !this.isMainBannerVisible;
      }
    

    getFirstWord(title: string): string {
        return title.split(' ')[0];
    }

    getRemainingWords(title: string): string {
        return title.split(' ').slice(1).join(' ');
    }
}