import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-testimonials',
    templateUrl: './homeone-testimonials.component.html',
    styleUrls: ['./homeone-testimonials.component.scss']
})
export class HomeoneTestimonialsComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Client Testimonials",
            title: ' Are Saying?',
            paragraphText: 'Serving clients around the world, we provide cost-effective solutions in diverse industries – from energy to manufacturing to utilities. Each client is provided a customized connectivity package that builds operational intelligence to make superior data-driven decisions – all presented and delivered in an easy to use dashboard (MES)'
        }
    ]
    singleTestimonialsItem: TestimonialsItemContent[] = [
        {
            paragraphText: 'A Data & Tracking Solution for Large-Scale Operation.',
            clientImg: 'assets/img/testimonials/img1.jpg',
            clientName: 'Alex Maxwell',
            clientDesignation: 'CEO at EnvyTheme'
        },
        {
            paragraphText: 'Executed on saving their bottom line – through more effectively utilizing their machines.',
            clientImg: 'assets/img/testimonials/img2.jpg',
            clientName: 'David Warner',
            clientDesignation: 'CEO at Envato'
        },
        {
            paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna ali. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
            clientImg: 'assets/img/testimonials/img3.jpg',
            clientName: 'Sarah Taylor',
            clientDesignation: 'CEO at ThemeForest'
        }
    ]
    testimonialsBtn: Btn[] = [
        {
            link: "/Client-Testimonials",
            icon: 'flaticon-view',
            text: 'Check Out All Reviews'
        }
    ]
    showDiv = {
        previous : false,
        current : false,
        next : true,
        nextTo:false,
      }

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class TestimonialsItemContent {
    paragraphText : string;
    clientImg : string;
    clientName : string;
    clientDesignation : string;
}
class Btn {
    link : string;
    icon : string;
    text : string;
}