import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services-details-page',
    templateUrl: './services-details-page.component.html',
    styleUrls: ['./services-details-page.component.scss']
})
export class ServicesDetailsPageComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }


    
    pageTitleArea: pageTitle[] = [
        {
            title: 'Product Features',
            subTitle: 'FactoIQ',
            subTitle1: 'IQ'
        }
    ]
    servicesDetailsImage: DetailsImage[] = [
        // {
        //     img: 'img\OptebizImage\Process_Cycle.jpg'
        // }
    ]
    singleSolutionsBox: solutionsBoxContent[] = [
        {
            icon: 'flaticon-analytics',
            title: 'Time Series Analytics',
            paragraphText: 'Time series data is information collected at regular time intervals, representing variables like stock prices, temperature, or sales figures that change over time',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Mes Analytics',
            paragraphText: 'MES Analytics empowers manufacturing with actionable insights from production data, enabling predictive maintenance, quality control, and resource optimization for improved efficiency and product quality',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Alarm Analytics',
            paragraphText: ' Alarm analytics refers to the analysis of system alarms and notifications to identify patterns, anomalies, and insights for improved system reliability and performance',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Quality Analytics',
            paragraphText: 'Our Quality Analytics enhances efficiency, reliability, and customer satisfaction by identifying trends, patterns, and anomalies in products or services, enabling proactive quality control measures and reducing defects',
            link: 'services-details',
            linkText: 'View Details'
        },
        {
            icon: 'flaticon-analytics',
            title: 'Process Control Analytics',
            paragraphText: 'FactoIQ Process Control uses real-time data analysis to enhance quality, reduce waste, and minimize errors, aiding better decision-making and operational optimization for peak efficiency',
            link: 'services-details',
            linkText: 'View Details'
        },
        
        
    ]
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: 'FactoIQ',
            // title1: 'Collect Real-Time timeseries data in a timeseries database and analyze the shop floor process, maintenance,manufacturing and operations data using statistical and AI methodologies',
            paragraphText1: "FactoIQ's Time Series Analytics extracts insights from sequential data, aiding predictive decisions, market trend forecasting, inventory optimization, demand forecasting, and operational efficiency in the evolving digital landscape",
            // paragraphText2: 'Ensure product quality through quality checks and inspections, and track product defects and rework.',
            // title2: 'Important Facts',
            // title3: 'Products Areas',
            // title4: 'Technologies That We Use',
            // img1: 'assets/img/projects/img2.jpg',
            // img2: 'assets/img/services/charts.jpg'
        }
    ]
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: 'The Field of Data Science'
        },
        {
            title: 'The Problem'
        },
        {
            title: 'The Solution'
        },
        {
            title: 'The Skills'
        },
        {
            title: 'Statistics'
        },
        {
            title: 'Mathematics'
        }
    ]
    servicesDetailsDescApplication: Application[] = [
        {
            title: 'Manufacturing',
            icon: 'flaticon-factory'
        },
        {
            title: 'Healthcare',
            icon: 'flaticon-hospital'
        },
        {
            title: 'Automobile',
            icon: 'flaticon-tracking'
        },
        {
            title: 'Banking',
            icon: 'flaticon-investment'
        },
        {
            title: 'Real Estate',
            icon: 'flaticon-house'
        },
        {
            title: 'Logistics',
            icon: 'flaticon-order'
        }
    ]
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: 'JavaScript'
        },
        {
            title: 'Python'
        },
        {
            title: 'Java'
        },
        {
            title: 'C/CPP'
        },
        {
            title: 'PHP'
        },
        {
            title: 'Swift'
        },
        {
            title: 'C# (C- Sharp)'
        },
        {
            title: 'Ruby'
        },
        {
            title: 'SQL'
        }
    ]

    sidebarServicesList: ServicesList[] = [
        // {
        //     title: 'AI & ML Development',
        //     link: 'services-details'
        // },
        // {
        //     title: 'Data Analytics',
        //     link: 'services-details'
        // },
        // {
        //     title: 'Data Science',
        //     link: 'services-details'
        // },
        // {
        //     title: 'Artificial Intelligence',
        //     link: 'services-details'
        // },
        // {
        //     title: 'Data Visualization',
        //     link: 'services-details'
        // }
    ]
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: 'PDF Download',
            icon: 'bx bxs-file-pdf',
            link: 'services-details'
        },
        {
            title: 'Services Details.txt',
            icon: 'bx bxs-file-txt',
            link: 'services-details'
        }
    ]
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: 'bx bx-user-pin',
            title: 'Phone',
            subTitle: '+2145 354 5413'
        },
        {
            icon: 'bx bx-map',
            title: 'Location',
            subTitle: 'New York, USA'
        },
        {
            icon: 'bx bx-envelope',
            title: 'Email',
            subTitle: 'hello@wilo.com'
        }
    ]
    loadMoreBtn: loadMore[] = [
        {
            link: 'services',
            linkText: 'Load More',
            linkIcon: 'flaticon-refresh'
        }
    ]
    
}
class pageTitle {
    title : string;
    subTitle : string;
    subTitle1 : string;
}
class DetailsImage {
    img : string;
}
class DetailsDesc {
    subTitle : string;
    // title1 : string;
    // title2 : string;
    // title3 : string;
    // title4 : string;
    paragraphText1 : string;
    // paragraphText2 : string;
    // img1 : string;
    // img2 : string;
}
class ImportantFacts {
    title : string;
}
class Application {
    title : string;
    icon: string;
}
class TechnologiesFeatures {
    title : string;
}

class ServicesList {
    title : string;
    link : string;
}
class DownloadFile {
    title : string;
    icon : string;
    link : string;
}
class ContactInfo {
    icon : string;
    title : string;
    subTitle : string;
}
class solutionsBoxContent {
    icon : string;
    title : string;
    paragraphText : string;
    link : string;
    linkText : string;
}
class loadMore {
    link : string;
    linkText : string;
    linkIcon : string;
}