import { Component } from '@angular/core';

@Component({
  selector: 'app-integration-service',
  templateUrl: './integration-service.component.html',
  styleUrls: ['./integration-service.component.scss']
})
export class IntegrationServiceComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto CloudConnect',
          subTitle: 'Integration Service Utilizing Apache Camel',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: 'assets/img/OptebizImage/apache-camel-architecture.png'
      }
  ]
  servicesDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Integration Service Utilizing Apache Camel',
          title1: 'Integration Service Utilizing Apache Camel',
          paragraphText1: 'When deploying an integration service utilizing Apache Camel in a cloud environment, you have several options depending on the cloud provider you are using. Below is a general guide on how to adapt your Apache Camel integration service for cloud connectivity, considering a generic cloud environment.',
          paragraphText2: 'Building an integration service utilizing Apache Camel involves understanding the systems you are integrating, defining Camel routes, choosing the right components, handling data transformations, and considering deployment and monitoring aspects. The flexibility and extensibility of Apache Camel make it a powerful tool for creating robust and scalable integration solutions.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: 'assets/img/OptebizImage/2021_CamelK_Crossteam__A.png',
          img2: 'assets/img/OptebizImage/imagen.webp'
          
      }
  ]
  servicesDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Routes'
      },
      {
          title: 'Components'
      },
      {
          title: 'Processors'
      },
      {
          title: 'Endpoints'
      },
      {
          title: 'Handle Data Transformation'
      },
      {
          title: 'Cloud Connectors'
      }
  ]
  servicesDetailsDescApplication: Application[] = [
      {
          title: 'Manufacturing',
          icon: 'flaticon-factory'
      },
      {
          title: 'Healthcare',
          icon: 'flaticon-hospital'
      },
      {
          title: 'Automobile',
          icon: 'flaticon-tracking'
      },
      {
          title: 'Banking',
          icon: 'flaticon-investment'
      },
      {
          title: 'Real Estate',
          icon: 'flaticon-house'
      },
      {
          title: 'Logistics',
          icon: 'flaticon-order'
      }
  ]
  servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
      {
          title: 'JavaScript'
      },
      {
          title: 'Python'
      },
      {
          title: 'Java'
      },
      {
          title: 'C/CPP'
      },
      {
          title: 'PHP'
      },
      {
          title: 'Swift'
      },
      {
          title: 'C# (C- Sharp)'
      },
      {
          title: 'Ruby'
      },
      {
          title: 'SQL'
      }
  ]

  sidebarServicesList: ServicesList[] = [
      {
          title: 'Data Integration ',
          link: 'data-integration'
      },
      {
          title: 'Resilient Architecture',
          link: 'resilient-architecture'
      },
      {
          title: 'Trigger-Based',
          link: 'trigger-based'
      },
      {
          title: 'Transaction Progress',
          link: 'transaction-progress'
      },
      {
          title: 'Dynamic Tag Selection',
          link: 'dynamic-tag-selection'
      }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'services-details'
      },
      {
          title: 'Services Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'services-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
    {
      icon: 'bx bx-user-pin',
      title: 'Phone',
      subTitle: '+1 (419) 476-4520'
  },
  {
      icon: 'bx bx-map',
      title: 'Location',
      subTitle: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
  },
  {
      icon: 'bx bx-envelope',
      title: 'Email',
      subTitle: 'bizops@OpteBiz.com'
  }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}

