import { Component } from '@angular/core';

@Component({
  selector: 'app-testimonialcase3',
  templateUrl: './testimonialcase3.component.html',
  styleUrls: ['./testimonialcase3.component.scss']
})
export class Testimonialcase3Component {
    constructor() { }

    ngOnInit(): void {
    }
  sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Facto-Tools We Implemented",
        title: 'Business with Best in Class Solutions',
        paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach.'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
    {
        icon: 'assets/img/OptebizImage/Asset 6@36x.png',
        title: 'FactoIQ',
        paragraphText: ' Collect Realtime timeseries data in a timeseries database and analyze the shop floor process, maintenance, manufacturing and operations data using statistical and AI methodologies',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoIQ'
    },
    {
        icon: 'assets/img/OptebizImage/FactoMES.svg',
        title: 'FactoMes',
        paragraphText: 'Delivering operational excellence with our ISA-95 compliant FactoMes Solution a cutting-edge platform designed to elevate manufacturing processes and ensure seamless integration',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoMES'
    },
    // {
    //     icon: 'assets/img/OptebizImage/Asset 4@36x.png',
    //     title: 'FactoCloudConnect',
    //     paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shopfloor and machine control system into various cloud environments and Big Data Platforms',
    //     linkText: 'Learn More',
    //     linkIcon: 'flaticon-right',
    //     link: 'services-details-factocloud'
    // },
    // {
    //     icon: 'assets/img/services/icon10.png',
    //     title: 'Data Visualization',
    //     paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.',
    //     linkText: 'Learn More',
    //     linkIcon: 'flaticon-right',
    //     link: 'services-details'
    // },
    // {
    //     icon: 'assets/img/services/icon11.png',
    //     title: 'Securities Research',
    //     paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.',
    //     linkText: 'Learn More',
    //     linkIcon: 'flaticon-right',
    //     link: 'services-details'
    // },
    // {
    //     icon: 'assets/img/services/icon12.png',
    //     title: 'Data Governance',
    //     paragraphText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.',
    //     linkText: 'Learn More',
    //     linkIcon: 'flaticon-right',
    //     link: 'services-details'
    // }
]
exploreLearningContent: Content[] = [
    {
        title: 'Old Processes & Issues They Faced',
        paragraphText: 'Before starting a new work order, many setup parameters needed to be entered manually onto the machine. This is not only time-consuming but information may be spread across multiple papers leading to misinterpretations.        ',
        linkText: 'Explore Learing',
        link: 'courses'
    }
]
exploreLearningVideo: Video[] = [
    {
        img: 'assets/img/OptebizImage/bussiness-removebg-preview.png',
        icon: 'flaticon-google-play',
        videoLink: 'https://www.youtube.com/watch?v=Y5KCDWi7h9o'
    }
]

whyChooseUsContent: Content1[] = [
    {
        title: 'The LeanQubit Advantage',
        paragraphText1: 'Our expert team of consultants and proprietary tools enable your business to collect and analyze current and historical data. Our FactoTools break this data down, providing key insights of your business. Whether it’s on a small scale, or dealing with big-data on a multi-plant scale, we digest the data and present it through intuitive dashboards and reports built for you – giving you an in-depth oversight of your operations.',
        paragraphText2: 'Our solutions are flexible – we can design web based or mobile solutions to fit your needs – giving you access anytime, anywhere.',
        linkIcon: 'flaticon-right',
        linkText: 'More About Us',
        link: 'about-us'
    }
]
ourBrandPartnersList: List[] = [
    {
        title: 'Our Brand Partners',
        linkText: 'View All',
        link: 'partner'
    }
]
singleFunfactsItem: Funfacts[] = [
    {
        icon: "assets/img/funfacts/icon1.png",
        title: '5%',
        subTitle: 'Reduction in total Downtime.',
        color:'red'
    },
    {
        icon: "assets/img/funfacts/icon2.png",
        title: '30%',
        subTitle: 'Reduction in labor costs due to data-entry.',
        color:'Green'
    },
    {
        icon: "assets/img/funfacts/icon3.png",
        title: '2%',
        subTitle: 'Satisfaction rateIncrease in Efficiency Throughout.',
        color:'Green'
    },
    // {
    //     icon: "assets/img/funfacts/icon4.png",
    //     title: '90%',
    //     subTitle: 'Senior scientist'
    // }
]

}
class Funfacts {
icon : string;
title : string;
subTitle : string;
color:string;
}
class Content1 {
title : string;
paragraphText1 : string;
paragraphText2 : string;
linkIcon : string;
linkText : string;
link : string;
}
class List {
title : string;
linkText : string;
link : string;
}
class Content {
title : string;
paragraphText : string;
linkText : string;
link : string;
}
class Video {
img : string;
icon : string;
videoLink : string;
}


class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText : string;
linkText : string;
linkIcon : string;
link : string;
}