import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-about',
    templateUrl: './homeone-about.component.html',
    styleUrls: ['./homeone-about.component.scss']
})
export class HomeoneAboutComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    aboutImage: Image[] = [
        {
            img: 'assets\img\OptebizImage\Facto-Node.png'
        }
    ]
    aboutContent: Content[] = [
        {
            subTitle: 'Our Impact',
            title: 'Value to your Business',
            paragraphText1: 'our value proposition revolves around providing expertise, tailored solutions, measurable results, collaborative support, and agility to help drive the success and growth of your business',
            paragraphText2: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
            defaultBtnIcon: 'flaticon-right',
            defaultBtnText: 'More About Us',
            defaultBtnLink: 'about-us'
        }
    ]
    featuresList: List[] = [
        {
            icon: 'assets/img/icon1.png',
            title: '8 %',
            subTitle: 'Improve Product Quality'
        },
        {
            icon: 'assets/img/icon2.png',
            title: '20 %',
            subTitle: 'Increase Delivery Operation Speed'
        },
        {
            icon: 'assets/img/icon3.png',
            title: '15 %',
            subTitle: 'Reduce Operational Costs'
        },
        {
            icon: 'assets/img/icon4.png',
            title: '15%',
            subTitle: 'Reduce Total Operational Downtime'
        }
    ]
    singleFunfactsBox: Funfacts[] = [
        {
            icon: "assets/img/funfacts/icon1.png",
            title: 'Regulatory Compliance',
            subTitle: 'On the market'
        },
        {
            icon: "assets/img/funfacts/icon2.png",
            title: 'Quality Customer',
            subTitle: 'Team members'
        },
        {
            icon: "assets/img/funfacts/icon3.png",
            title: 'Operation Effiency',
            subTitle: 'Satisfaction rate'
        },
        // {
        //     icon: "assets/img/funfacts/icon4.png",
        //     title: 'Inventory',
        //     subTitle: 'Senior scientist'
        // },
        {
            icon: "assets/img/funfacts/icon4.png",
            title: 'Lead Time Flexibility',
            subTitle: 'Senior scientist'
        },
        // {
        //     icon: "assets/img/funfacts/icon4.png",
        //     title: 'Inventory',
        //     subTitle: 'Lead Time Flexibility'
        // }
    ]
    


singleIndustriesServeBox: IndustriesServeContent[] = [
    {
        title: 'Regulatory Compliance',
        icon: 'flaticon-factory',
        link: 'services-details'
    },
    {
        title: 'Quality Customer',
        icon: 'flaticon-hospital',
        link: 'services-details'
    },
    {
        title: 'Operation Effiency',
        icon: 'flaticon-tracking',
        link: 'services-details'
    },
    {
        title: 'Inventory',
        icon: 'flaticon-investment',
        link: 'services-details'
    },
    {
        title: 'Lead Time Flexibility',
        icon: 'flaticon-house',
        link: 'services-details'
    },
    // {
    //     title: 'Logistics',
    //     icon: 'flaticon-order',
    //     link: 'services-details'
    // },
    // {
    //     title: 'Insurance',
    //     icon: 'flaticon-family-insurance',
    //     link: 'services-details'
    // },
    // {
    //     title: 'Capital Markets',
    //     icon: 'flaticon-bitcoin',
    //     link: 'Lead Time Flexibility'
    // }
]
}


class IndustriesServeContent {
title : string;
icon : string;
link : string;
}


class Funfacts {
    icon : string;
    title : string;
    subTitle : string;
}
class Image {
    img : string;
}
class Content {
    subTitle : string;
    title : string;
    paragraphText1 : string;
    paragraphText2 : string;
    defaultBtnIcon : string;
    defaultBtnText : string;
    defaultBtnLink : string;
}
class List {
    icon : string;
    title : string;
    subTitle : string;
}