import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    
    demoForm: FormGroup;
    productForm: FormGroup;
    submitted = false;
    showFormError = false;
    searchText: string = '';
    filteredCountries: string[] = [];
    showCountryList = false;
    siteKey:string;
    currentStep = 1; 
    
    salutations = [
      'Mr.',
      'Mrs.',
      'Ms.',
      'Dr.',
      'Prof.'
    ];
  
    countries = [
      "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria",
      "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan",
      "Bolivia", "Bosnia and Herzegovina", "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cambodia", "Cameroon",
      "Canada", "Cape Verde", "Central African Republic", "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica",
      "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador",
      "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Fiji", "Finland", "France", "Gabon",
      "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
      "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel",
      "Italy", "Jamaica", "Japan", "Jordan", "Kazakhstan", "Kenya", "Kiribati", "Korea North", "Korea South", "Kosovo",
      "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania",
      "Luxembourg", "Macedonia", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands", "Mauritania",
      "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar",
      "Namibia", "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Norway", "Oman",
      "Pakistan", "Palau", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar",
      "Romania", "Russia", "Rwanda", "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent", "Samoa", "San Marino", "Sao Tome", "Saudi Arabia",
      "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
      "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", "Sweden", "Switzerland", "Syria", "Taiwan",
      "Tajikistan", "Tanzania", "Thailand", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu",
      "Uganda", "Ukraine", "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela",
      "Vietnam", "Yemen", "Zambia", "Zimbabwe"
    ];
  
    constructor(
      private fb: FormBuilder,
      private toastr: ToastrService,
      private router: Router
    ) {
      this.filteredCountries = this.countries;
      this.siteKey = '6LdzXr8qAAAAAHRFXH8YXhR-zSf03SkaqH8ZpjNq';
      emailjs.init("_2CXtzL4SmhBGEjgD");
    }
  
    ngOnInit(): void {
      this.initializeForm();
      this.initializeProductForm();
    }
  
    filterCountries(event: any): void {
      const searchValue = event.target.value.toLowerCase();
      this.filteredCountries = this.countries.filter(country => 
        country.toLowerCase().includes(searchValue)
      );
      this.showCountryList = true;
    }
  
  
  
     selectCountry(country: string) {
      this.demoForm.get('country')?.setValue(country);
      this.showCountryList = false;
    }
  
   
  
    private initializeForm(): void {
      this.demoForm = this.fb.group({
        firstName: ['', [Validators.required, Validators.minLength(2)]],
        lastName: ['', [Validators.required, Validators.minLength(2)]],
        companyName: ['', [Validators.required, Validators.minLength(2)]],
        emailAddress: ['', [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
        ]],
        phoneNumber: ['', [
          Validators.required,
          Validators.pattern('^[+]?([0-9]{1,4})?[-\\s.]?[(]?[0-9]{1,4}[)]?[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{3}[-\\s.]?[0-9]{3,4}$')
        ]],
        city: ['', [Validators.required, Validators.minLength(2)]],
        zipCode: ['', [
          Validators.required,
          Validators.pattern('^[0-9]{5}(?:-[0-9]{4})?$|^[0-9]{6}$')
        ]],
        country: ['', Validators.required],
        message: ['', Validators.maxLength(500)]
      });
    }
    products = [
      'Ignition', 'FactoMES', 'FactoPlan', 'FactoVision', 
      'FactoLake', 'FactoIQ', 'AWS (SaaS)'
    ];
    
    features = [
      'Shop Floor Monitoring', 'Predictive Maintenance', 'Statistical Process Control',
      'Ignition Integration', 'Computer Vision Monitoring'
    ];
    
    private initializeProductForm(): void {
      this.productForm = this.fb.group({
        selectedProducts: this.fb.array(
          this.products.map(() => this.fb.control(false))
        ),
        selectedFeatures: this.fb.array(
          this.features.map(() => this.fb.control(false))
        ),
        requirementsAndQuestions: ['', Validators.maxLength(1000)]
      });
    }
    // Getter methods for easy access in template
    get f() { return this.demoForm.controls; }
    get pf() { return this.productForm.controls; }
  
    async onSubmit(): Promise<void> {
      this.submitted = true;
      this.showFormError = false;
  
      if (this.currentStep === 1) {
        if (this.demoForm.invalid) {
          this.showFormError = true;
          Object.keys(this.demoForm.controls).forEach(key => {
            const control = this.demoForm.get(key);
            if (control) {
              control.markAsTouched();
            }
          });
          return;
        }
        
        // Move to step 2
        this.currentStep = 2;
        this.submitted = false;
        return;
      }
      
      if (this.currentStep === 2) {
        if (this.productForm.invalid) {
          this.showFormError = true;
          return;
        }
        
        try {
          // Get selected products
          const selectedProducts = this.products.filter((_, i) => 
            this.productForm.get('selectedProducts')?.value[i]
          );
          
          // Get selected features
          const selectedFeatures = this.features.filter((_, i) => 
            this.productForm.get('selectedFeatures')?.value[i]
          );
          
          // Prepare template parameters based on your EmailJS template
          const templateParams = {
            from_firstname: this.demoForm.value.firstName,
            from_lastname: this.demoForm.value.lastName,
            company: this.demoForm.value.companyName,
            email: this.demoForm.value.emailAddress,
            phone: this.demoForm.value.phoneNumber,
            code: this.demoForm.value.zipCode,
            city: this.demoForm.value.city,
            country: this.demoForm.value.country,
            message: this.demoForm.value.message || 'No message provided',
            products: selectedProducts.join(', ') || 'None selected',
            features: selectedFeatures.join(', ') || 'None selected',
            requirements: this.productForm.value.requirementsAndQuestions || 'No specific requirements'
          };
  
          // Send email using EmailJS
          const response = await emailjs.send(
            'service_zkklyxk', // Your EmailJS service ID
            'template_xukmjqu', // Your EmailJS template ID
            templateParams
          );
  
          if (response.status === 200) {
            this.toastr.success('Your message has been sent successfully!', 'Success');
            console.log('Email sent successfully!');
            this.router.navigate(['/ThankYou']);
            this.onReset();
          }
        } catch (error) {
          console.error('Failed to send email:', error);
          this.toastr.error('Failed to send message. Please try again.', 'Error');
        }
      }
    }
  
    onReset(): void {
      this.submitted = false;
      this.demoForm.reset();
      this.productForm.reset();
      this.currentStep = 1;
    }
  
    isFieldInvalid(fieldName: string): boolean {
      const field = this.demoForm.get(fieldName);
      return field ? (field.invalid && (field.dirty || field.touched || this.submitted)) : false;
    }
  }