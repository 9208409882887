import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CaseStudiesThreeColumnsPageComponent } from './components/pages/case-studies-three-columns-page/case-studies-three-columns-page.component';
import { HomepageOneComponent } from './components/pages/homepage-one/homepage-one.component';
import { AboutPageComponent } from './components/pages/about-page/about-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesDetailsPageComponent } from './components/pages/services-details-page/services-details-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { CaseStudiesDetailsPageComponent } from './components/pages/case-studies-details-page/case-studies-details-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { ServicesDetailsFactocloudComponent } from './services-details-factocloud/services-details-factocloud.component';
import { ServicesDetailsFactomesComponent } from './services-details-factomes/services-details-factomes.component';
import { InductiveDetailsComponent } from './components/pages/homepage-one/inductive-details/inductive-details.component';
import { AWSComponent } from './components/pages/homepage-one/aws/aws.component';
import { TimeSeriesAnalyticsComponent } from './components/pages/time-series-analytics/time-series-analytics.component';
import { MesAnalyticsComponent } from './components/pages/mes-analytics/mes-analytics.component';
import { AlarmAnalyticsComponent } from './components/pages/alarm-analytics/alarm-analytics.component';
import { QualityAnalyticsComponent } from './components/pages/quality-analytics/quality-analytics.component';
import { ProcessControlAnalyticsComponent } from './components/pages/process-control-analytics/process-control-analytics.component';
import { WorkOrderManagementComponent } from './components/pages/work-order-management/work-order-management.component';
import { InventoryManagementComponent } from './components/pages/inventory-management/inventory-management.component';
import { QualityControlComponent } from './components/pages/quality-control/quality-control.component';
import { DowntimeManagementComponent } from './components/pages/downtime-management/downtime-management.component';
import { ReportandAnalyticsComponent } from './components/pages/reportand-analytics/reportand-analytics.component';
import { ProductionMontioringComponent } from './components/pages/production-montioring/production-montioring.component';
import { IntegrationServiceComponent } from './components/pages/integration-service/integration-service.component';
import { ResilientArchitectureComponent } from './components/pages/resilient-architecture/resilient-architecture.component';
import { TriggerBasedComponent } from './components/pages/trigger-based/trigger-based.component';
import { TransactionProgressComponent } from './components/pages/transaction-progress/transaction-progress.component';
import { DynamicTagSelectionComponent } from './components/pages/dynamic-tag-selection/dynamic-tag-selection.component';
import { ProcessMonitoringComponent } from './components/pages/process-monitoring/process-monitoring.component';
import { Testimonialcase2Component } from './components/pages/testimonialcase2/testimonialcase2.component';
import { Testimonialcase3Component } from './components/pages/testimonialcase3/testimonialcase3.component';
import { Testimonialcase4Component } from './components/pages/testimonialcase4/testimonialcase4.component';
import { FactoToolKitComponent } from './components/facto-tool-kit/facto-tool-kit.component';
import { DataIntegrationComponent } from './components/pages/data-integration/data-integration.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsComponent } from './terms/terms.component';
import { TestingComponent } from './testing/testing.component';
import { Testing2Component } from './testing2/testing2.component';
import { Testingfrontpage3Component } from './testingfrontpage3/testingfrontpage3.component';
import { Testingfrontpage4Component } from './testingfrontpage4/testingfrontpage4.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { BasicComponent } from './basic/basic.component';
import { InterimediateComponent } from './interimediate/interimediate.component';
import { AdvanceComponent } from './advance/advance.component';
import { AddOnServiceComponent } from './add-on-service/add-on-service.component';
import { PricingPageComponent } from './pricing-page/pricing-page.component';
import { TestingMainbannerComponent } from './testing-mainbanner/testing-mainbanner.component';
import { BookDemoPageComponent } from './components/book-demo-page/book-demo-page.component';
import { TryitnowComponent } from './components/tryitnow/tryitnow.component';
import { LandingPageNewComponent } from './components/landing-page-new/landing-page-new.component';
import { ServiceDetailsFactoplanComponent } from './components/pages/service-details-factoplan/service-details-factoplan.component';
import { ThankYouComponent } from './components/layouts/thank-you/thank-you.component';
import { ServiceDetailsFactoVisionComponent } from './components/pages/service-details-facto-vision/service-details-facto-vision.component';
import { ServiceDetailsFactoLakeComponent } from './components/pages/service-details-facto-lake/service-details-facto-lake.component';
import { ServiceDetailsFactoIQComponent } from './components/pages/service-details-facto-iq/service-details-facto-iq.component';
import { SCADALandingPageComponent } from './scadalanding-page/scadalanding-page.component';
const routes: Routes = [
    {path: '', component: HomepageOneComponent},
    {path: 'Overview', component: AboutPageComponent},
    {path: 'team', component: TeamPageComponent},
    // {path: 'FactoIQ', component: ServicesDetailsPageComponent},
    {path: 'Client-Testimonials', component: CaseStudiesThreeColumnsPageComponent},
    {path: 'testimonialcase1', component: CaseStudiesDetailsPageComponent},
    {path: 'error', component: ErrorPageComponent},
    {path: 'faq', component: FaqPageComponent},
    {path: 'contact', component: ContactPageComponent},
    {path: 'FactoCloud', component: ServicesDetailsFactocloudComponent},
    {path: 'FactoMES',component:ServicesDetailsFactomesComponent},
    {path:'inductive-details',component:InductiveDetailsComponent},
    {path:'aws',component:AWSComponent},
    {path:'time-series-analytics',component:TimeSeriesAnalyticsComponent},
    {path:'mes-analytics',component:MesAnalyticsComponent},
    {path:'alarm-analytics',component:AlarmAnalyticsComponent},
    {path:'quality-analytics',component:QualityAnalyticsComponent},
    {path:'process-control-analytics',component:ProcessControlAnalyticsComponent},
    {path:'work-order-management',component:WorkOrderManagementComponent},
    {path:'inventory-management',component:InventoryManagementComponent},
    {path:'quality-control',component:QualityControlComponent},
    {path:'downtime-management',component:DowntimeManagementComponent},
    {path:'reportand-analytics',component:ReportandAnalyticsComponent},
    {path:'production-montioring',component:ProductionMontioringComponent},
    {path:'integration-service',component:IntegrationServiceComponent},
    {path:'resilient-architecture',component:ResilientArchitectureComponent},
    {path:'trigger-based',component:TriggerBasedComponent},
    {path:'transaction-progress',component:TransactionProgressComponent},
    {path:'dynamic-tag-selection',component:DynamicTagSelectionComponent},
    {path:'process-Monitoring',component:ProcessMonitoringComponent},
    {path:'testimonialcase2',component:Testimonialcase2Component},
    {path:'testimonialcase3',component:Testimonialcase3Component},
    {path:'testimonialcase4',component:Testimonialcase4Component},
    {path:'Facto-Tool-Kit',component:FactoToolKitComponent},
    {path:'data-integration',component:DataIntegrationComponent},
    {path:'privacy&policy',component:PrivacyComponent},
    {path:'Terms&Condition',component:TermsComponent},
    {path:'Testing',component:TestingComponent},
    {path:'Testing2',component:Testing2Component},
    {path:'Testing3',component:Testingfrontpage3Component},
    {path:'Testing4',component:Testingfrontpage4Component},
    {path:'Basic',component:BasicComponent},
    {path:'intermediate',component:InterimediateComponent},
    {path:'Advanced',component:AdvanceComponent},
    {path:'Add-on-service',component:AddOnServiceComponent},
    {path:'pricing',component:PricingPageComponent},
    {path:'TestingMainBanner',component:TestingMainbannerComponent},
    {path:'BookDemo',component:BookDemoPageComponent},
    {path:'TryItNow',component:TryitnowComponent},
    {path:'Solution',component:LandingPageNewComponent},
    {path:'FactoPlan',component:ServiceDetailsFactoplanComponent},
    {path:'FactoVision',component:ServiceDetailsFactoVisionComponent},
    {path:'ThankYou',component:ThankYouComponent},
    {path:'FactoLake',component:ServiceDetailsFactoLakeComponent},
    {path:'FactoIQ',component:ServiceDetailsFactoIQComponent},
    {path:'Scada',component:SCADALandingPageComponent},




    // Here add new component
    
    {path: '**', component: ErrorPageComponent} // This line will remain down from the whole component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }