import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details-facto-mpcp',
  templateUrl: './service-details-facto-mpcp.component.html',
  styleUrls: ['./service-details-facto-mpcp.component.scss']
})
export class ServiceDetailsFactoMpcpComponent {

}
