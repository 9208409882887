import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
    
   siteKey:string;
   submitted = false;
   clicked = false;

    form: FormGroup=this.fb.group({
        from_name:'',
        to_name:'OpteBiz Inc',
        from_email:'',
        contact:'',
        subject:'',
        message:'',

    });

   
    
    constructor(private fb: FormBuilder,private toastr: ToastrService) {
        this.siteKey='6Lcexi8pAAAAAITzr52QF90-gVgfO6fdrfcLmMxc';
     }
   
    ngOnInit(): void {
      
    }

    
    pageTitleArea: pageTitle[] = [
        {
            title: 'Contact Us'
        }
    ]
    contactInfoBox1: InfoBox1[] = [
        {
            icon: 'bx bx-map',
            title: 'Our Address',
            location: 'Optebiz Inc. Research & Technology Complex 2600 Dorr Street Toledo, OH 43606'
        }
    ]
    contactInfoBox2: InfoBox2[] = [
        {
            icon: 'bx bx-phone-call',
            title: 'Contact',
            number: '+1(419)476-4520',
            email: 'bizops@OpteBiz.com'
        }
    ]
    contactInfoBox3: InfoBox3[] = [
        {
            icon: 'bx bx-time-five',
            title: 'Hours of Operation',
            text1: 'Monday - Friday: 10:00 - 20:00',
            text2: 'Alternate Saturday: 00:09 - 13:00'
        }
    ]

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Get In Touch",
            title: 'Ready to Get Started?',
            paragraphText: 'Your email address will not be published. Required fields are marked *'
        }
    ]
    contactImage: Image[] = [
        {
            img: 'assets/img/OptebizImage/contact.png'
        }
    ]

    

   async send(){
    emailjs.init('xyznB1nuGXtmxbcUn');
    
       let response =  await emailjs.send("service_rlvyte7","template_1amp9rs",{
            from_name: this.form.value.from_name,
            to_name: this.form.value.to_name,
            from_email: this.form.value.from_email,
            subject: this.form.value.subject,
            from_contact: this.form.value.from_contact,
            message: this.form.value.message,
            
            });
            if (response.status == 200) {
                this.clicked
                this.showSuccess()
                this.form.reset();
              }
            else
       {
        console.log('error')
       }
            
            
    }
    showSuccess() {
        this.toastr.success('Message Has Been Sent', 'Success',{timeOut: 3000});
      }

}
class pageTitle {
    title : string;
}
class InfoBox1 {
    icon : string;
    title : string;
    location : string;
}
class InfoBox2 {
    icon : string;
    title : string;
    number : string;
    email : string;
}
class InfoBox3 {
    icon : string;
    title : string;
    text1 : string;
    text2 : string;
}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class Image {
    img : string;
}