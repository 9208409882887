<div class="main" data-v-bc4c6fa8="">
    <div
        class="hero container text-center"
        data-v-bc4c6fa8=""
        style="padding-top: 3rem"
    >
        <img
            src="assets\img\LeanQbit_logo.png"
            style="width: 150px; height: 80px"
        />
        <h1 class="wow animate__animated animate__fadeInLeft">
            Building the Future of IOT and Automation
        </h1>
    </div>
</div>
<div class="container" data-v-bc4c6fa8="">
    <section class="pt-3" data-v-bc4c6fa8="" data-v-27bd6564="">
        <div
            class="subtitle aos-init aos-animate"
            data-aos="fade-right"
            data-v-27bd6564=""
        >
            <p data-v-27bd6564="">Our story</p>
        </div>
        <h2
            data-aos="fade-left"
            data-v-27bd6564=""
            class="aos-init aos-animate"
        >
            <img src="assets/img/star-icon.png" alt="image" /> About LeanQubit
        </h2>
        <div class="main" data-v-27bd6564="">
            <div
                class="container text-center baseline-max-width aos-init aos-animate"
                data-aos="fade-left"
                data-v-bc4c6fa8=""
            >
                <p
                    data-v-bc4c6fa8=""
                    style="
                        font-weight: 400;
                        font-family: Condensed;
                        font-size: 18px;
                        line-height: 30px;
                        color: #4e5f6d;
                        max-width: 935px;
                        margin: 0 auto 32px;
                    "
                >
                    LeanQubit is a Certified Integrator of Ignition. Our status
                    ensures you are working with experts who can understand the
                    fundamentals of your business, and effectively apply
                    Ignition-based solutions to fit what you need.
                </p>
                <p
                    data-v-bc4c6fa8=""
                    style="
                        font-weight: 400;
                        font-family: Condensed;

                        font-size: 18px;
                        line-height: 30px;
                        color: #4e5f6d;
                        max-width: 935px;
                        margin: 0 auto 32px;
                    "
                >
                    We have worked in the manufacturing, energy, and utility
                    industries, giving us the experience to build custom
                    solutions for your operation, big or small. We maintain our
                    certification for the latest software of Ignition.
                </p>
                <p
                    data-v-bc4c6fa8=""
                    style="
                        font-weight: 400;
                        font-family: Condensed;

                        font-size: 18px;
                        line-height: 30px;
                        color: #4e5f6d;
                        max-width: 935px;
                        margin: 0 auto 32px;
                    "
                >
                    Whether your business is new to automation or looking to
                    take it to the next level, we integrate flexible,
                    cost-effective hardware with our FactoTools to create a
                    packaged solution that drives results you need for your
                    business.
                </p>
            </div>
        </div>
    </section>
</div>
<div class="container-fluid" data-v-8a7cad3c="">
    <div class="hero container" data-v-8a7cad3c="">
        <div class="row" data-v-8a7cad3c="">
            <div class="col-md-6 d-flex align-items-center" data-v-8a7cad3c="">
                <div data-v-8a7cad3c="">
                    <h2
                        data-aos="fade-left"
                        data-v-8a7cad3c=""
                        class="aos-init aos-animate"
                        style="
                            font: 40px;
                            margin: 0px 0px 8px;
                            color: var(--mainColor);
                        "
                    >
                        <img src="assets/img/star-icon.png" alt="image" />
                        Crafting a Path to Success
                    </h2>
                    <p
                        class="mt-5 aos-init aos-animate"
                        data-aos="fade-right"
                        data-v-8a7cad3c=""
                        style="
                            font-weight: 400;
                            font-family: Condensed;

                            font-size: 18px;
                            line-height: 30px;
                            color: #fff4f4;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                    >
                        Today, our platform is operational, in production, and
                        performing beyond expectations. Hundreds of internal
                        users have experienced significant productivity gains
                        since adopting LeanQubit, validating our efforts to
                        create a truly revolutionary solution.
                    </p>
                    <p
                        data-aos="fade-right"
                        data-v-8a7cad3c=""
                        class="aos-init aos-animate"
                        style="
                            font-weight: 400;
                            font-family: Condensed;

                            font-size: 18px;
                            line-height: 30px;
                            color: #fff4f4;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                    >
                        We are incredibly proud of the impact that our platform
                        has made, and we are inspired to continue our mission to
                        redefine the FactoTools and automation landscape.
                    </p>
                </div>
            </div>
            <div class="col-md-6" data-v-8a7cad3c="">
                <img
                    class="img-fluid"
                    src="assets/img/OptebizImage/CraftingPath.png"
                    alt="Picture of the user interface of OpteBiz on light mode"
                    data-aos="zoom-in"
                    style="height: 430px"
                    class="wow animate__animated animate__fadeInRight"
                />
            </div>
        </div>
    </div>
</div>
<div data-v-56ea8e9b="" class="container-fluid bg-body-tertiary">
    <div data-v-56ea8e9b="" class="container">
        <div data-v-56ea8e9b="" class="row">
            <div
                data-v-56ea8e9b=""
                class="col-lg-6 aos-init aos-animate"
                data-aos="zoom-in"
            >
                <img
                    data-v-56ea8e9b=""
                    class="img-fluid mt-5 mt-kg-0"
                    src="assets\img\OptebizImage\Technologyused.png"
                    alt="Logos of tools"
                />
            </div>
            <div data-v-56ea8e9b="" class="col-lg-6 mb-5">
                <h2
                    data-v-56ea8e9b=""
                    data-aos="fade-left"
                    class="aos-init aos-animate"
                    style="color: black; margin: 0px 0px 32px"
                >
                    <img src="assets/img/star-icon.png" alt="image" /> Our Tech
                    Landscape
                </h2>
                <p
                    data-v-56ea8e9b=""
                    class="mb-5 aos-init aos-animate"
                    data-aos="fade-right"
                    style="font-family: Condensed"
                >
                    <b
                        data-v-56ea8e9b=""
                        style="
                            font-weight: 400;

                            font-size: 18px;
                            line-height: 30px;
                            color: #4e5f6d;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                        >Our Solutions</b
                    >
                    cover a broad variety of technology, allowing you to
                    integrate and build systems to specifically fit your needs.
                </p>
                <p
                    data-v-56ea8e9b=""
                    class="mb-5 aos-init aos-animate"
                    data-aos="fade-right"
                    style="font-family: Condensed"
                >
                    <b
                        data-v-56ea8e9b=""
                        style="
                            font-weight: 400;
                            font-size: 18px;
                            line-height: 30px;
                            color: #4e5f6d;
                            max-width: 935px;
                            margin: 0 auto 32px;
                        "
                        >Integrated, Dynamic Solutions</b
                    >
                    We’re here to engineer a custom solution for you – or if you
                    have existing MES/Data-Tracking, address the gaps present to
                    optimize your operations.
                </p>
            </div>
        </div>
    </div>
</div>
<div data-v-d5c9078a="" class="container">
    <section data-v-27bd6564="" data-v-d5c9078a="">
        <h2
            data-v-27bd6564=""
            data-aos="fade-left"
            class="aos-init aos-animate"
        >
            <img src="assets/img/star-icon.png" alt="image" /> Getting started
            with LeanQubit
        </h2>
        <div data-v-27bd6564="" class="main">
            <div data-v-d5c9078a="" class="d-flex justify-content-center">
                <p
                    data-v-d5c9078a=""
                    class="text-center mb-5 baseline-max-width aos-init aos-animate"
                    data-aos="fade-right"
                    style="font-family: Condensed"
                >
                    We are always on the lookout for passionate and talented
                    individuals. If you are excited about the prospect of
                    working on cutting-edge technology and shaping the future of
                    orchestration, we would love to hear from you
                </p>
            </div>
            <a routerLink="/contact" class="default-btn"
                ><i class="bx bx-phone-call"></i>Contact Us<span></span
            ></a>
        </div>
    </section>
</div>
<section class="why-choose-us-area bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div
                    class="why-choose-us-content"
                    *ngFor="let Content1 of whyChooseUsContent"
                >
                    <h2>{{ Content1.title }}</h2>
                    <p>{{ Content1.paragraphText1 }}</p>
                    <p>{{ Content1.paragraphText2 }}</p>
                    <!-- <a routerLink="/{{Content1.link}}" class="default-btn"><i class="{{Content1.linkIcon}}"></i>{{Content1.linkText}}<span></span></a> -->
                    <div class="map-shape2">
                        <img
                            src="assets/img/shape/map-shape2.png"
                            alt="image"
                        />
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="our-brand-partners-list">
                    <h2>A Trusted and Reliable Partner to Support your Ops</h2>
                    <p style="font-family: Condensed">
                        LeanQubit is an ISA-95 compliant solution provider for
                        enterprise automation. We are a preferred partner of
                        Inductive Automation for consulting and implementation
                        of Ignition based solutions across diverse manufacturing
                        industries. We are also a Validated AWS Partner for our
                        SaaS offerings on AWS. With a comprehensive
                        implementation and support experience of over 20 years,
                        we build customized, reliable solutions for all of your
                        MES tracking and reporting needs.
                    </p>
                    <p style="font-family: Condensed">
                        We’re here for you every step of the way. Contact us to
                        get started!
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
