import { Component, OnInit, ViewChildren, QueryList, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { trigger, transition, style, animate, state, query, stagger, group } from '@angular/animations';
import { interval, Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-scadalanding-page',
  templateUrl: './scadalanding-page.component.html',
  styleUrls: ['./scadalanding-page.component.scss'],
  animations: [
    trigger('zoomTransition', [
      transition(':increment', [
        style({ opacity: 0, transform: 'scale(1)' }),
        animate(
          '1500ms ease-out',
          style({ opacity: 1, transform: 'scale(1.1)' })
        ),
      ]),
      transition(':decrement', [
        style({ opacity: 0, transform: 'scale(1)' }),
        animate(
          '1500ms ease-out',
          style({ opacity: 1, transform: 'scale(1.1)' })
        ),
      ]),
    ]),
  
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(20px)' }),
        animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ])
    ]),
    
    trigger('cardsBatch', [
      // Card animations for increments and decrements
    ])
  ]
  
})
export class SCADALandingPageComponent implements OnInit,AfterViewInit, OnDestroy  {


  selectedService: string = 'Basic';


  slides: Slide[] = [];
images: ImageConfig[] = [];
currentImageIndex = 0;
  currentSlideIndex = 0;
  private imageInterval: any;
  private currentVideo: HTMLVideoElement | null = null;
  visibleCards: any[] = [];
  cardsPerView = 3;
  private slideInterval: Subscription;
  readonly batchSizes = [3, 3];
  currentBatchIndex = 0;
  private totalBatches = this.batchSizes.length;
  private autoSlideSubscription: Subscription;
  private readonly AUTO_SLIDE_INTERVAL = 5000;

  // Contact form
  contactForm: FormGroup;
  isSubmitting = false;
  submitSuccess = false;
  submitError = false;

  @ViewChildren('videoPlayer') videoPlayers: QueryList<ElementRef<HTMLVideoElement>>;

  constructor(private sanitizer: DomSanitizer,private fb: FormBuilder, private toastr: ToastrService,
      private router: Router) {
    this.slideInterval = new Subscription();
    this.autoSlideSubscription = new Subscription();
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', []],
    });
  }
  isDotActive(index: number): boolean {
    return this.currentBatchIndex === index;
  }

ngOnInit() {
  this.initializeSlides();
  this.startImageCycle();
  this.initializeCardDisplay();
  this.startAutoSlide();
  emailjs.init('_2CXtzL4SmhBGEjgD');
  
}

onSubmit(): void {
  if (this.contactForm.valid) {
    this.isSubmitting = true;
    this.submitSuccess = false;
    this.submitError = false;

    const serviceId = 'service_zkklyxk';
    const templateId = 'template_xukmjqu';

    // Format the current date as YYYY-MM-DD
    const formattedDate = new Date().toISOString().split("T")[0];

    // Prepare template parameters
    const templateParams = {
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      email: this.contactForm.value.email,
      phone: this.contactForm.value.phone,
      sent_date: formattedDate  // Add the formatted date here
    };

    // Send email using EmailJS
    emailjs.send(serviceId, templateId, templateParams)
      .then(response => {
        this.toastr.success('Your message has been sent successfully!', 'Success');
        console.log('Email sent successfully!');
        this.router.navigate(['/ThankYou']);
        this.isSubmitting = false;
        this.submitSuccess = true;
        this.contactForm.reset();
      })
      .catch(error => {
        console.error('Failed to send email:', error);
        this.isSubmitting = false;
        this.submitError = true;
      });
  } else {
    this.contactForm.markAllAsTouched();
  }
}




private initializeSlides() {
  this.slides = [
    {
      id: 'slide-1',
      stepCounter: '01',
      title: this.sanitizer.bypassSecurityTrustHtml('Real-Time Visibility & <strong>Control</strong>'),
      description: 'Achieve end-to-end transparency and centralized monitoring of your production processes, enabling data-driven decision making.',
     
       mediaSource: 'assets/img/OptebizImage/Delivery on Time (4).mp4',
      mediaType: 'video'
    },
    {
      id: 'slide-2',
      stepCounter: '02',
      title: this.sanitizer.bypassSecurityTrustHtml('Predictive Maintenance & <strong>Optimization</strong>'),
      description: 'Leverage AI-powered analytics to forecast equipment failures, optimize asset performance, and maximize Overall Equipment Effectiveness (OEE).',
      mediaSource: 'assets/img/OptebizImage/Delivery on Time (2) (1).mp4',
      mediaType: 'video'
    },
    {
      id: 'slide-3',
      stepCounter: '03',
      title: this.sanitizer.bypassSecurityTrustHtml('Intelligent Insights for Informed <strong>Decision Making</strong>'),
      description: 'Unlock hidden capacity, identify bottlenecks, and drive continuous improvement with comparative analytics and performance benchmarking.',
      mediaSource: 'assets/img/OptebizImage/Delivery on Time (3).mp4',
      mediaType: 'video'
    }
  ];
}

private initializeCardDisplay() {
  this.updateVisibleCards();
  this.currentBatchIndex = 0;
  this.visibleCards = this.getVisibleCardsForCurrentBatch();
}
ngAfterViewInit() {
  // Initialize video players
  this.initializeVideos();
  
  // Subscribe to changes in video players (when slides change)
  this.videoPlayers.changes.subscribe(() => {
    this.initializeVideos();
  });
}

ngOnDestroy() {
  if (this.imageInterval) {
    clearInterval(this.imageInterval);
  }
  else{
// Clean up any playing videos
this.stopCurrentVideo();
  }
  if (this.slideInterval) {
    this.slideInterval.unsubscribe();
  }
  
}
private startAutoSlide() {
  this.autoSlideSubscription = interval(this.AUTO_SLIDE_INTERVAL).subscribe(() => {
    this.slideNext();
  });
}
nextSlide() {
  if (this.currentSlideIndex < this.slides.length - 1) {
    this.stopCurrentVideo();
    this.currentSlideIndex++;
    // Initialize video for new slide if it's a video
    if (this.slides[this.currentSlideIndex].mediaType === 'video') {
      this.initializeVideos();
    }
  }
}
prevSlide() {
  if (this.currentSlideIndex > 0) {
    this.stopCurrentVideo();
    this.currentSlideIndex--;
    // Initialize video for new slide if it's a video
    if (this.slides[this.currentSlideIndex].mediaType === 'video') {
      this.initializeVideos();
    }
  }
}

private initializeVideos() {
  const currentPlayer = this.videoPlayers.toArray()[this.currentSlideIndex]?.nativeElement;

  if (currentPlayer) {
    // Configure video attributes
    currentPlayer.playsInline = true;
    currentPlayer.muted = true;
    currentPlayer.preload = 'auto';

    // Add event listeners
    currentPlayer.addEventListener('loadedmetadata', () => this.playVideo(currentPlayer));
    currentPlayer.addEventListener('error', (e) => this.onVideoError(e));

    // Play video
    this.playVideo(currentPlayer);
  }
}
updateVisibleCards() {
  const newVisibleCards = this.getVisibleCardsForCurrentBatch();
  
  // Only update if the cards have actually changed
  if (JSON.stringify(this.visibleCards) !== JSON.stringify(newVisibleCards)) {
    this.visibleCards = newVisibleCards;
  }
}
private getVisibleCardsForCurrentBatch(): any[] {
  let startIndex = 0;
  for (let i = 0; i < this.currentBatchIndex; i++) {
    startIndex += this.batchSizes[i];
  }
  
  const batchSize = this.batchSizes[this.currentBatchIndex];
  return this.singleServicesBoxItem.slice(startIndex, startIndex + batchSize);
}
private onVideoError(event: ErrorEvent) {
  console.error('Video loading error:', event);
}
slideNext() {
  this.currentBatchIndex = (this.currentBatchIndex + 1) % this.totalBatches;
  this.updateVisibleCards();
}

slidePrev() {
  this.currentBatchIndex = (this.currentBatchIndex - 1 + this.totalBatches) % this.totalBatches;
  this.updateVisibleCards();

}
private playVideo(video: HTMLVideoElement) {
  this.stopCurrentVideo();
  this.currentVideo = video;
  
  video.play().catch(error => {
    console.warn('Autoplay failed:', error);
    
    // Fallback for autoplay restrictions
    const playOnInteraction = () => {
      video.play().catch(console.error);
      document.removeEventListener('click', playOnInteraction);
    };
    document.addEventListener('click', playOnInteraction);
  });
}
private stopCurrentVideo() {
  if (this.currentVideo) {
    this.currentVideo.pause();
    this.currentVideo.currentTime = 0;
  }
}

getProgressWidth(index: number): number {
  return index === this.currentSlideIndex ? 100 : 0;
}

startImageCycle() {
  setInterval(() => {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
  }, 3500);
}



  slide = {
    backgroundImage: 'assets/img/OptebizImage/image1_4.jpg',
    smallTitle: 'Advanced Control Systems',
    largeTitle: 'Ignition SCADA Solutions for ​Modern Industry',
    info: 'Real-time insights, predictive maintenance, and seamless ​integration—powering industrial automation with SCADA ​technology',
    Demo: '/BookDemo',
    Freetrail: '/TryItNow',
    maskingTitle: 'SCADA Systems'
  };

  services = [
    { 
      name: 'Basic', 
      title: 'Our Basic Services',
      subtitle: 'Small businesses or those new to Ignition',
      target: 'Small businesses or those new to Ignition',
      services: [
        'Initial Consultation: 1-2 hour consultation to understand project requirements',
        'Quick Troubleshooting: Basic issue resolution via chat or call',
        'Ignition Setup Assistance: Help with software installation and licensing',
        'Knowledge Base Access: Access to how-to guides, training videos, and FAQs'
      ],
      features: [
        'Instant live chat or ticketing system',
        'Self-paced learning modules'
      ]
    },
    { 
      name: 'Intermediate',
      title: 'Our Intermediate Services',
      subtitle: 'Advanced solutions for sophisticated industrial automation requirements',
      target: 'Companies looking to integrate Ignition into existing systems',
      services: [
        'Custom Project Design: SCADA dashboards, tags, scripting & alarms',
        'Integrations: Connecting Ignition to PLCs, ERP systems, or databases',
        'Development Guidance: Code review and optimization for in-house teams',
        'Basic Training: Live sessions on core Ignition modules',
        'Service Packages: Flexible packages based on time or milestones'
      ],
      features: [
        'Booking system for live development or consultation slots',
        'Resource allocation and project tracking tools',
      ]
    },
    { 
      name: 'Advanced',
      title: 'Our Advanced Services',
      subtitle: 'Enterprise-grade solutions for complex industrial automation challenges',
      target: 'Enterprises with large-scale implementation needs',
      services: [
        'Turnkey Solutions: Complete Ignition project development and deployment',
        'Advanced Training: Ignition Edge, Perspective & advanced scripting',
        '24/7 Support: Priority support for mission-critical systems',
        'Post-Implementation Support: System monitoring, updates & upgrades'
      ],
      features: [
        'Real-time collaboration (video calls, shared dashboards)',
        'SLA-based support escalation',
        'Custom analytics to measure system performance and ROI'
      ]
    },
    { 
      name: 'Add-On-Services',
      title: 'Add-On Services',
      subtitle: 'Enhanced support and solutions for growing enterprises',
      target: 'Advanced services for established Ignition users and growing enterprises',
      services: [
        'Remote troubleshooting and system monitoring',
        'Dedicated account management',
        'Regular system health check-ups',
        'Optional on-site assistance',
        'App Functional Architecture support'
      ],
      features: [
        'Ignition Licensing Management',
        'Hardware Provisioning Guidance',
        'Advanced Troubleshooting',
        'Custom API Integration Development'
      ]
    }
  ];
  singleServicesBoxItem: ServicesBoxContent[] = [
    {
      icon: 'assets/img/OptebizImage/facto MESLogo.png',
      title: 'FactoMES',
      paragraphText: [
        'All-in-one shop floor monitoring solution.',
        'Optimizes operations & automates workflows.',
        'Manages orders, scheduling & product flow.',
        'Improves production efficiency with real-time tracking.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoMES'
    },
    {
      icon: 'assets/img/OptebizImage/facto SPC.png',
      title: 'FactoVision',
      paragraphText: [
        'AI cameras monitor SOP compliance.',
        'Detects defects and ensures accurate counting in real-time.',
        'Integrates with MES, ERP, and quality systems.',
        'Provides instant alerts and quality control reports.'
      ],   
    
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoVision'
    },
    {
      icon: 'assets/img/OptebizImage/factoLake 2.png',
      title: 'FactoLake',
      paragraphText: [
        'Tracks and assesses key performance indicators (KPIs).',
        'Identifies inefficiencies and optimizes resource allocation.',
        'Enhances supply chain visibility and reduces waste.',
        'Provides actionable insights through advanced analytics.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoLake'
    },
    {
      icon: 'assets/img/OptebizImage/facto SPC.png',
      title: 'FactoPlan',
      paragraphText: [
        'Optimizes resource allocation for better efficiency.',
        'Ensures projects are delivered on time and within budget.',
        'Provides clear insights into resource availability.',
        'Supports strategic decision-making with real-time data.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoPlan'
    },
    {
      icon: 'assets/img/OptebizImage/facto IQlogo.png',
      title: 'FactoIQ',
      paragraphText: [
        'Collects real-time timeseries data for analysis.',
        'Analyzes shop floor processes, maintenance, and operations data.',
        'Uses statistical and AI methodologies for insights.',
        'Enhances predictive maintenance to minimize downtime.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoIQ'
    },
    {
      icon: 'assets/img/OptebizImage/facto MESLogo.png',
      title: 'FactoMES',
      paragraphText: [
        'All-in-one shop floor monitoring solution.',
        'Optimizes operations & automates workflows.',
        'Manages orders, scheduling & product flow.',
        'Improves production efficiency with real-time tracking.'
      ],
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoMES'
    },
  ];
  selectService(service: string): void {
    this.selectedService = service;
  }

  getSelectedService() {
    return this.services.find(service => service.name === this.selectedService);
  }




  
}




interface Slide {
  id: string;
  stepCounter: string;
  title: string | SafeHtml;
  description: string;
  mediaSource: string;
  mediaType: 'image' | 'video';
}

interface ImageConfig {
  src: string;
  animationIn: any[];
  animationOut: any[];
}

class sectionTitleContent {
  subtitle: string;
  title: string;
  paragraphText: string;
}

class ServicesBoxContent {
  icon: string;
  title: string;
  paragraphText: string[];
  linkText: string;
  linkIcon: string;
  link: string;
}

