<div class="ContactUscontainer">
    <div class="left-section">
        <h1>CONTACT US</h1>

        <p class="description">
            Fill out the form below to get in touch with our team. We'll respond
            promptly to discuss your specific needs and challenges.
        </p>

        <hr class="custom-hr" />

        <div class="contact-info">
            <p>
                <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M20 4H4C2.9 4 2.01 4.9 2.01 6L2 18C2 19.1 2.9 20 4 20H20C21.1 20 22 19.1 22 18V6C22 4.9 21.1 4 20 4ZM20 8L12 13L4 8V6L12 11L20 6V8Z"
                    />
                </svg>
                sales@leanqubit.ai
            </p>
            <p>
                <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M6.62 10.79C8.06 13.62 10.38 15.94 13.21 17.38L15.41 15.18C15.69 14.9 16.08 14.82 16.43 14.93C17.55 15.3 18.75 15.5 20 15.5C20.55 15.5 21 15.95 21 16.5V20C21 20.55 20.55 21 20 21C10.61 21 3 13.39 3 4C3 3.45 3.45 3 4 3H7.5C8.05 3 8.5 3.45 8.5 4C8.5 5.25 8.7 6.45 9.07 7.57C9.18 7.92 9.1 8.31 8.82 8.59L6.62 10.79Z"
                    />
                </svg>
                +14199133878
            </p>
            <p>
                <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    />
                </svg>
                2600 Dorr Street Toledo, OH 43606, United States
            </p>
            <p>
                <svg
                    class="icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z"
                    />
                </svg>
                B-205, Mastermind III, Royal Palms IT Park Aarey Colony,
                Goregaon(East), Mumbai, MH, India
            </p>
        </div>

        <hr class="custom-hr" />
    </div>

    <div class="right-section">
        <div class="form-container">
            <h1 class="form-title">Request a personalized demo</h1>

            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="name-row">
                    <div class="form-group half-width">
                        <label class="required">First Name</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="firstName"
                            [ngClass]="{
                                'is-invalid': isFieldInvalid('firstName')
                            }"
                        />
                        <div
                            *ngIf="isFieldInvalid('firstName')"
                            class="invalid-feedback"
                        >
                            <div *ngIf="f.firstName.errors?.required">
                                First name is required
                            </div>
                        </div>
                    </div>

                    <div class="form-group half-width">
                        <label class="required">Last Name</label>
                        <input
                            type="text"
                            class="form-control"
                            formControlName="lastName"
                            [ngClass]="{
                                'is-invalid': isFieldInvalid('lastName')
                            }"
                        />
                        <div
                            *ngIf="isFieldInvalid('lastName')"
                            class="invalid-feedback"
                        >
                            <div *ngIf="f.lastName.errors?.required">
                                Last name is required
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Email</label>
                    <input
                        type="email"
                        class="form-control"
                        formControlName="email"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('email') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('email')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.email.errors?.required">
                            Email is required
                        </div>
                        <div
                            *ngIf="
                                f.email.errors?.email || f.email.errors?.pattern
                            "
                        >
                            Please enter a valid email address
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Phone</label>
                    <input
                        type="tel"
                        class="form-control"
                        formControlName="phone"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('phone') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('phone')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.phone.errors?.required">
                            Phone number is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label class="required">Company</label>
                    <input
                        type="text"
                        class="form-control"
                        formControlName="company"
                        [ngClass]="{ 'is-invalid': isFieldInvalid('company') }"
                    />
                    <div
                        *ngIf="isFieldInvalid('company')"
                        class="invalid-feedback"
                    >
                        <div *ngIf="f.company.errors?.required">
                            Company is required
                        </div>
                    </div>
                </div>

                <div class="form-group">
                    <label>Additional Notes</label>
                    <textarea
                        class="form-control"
                        formControlName="additionalNotes"
                        rows="4"
                    ></textarea>
                </div>

                <div *ngIf="showFormError" class="alert alert-danger">
                    Please correct the errors in the form before submitting.
                </div>

                <button
                    type="submit"
                    class="submit-btn"
                    [disabled]="submitted && contactForm.invalid"
                >
                    SUBMIT
                </button>
            </form>
        </div>
    </div>
</div>

<!-- New Html -->
<div class="section-wrapper">
    <div class="heading">HOW WE CAN HELP</div>
    <div class="subheading">
        At LeanQubit, we're passionate about helping manufacturers like you
        optimize their operations and drive growth. Whether you're looking to
        boost productivity, enhance supply chain visibility, or unlock new
        revenue streams, we're here to help.
    </div>

    <div class="services-grid">
        <div class="service-item">
            <div class="service-icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="3" y="12" width="4" height="9" fill="white" />
                    <rect x="10" y="7" width="4" height="14" fill="white" />
                    <rect x="17" y="3" width="4" height="18" fill="white" />
                    <path
                        d="M5 8L9 4L13 8"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M9 4V12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div class="service-content">
                <div class="service-title">Boost Productivity</div>
                <div class="service-description">
                    Drive actionable insights in minutes and simplify production
                    monitoring and optimization.
                </div>
            </div>
        </div>

        <div class="service-item">
            <div class="service-icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12 3V7M12 7L9 5M12 7L15 5"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M12 17V21M12 21L9 19M12 21L15 19"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M17 9L20 12M20 12L17 15M20 12H8"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M6 14C4.9 14 4 13.1 4 12C4 10.9 4.9 10 6 10"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <circle cx="6.5" cy="8.5" r="1.5" fill="white" />
                    <circle cx="6.5" cy="15.5" r="1.5" fill="white" />
                </svg>
            </div>
            <div class="service-content">
                <div class="service-title">Unlock New Revenue Streams</div>
                <div class="service-description">
                    Leverage advanced analytics to identify new business
                    opportunities and drive growth.
                </div>
            </div>
        </div>

        <div class="service-item">
            <div class="service-icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3 12H6L9 7L13 17L17 12H21"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <circle cx="3" cy="12" r="1.5" fill="white" />
                    <circle cx="9" cy="7" r="1.5" fill="white" />
                    <circle cx="13" cy="17" r="1.5" fill="white" />
                    <circle cx="17" cy="12" r="1.5" fill="white" />
                    <circle cx="21" cy="12" r="1.5" fill="white" />
                    <path
                        d="M19 6L19 4"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M19 20L19 18"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M5 6L5 4"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                    <path
                        d="M5 20L5 18"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                    />
                </svg>
            </div>
            <div class="service-content">
                <div class="service-title">Enhance Supply Chain Visibility</div>
                <div class="service-description">
                    Gain real-time visibility into your supply chain and improve
                    agility and responsiveness.
                </div>
            </div>
        </div>

        <div class="service-item">
            <div class="service-icon">
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3 7L7 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M11 7L21 7"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M3 12L13 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M17 12L21 12"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M3 17L5 17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <path
                        d="M9 17L21 17"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                    />
                    <circle cx="9" cy="7" r="2" fill="white" />
                    <circle cx="15" cy="12" r="2" fill="white" />
                    <circle cx="7" cy="17" r="2" fill="white" />
                </svg>
            </div>
            <div class="service-content">
                <div class="service-title">Streamline Operations</div>
                <div class="service-description">
                    Automate defect detection, optimize maintenance and reduce
                    costs.
                </div>
            </div>
        </div>
    </div>
</div>
