<section class="feature-one">
    <div class="container">
        <div class="feature-one__inner">
            <h2 class="feature-one__title">
                Explore the Possibilities of <br />
                AI 🤖 with <span>AIMug</span>
            </h2>
            <div class="feature-one__btn-box">
                <a
                    href="contact.html"
                    class="thm-btn feature-one__btn"
                    contenteditable="false"
                    style="cursor: pointer"
                >
                    <i class="fal fa-plus"></i> Get Started Free</a
                >
            </div>
            <div class="feature-one__main-content-box">
                <div class="feature-one__color-overly-1 flaot-bob-y"></div>
                <div class="feature-one__color-overly-2 flaot-bob-x"></div>
                <div class="feature-one__color-overly-3 img-bounce"></div>
                <div class="feature-one__main-content-top">
                    <ul class="feature-one__list">
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-content-writing.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >AI Content <br />
                                        Writing</a
                                    >
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-image.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >AI Image <br />
                                        Generator</a
                                    >
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="feature-one__main-content-middle">
                    <div class="feature-one__start-1 zoominout">
                        <img
                            src="../../assets/img/OptebizImage/feature-one-star-1.png"
                            alt=""
                        />
                    </div>
                    <div class="feature-one__start-2 float-bob-x">
                        <img
                            src="../../assets/img/OptebizImage/feature-one-star-2.png"
                            alt=""
                        />
                    </div>
                    <div class="feature-one__start-3 float-bob-y">
                        <img
                            src="images/shapes/feature-one-star-3.png"
                            alt=""
                        />
                    </div>

                    <div class="feature-one__start-5 zoominout">
                        <img
                            src="images/shapes/feature-one-star-5.png"
                            alt=""
                        />
                    </div>

                    <!-- <div class="feature-one__ai-pack">
                        <h3>AI Pack</h3>
                    </div> -->
                    <ul class="feature-one__list">
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-code.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >AI Code <br />
                                        Generator</a
                                    >
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div class="feature-one__cpu-icon-box">
                                <img
                                    src="../../assets/img/OptebizImage/cpu.png"
                                    alt=""
                                />
                            </div>
                        </li>
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-chat.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >AI Chat Bot</a
                                    >
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="feature-one__main-content-bottom">
                    <div class="feature-one__start-4 zoominout">
                        <img
                            src="images/shapes/feature-one-star-4.png"
                            alt=""
                        />
                    </div>
                    <div class="feature-one__start-8 float-bob-x">
                        <img
                            src="images/shapes/feature-one-star-8.png"
                            alt=""
                        />
                    </div>
                    <ul class="feature-one__list">
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-audio.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >Generate <br />
                                        Text to Audio</a
                                    >
                                </h5>
                            </div>
                        </li>
                        <li>
                            <div class="feature-one__single">
                                <div class="feature-one__icon">
                                    <img
                                        src="../../assets/img/OptebizImage/ai-speech.png"
                                        alt=""
                                    />
                                </div>
                                <h5 class="feature-one__title-2">
                                    <a
                                        href="about.html"
                                        contenteditable="false"
                                        style="cursor: pointer"
                                        >Speech to <br />
                                        Text</a
                                    >
                                </h5>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="feature-one__rating-box">
                <ul class="feature-one__rating">
                    <li>
                        <div class="feature-one__rating-icon">
                            <img
                                src="../../assets/img/OptebizImage/rateing.png"
                                alt=""
                            />
                        </div>
                        <div class="feature-one__rating-star-and-text">
                            <div class="feature-one__rating-star">
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                            </div>
                            <p class="feature-one__rating-text">
                                Rated 4.8 . 1,000+ Reviews
                            </p>
                        </div>
                    </li>
                    <li>
                        <div class="feature-one__rating-icon">
                            <img
                                src="../../assets/img/OptebizImage/send.png"
                                alt=""
                            />
                        </div>
                        <div
                            class="feature-one__rating-star-and-text feature-one__rating-star-and-text--two"
                        >
                            <div class="feature-one__rating-star">
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                                <span class="icon-star"></span>
                            </div>
                            <p class="feature-one__rating-text">
                                Rated 4.8 . 1,000+ Reviews
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
