<div class="banner-area">
    <div class="container">
        <div class="banner-content">
            <span class="typewrite"
                >{{ currentText }}<span class="cursor">|</span></span
            >
            <h1>Aoriv - AI &amp; Robotics + RTL</h1>
            <div class="button-container">
                <a href="#" target="_blank" class="default-btn">
                    Purchase Aoriv Now
                </a>
                <a
                    [href]="mainBannerContent.videoBtnLink"
                    class="video-btn wow animate__animated animate__fadeInLeft popup-youtube"
                >
                    <i [class]="mainBannerContent.videoBtnIcon"></i>
                    {{ mainBannerContent.videoBtnText }}
                </a>
            </div>
            <img
                src="../../assets/img/OptebizImage/banner6.jpg"
                [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
                alt="demo-image"
            />
        </div>
    </div>
    <div
        class="banner-box one"
        [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
    >
        <img src="../../assets/img/OptebizImage/banner1.jpg" alt="demo-image" />
    </div>
    <div
        class="banner-box two"
        [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
    >
        <img src="../../assets/img/OptebizImage/banner2.jpg" alt="demo-image" />
    </div>
    <div
        class="banner-box three"
        [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
    >
        <img src="../../assets/img/OptebizImage/banner3.jpg" alt="demo-image" />
    </div>
    <div
        class="banner-box four"
        [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
    >
        <img src="../../assets/img/OptebizImage/banner4.jpg" alt="demo-image" />
    </div>
    <div
        class="banner-box five"
        [@fadeInUp]="{ value: '', params: { delay: '600ms' } }"
    >
        <img src="../../assets/img/OptebizImage/banner1.jpg" alt="demo-image" />
    </div>
    <div class="shape2">
        <img src="../../assets/img/OptebizImage/shape2.png" alt="shape" />
    </div>
</div>
