import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-blog',
    templateUrl: './homeone-blog.component.html',
    styleUrls: ['./homeone-blog.component.scss']
})
export class HomeoneBlogComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our Services",
            title: 'Delivering value through Operational',
            paragraphText: 'We are more than partners; we strive to be your steadfast companions on the road to operational excellence. Our commitment goes beyond providing solutions. With our experience and expertise at all levels of manufacturing domain, we become integral allies in transforming your vision into reality.'
        }
    ]
    singleBlogPost: blogPostContent[] = [
        {
            postImg: 'assets/img/blog/img1.jpg',
            postTitle: 'Six Ways to Make Smarter Decisions',
            postLink: 'blog-details',
            postDate: 'April 30, 2023',
            postAuthorImage: 'assets/img/user1.jpg',
            postAuthorName: 'Alex Morgan'
        },
        {
            postImg: 'assets/img/blog/img2.jpg',
            postTitle: 'The Challenges to Tackle Before You Start With AI',
            postLink: 'blog-details',
            postDate: 'April 28, 2023',
            postAuthorImage: 'assets/img/user2.jpg',
            postAuthorName: 'Sarah Taylor'
        },
        {
            postImg: 'assets/img/blog/img3.jpg',
            postTitle: 'Why Organisations Want an Analytics Platform',
            postLink: 'blog-details',
            postDate: 'April 27, 2023',
            postAuthorImage: 'assets/img/user3.jpg',
            postAuthorName: 'David Warner'
        }
    ]
    singleServicesBoxItem: ServicesBoxContent[] = [
        {
            // icon: 'assets/img/OptebizImage/Asset 6@36x.png',
            // title: 'FactoIQ',
            // paragraphText: ' Collect Real-Time timeseries data in a timeseries database and analyze the shop floor process, maintenance,manufacturing and operations data using statistical and AI methodologies',
            linkText: 'Learn More',
            linkIcon: 'flaticon-right',
            link: 'services-details'
        },
    ]


}
class ServicesBoxContent {
    // icon : string;
    // title : string;
    // paragraphText : string;
    linkText : string;
    linkIcon : string;
    link : string;
    }
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
class blogPostContent {
    postImg : string;
    postLink : string;
    postTitle : string;
    postDate : string;
    postAuthorImage : string;
    postAuthorName : string;
}