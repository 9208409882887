import { Component } from '@angular/core';

@Component({
  selector: 'app-process-control-analytics',
  templateUrl: './process-control-analytics.component.html',
  styleUrls: ['./process-control-analytics.component.scss']
})
export class ProcessControlAnalyticsComponent {
  constructor() { }

  ngOnInit(): void {
  }

  pageTitleArea: pageTitle[] = [
      {
          title: 'Facto IQ',
          subTitle: 'Process Control Analytics',
      }
  ]
  servicesDetailsImage: DetailsImage[] = [
      {
          img: '/assets/img/OptebizImage/pca.png'
      }
  ]
  viewDetailsDesc: DetailsDesc[] = [
      {
          subTitle: 'Process Control Analytics',
          title1: 'Process Control Analytics',
          paragraphText1: 'FactoIQ Process Control uses real-time data analysis to enhance quality, reduce waste, and minimize errors, aiding better decision-making and operational optimization for peak efficiency',
          paragraphText2: 'This could involve monitoring equipment performance, production rates, quality control, and other relevant metrics. The goal is to identify and address any issues that may arise in real-time, minimizing downtime and maximizing productivity. Its like having a watchful eye over the entire production process to keep things on track.',
          title2: 'Important Facts',
          title3: 'Application Areas',
          title4: 'Technologies That We Use',
          img1: '/assets/img/OptebizImage/pca1.png',
          img2: 'assets/img/OptebizImage/manufacturing-process-control-diagram.svg'
      }
  ]
  viewDetailsDescImportantFacts: ImportantFacts[] = [
      {
          title: 'Advanced Process Control (APC)'
      },
      {
          title: 'Integration with SCADA Systems'
      },
      {
          title: 'Real-time Monitoring'
      },
      {
          title: 'Statistical Process Control (SPC)'
      },
      {
          title: 'Data Analysis for Quality Assurance'
      },
      {
          title: 'Feedback Control Systems'
      }
  ]
  
  sidebarServicesList: ServicesList[] = [
    {
        title: 'Time Series Analytics',
        link: 'time-series-analytics'
    },
    {
        title: 'MES Analytics',
        link: 'mes-analytics'
    },
    {
        title: 'Alarm Analytics',
        link: 'alarm-analytics'
    },
    {
        title: 'Quality Analytics',
        link: 'quality-analytics'
    }
  ]
  sidebarDownloadFile: DownloadFile[] = [
      {
          title: 'PDF Download',
          icon: 'bx bxs-file-pdf',
          link: 'view-details'
      },
      {
          title: 'view Details.txt',
          icon: 'bx bxs-file-txt',
          link: 'view-details'
      }
  ]
  sidebarContactInfo: ContactInfo[] = [
      {
          icon: 'bx bx-user-pin',
          title: 'Phone',
          subTitle: '+2145 354 5413'
      },
      {
          icon: 'bx bx-map',
          title: 'Location',
          subTitle: 'New York, USA'
      },
      {
          icon: 'bx bx-envelope',
          title: 'Email',
          subTitle: 'hello@wilo.com'
      }
  ]
  
}
class pageTitle {
  title : string;
  subTitle : string;
}
class DetailsImage {
  img : string;
}
class DetailsDesc {
  subTitle : string;
  title1 : string;
  title2 : string;
  title3 : string;
  title4 : string;
  paragraphText1 : string;
  paragraphText2 : string;
  img1 : string;
  img2 : string;
}
class ImportantFacts {
  title : string;
}
class Application {
  title : string;
  icon: string;
}
class TechnologiesFeatures {
  title : string;
}

class ServicesList {
  title : string;
  link : string;
}
class DownloadFile {
  title : string;
  icon : string;
  link : string;
}
class ContactInfo {
  icon : string;
  title : string;
  subTitle : string;
}



