import { Component } from '@angular/core';

@Component({
  selector: 'app-service-ia',
  templateUrl: './service-ia.component.html',
  styleUrls: ['./service-ia.component.scss']
})
export class ServiceIAComponent {


  showDiv = {
    previous : false,
    current : false,
    next : true,
    nextTo:false,
  }
}
