<footer class="footer-section">
    <div class="container">
        <!-- <div class="footer-cta pt-5 pb-5">
            <div class="row">
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class='bx bxs-phone' ></i>
                        <div class="cta-text">
                            <h4>Find us</h4>
                            <span>Optebiz Inc.
                               </span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class='bx bxs-phone' ></i>
                        <div class="cta-text">
                            <h4>Call us</h4>
                            <span>tel:+1 (419) 476-4520</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class='bx bx-envelope' ></i>
                        <div class="cta-text">
                            <h4>Mail us</h4>
                            <span>bizops@OpteBiz.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="footer-content pt-5 pb-5">
            <div class="row">
                <div class="col-xl-4 col-lg-4 mb-50">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a href="index.html"
                                ><img
                                    src="assets\img\LeanQbit_logo.png"
                                    alt="logo"
                                    style="width: 150px; height: 80px"
                                    class="img-fluid"
                                    alt="logo"
                            /></a>
                        </div>
                        <div class="footer-text">
                            <p style="font-family: Condensed">
                                LeanQubit is a dynamic company at the forefront
                                of the industrial revolution 4.0 in
                                manufacturing, specializing in MES-based
                                solutions, CloudConnect, AWS, and Inductive
                                Automation
                            </p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Follow us</span>
                            <a
                                href="https://www.linkedin.com/company/optebiz-pvt-ltd-/mycompany/"
                                ><i
                                    class="bx bxl-linkedin-square facebook-bg"
                                ></i
                            ></a>
                            <a href="https://twitter.com/optebiz?lang=en"
                                ><i class="bx bxl-twitter twitter-bg"></i
                            ></a>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Explore</h3>
                        </div>
                        <ul>
                            <li><a href="#">Home</a></li>
                            <li><a href="/about-us">About us</a></li>
                            <li><a href="/Facto-Tool-Kit">Facto toolkit</a></li>
                            <li>
                                <a href="/Client-Testimonials">Testimonials</a>
                            </li>
                            <li><a href="/contact">Contact</a></li>
                            <li><a href="/faq">FAQ</a></li>
                            <li>
                                <a href="/inductive-details"
                                    >Inductive Automation</a
                                >
                            </li>
                            <li><a href="#">Amazon Web Service(Saas)</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4 col-md-6 mb-50">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Contact Us</h3>
                        </div>
                        <div class="footer-text mb-25">
                            <p>Don’t miss to subscribe to our new update</p>
                        </div>
                        <div class="subscribe-form">
                            <form action="#">
                                <input
                                    type="text"
                                    placeholder="Email Address"
                                />
                                <button><i class="bx bx-mail-send"></i></button>
                            </form>
                        </div>
                        <br />

                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Address</h3>
                                <p style="font-family: Condensed">
                                    LeanQubit Inc. Research & Technology Complex
                                    2600 Dorr Street Toledo, OH 43606
                                </p>
                                <p style="font-family: Condensed">
                                    LeanQubit Private Limited B-205, Mastermind
                                    III, Royal Palms IT Park, Aarey Coloney,
                                    Goregaon (East), Mumbai, Maharashtra, IN
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div
                    class="col-xl-6 col-lg-6 text-center text-lg-left d-flex align-items-center"
                >
                    <div class="copyright-text">
                        <p>
                            Copyright &copy; 2023, All Right Reserved
                            <a href="/">LeanQubit Private Limited</a>
                        </p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div class="footer-menu">
                        <ul style="margin-left: 149px">
                            <!-- <li><a href="#">Home</a></li> -->
                            <li><a href="/Terms&Condition">Terms</a></li>
                            <li><a href="/privacy&policy">Privacy</a></li>
                            <!-- <li><a href="#">cookies</a></li> -->
                            <li><a href="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<div class="go-top"><i class="flaticon-up"></i></div>
