<section class="page-title ">  
<div class="container mb-5" data-v-116f5dc2="">
   
    <div class="mt-5" data-v-116f5dc2="">
       <p class="top-breadcrumb aos-init aos-animate" data-aos="fade-right" data-v-116f5dc2=""> FAQ </p>
       <h1 data-aos="fade-left" data-v-116f5dc2="" class="aos-init aos-animate" style="color: #044f67;">Frequently Asked Questions</h1>
    </div>
    <h2 data-aos="zoom-in" class="mt-5 aos-init aos-animate" data-v-116f5dc2="" style="color: var(--mainColor);">General Questions</h2>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q1. Why do we need MES Solution if we already have an ERP Solution?</h3>
        
       </summary>
       <div data-v-bcf7a0d4="">
          
          <p data-v-116f5dc2=""> <strong>While ERP solutions excel in managing overall business processes,</strong> MES serves as a specialized layer designed to optimize and control manufacturing operations. MES provides real-time visibility into the production process, allowing for precise monitoring, tracking, and control of shop floor activities. It bridges the gap between planning and execution, enhancing efficiency, reducing lead times, and ensuring quality control in manufacturing. In essence, combining ERP with MES creates a comprehensive ecosystem that not only manages the entire shop floor environment but also fine-tunes and maximizes the efficiency of the manufacturing process.</p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                   <!---->
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q2. Will FactoMES Solution be fully compatible with my ERP Solution</h3>
         
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""><strong>Yes, Facto Tools seamlessly integrates with a diverse range of ERP systems,</strong> including SAP, Oracle, JDE, Sage, and various others, ensuring a comprehensive collaboration within your existing technological ecosystem This interoperability is designed to enhance efficiency and minimize disruptions, making Facto Tools a versatile solution for businesses with different ERP preferences. In the integration process, approximately 98% of the data exchange pertains to critical manufacturing components. This includes the synchronization of schedules, work orders, production and downtime values, and Bill of Materials (BOMs), ensuring that Facto Tools becomes a synchronized extension of your ERP, streamlining manufacturing processes and bolstering data accuracy for optimal operational performance.</p>
         
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                   <!---->
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q3. What Measurable Results should I expect by partnering with LeanQubit?</h3>
        
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""> <strong>Choosing the ideal solution and a strategic implementation partner is pivotal in reaping significant rewards for your organization.</strong> This thoughtful selection process not only enhances customer satisfaction by ensuring on-time deliveries but also contributes to substantial reductions in downtime and wastage. The synergy between the right solution and a competent implementation partner goes beyond mere efficiency – it establishes a robust platform that grants your organization maximum control over manufacturing activities. Importantly, this is achieved without burdening your existing infrastructure with unnecessary resources, ensuring a seamless integration that elevates operational excellence while optimizing resource utilization.</p>
          
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q4. What Skill Sets are needed to use Facto Tools?</h3>
          
            
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""> <strong>The efficiency of any advanced solution is intricately tied to the competence of the individuals operating it.</strong> Recognizing this, LeanQubit goes beyond providing a cutting-edge solution; we offer comprehensive support through detailed training materials. These materials are designed to aid in the training of the right individuals within your organization. Moreover, our tools are designed with user-friendliness in mind, adhering to industry standards. This means that in many cases, your existing team can seamlessly integrate and navigate the solution without the need for specialized training.</p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
        <summary data-v-bcf7a0d4="">
           <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
              <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                 <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                 </path>
              </svg>
           </span>
           <h3 data-v-bcf7a0d4=""> Q5. What is the Implementation Cost for Facto MES?</h3>
           
        </summary>
        <div data-v-bcf7a0d4="">
           <p data-v-116f5dc2="">  <strong>Determining the software pricing and tailoring the implementation process is a customized journey at LeanQubit,</strong> shaped by your unique requirements. Our approach involves a comprehensive assessment, enabling us to gain a thorough understanding of your specific needs and the intricacies of your existing systems. This detailed evaluation serves as the foundation for presenting you with the most suitable solution set and pricing options that align with your budget constraints. At LeanQubit, transparency is key, and our commitment is to deliver a bespoke solution that not only addresses your current needs but also anticipates and accommodates your future growth. We prioritize a collaborative and consultative process, ensuring that the proposed pricing and implementation plan meets your organizational objectives and financial considerations.</p>
        </div>
     </details>

<!-- newDiv -->
     <h2 data-aos="zoom-in" class="mt-5 aos-init aos-animate" data-v-116f5dc2="" style="color: var(--mainColor);">Features and Functionality</h2>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q1. What are some of the key features of LeanQubit?</h3>
         
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""> <strong>LeanQubit solutions operate seamlessly across various platforms, ensuring accessibility from any device with internet connectivity.</strong>
            <li><strong>Real-time Production Insights :</strong> Provides instant visibility into production processes, enabling real-time monitoring, analysis, and decision-making for enhanced operational control.</li>
            <li><strong>Scalability and Flexibility :</strong> Easily adapts to changing business needs, accommodating fluctuations in production volume, and supporting scalability without compromising performance.</li>
            <li><strong>Cross-Device Accessibility :</strong> Facilitates mobile accessibility, empowering users to manage and monitor manufacturing processes on the go, fostering agility and responsiveness.</li>
            <li><strong>Integrated Data Analysis :</strong> Offers robust data collection and analysis capabilities, supporting data-driven decision-making and providing insights into trends and areas for improvement.</li>
         </p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q2. Will LeanQubit Solution be compatible with my existing application</h3>
          
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""><strong>Absolutely, LeanQubit solutions are designed to be highly compatible with existing applications.</strong> Our implementation team will conduct a thorough assessment of your current systems to ensure seamless integration and functionality. With a commitment to interoperability and customization, we tailor our solutions to work harmoniously with most existing applications.</p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                   
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q3. How will LeanQubit help with training my workforce for Facto solutions?</h3>
       </summary>
       <div data-v-bcf7a0d4="">
          <p > <strong>LeanQubit is dedicated to ensuring a smooth onboarding process for your workforce with Facto solutions. Our training modules and sessions equip your team with the knowledge and skills needed to effectively utilize the Facto tools. This includes : </strong>
            <li><strong>*Customized Training Modules and user guides*</strong> - We develop training modules and user guides that cater to the specific needs of your workforce.</li>
            <li><strong>*Hands-On Workshops*</strong> - Conducting online hands-on workshops allows your team to interact with Facto solutions in a practical environment, fostering a deeper understanding of the tools and their applications.</li>
            <li><strong> *Dedicated Support Team*</strong> - Our support team is readily available to address any queries or concerns during the training period and beyond, ensuring a responsive and supportive learning environment.</li>
         
         </p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
       <summary data-v-bcf7a0d4="">
          <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
             <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                </path>
             </svg>
          </span>
          <h3 data-v-bcf7a0d4="">Q4. How long will it take for me to implement LeanQubit solutions?</h3>
       </summary>
       <div data-v-bcf7a0d4="">
          <p data-v-116f5dc2=""> <strong>The timeframe for implementing an LeanQubit solution varies, ranging from 6 weeks to 18 months,</strong> contingent upon your chosen product mix and the scale and complexity of your business. Evaluating the success of the implementation hinges on your defined objectives.</p>
       </div>
    </details>
    <details data-aos="zoom-in" data-v-116f5dc2="" data-v-bcf7a0d4="" class="aos-init aos-animate">
        <summary data-v-bcf7a0d4="">
           <span aria-hidden="true" class="material-design-icon help-circle-outline-icon" role="img" data-v-bcf7a0d4="">
              <svg fill="currentColor" class="material-design-icon__svg" width="24" height="24" viewBox="0 0 24 24">
                 <path d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z">
                 </path>
              </svg>
           </span>
           <h3 data-v-bcf7a0d4=""> Q5. How do you manage the future updates to the product?</h3>
          
        </summary>
        <div data-v-bcf7a0d4="">
           <p data-v-116f5dc2="">  <strong>Future enhancements are not included</strong> in the initial product release but can be requested later as additional purchases. This allows you to tailor and expand the product according to your evolving needs.</p>
        </div>
     </details>
 </div>

 <div class="container text-center mb-5" data-v-116f5dc2="" data-v-97050013="">
    <div class="rounded-3" data-v-97050013="">
       <div data-v-97050013="">
          <h3 data-aos="fade-right" data-v-97050013="" class="aos-init aos-animate"><span data-v-97050013="" style="color:var(--mainColor)">Get LeanQubit updates</span> to your inbox</h3>
          <form class="row row-cols-lg-auto g-3 mt-4 mb-4 justify-content-center needs-validation aos-init aos-animate" id="newsletter" novalidate="" data-aos="fade-left" data-v-97050013="" data-hs-cf-bound="true">
             <div class="col-12" data-v-97050013=""><label class="visually-hidden" for="newsletter-email" data-v-97050013="">Email</label><input type="email" class="form-control form-control-lg" id="email" placeholder="Email" required="" data-v-97050013="" fdprocessedid="ncwz5"></div>
             <div class="col-12" data-v-97050013=""><button type="submit"   href="mailto:bizops@OpteBiz.com" class="btn btn-dark" >Subscribe</button></div>
          </form>
          <p class="mt-3 aos-init aos-animate" data-aos="zoom-in" data-v-97050013="" style="color:var(--mainColor);">Stay up to date with the latest features and changes to LeanQubit</p>
          <ul class="list-unstyled mt-4 mb-0 socials aos-init aos-animate" data-aos="zoom-in" >
             <li class="list-inline-item">
                <a href="https://www.linkedin.com/company/optebiz-pvt-ltd-/mycompany/" class="d-block" target="_blank"><i class='fa fa-linkedin' style="font-size: 25px;"></i></a>
             </li>
             <li class="list-inline-item ml-1">
                <a href="#" class="d-block" target="_blank"><i class='fa fa-facebook' style="font-size: 25px;"></i></a>
             </li>
             <li class="list-inline-item ml-1">
                <a href="#" class="d-block" target="_blank"><i class='fa fa-instagram' style="font-size: 25px;"></i></a>
             </li>
             <li class="list-inline-item ml-1"> 
                <a href="https://twitter.com/optebiz?lang=en" class="d-block" target="_blank"><i class='fa fa-twitter' style="font-size: 25px"></i></a> 
             </li>
          
          </ul>
       </div>
    </div>
 </div>
</section>