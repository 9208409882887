<div id="main" class="site-main- clearfix">
    <svg style="display: none"></svg>
    <div class="hero-section yb-section">
        <div class="headline text-center">
            <div class="container">
                <div class="left-side">
                    <h1>
                        <span class="gradient-word">Facto IQ</span> The Physical
                        Connection Between Machine And Data
                    </h1>
                    <div class="subheading">
                        FactoIQ's Time Series Analytics extracts insights from
                        sequential data, aiding predictive decisions, market
                        trend forecasting, inventory optimization, demand
                        forecasting, and operational efficiency in the evolving
                        digital landscape.
                    </div>
                </div>
                <div class="right-side">
                    <img
                        src="assets\img\OptebizImage\facto iq Latest.svg"
                        alt="FactoIQ"
                        title="FactoIQ"
                        class="wow animate__animated animate__fadeInDown"
                        loading="lazy"
                        style="top: 28px"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="pdf-download-section yb-section dark-bg">
        <div class="container">
            <div
                class="page-title-content"
                *ngFor="let pageTitle of pageTitleArea"
            >
                <!-- <h2>{{pageTitle.title}}</h2> -->
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li>{{ pageTitle.title }}</li>
                    <li>{{ pageTitle.subTitle }}</li>
                </ul>
            </div>
        </div>
        <div class="c-v2-yugabyte-db-section-triangle"></div>
        <div class="container" style="max-width: 1200px">
            <section class="section solutions-importance">
                <div class="c-row">
                    <h2>Big Data Analytics With <span>FactoIQ</span></h2>
                    <p>
                        FactoIQ's Time Series Analytics extracts insights from
                        sequential data, aiding predictive decisions, market
                        trend forecasting, inventory optimization, demand
                        forecasting, and operational efficiency in the evolving
                        digital landscape.
                    </p>
                    <div class="importance-boxes">
                        <div>
                            <img
                                src="assets\img\OptebizImage\time series_Icon.png"
                                data-src="assets\img\OptebizImage\time series_Icon.png"
                                alt="time-series-analytics"
                                title="time-series-analytics"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>Time Series</span> Analytics</h3>
                            <p>
                                Time series data is information collected at
                                regular time intervals,each tagged with a
                                timestamp representing variables like stock
                                prices, temperature, or sales figures that
                                change over time.
                            </p>
                            <a
                                routerLink="/time-series-analytics"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\mes_analytics_Icon.png"
                                data-src="assets\img\OptebizImage\mes_analytics_Icon.png"
                                alt="mes-analytics"
                                title="mes-analytics"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>MES</span> Analytics</h3>
                            <p>
                                MES Analytics empowers manufacturing with
                                actionable insights from production data,
                                enabling predictive maintenance, quality
                                control, and resource optimization for improved
                                efficiency and product quality.
                            </p>
                            <a routerLink="/mes-analytics" class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\alarm_icon.png"
                                data-src="assets\img\OptebizImage\alarm_icon.png"
                                alt="alarm-analytics"
                                title="alarm-analytics"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>Alarm</span> Analytics</h3>
                            <p>
                                Alarm analytics refers to the analysis of system
                                alarms and notifications to identify patterns,
                                anomalies, and insights for improved system
                                reliability and performance.
                            </p>
                            <a
                                routerLink="/alarm-analytics"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\QualityIcon.png"
                                data-src="assets\img\OptebizImage\QualityIcon.png"
                                alt="quality-analytics"
                                title="quality-analytics"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>Quality</span> Analytics</h3>
                            <p>
                                Control data access and storage across regions
                                to meet evolving regulatory and compliance
                                requirements.
                            </p>
                            <a
                                routerLink="/quality-analytics"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\process_control_Icon.png"
                                data-src="assets\img\OptebizImage\process_control_Icon.png"
                                alt="process-control-analytics"
                                title="process-control-analytics"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>Process Control</span> Analytics</h3>
                            <p>
                                Control data access and storage across regions
                                to meet evolving regulatory and compliance
                                requirements.
                            </p>
                            <a
                                routerLink="/process-control-analytics"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                        </div>
                        <div>
                            <img
                                src="assets\img\OptebizImage\process monitoring icon.png"
                                data-src="assets\img\OptebizImage\process monitoring icon.png"
                                alt="process-Monitoring"
                                title="process-Monitoring"
                                width="64"
                                height="64"
                                class="lazy-loaded"
                            />
                            <h3><span>Process</span> Monitoring</h3>
                            <p>
                                Optimize operations by evaluating indicators and
                                user behavior for efficiency and compliance.
                            </p>
                            <a
                                routerLink="/process-Monitoring"
                                class="read-more-btn"
                                ><i class="flaticon-right"></i>View Details
                            </a>
                            <!-- <a routerLink="/{{ServicesBoxContent.link}}" class="learn-more-btn"><i class="{{ServicesBoxContent.linkIcon}}"></i>{{ServicesBoxContent.linkText}}</a> -->
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
