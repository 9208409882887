<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image" *ngFor="let Image of subscribeImage;">
                    <img [src]="Image.img" data-speed="0.06" data-revert="true" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content" *ngFor="let Content of subscribeContent;">
                    <h2>{{Content.title}}</h2>
                    <p>{{Content.paragraphText}}</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="{{Content.inputText}}" name="EMAIL">
                        <button type="submit" class="default-btn"><i class="{{Content.buttonIcon}}"></i>{{Content.buttonText}}<span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="vector-shape6"><img src="assets/img/shape/vector-shape6.png" alt="image"></div>
</section>