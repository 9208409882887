<section class="process-section">
    <!-- Animated particles -->
    <div class="particles">
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
        <div class="particle"></div>
    </div>

    <div class="container">
        <div class="section-title">
            <div class="sub-title glow">
                <img src="assets/img/star-icon.png" alt="star icon" /> What We
                Do
            </div>
            <h2>Transforming Ideas Into Solutions</h2>
            <p>
                Our solution design is based on and compliant with
                industry-leading ISA-95 Guidelines with enough flexibility to
                tailor the models to meet your business needs seamlessly
            </p>
        </div>

        <div class="process-flow">
            <img
                src="assets\img\OptebizImage\ProcessFLOW.png"
                alt="Process Flow Diagram"
            />
        </div>

        <div class="process-cards">
            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Requirement Gathering.png"
                            alt="Requirement Gathering"
                        />
                    </div>
                    <span class="step">STEP 01</span>
                </div>
                <h3>Requirement Gathering</h3>
                <p>
                    Requirements are gathered by engaging with stakeholders,
                    analyzing sample data for the current state and identifying
                    gaps and pain points through mapping existing business
                    processes.
                </p>
                <span class="number">01</span>
                <div class="corner-decoration"></div>
            </div>

            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Structure Requirements.png"
                            alt="Structure Requirements"
                        />
                    </div>
                    <span class="step">STEP 02</span>
                </div>
                <h3>Structure Requirements</h3>
                <p>
                    This involves setting capacity and efficiency goals, quality
                    standards, addressing inventory and fulfillment issues, and
                    aligning technology with customer needs and growth
                    strategies for long-term success.
                </p>
                <span class="number">02</span>
                <div class="corner-decoration"></div>
            </div>

            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Documenting Requirements.png"
                            alt="Documenting Requirements"
                        />
                    </div>
                    <span class="step">STEP 03</span>
                </div>
                <h3>Documenting Requirements</h3>
                <p>
                    Using our detailed templates ensures clear project
                    requirements, reducing ambiguity, and facilitating efficient
                    decision-making for all stakeholders, promoting project
                    success.
                </p>
                <span class="number">03</span>
                <div class="corner-decoration"></div>
            </div>

            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Requirement Prioritization.png"
                            alt="Requirement Prioritization"
                        />
                    </div>
                    <span class="step">STEP 04</span>
                </div>
                <h3>Requirement Prioritization</h3>
                <p>
                    Requirement prioritization ranks needs by importance,
                    allocating resources to address high-impact requirements for
                    maximum value within constraints.
                </p>
                <span class="number">04</span>
                <div class="corner-decoration"></div>
            </div>

            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Solution Design.png"
                            alt="Solution Design"
                        />
                    </div>
                    <span class="step">STEP 05</span>
                </div>
                <h3>Solution Design</h3>
                <p>
                    Create a blueprint for a cohesive and efficient solution,
                    considering scalability and usability, translating
                    requirements into an actionable plan that aligns with
                    project goals.
                </p>
                <span class="number">05</span>
                <div class="corner-decoration"></div>
            </div>

            <div class="process-card">
                <div class="process-header">
                    <div class="icon">
                        <img
                            src="assets\img\OptebizImage\Solution Demo and Sign Off.png"
                            alt="Solution Demo"
                        />
                    </div>
                    <span class="step">STEP 06</span>
                </div>
                <h3>Solution Demo</h3>
                <p>
                    Final design is formally proposed based on the identified
                    requirements highlighting the key features and
                    functionalities of the point of arrival system comparing it
                    with the existing solution.
                </p>
                <span class="number">06</span>
                <div class="corner-decoration"></div>
            </div>
        </div>
    </div>
</section>
