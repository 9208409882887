<div class="page-wrapper">
    <!-- <div class="green-strap"></div> -->
    <div class="demo-container">
        <h1 class="demo-title">Book a Demo</h1>

        <div class="demo-content">
            <div class="info-section">
                <h2>
                    Experience the Power of
                    <span style="color: #1d9395">Lean</span
                    ><span style="color: #da5c30">Q</span
                    ><span style="color: #1d9395">ubit's</span> Solutions
                </h2>
                <p>
                    Discover how our innovative solutions can transform your
                    manufacturing operations. Select a product below to schedule
                    a personalized demo.
                </p>
                <div class="form-section">
                    <!-- Step 1: Contact Information Form -->
                    <form
                        [formGroup]="demoForm"
                        (ngSubmit)="onSubmit()"
                        class="form-wrapper"
                        *ngIf="currentStep === 1"
                    >
                        <!-- First Name and Last Name -->
                        <div class="form-row">
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('firstName')"
                            >
                                <input
                                    type="text"
                                    formControlName="firstName"
                                    placeholder="First Name*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('firstName')"
                                >
                                    First name is required
                                </div>
                            </div>
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('lastName')"
                            >
                                <input
                                    type="text"
                                    formControlName="lastName"
                                    placeholder="Last Name*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('lastName')"
                                >
                                    Last name is required
                                </div>
                            </div>
                        </div>

                        <!-- Company/Institution and Email -->
                        <div class="form-row">
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('companyName')"
                            >
                                <input
                                    type="text"
                                    formControlName="companyName"
                                    placeholder="Company/Institution*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('companyName')"
                                >
                                    Company/Institution is required
                                </div>
                            </div>
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('emailAddress')"
                            >
                                <input
                                    type="email"
                                    formControlName="emailAddress"
                                    placeholder="Email Address*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('emailAddress')"
                                >
                                    Please enter a valid email address
                                </div>
                            </div>
                        </div>

                        <!-- Phone and ZIP Code -->
                        <div class="form-row">
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('phoneNumber')"
                            >
                                <input
                                    type="tel"
                                    formControlName="phoneNumber"
                                    placeholder="Phone Number*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('phoneNumber')"
                                >
                                    Please enter a valid phone number
                                </div>
                            </div>
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('zipCode')"
                            >
                                <input
                                    type="text"
                                    formControlName="zipCode"
                                    placeholder="ZIP Code*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('zipCode')"
                                >
                                    Please enter a valid ZIP code
                                </div>
                            </div>
                        </div>

                        <!-- City and Country -->
                        <div class="form-row">
                            <div
                                class="form-group"
                                [class.invalid]="isFieldInvalid('city')"
                            >
                                <input
                                    type="text"
                                    formControlName="city"
                                    placeholder="City*"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('city')"
                                >
                                    City is required
                                </div>
                            </div>
                            <div class="form-group country-input">
                                <input
                                    type="text"
                                    formControlName="country"
                                    placeholder="Select Country*"
                                    (input)="filterCountries($event)"
                                    (focus)="showCountryList = true"
                                />
                                <div
                                    class="error-message"
                                    *ngIf="isFieldInvalid('country')"
                                >
                                    Please select a country
                                </div>

                                <div
                                    class="country-list"
                                    *ngIf="
                                        showCountryList &&
                                        filteredCountries.length > 0
                                    "
                                >
                                    <div
                                        class="country-item"
                                        *ngFor="
                                            let country of filteredCountries
                                        "
                                        (mousedown)="selectCountry(country)"
                                    >
                                        {{ country }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Message
                        <div
                            class="form-group"
                            [class.invalid]="isFieldInvalid('message')"
                        >
                            <textarea
                                formControlName="message"
                                placeholder="Your Message"
                            ></textarea>
                            <div
                                class="error-message"
                                *ngIf="isFieldInvalid('message')"
                            >
                                Message cannot exceed 500 characters
                            </div>
                        </div> -->

                        <ngx-recaptcha2
                            #captchaElem
                            [siteKey]="siteKey"
                        ></ngx-recaptcha2>
                        <div class="form-error-message" *ngIf="showFormError">
                            Please complete all required fields.
                        </div>
                        <button type="submit" class="submit-btn">NEXT</button>
                    </form>

                    <!-- Step 2: Product Selection Form -->
                    <form
                        [formGroup]="productForm"
                        (ngSubmit)="onSubmit()"
                        class="form-wrapper"
                        *ngIf="currentStep === 2"
                    >
                        <!-- Product Selection -->
                        <div class="form-group">
                            <h5>Select Product(s) for demo</h5>
                            <div class="checkbox-group">
                                <div
                                    *ngFor="
                                        let product of products;
                                        let i = index
                                    "
                                    class="checkbox-item"
                                >
                                    <input
                                        type="checkbox"
                                        [id]="'product-' + i"
                                        [formControlName]="i"
                                        [formArrayName]="'selectedProducts'"
                                    />
                                    <label [for]="'product-' + i">{{
                                        product
                                    }}</label>
                                </div>
                            </div>
                        </div>

                        <!-- Feature Selection -->
                        <div class="form-group">
                            <h5>Specific features or modules of interest</h5>
                            <div class="checkbox-group">
                                <div
                                    *ngFor="
                                        let feature of features;
                                        let i = index
                                    "
                                    class="checkbox-item"
                                >
                                    <input
                                        type="checkbox"
                                        [id]="'feature-' + i"
                                        [formControlName]="i"
                                        [formArrayName]="'selectedFeatures'"
                                    />
                                    <label [for]="'feature-' + i">{{
                                        feature
                                    }}</label>
                                </div>
                            </div>
                        </div>

                        <!-- Requirements and Questions -->
                        <div class="form-group">
                            <h5>
                                Tell us more about your requirements and
                                questions
                            </h5>
                            <textarea
                                formControlName="requirementsAndQuestions"
                                placeholder="Please describe your specific requirements or questions"
                            ></textarea>
                        </div>

                        <div class="form-error-message" *ngIf="showFormError">
                            Please review the form and try again.
                        </div>
                        <button type="submit" class="submit-btn">SUBMIT</button>
                    </form>
                </div>
            </div>
            <div class="info-sectionnew">
                <div class="content-wrapper">
                    <div class="overlay">
                        <h2>What to Expect:</h2>
                        <div class="benefits-list">
                            <ul>
                                <li>
                                    A personalized demo with our expert team
                                </li>
                                <li>
                                    A comprehensive overview of the selected
                                    product's features and benefits
                                </li>
                                <li>
                                    A discussion of your specific challenges and
                                    goals
                                </li>
                                <li>
                                    A follow-up conversation to answer any
                                    additional questions you may have
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
