import { Component, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-service-ia',
  templateUrl: './service-ia.component.html',
  styleUrls: ['./service-ia.component.scss']
})
export class ServiceIAComponent implements OnInit, OnDestroy {
  // Dashboard data with descriptions
  dashboards = [
    {
      id: 'production',
      title: 'Production Performance Monitor',
      // description: 'Monitor your production metrics with comprehensive analytics',
      image: 'assets/img/OptebizImage/Production Data Monitoring.png',
      icon: 'M2 5V19H22V5H2M20 12H16V7H20V12M14 10H10V7H14V10M10 12H14V17H10V12M4 7H8V17H4V7M16 17V14H20V17H16Z'
    },
    {
      id: 'shift',
      title: 'Production Shift Insights',
      // description: 'Track performance metrics across different shifts',
      image: 'assets/img/OptebizImage/Shift Summary.png',
      icon: 'M3,14L3.5,14.07L8.07,9.5C7.89,8.85 8.06,8.11 8.59,7.59C9.37,6.8 10.63,6.8 11.41,7.59C11.94,8.11 12.11,8.85 11.93,9.5L14.5,12.07L15,12C15.18,12 15.35,12 15.5,12.07L19.07,8.5C19,8.35 19,8.18 19,8A2,2 0 0,1 21,6A2,2 0 0,1 23,8A2,2 0 0,1 21,10C20.82,10 20.65,10 20.5,9.93L16.93,13.5C17,13.65 17,13.82 17,14A2,2 0 0,1 15,16A2,2 0 0,1 13,14L13.07,13.5L10.5,10.93C10.18,11 9.82,11 9.5,10.93L4.93,15.5L5,16A2,2 0 0,1 3,18A2,2 0 0,1 1,16A2,2 0 0,1 3,14Z'
    },
    {
      id: 'realtime',
      title: 'Real-Time KPI Monitor',
      // description: 'Live monitoring of all production operations',
      image: 'assets/img/OptebizImage/RealTime Dashboard.png',
      icon: 'M9.5,8.5L11,10L8,13L11,16L9.5,17.5L5,13L9.5,8.5M14.5,17.5L13,16L16,13L13,10L14.5,8.5L19,13L14.5,17.5M21,2H3A2,2 0 0,0 1,4V20A2,2 0 0,0 3,22H21A2,2 0 0,0 23,20V4A2,2 0 0,0 21,2M21,20H3V6H21V20Z'
    },
    {
      id: 'downtime',
      title: 'Machine Downtime Analyzer',
      // description: 'Analyze and minimize production downtime',
      image: 'assets/img/OptebizImage/Downtime Dashboard.png',
      icon: 'M22.61,19L13.53,9.91C14.46,7.57 14,4.81 12.09,2.91C9.79,0.61 6.21,0.4 3.66,2.26L7.5,6.11L6.08,7.5L2.25,3.69C0.39,6.23 0.6,9.82 2.9,12.11C4.76,13.97 7.47,14.46 9.79,13.59L18.9,22.7C19.29,23.09 19.92,23.09 20.31,22.7L22.61,20.4C23,20 23,19.39 22.61,19M19.61,20.59L10.15,11.13C9.54,11.58 8.86,11.85 8.15,11.95C6.79,12.15 5.36,11.74 4.32,10.7C3.37,9.76 2.93,8.5 3,7.26L6.09,10.35L10.33,6.11L7.24,3C8.5,2.95 9.73,3.39 10.68,4.33C11.76,5.41 12.17,6.9 11.92,8.29C11.8,9 11.5,9.66 11.04,10.25L20.5,19.7L19.61,20.59Z'
    }
  ];
  
  currentDashboardIndex = 0;
  activeDashboard = this.dashboards[0];
  private autoRotateInterval: any;
  fadeState = 'in'; // For animation

  ngOnInit(): void {
    this.startAutoRotate();
  }

  ngOnDestroy(): void {
    this.stopAutoRotate();
  }

  startAutoRotate(): void {
    this.autoRotateInterval = setInterval(() => {
      this.showNextDashboard();
    }, 5000);
  }

  stopAutoRotate(): void {
    if (this.autoRotateInterval) {
      clearInterval(this.autoRotateInterval);
    }
  }

  showNextDashboard(): void {
    this.fadeState = 'out';
    setTimeout(() => {
      this.currentDashboardIndex = (this.currentDashboardIndex + 1) % this.dashboards.length;
      this.activeDashboard = this.dashboards[this.currentDashboardIndex];
      this.fadeState = 'in';
    }, 300);
  }

  showPreviousDashboard(): void {
    this.fadeState = 'out';
    setTimeout(() => {
      this.currentDashboardIndex = (this.currentDashboardIndex - 1 + this.dashboards.length) % this.dashboards.length;
      this.activeDashboard = this.dashboards[this.currentDashboardIndex];
      this.fadeState = 'in';
    }, 300);
  }

  isDashboardActive(index: number): boolean {
    return this.currentDashboardIndex === index;
  }
}