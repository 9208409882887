<section class="cs_hero cs_style_2 position-relative" id="home">
    <div class="container">
        <div class="cs_product_title">
            <h1><span>FactoLake</span> Manufacturing Data</h1>
        </div>
        <div class="row align-items-center cs_gap_y_40 cs_reverse_md">
            <div class="col-lg-7">
                <div class="cs_hero_text">
                    <h1 class="cs_hero_title">
                        <!-- <div class="main-title">
                            Unlocking Supply Chain Efficiency with FactoLake
                        </div> -->
                        <div class="subtitle">
                            Unlock the Power of Data-Driven Decision Making
                        </div>
                    </h1>
                    <p class="cs_hero_subtitle">
                        Transform Your Manufacturing Operations with Real-Time
                        Insights.
                    </p>
                    <div
                        class="cs_btn_group cs_version_2 wow fadeIn"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.25s"
                    >
                        <a
                            href="/BookDemo"
                            class="cs_btn cs_bg_white cs_dark_hover"
                            >Product Demo</a
                        >
                        <a
                            href="/TryItNow"
                            class="cs_btn cs_bg_accent cs_dark_hover"
                            >Start Free Trial</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="cs_hero_thumb">
                    <video
                        id="videoPlayer"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="max-width: 100%; height: auto"
                    >
                        <source
                            src="assets/img/OptebizImage/ERP Systemlake.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_hero_shape1"></div>
    <div class="cs_hero_shape2">
        <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
    </div>
    <div class="cs_hero_shape3"></div>
</section>
<!-- Second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="container">
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes flow {
                                0% {
                                    transform: translateY(0);
                                }
                                50% {
                                    transform: translateY(10px);
                                }
                                100% {
                                    transform: translateY(0);
                                }
                            }
                            @keyframes pulse {
                                0% {
                                    transform: scale(0.8);
                                    opacity: 0.7;
                                }
                                50% {
                                    transform: scale(1.2);
                                    opacity: 1;
                                }
                                100% {
                                    transform: scale(0.8);
                                    opacity: 0.7;
                                }
                            }
                            .data-flow {
                                animation: flow 3s ease-in-out infinite;
                            }
                            .pulse {
                                animation: pulse 2s infinite;
                            }
                        </style>
                        <rect
                            x="15"
                            y="20"
                            width="70"
                            height="60"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                            rx="5"
                            ry="5"
                        />
                        <g class="data-flow">
                            <path
                                d="M50,15 L65,30 L50,45 L35,30 Z"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <line
                                x1="50"
                                y1="45"
                                x2="50"
                                y2="60"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </g>
                        <circle
                            class="pulse"
                            cx="50"
                            cy="65"
                            r="10"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <path
                            d="M25,75 L35,75 L35,65 L65,65 L65,75 L75,75"
                            stroke="#1d9395"
                            stroke-width="2"
                            fill="none"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Data Ingestion</h3>
                    <p class="cs_iconbox_subtitle">
                        Ingest data from various manufacturing sources,
                        including process control systems, quality control
                        systems, and sensor data.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes draw {
                                0% {
                                    stroke-dashoffset: 300;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes rotate {
                                0% {
                                    transform: rotate(0deg);
                                }
                                100% {
                                    transform: rotate(360deg);
                                }
                            }
                            .chart-line {
                                stroke-dasharray: 300;
                                animation: draw 4s linear infinite;
                            }
                            .clock-hand {
                                transform-origin: 50px 50px;
                                animation: rotate 10s linear infinite;
                            }
                        </style>
                        <circle
                            cx="50"
                            cy="50"
                            r="40"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            class="clock-hand"
                            x1="50"
                            y1="50"
                            x2="50"
                            y2="20"
                            stroke="#1d9395"
                            stroke-width="3"
                        />
                        <circle cx="50" cy="50" r="5" fill="#1d9395" />
                        <path
                            class="chart-line"
                            d="M25,75 L25,55 L35,45 L45,55 L55,35 L65,45 L75,25"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <text
                            x="25"
                            y="85"
                            font-family="Arial"
                            font-size="8"
                            fill="#1d9395"
                        >
                            0s
                        </text>
                        <text
                            x="75"
                            y="85"
                            font-family="Arial"
                            font-size="8"
                            fill="#1d9395"
                        >
                            Now
                        </text>
                    </svg>
                    <h3 class="cs_iconbox_title">Real-time Processing</h3>
                    <p class="cs_iconbox_subtitle">
                        Process data in real-time, enabling instant insights and
                        decision-making in response to changes in production,
                        quality, and equipment performance.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes blink {
                                0%,
                                100% {
                                    opacity: 0.3;
                                }
                                50% {
                                    opacity: 1;
                                }
                            }
                            @keyframes slide {
                                0% {
                                    transform: translateX(-5px);
                                }
                                100% {
                                    transform: translateX(5px);
                                }
                            }
                            .monitor-blink {
                                animation: blink 2s infinite;
                            }
                            .data-slide {
                                animation: slide 2s ease-in-out infinite
                                    alternate;
                            }
                        </style>
                        <rect
                            x="15"
                            y="15"
                            width="70"
                            height="50"
                            rx="5"
                            ry="5"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="15"
                            y1="30"
                            x2="85"
                            y2="30"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <circle
                            class="monitor-blink"
                            cx="22"
                            cy="22"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="monitor-blink"
                            cx="32"
                            cy="22"
                            r="3"
                            fill="#1d9395"
                        />
                        <circle
                            class="monitor-blink"
                            cx="42"
                            cy="22"
                            r="3"
                            fill="#1d9395"
                        />
                        <path
                            class="data-slide"
                            d="M25,50 L35,40 L45,60 L55,35 L65,45 L75,50"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="3"
                        />
                        <rect
                            x="35"
                            y="70"
                            width="30"
                            height="15"
                            rx="3"
                            ry="3"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="25"
                            y1="80"
                            x2="35"
                            y2="80"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="65"
                            y1="80"
                            x2="75"
                            y2="80"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Data Analytics</h3>
                    <p class="cs_iconbox_subtitle">
                        Analyze data using advanced analytics techniques,
                        including machine learning and predictive analytics to
                        identify trends, patterns, and anomalies, and gain
                        actionable insights.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <style>
                                @keyframes highlight {
                                    0%,
                                    100% {
                                        stroke-width: 2;
                                        r: 4.5;
                                    }
                                    50% {
                                        stroke-width: 3;
                                        r: 5.5;
                                    }
                                }
                                @keyframes checkmark {
                                    0% {
                                        stroke-dashoffset: 100;
                                    }
                                    100% {
                                        stroke-dashoffset: 0;
                                    }
                                }
                                .insight-point {
                                    animation: highlight 2s infinite;
                                }
                                .check {
                                    stroke-dasharray: 100;
                                    animation: checkmark 2s ease-out forwards;
                                }
                            </style>
                            <circle
                                cx="50"
                                cy="35"
                                r="20"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                class="check"
                                d="M40,35 L48,43 L60,28"
                                stroke="#1d9395"
                                stroke-width="3"
                                fill="none"
                            />
                            <path
                                d="M30,75 C30,65 70,65 70,75"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <polygon
                                points="50,55 40,75 60,75"
                                fill="#1d9395"
                            />
                            <path
                                d="M20,85 L80,85"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <circle
                                class="insight-point"
                                cx="20"
                                cy="85"
                                r="5"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <circle
                                class="insight-point"
                                cx="50"
                                cy="85"
                                r="5"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <circle
                                class="insight-point"
                                cx="80"
                                cy="85"
                                r="5"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </svg>
                    </div>
                    <h3 class="cs_iconbox_title">
                        Data-Driven Decision Making
                    </h3>
                    <p class="cs_iconbox_subtitle">
                        Make data-driven decisions to optimize manufacturing
                        operations, improve product quality, and reduce waste.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>
<!-- Third section -->

<div class="banner-container">
    <div class="banner-area">
        <div class="slider-container">
            <div
                class="slider-content"
                style="
                    background-image: url('assets/img/OptebizImage/image1_6.jpg');
                "
            >
                <div class="overlay"></div>
                <div class="content-container">
                    <!-- Top Right Content -->
                    <div class="content-wrapper-top">
                        <!-- <span class="small-title">Supply Chain Inefficiencies</span> -->
                        <h2 class="large-title">Inefficient Data Management</h2>
                        <p class="info">
                            Manufacturing organizations often struggle with
                            inefficient data management, leading to data silos,
                            delayed decision-making, and reduced competitiveness
                        </p>
                        <div class="masking-text">
                            <h1>Industry Challenge</h1>
                        </div>
                    </div>

                    <!-- Bottom Left Content -->
                    <div class="content-wrapper-bottom">
                        <!-- <span class="small-title">Data-driven insights</span> -->
                        <h2 class="large-title">Real-Time Data Lake</h2>
                        <p class="info">
                            FactoLake provides a real-time data lake solution,
                            leveraging Apache Iceberg to store, manage, and
                            analyze large volumes of manufacturing data
                        </p>
                        <div class="masking-text">
                            <h1>Our Solution</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="container">
    <div class="m2-afmc-bottom-section">
        <!-- First Row -->
        <div class="m2-afmc-item active" id="bottom-1">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes float {
                            0%,
                            100% {
                                transform: translateY(0);
                            }
                            50% {
                                transform: translateY(-5px);
                            }
                        }
                        @keyframes wave {
                            0% {
                                transform: translateX(-3px);
                            }
                            100% {
                                transform: translateX(3px);
                            }
                        }
                        .iceberg {
                            animation: float 4s ease-in-out infinite;
                        }
                        .water {
                            animation: wave 3s ease-in-out infinite alternate;
                        }
                    </style>
                    <path
                        class="water"
                        d="M0,60 Q25,55 50,60 Q75,65 100,60 L100,100 L0,100 Z"
                        fill="#1d9395"
                        opacity="0.3"
                    />
                    <path
                        class="iceberg"
                        d="M30,60 L25,40 L40,30 L35,20 L50,10 L65,20 L60,30 L75,40 L70,60 Z"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle cx="45" cy="25" r="3" fill="#1d9395" />
                    <circle cx="55" cy="40" r="3" fill="#1d9395" />
                    <path
                        class="iceberg"
                        d="M30,60 Q50,75 70,60"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                        stroke-dasharray="4"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Real-Time Data Ingestion </strong>
                <p>
                    Ingest data from various manufacturing sources in real-time,
                    including process control systems, quality control systems,
                    and sensor data.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-2">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes pulse {
                            0%,
                            100% {
                                opacity: 0.2;
                            }
                            50% {
                                opacity: 1;
                            }
                        }
                        @keyframes rotate {
                            from {
                                transform: rotate(0deg);
                            }
                            to {
                                transform: rotate(360deg);
                            }
                        }
                        .data-pulse {
                            animation: pulse 2s infinite;
                        }
                        .rotate {
                            transform-origin: 50px 50px;
                            animation: rotate 10s linear infinite;
                        }
                    </style>
                    <circle
                        cx="50"
                        cy="50"
                        r="35"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle
                        class="data-pulse"
                        cx="50"
                        cy="50"
                        r="5"
                        fill="#1d9395"
                    />
                    <line
                        class="rotate"
                        x1="50"
                        y1="50"
                        x2="75"
                        y2="35"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <line
                        class="rotate"
                        x1="50"
                        y1="50"
                        x2="35"
                        y2="65"
                        stroke="#1d9395"
                        stroke-width="1.5"
                    />
                    <path
                        d="M20,70 L30,65 L40,75 L50,55 L60,60 L70,45 L80,50"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle
                        class="data-pulse"
                        cx="30"
                        cy="65"
                        r="3"
                        fill="#1d9395"
                    />
                    <circle
                        class="data-pulse"
                        cx="50"
                        cy="55"
                        r="3"
                        fill="#1d9395"
                    />
                    <circle
                        class="data-pulse"
                        cx="70"
                        cy="45"
                        r="3"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Scalable Data Storage </strong>
                <p>
                    Store large volumes of data in a scalable and flexible data
                    architecture, leveraging Apache Iceberg's advanced storage
                    capabilities.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes move {
                            0% {
                                transform: translateX(-30px);
                                opacity: 0.2;
                            }
                            50% {
                                opacity: 1;
                            }
                            100% {
                                transform: translateX(30px);
                                opacity: 0.2;
                            }
                        }
                        @keyframes scan {
                            0% {
                                transform: translateY(-20px);
                                opacity: 0.2;
                            }
                            50% {
                                opacity: 1;
                            }
                            100% {
                                transform: translateY(20px);
                                opacity: 0.2;
                            }
                        }
                        .box-move {
                            animation: move 4s infinite;
                        }
                        .scan-beam {
                            animation: scan 3s infinite alternate;
                        }
                    </style>
                    <path
                        d="M20,30 L80,30 L80,70 L20,70 Z"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <rect
                        class="box-move"
                        x="40"
                        y="45"
                        width="20"
                        height="15"
                        fill="#1d9395"
                        opacity="0.7"
                    />
                    <path
                        d="M10,80 L30,80 L30,70 M70,70 L70,80 L90,80"
                        stroke="#1d9395"
                        stroke-width="2"
                        fill="none"
                    />
                    <line
                        class="scan-beam"
                        x1="10"
                        y1="50"
                        x2="90"
                        y2="50"
                        stroke="#1d9395"
                        stroke-width="1"
                        stroke-dasharray="3"
                        opacity="0.7"
                    />
                    <circle cx="20" cy="80" r="3" fill="#1d9395" />
                    <circle cx="50" cy="80" r="3" fill="#1d9395" />
                    <circle cx="80" cy="80" r="3" fill="#1d9395" />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Streamlined Data Processing </strong>
                <p>
                    Streamline data processing to reduce latency and improve
                    decision-making which includes real-time data ingestion and
                    processing.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Second Row -->
<div class="m2-afmc-bottom-section">
    <div class="m2-afmc-item" id="bottom-4">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes extend {
                        0% {
                            stroke-dashoffset: 200;
                        }
                        100% {
                            stroke-dashoffset: 0;
                        }
                    }
                    @keyframes glow {
                        0%,
                        100% {
                            opacity: 0.4;
                            r: 3;
                        }
                        50% {
                            opacity: 1;
                            r: 5;
                        }
                    }
                    .predict-line {
                        stroke-dasharray: 200;
                        animation: extend 4s linear forwards;
                    }
                    .future-point {
                        animation: glow 2s infinite;
                    }
                </style>
                <path
                    d="M10,70 L20,60 L30,65 L40,50 L50,45"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <path
                    class="predict-line"
                    d="M50,45 L60,30 L70,35 L80,25 L90,20"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-dasharray="4"
                />
                <circle cx="20" cy="60" r="3" fill="#1d9395" />
                <circle cx="40" cy="50" r="3" fill="#1d9395" />
                <circle
                    class="future-point"
                    cx="60"
                    cy="30"
                    r="3"
                    fill="#1d9395"
                />
                <circle
                    class="future-point"
                    cx="80"
                    cy="25"
                    r="3"
                    fill="#1d9395"
                />
                <rect x="10" y="80" width="80" height="1" fill="#1d9395" />
                <text
                    x="20"
                    y="85"
                    font-family="Arial"
                    font-size="8"
                    fill="#1d9395"
                >
                    Past
                </text>
                <text
                    x="70"
                    y="85"
                    font-family="Arial"
                    font-size="8"
                    fill="#1d9395"
                >
                    Future
                </text>
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Actionable Insights </strong>
            <p>
                Gain actionable insights to optimize operations and improve
                decision-making with predictive analytics and machine learning.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-5">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes expand {
                        0% {
                            transform: scale(0.6);
                            opacity: 0.4;
                        }
                        80%,
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    @keyframes rotate {
                        0% {
                            transform: rotate(0deg);
                        }
                        100% {
                            transform: rotate(360deg);
                        }
                    }
                    .data-sphere {
                        animation: expand 3s infinite alternate;
                    }
                    .orbit {
                        transform-origin: 50px 50px;
                        animation: rotate 15s linear infinite;
                    }
                </style>
                <circle
                    class="data-sphere"
                    cx="50"
                    cy="50"
                    r="25"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <circle
                    class="data-sphere"
                    cx="50"
                    cy="50"
                    r="5"
                    fill="#1d9395"
                    opacity="0.8"
                />
                <ellipse
                    class="orbit"
                    cx="50"
                    cy="50"
                    rx="40"
                    ry="15"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="1"
                />
                <circle class="orbit" cx="90" cy="50" r="3" fill="#1d9395" />
                <ellipse
                    class="orbit"
                    cx="50"
                    cy="50"
                    rx="30"
                    ry="35"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="1"
                    transform="rotate(60,50,50)"
                />
                <circle class="orbit" cx="50" cy="15" r="3" fill="#1d9395" />
                <ellipse
                    class="orbit"
                    cx="50"
                    cy="50"
                    rx="35"
                    ry="25"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="1"
                    transform="rotate(120,50,50)"
                />
                <circle class="orbit" cx="22" cy="65" r="3" fill="#1d9395" />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Data Visualization </strong>
            <p>
                Visualize data in real-time, using customizable dashboards and
                reports to quickly identify areas for improvement and track key
                performance indicators (KPIs).
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes appear {
                        0% {
                            opacity: 0;
                            transform: translateY(5px);
                        }
                        100% {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }
                    @keyframes highlight {
                        0%,
                        100% {
                            stroke-width: 1;
                            opacity: 0.6;
                        }
                        50% {
                            stroke-width: 3;
                            opacity: 1;
                        }
                    }
                    .chart-bar {
                        animation: appear 0.5s ease-out forwards;
                    }
                    .chart-bar:nth-child(2) {
                        animation-delay: 0.1s;
                    }
                    .chart-bar:nth-child(3) {
                        animation-delay: 0.2s;
                    }
                    .chart-bar:nth-child(4) {
                        animation-delay: 0.3s;
                    }
                    .report-highlight {
                        animation: highlight 2s infinite;
                    }
                </style>
                <rect
                    x="20"
                    y="20"
                    width="60"
                    height="50"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    rx="3"
                />
                <line
                    x1="20"
                    y1="30"
                    x2="80"
                    y2="30"
                    stroke="#1d9395"
                    stroke-width="1"
                />
                <rect
                    class="chart-bar"
                    x="30"
                    y="55"
                    width="10"
                    height="10"
                    fill="#1d9395"
                    opacity="0.7"
                />
                <rect
                    class="chart-bar"
                    x="45"
                    y="45"
                    width="10"
                    height="20"
                    fill="#1d9395"
                    opacity="0.7"
                />
                <rect
                    class="chart-bar"
                    x="60"
                    y="35"
                    width="10"
                    height="30"
                    fill="#1d9395"
                    opacity="0.7"
                />
                <path
                    class="report-highlight"
                    d="M20,75 L25,75 M35,75 L40,75 M50,75 L55,75 M65,75 L70,75 M80,75 L85,75"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="20"
                    y1="80"
                    x2="80"
                    y2="80"
                    stroke="#1d9395"
                    stroke-width="1"
                />
                <line
                    x1="20"
                    y1="85"
                    x2="80"
                    y2="85"
                    stroke="#1d9395"
                    stroke-width="1"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong>Integration with Existing Systems </strong>
            <p>
                Integrate with existing manufacturing systems, including process
                control systems, quality control systems, and ERP systems.
            </p>
        </div>
    </div>
</div>

<!-- Fifth Section -->
<!-- First Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-1">
        <img
            src="assets/img/OptebizImage/one_predictive maintenance.jpg"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Predictive Maintenance</div>
        <div class="m2-afmc-copy-text">
            <p>
                Predict equipment failures and schedule maintenance proactively,
                reducing downtime and increasing overall equipment
                effectiveness.
            </p>
            <p>This enables manufacturers to:</p>
            <ul>
                <li>
                    Minimize unplanned downtime and reduce maintenance costs
                </li>
                <li>
                    Extend equipment lifespan and improve overall equipment
                    effectiveness
                </li>
                <li>Improve production efficiency and reduce waste</li>
                <li>Enhance maintenance scheduling and planning</li>
            </ul>
        </div>
    </div>
</div>

<!-- Second Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-4">
        <img
            src="assets/img/OptebizImage/FactoLake2Dashboard.jpg"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Real-Time Quality Control</div>
        <div class="m2-afmc-copy-text">
            <p>
                Monitor product quality in real-time, enabling prompt corrective
                action and reducing waste.
            </p>
            <p>This enables manufacturers to:</p>
            <ul>
                <li>Identify and address quality issues promptly</li>
                <li>Reduce scrap rates and improve product quality</li>
                <li>Enhance customer satisfaction and loyalty</li>
                <li>Improve supply chain visibility and collaboration</li>
            </ul>
        </div>
    </div>
</div>

<!-- Third Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/three_factolake.jpg"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Optimized Production Planning</div>
        <div class="m2-afmc-copy-text">
            <p>
                Optimize production planning and scheduling, using real-time
                data and advanced analytics to improve efficiency and reduce
                costs.
            </p>
            <p>This enables manufacturers to:</p>
            <ul>
                <li>Improve production efficiency and reduce waste</li>
                <li>Optimize inventory levels and reduce inventory costs</li>
                <li>Enhance supply chain visibility and collaboration</li>
                <li>Improve production planning and scheduling accuracy</li>
            </ul>
        </div>
    </div>
</div>

<!-- last section -->
<section class="mb-5 mt-5">
    <div class="container">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title cs_text_white">
                        Unlock the Full Potential of Your Manufacturing Data
                    </h2>
                    <p class="cs_section_subtitle mb-0">
                        Discover how FactoLake can transform your manufacturing
                        operations. Contact our team today for a consultation
                        and demo!
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="/BookDemo" class="cs_btn cs_bg_white"
                        >Book A Demo</a
                    >
                    <a href="/TryItNow" class="cs_btn cs_bg_accent"
                        >Try It Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
