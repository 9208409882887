import { Component } from '@angular/core';

@Component({
  selector: 'app-service-details-facto-lake',
  templateUrl: './service-details-facto-lake.component.html',
  styleUrls: ['./service-details-facto-lake.component.scss']
})
export class ServiceDetailsFactoLakeComponent {

}
