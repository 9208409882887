import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-services',
    templateUrl: './homeone-services.component.html',
    styleUrls: ['./homeone-services.component.scss']
})
export class HomeoneServicesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: "Our History",
            title: 'Partners in your journey through',
            paragraphText: 'We are more than partners—we are committed allies in your journey to operational excellence, leveraging our expertise to turn your vision into reality'
        }
    ]
   

}
class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}
