<div class="banner-area">
    <div class="slider-container">
        <div
            class="slider-content"
            style="
                background-image: url('assets/img/OptebizImage/LandingImg2.jpg');
            "
        >
            <div class="overlay"></div>
            <div class="content-wrapper">
                <!-- <span class="small-title">Advanced Control Systems</span> -->
                <h2 class="large-title">
                    Ignition SCADA Solutions for Modern Industry
                </h2>
                <p class="info">
                    Gain real-time insights, optimize predictive maintenance,
                    and integrate seamlessly—power industrial automation with
                    SCADA technology
                </p>
                <div class="buttons">
                    <a href="/TryItNow" class="btn btn-primary"
                        >Start Your Free Trial</a
                    >
                </div>
            </div>
            <!-- <div class="masking-text">
                <h1>SCADA Systems</h1>
            </div> -->
        </div>
    </div>
</div>

<!-- Section section -->

<div class="ia-section">
    <div class="ia-container">
        <div class="ia-header">
            <h1>CERTIFIED BY INDUCTIVE <br />AUTOMATION</h1>
        </div>

        <div class="ia-content">
            <div class="ia-left-section">
                <img
                    src="assets/img/OptebizImage/GoldCertified.svg"
                    alt="Ignition Logo"
                    class="ia-logo"
                />

                <div class="ia-description">
                    <h2>
                        Seamless SCADA integration for real-time monitoring and
                        industrial automation
                    </h2>
                </div>

                <div class="ia-features">
                    <div class="ia-feature">
                        <div class="ia-feature-icon">✓</div>
                        <div class="ia-feature-text">
                            <strong>Certified SCADA Experts –</strong> Trusted
                            by manufacturing, energy & utility industries.
                        </div>
                    </div>

                    <div class="ia-feature">
                        <div class="ia-feature-icon">✓</div>
                        <div class="ia-feature-text">
                            <strong>Tailored Automation –</strong> Custom-built
                            solutions for industrial efficiency.
                        </div>
                    </div>
                </div>

                <!-- <a href="#" class="ia-cta-button">Try Free Trial</a> -->
            </div>

            <div class="ia-right-section">
                <img
                    src="assets/img/OptebizImage/mobile-responsive.png"
                    alt="SCADA Dashboard"
                    class="ia-dashboard-image"
                />
                <p class="ia-caption">
                    Inductive Automation by Ignition<br />A Single Tool for All
                    Digital Transformation Solutions
                </p>
            </div>
        </div>
    </div>
</div>

<!-- third section -->
<div class="scada-container">
    <div class="scada-content">
        <h1 class="scada-main-heading">
            SCADA CONTROL<br />
            MAKE <span class="scada-smarter">SMARTER</span>,<br />
            <span class="scada-faster">FASTER</span> DECISIONS
        </h1>

        <p class="scada-subheading">
            Real-time monitoring,<br />
            predictive maintenance,<br />
            and compliance –<br />
            <span class="scada-platform-text">All in one platform</span>
        </p>

        <div class="scada-feature">
            <p class="scada-feature-title">
                Live SCADA Dashboards – Full visibility
            </p>
            <p class="scada-feature-title">into operations & control.</p>
        </div>

        <div class="scada-feature">
            <p class="scada-feature-title">
                Predictive Analytics – Prevent failures
            </p>
            <p class="scada-feature-title">before they happen.</p>
        </div>

        <div class="scada-feature">
            <p class="scada-feature-title">Regulatory Compliance – Automate</p>
            <p class="scada-feature-title">reporting & traceability.</p>
        </div>
    </div>

    <div class="scada-image-container">
        <img
            src="assets/img/OptebizImage/ScadaImage.png"
            alt="SCADA Dashboard Interface"
        />
    </div>
</div>

<!-- Fourth section -->
<!-- services.component.html -->
<div class="services-container">
    <div class="services-header">
        <h1>CUSTOMIZED SOLUTIONS<br />BASED ON YOUR NEEDS</h1>

        <div class="services-tabs">
            <div
                *ngFor="let service of services"
                class="service-tab"
                [class.active]="selectedService === service.name"
                (click)="selectService(service.name)"
            >
                {{ service.name }}
            </div>
        </div>
    </div>

    <div class="services-content">
        <div class="service-info" *ngIf="getSelectedService()">
            <div class="service-header">
                <h2>{{ getSelectedService()?.title }}</h2>
                <p>{{ getSelectedService()?.subtitle }}</p>
            </div>

            <div class="service-details">
                <!-- Conditionally display different title for Add-On-Service -->
                <div class="target-audience">
                    <h3 *ngIf="selectedService !== 'Add-On-Service'">
                        TARGET AUDIENCE
                    </h3>
                    <h3 *ngIf="selectedService === 'Add-On-Service'">
                        Comprehensive Support Solutions
                    </h3>
                    <p>{{ getSelectedService()?.target }}</p>
                </div>

                <div class="two-column-layout">
                    <div class="services-offered">
                        <!-- Conditionally display different title for Add-On-Service -->
                        <h3 *ngIf="selectedService !== 'Add-On-Service'">
                            SERVICES OFFERED
                        </h3>
                        <h3 *ngIf="selectedService === 'Add-On-Service'">
                            Core Features
                        </h3>
                        <ul>
                            <li
                                *ngFor="
                                    let item of getSelectedService()?.services
                                "
                            >
                                <span class="checkmark">✓</span> {{ item }}
                            </li>
                        </ul>
                    </div>

                    <div class="key-features">
                        <!-- Conditionally display different title for Add-On-Service -->
                        <h3 *ngIf="selectedService !== 'Add-On-Service'">
                            KEY APP FEATURES
                        </h3>
                        <h3 *ngIf="selectedService === 'Add-On-Service'">
                            Technical Services
                        </h3>
                        <ul>
                            <li
                                *ngFor="
                                    let feature of getSelectedService()
                                        ?.features
                                "
                            >
                                <span class="checkmark">✓</span> {{ feature }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="learn-more-container">
        <a href="/Basic" class="learn-more-btn">Learn More</a>
    </div>
</div>

<!-- fourth section -->
<div class="m2-fixed-container" style="max-width: 1400px">
    <div class="carousel-section">
        <div class="carousel-section-header">
            <h2>
                Unlock Operational Excellence with
                <strong
                    ><span>Lean</span><span>Q</span><span>ubit</span></strong
                >
            </h2>
        </div>
        <div class="slides-container">
            <div
                *ngFor="let slide of slides; let i = index"
                class="slide"
                [class.active]="currentSlideIndex === i"
                [id]="slide.id"
            >
                <div class="content">
                    <!-- <span class="step-counter">{{ slide.stepCounter }}</span> -->
                    <h5 [innerHTML]="slide.title"></h5>
                    <p>{{ slide.description }}</p>
                </div>
                <div class="lottie-container" [id]="'animation-' + (i + 1)">
                    <div class="image-container">
                        <ng-container *ngIf="slide.mediaType === 'video'">
                            <video
                                #videoPlayer
                                [src]="slide.mediaSource"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                                [muted]="true"
                                preload="auto"
                                loop
                            >
                                Your browser does not support the video tag.
                            </video>
                        </ng-container>
                        <ng-container *ngIf="slide.mediaType === 'image'">
                            <img
                                [src]="slide.mediaSource"
                                alt="Description"
                                style="
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                "
                            />
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="navigation-arrows progress-bars">
            <button
                class="nav-arrow prev"
                [disabled]="currentSlideIndex === 0"
                (click)="prevSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M14.5 17L9.5 12L14.5 7"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
            <div class="progress-bar-inner">
                <div
                    *ngFor="let slide of slides; let i = index"
                    class="progress-bar"
                >
                    <div
                        class="progress-bar-fill"
                        [style.width.%]="getProgressWidth(i)"
                    ></div>
                </div>
            </div>
            <button
                class="nav-arrow next"
                [disabled]="currentSlideIndex === slides.length - 1"
                (click)="nextSlide()"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="800px"
                    height="800px"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M9.5 7L14.5 12L9.5 17"
                        stroke="#FFFFFF"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    ></path>
                </svg>
            </button>
        </div>
    </div>
</div>

<!-- fifth section -->
<div class="methodology-section">
    <div class="methodology-container">
        <div class="methodology-title">
            <h2>OUR METHODOLOGY <br />IN IGNITION</h2>
        </div>
        <div class="methodology-content">
            <div class="methodology-text-box">
                <p>
                    With
                    <span class="brand-name"
                        >Lean<span class="brand-highlight">Q</span>ubit</span
                    >'s Ignition SCADA solutions, easily integrate any PLC or
                    industrial controller, enabling real-time monitoring,
                    automated alerts, custom reports, and dynamic HMIs.
                </p>
                <p>
                    Gain full operational visibility from anywhere, keeping your
                    industrial processes optimized, secure, and always
                    connected.
                </p>
            </div>
            <div class="methodology-image">
                <img
                    src="assets/img/OptebizImage/FifthsecionImage.jpg"
                    alt="Engineers monitoring industrial equipment with tablet"
                />
            </div>
        </div>
    </div>
</div>

<!-- sixth section -->

<div class="metrics-section">
    <div class="metrics-container">
        <div class="metrics-header">
            <h2 class="metrics-title">
                <span class="lean-text">Lean</span
                ><span class="qubit-text">Q</span
                ><span class="lean-text">ubit</span> VALUES TO<br />
                YOUR BUSINESS
            </h2>
        </div>

        <div class="metrics-grid">
            <div class="metric-box">
                <div class="metric-value">8<span class="percent">%</span></div>
                <div class="metric-label">
                    <span class="icon">📊</span>
                    Quality Improvement
                </div>
            </div>

            <div class="metric-box">
                <div class="metric-value">20<span class="percent">%</span></div>
                <div class="metric-label">
                    <span class="icon">🚚</span>
                    Faster Delivery
                </div>
            </div>

            <div class="metric-box">
                <div class="metric-value">15<span class="percent">%</span></div>
                <div class="metric-label">
                    <span class="icon">💰</span>
                    Costs Cutting
                </div>
            </div>

            <div class="metric-box">
                <div class="metric-value">15<span class="percent">%</span></div>
                <div class="metric-label">
                    <span class="icon">⏱️</span>
                    Maximize Uptime
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Last esction -->

<!-- contact-->
<div class="contact-section">
    <div class="container">
        <h1>GET IN TOUCH WITH US</h1>
        <p class="subtitle">
            Fill out the form below to get in touch with our team. We'll respond
            promptly to discuss your specific needs and challenges.
        </p>

        <div class="form-container">
            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-field">
                    <input
                        type="text"
                        placeholder="First Name"
                        formControlName="firstName"
                        [ngClass]="{
                            invalid:
                                contactForm.get('firstName')?.invalid &&
                                contactForm.get('firstName')?.touched
                        }"
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('firstName')?.invalid &&
                            contactForm.get('firstName')?.touched
                        "
                    >
                        First name is required
                    </div>
                </div>

                <div class="form-field">
                    <input
                        type="text"
                        placeholder="Last Name"
                        formControlName="lastName"
                        [ngClass]="{
                            invalid:
                                contactForm.get('lastName')?.invalid &&
                                contactForm.get('lastName')?.touched
                        }"
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('lastName')?.invalid &&
                            contactForm.get('lastName')?.touched
                        "
                    >
                        Last name is required
                    </div>
                </div>

                <div class="form-field">
                    <input
                        type="email"
                        placeholder="Email Address"
                        formControlName="email"
                        [ngClass]="{
                            invalid:
                                contactForm.get('email')?.invalid &&
                                contactForm.get('email')?.touched
                        }"
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('email')?.invalid &&
                            contactForm.get('email')?.touched
                        "
                    >
                        Please enter a valid email address
                    </div>
                </div>

                <div class="form-field">
                    <input
                        type="tel"
                        placeholder="Phone Number"
                        formControlName="phone"
                        [ngClass]="{
                            invalid:
                                contactForm.get('phone')?.invalid &&
                                contactForm.get('phone')?.touched
                        }"
                    />
                    <div
                        class="error-message"
                        *ngIf="
                            contactForm.get('phone')?.invalid &&
                            contactForm.get('phone')?.touched
                        "
                    >
                        Phone number is required
                    </div>
                </div>

                <button
                    type="submit"
                    class="submit-btn"
                    [disabled]="isSubmitting"
                >
                    {{ isSubmitting ? "SUBMITTING..." : "SUBMIT" }}
                </button>

                <div class="success-message" *ngIf="submitSuccess">
                    Thank you! Your message has been sent successfully.
                </div>

                <div class="error-submit-message" *ngIf="submitError">
                    Sorry, there was a problem sending your message. Please try
                    again later.
                </div>
            </form>
        </div>
    </div>
</div>
