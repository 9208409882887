<div class="banner-area">
    <div class="banner-area-style3">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6" [@imageChange]="currentIndex">
                    <div class="style3-banner-image">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 p-0">
                                <div class="col-lg-12 p-0 col-md-12">
                                    <img
                                        [src]="
                                            content[currentIndex].images.grid1
                                        "
                                        class="banner-grid1"
                                        alt="image"
                                    />
                                </div>
                                <div class="col-lg-12 p-0 col-md-12">
                                    <div class="grid-image">
                                        <img
                                            [src]="
                                                content[currentIndex].images
                                                    .grid2
                                            "
                                            class="banner-grid2"
                                            alt="image"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8">
                                <div class="banner3-big">
                                    <img
                                        [src]="
                                            content[currentIndex].images.grid3
                                        "
                                        alt="image"
                                    />
                                    <div class="video-btn">
                                        <div
                                            class="popup-youtube"
                                            (click)="playVideo()"
                                        >
                                            <i class="flaticon-play"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div
                        class="banner-content banner-content-style3"
                        [@contentChange]="currentIndex"
                    >
                        <p class="title-tag">
                            <svg
                                class="dot-icon"
                                viewBox="0 0 100 100"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="50" cy="50" r="50" />
                            </svg>
                            LeanQubit AI for Solution
                        </p>
                        <h1 [@textColorChange]="colorState">
                            {{ content[currentIndex].heading }}
                        </h1>
                        <p class="banner-p">
                            {{ content[currentIndex].subheading }}
                        </p>
                        <div class="banner-btn d-flex">
                            <a href="/vnet/contact" class="default-btn"
                                >Get In Touch</a
                            >
                            <a
                                href="/vnet/signup"
                                class="default-btn default-btn-style2"
                                >Sign Up Free</a
                            >
                        </div>
                        <p class="link-p2">
                            <i class="bx bxs-star"></i> Rated 4.9/5 from over
                            600 reviews.
                        </p>
                        <div class="image-with-video">
                            <div class="image-video">
                                <img
                                    src="assets/img/OptebizImage/banner-video1.03828b92.webp"
                                    alt="image"
                                />
                                <div
                                    class="popup-youtube"
                                    (click)="playVideo()"
                                >
                                    <div class="icon-play">
                                        <i class="flaticon-play-1"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Video Modal -->
        <div *ngIf="showVideo" class="video-modal">
            <div class="video-container">
                <button class="close-btn" (click)="closeVideo()">
                    &times;
                </button>
                <video controls autoplay>
                    <source [src]="videoUrl" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
    </div>
</div>
