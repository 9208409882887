<!-- First section -->
<section class="hero-section">
    <div class="hero-content">
        <h1 class="hero-title">
            Amazon Web Service<br />Integrated Cloud MES Integration
        </h1>
        <p class="hero-text">
            LeanQubit Facto Tools on AWS provide a scalable, secure, and
            efficient cloud-based MES solution for businesses
        </p>
        <div class="button-group">
            <a href="TryItNow" class="btn btn-primary">TRY IT NOW</a>
            <a href="BookDemo" class="btn btn-secondary">SCHEDULE DEMO</a>
        </div>
    </div>
</section>

<!-- Second section -->

<section class="services-section">
    <div class="services-container">
        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path
                        d="M21 7v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14"
                    />
                    <path d="M8 14h8" />
                    <path d="M8 10h8" />
                    <path d="M8 18h5" />
                    <circle cx="17" cy="4" r="3" />
                </svg>
            </div>
            <h3 class="service-title">Collect Data</h3>
            <p class="service-text">
                We analyze your current operations and systems involved and any
                previous data-related work.
            </p>
        </div>

        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <rect x="4" y="4" width="16" height="16" rx="2" />
                    <path d="M4 10h16" />
                    <path d="M10 4v16" />
                    <circle cx="7" cy="7" r="1" />
                    <circle cx="13" cy="7" r="1" />
                    <circle cx="19" cy="7" r="1" />
                </svg>
            </div>
            <h3 class="service-title">Organize Data</h3>
            <p class="service-text">
                Find the areas that need help the most – filling gaps present
                from previous solutions, or assessing a solution from scratch.
            </p>
        </div>

        <div class="service-card">
            <div class="service-icon">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path d="M3 3v18h18" />
                    <path d="M7 17l4-8 4 4 4-10" />
                    <circle cx="7" cy="17" r="1" />
                    <circle cx="11" cy="9" r="1" />
                    <circle cx="15" cy="13" r="1" />
                    <circle cx="19" cy="7" r="1" />
                </svg>
            </div>
            <h3 class="service-title">Analyze Data</h3>
            <p class="service-text">
                Work with your team to implement our solutions, and guide you
                through the system – ensuring the product is built around you,
                for you.
            </p>
        </div>
    </div>
</section>

<!-- Third section -->
<div class="container">
    <div class="header">
        <h1>
            <span
                style="
                    color: #1d9395;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >Lean</span
            ><span
                style="
                    color: #da5c30;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >Q</span
            ><span
                style="
                    color: #1d9395;
                    font-family: 'Bahnschrift Condensed', sans-serif;
                    text-transform: none;
                "
                >ubit</span
            >
            Cloud Solutions Financial Efficiency
        </h1>
        <p>
            LeanQubit’s AWS cloud solutions enhance efficiency, cut costs, and
            boost productivity through seamless integration and optimized
            resource utilization.
        </p>
    </div>

    <div class="card-grid">
        <div class="card">
            <div class="card-icon">
                <div class="card-icon primary-clr">
                    <i class="bx bx-sitemap blue-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">01</div>
            <h3>AWS IoT SiteWise</h3>
            <p>
                AWS IoT Sitewise unlocks data from industrial equipment to
                deliver an organized view of live and historical operational
                insights
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon blue-clr">
                    <i class="fa fa-bar-chart primary-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">02</div>
            <h3>AWS IoT Greengrass</h3>
            <p>
                It allows devices to gather and analyze data locally, respond
                autonomously to nearby events, and communicate securely within
                local networks
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon green-clr">
                    <i class="fa fa-user-secret green-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">03</div>
            <h3>Security & Policy</h3>
            <p>
                Set up and manage device and data security, and oversee
                authentication and authorization to control access to components
                and services in your AWS IoT solution
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon blue-clr">
                    <i class="fa fa-database primary-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">04</div>
            <h3>Amazon S3 Buckets</h3>
            <p>
                Amazon S3 offers management features to optimize, organize, and
                configure data access according to your specific business,
                organizational, and compliance needs
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon green-clr">
                    <i class="bx bxl-google-cloud green-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">05</div>
            <h3>MQTT with AWS</h3>
            <p>
                MQTT (Message Queuing Telemetry Transport) in AWS IoT Core
                supports device connections using MQTT and MQTT over WSS
                protocols, identified by a client ID
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon primary-clr">
                    <i class="fa fa-commenting blue-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">06</div>
            <h3>Message Routing</h3>
            <p>
                Message routing in Amazon Pinpoint is determined by type
                (promotional or transactional). You specify the route using the
                optional parameter in the SendMessages operation of the API
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon green-clr">
                    <i class="fa fa-low-vision blue-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">07</div>
            <h3>Amazon Lookout for Vision</h3>
            <p>
                Spot product defects using computer vision to automate quality
                inspection A machine learning service that uses computer vision
                to automate visual inspection of product defects
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon primary-clr">
                    <i class="fa fa-line-chart primary-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">08</div>
            <h3>Amazon Lookout for Metrics</h3>
            <p>
                Effortlessly spot data anomalies using a machine learning
                service that continuously analyzes your business data with
                Amazon's technology, requiring no machine learning expertise
            </p>
        </div>

        <div class="card">
            <div class="card-icon">
                <div class="card-icon blue-clr">
                    <i class="fa fa-wrench green-clr-bg"></i>
                </div>
            </div>
            <div class="card-number">09</div>
            <h3>Amazon Lookout for Equipment</h3>
            <p>
                Machine learning Amazon Lookout for Equipment Detect abnormal
                equipment behavior by analyzing sensor data
            </p>
        </div>
    </div>
</div>

<!-- fourth section -->

<section class="industry-section">
    <div class="content-wrapper">
        <div class="text-content">
            <div class="header-with-logo">
                <p class="subtitle">Certified by Amazon Web Service</p>
                <img
                    src="assets\img\OptebizImage\aws-qualifed-software.png"
                    alt="AWS Logo"
                    class="ignition-logo"
                />
            </div>
            <h2 class="title">
                Implementing Standard &
                <span class="highlight">Custom Solutions</span>
            </h2>
            <p class="description">
                LeanQubit is a Certified Integrator on AWS, providing expert
                cloud-based solutions tailored to your business need.
            </p>
            <ul class="features">
                <li>Certified AWS Integrator</li>
                <li>Expert in cloud-based solutions</li>
                <li>Specialized in manufacturing, energy, and utilities</li>
                <li>Custom solutions for businesses of all sizes</li>
                <li>Enhancing efficiency and scalability</li>
            </ul>
        </div>
        <div class="image-content">
            <img
                src="assets\img\OptebizImage\aws flow Updated latest.svg"
                alt="Dashboard on devices"
                class="dashboard-image"
            />
        </div>
    </div>
</section>

<!-- Fifth section -->

<section class="testimonial-section">
    <div class="section-header">
        <span class="section-subtitle"
            >Walk into Future with
            <span style="color: #1d9395">Confidence</span>
        </span>
        <h2 class="section-title">
            LeanQubit AWS Solutions provide cutting-edge technology and
            scalability, helping businesses optimize operations and stay ahead
            in a dynamic industry.
        </h2>
    </div>

    <div class="testimonial-grid">
        <div
            class="testimonial-card"
            *ngFor="let testimonial of testimonials; trackBy: trackByFn"
        >
            <div class="client-info">
                <div class="client-image">
                    <img
                        [src]="testimonial.image"
                        [alt]="testimonial.name"
                        (error)="
                            $event.target.src = 'assets/img/fallback-image.png'
                        "
                    />
                </div>
                <div class="client-details">
                    <h4>{{ testimonial.name }}</h4>
                    <p>{{ testimonial.company }}</p>
                </div>
            </div>
            <p class="testimonial-text">{{ testimonial.text }}</p>
        </div>
    </div>
</section>

<!-- Last Section -->
<section class="methodology-section">
    <div class="container">
        <div class="methodology-grid">
            <!-- Left Image Side (Swapped position) -->
            <div class="image-side">
                <div class="image-wrapper">
                    <img
                        src="assets\img\OptebizImage\aws last Flow2.svg"
                        alt="Ignition Methodology Diagram"
                        class="main-image"
                    />
                    <!-- <div class="floating-card">
                        <div class="card-stat">
                            <span class="number">100%</span>
                            <span class="label">Certified Solutions</span>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- Right Content Side (Swapped position) -->
            <div class="content-side">
                <div class="header-content">
                    <div class="badge-wrapper">
                        <span class="badge">AWS Certified</span>
                        <div class="badge-line"></div>
                    </div>
                    <h1>
                        IOT Architecture on
                        <span style="color: #1d9395">AWS</span>
                    </h1>
                    <p class="intro-text">
                        LeanQubit is a Certified AWS Integrator, delivering
                        expert cloud-based SaaS solutions tailored to your
                        business need.
                    </p>
                </div>

                <div class="features-list">
                    <ul>
                        <li>
                            Expertise in manufacturing, energy, and utility
                            industries
                        </li>
                        <li>
                            Experience in building custom solutions for
                            businesses of all sizes
                        </li>
                        <li>
                            Certified in the latest AWS software and
                            technologies
                        </li>
                        <li>
                            Tailored cloud-based solutions to optimize
                            operations
                        </li>
                        <li>
                            Commitment to innovation and industry best practices
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
