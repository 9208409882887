import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';


@Component({
  selector: 'app-facto-tool-kit',
  templateUrl: './facto-tool-kit.component.html',
  styleUrls: ['./facto-tool-kit.component.scss']
})
export class FactoToolKitComponent implements OnInit, AfterViewInit {

  Operational: number = 0;
  Clients: number = 0;
  Savings: number = 0;

  private readonly OPERATIONAL_TARGET = 24;
  private readonly CLIENTS_TARGET = 35;
  private readonly SAVINGS_TARGET = 70;

  private observer: IntersectionObserver | null = null;

  constructor(private ngZone: NgZone, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    // Initialize other component properties here
  }

  ngAfterViewInit(): void {
    this.setupIntersectionObserver();
  }

  private setupIntersectionObserver(): void {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5
    };

    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.ngZone.run(() => {
            this.resetCounters();
            this.startCounting();
          });
        }
      });
    }, options);

    const factSection = document.querySelector('.fact-section');
    if (factSection) {
      this.observer.observe(factSection);
    }
  }

  private resetCounters(): void {
    this.Operational = 0;
    this.Clients = 0;
    this.Savings = 0;
    this.cdr.detectChanges();
  }

  private startCounting(): void {
    this.animateValue('Operational', 0, this.OPERATIONAL_TARGET, 1500);
    this.animateValue('Clients', 0, this.CLIENTS_TARGET, 1500);
    this.animateValue('Savings', 0, this.SAVINGS_TARGET, 1500);
  }

  private animateValue(property: string, start: number, end: number, duration: number): void {
    let startTimestamp: number | null = null;
    const step = (timestamp: number) => {
      if (!startTimestamp) startTimestamp = timestamp;
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);
      const value = Math.floor(progress * (end - start) + start);
      
      this.ngZone.run(() => {
        this[property] = value;
        this.cdr.detectChanges();
      });

      if (progress < 1) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  }

// 
sectionTitle: sectionTitleContent[] = [
  {
      subtitle:"What Our Facto-Tools Accomplish",
      title: 'Business with Best in Class Solutions',
      paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach.'
  }
]
singleServicesBoxItem: ServicesBoxContent[] = [
  {
      icon: 'assets/img/OptebizImage/Asset 6@36x.png',
      title: 'FactoIQ',
      paragraphText: ' Collect Realtime timeseries data in a timeseries database and analyze the shop floor process, maintenance, manufacturing and operations data using statistical and AI methodologies',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoIQ'
  },
  {
      icon: 'assets/img/OptebizImage/FactoMES.svg',
      title: 'FactoMes',
      paragraphText: 'Delivering operational excellence with our ISA-95 compliant FactoMes Solution a cutting-edge platform designed to elevate manufacturing processes and ensure seamless integration',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoMES'
  },
  {
      icon: 'assets/img/OptebizImage/Asset 4@36x.png',
      title: 'FactoCloudConnect',
      paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shopfloor and machine control system into various cloud environments and Big Data Platforms',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoCloud'
  },
  
]


}
class sectionTitleContent {
  subtitle:string;
  title : string;
  paragraphText : string;
  }
  class ServicesBoxContent {
  icon : string;
  title : string;
  paragraphText : string;
  linkText : string;
  linkIcon : string;
  link : string;
  }