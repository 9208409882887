<section class="cs_hero cs_style_2 position-relative" id="home">
    <div class="container">
        <div class="cs_product_title">
            <h1><span>FactoMES</span> Transforms Production</h1>
        </div>
        <div class="row align-items-center cs_gap_y_40 cs_reverse_md">
            <div class="col-lg-7">
                <div class="cs_hero_text">
                    <h1 class="cs_hero_title">
                        <!-- <div class="main-title">
                            Transform Your Production with FactoMES
                        </div> -->
                        <div class="subtitle">Seamless & Smart Execution</div>
                    </h1>
                    <p class="cs_hero_subtitle">
                        Empowering Your Shop Floor with Real-Time Monitoring,
                        Analytics, and Control.
                    </p>
                    <div
                        class="cs_btn_group cs_version_2 wow fadeIn"
                        data-wow-duration="0.8s"
                        data-wow-delay="0.25s"
                    >
                        <a
                            href="/BookDemo"
                            class="cs_btn cs_bg_white cs_dark_hover"
                            >Product Demo</a
                        >
                        <a
                            href="/TryItNow"
                            class="cs_btn cs_bg_accent cs_dark_hover"
                            >Start Free Trial</a
                        >
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="cs_hero_thumb">
                    <video
                        id="videoPlayer"
                        autoplay
                        loop
                        muted
                        playsinline
                        style="max-width: 100%; height: auto"
                    >
                        <source
                            src="assets/img/OptebizImage/processflow1.mp4"
                            type="video/mp4"
                        />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_hero_shape1"></div>
    <div class="cs_hero_shape2">
        <img src="assets/img/OptebizImage/Polygon.svg" alt="Polygon Icon" />
    </div>
    <div class="cs_hero_shape3"></div>
</section>
<!-- Second section -->
<section class="cs_business_feature position-relative" id="features">
    <div class="container">
        <div class="cs_height_85 cs_height_lg_60"></div>
        <div
            class="row cs_gap_y_30 wow fadeIn"
            data-wow-duration="0.8s"
            data-wow-delay="0.2s"
        >
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes moveLine {
                                0% {
                                    transform: translateX(-100%);
                                }
                                100% {
                                    transform: translateX(100%);
                                }
                            }
                            @keyframes pulse {
                                0% {
                                    transform: scale(1);
                                }
                                50% {
                                    transform: scale(1.1);
                                }
                                100% {
                                    transform: scale(1);
                                }
                            }
                            .conveyor {
                                animation: moveLine 3s linear infinite;
                            }
                            .status {
                                animation: pulse 2s infinite;
                            }
                        </style>
                        <rect
                            x="10"
                            y="40"
                            width="80"
                            height="20"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <g class="conveyor">
                            <rect
                                x="20"
                                y="45"
                                width="10"
                                height="10"
                                fill="#1d9395"
                            />
                            <rect
                                x="40"
                                y="45"
                                width="10"
                                height="10"
                                fill="#1d9395"
                            />
                            <rect
                                x="60"
                                y="45"
                                width="10"
                                height="10"
                                fill="#1d9395"
                            />
                        </g>
                        <circle
                            class="status"
                            cx="85"
                            cy="20"
                            r="5"
                            fill="#1d9395"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Live Production Tracking</h3>
                    <p class="cs_iconbox_subtitle">
                        Track your manufacturing processes in real-time,
                        ensuring transparency and efficiency across all
                        operations
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes dataFlow {
                                0% {
                                    stroke-dashoffset: 1000;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes rotate {
                                from {
                                    transform: rotate(0deg);
                                }
                                to {
                                    transform: rotate(360deg);
                                }
                            }
                            .flow {
                                stroke-dasharray: 1000;
                                animation: dataFlow 3s linear infinite;
                            }
                            .systems {
                                animation: rotate 8s linear infinite;
                            }
                        </style>
                        <g class="systems" transform-origin="50 50">
                            <rect
                                x="20"
                                y="20"
                                width="25"
                                height="25"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <rect
                                x="55"
                                y="55"
                                width="25"
                                height="25"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                        </g>
                        <path
                            class="flow"
                            d="M45 45 C50 50, 45 55, 55 55"
                            stroke="#1d9395"
                            stroke-width="2"
                            fill="none"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">
                        Seamless MES & ERP Integration
                    </h3>
                    <p class="cs_iconbox_subtitle">
                        Easily connect FactoMES with your existing Manufacturing
                        Execution System and Enterprise Resource Planning
                        software for a unified workflow.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 100 100"
                    >
                        <style>
                            @keyframes process {
                                0% {
                                    stroke-dashoffset: 1000;
                                }
                                100% {
                                    stroke-dashoffset: 0;
                                }
                            }
                            @keyframes gearSpin {
                                from {
                                    transform: rotate(0deg);
                                }
                                to {
                                    transform: rotate(360deg);
                                }
                            }
                            .flow-path {
                                stroke-dasharray: 1000;
                                animation: process 4s linear infinite;
                            }
                            .gear {
                                animation: gearSpin 4s linear infinite;
                            }
                        </style>
                        <g class="gear" transform-origin="50 50">
                            <circle
                                cx="50"
                                cy="50"
                                r="30"
                                fill="none"
                                stroke="#1d9395"
                                stroke-width="2"
                            />
                            <path
                                d="M50 20 L53 20 L55 25 L45 25 L47 20Z"
                                fill="#1d9395"
                            />
                            <path
                                d="M50 80 L53 80 L55 75 L45 75 L47 80Z"
                                fill="#1d9395"
                            />
                            <path
                                d="M20 50 L20 53 L25 55 L25 45 L20 47Z"
                                fill="#1d9395"
                            />
                            <path
                                d="M80 50 L80 53 L75 55 L75 45 L80 47Z"
                                fill="#1d9395"
                            />
                        </g>
                        <path
                            class="flow-path"
                            d="M20 20 L80 20 L80 80 L20 80 Z"
                            fill="none"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                    </svg>
                    <h3 class="cs_iconbox_title">Workflow Automation</h3>
                    <p class="cs_iconbox_subtitle">
                        Improve productivity with AI-driven automation that
                        optimizes resource allocation and reduces downtime.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="cs_iconbox cs_style_1">
                    <div class="cs_iconbox_icon">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 100 100"
                        >
                            <style>
                                @keyframes highlight {
                                    0%,
                                    100% {
                                        opacity: 0.3;
                                    }
                                    50% {
                                        opacity: 1;
                                    }
                                }
                                @keyframes grow {
                                    0% {
                                        height: 10px;
                                    }
                                    100% {
                                        height: 40px;
                                    }
                                }
                                .data-point {
                                    animation: highlight 2s infinite;
                                }
                                .bar {
                                    animation: grow 3s infinite alternate;
                                }
                            </style>
                            <rect
                                class="bar"
                                x="20"
                                y="60"
                                width="10"
                                height="30"
                                fill="#1d9395"
                            />
                            <rect
                                class="bar"
                                x="45"
                                y="60"
                                width="10"
                                height="30"
                                fill="#1d9395"
                                style="animation-delay: 0.5s"
                            />
                            <rect
                                class="bar"
                                x="70"
                                y="60"
                                width="10"
                                height="30"
                                fill="#1d9395"
                                style="animation-delay: 1s"
                            />
                            <circle
                                class="data-point"
                                cx="25"
                                cy="25"
                                r="5"
                                fill="#1d9395"
                            />
                            <circle
                                class="data-point"
                                cx="50"
                                cy="25"
                                r="5"
                                fill="#1d9395"
                                style="animation-delay: 0.3s"
                            />
                            <circle
                                class="data-point"
                                cx="75"
                                cy="25"
                                r="5"
                                fill="#1d9395"
                                style="animation-delay: 0.6s"
                            />
                        </svg>
                    </div>
                    <h3 class="cs_iconbox_title">
                        Data-Driven Decision Making
                    </h3>
                    <p class="cs_iconbox_subtitle">
                        Gain actionable insights with advanced analytics,
                        helping you make informed decisions to enhance
                        manufacturing efficiency.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="cs_height_150 cs_height_lg_80"></div>
</section>
<!-- Third section -->

<div class="banner-container">
    <div class="banner-area">
        <div class="slider-container">
            <div
                class="slider-content"
                style="
                    background-image: url('assets/img/OptebizImage/image1_5.jpg');
                "
            >
                <div class="overlay"></div>
                <div class="content-container">
                    <!-- Top Right Content -->
                    <div class="content-wrapper-top">
                        <h2 class="large-title">Manufacturing Challenges</h2>
                        <p class="info">
                            Manufacturers face inefficiencies from disjointed
                            systems, lack of real-time data, and manual
                            processes, causing downtime, waste, and lower
                            productivity
                        </p>
                        <div class="masking-text">
                            <h1>Industry Challenge</h1>
                        </div>
                    </div>

                    <!-- Bottom Left Content -->
                    <div class="content-wrapper-bottom">
                        <h2 class="large-title">
                            Unified Manufacturing Execution
                        </h2>
                        <p class="info">
                            FactoMES unifies shop-floor data with real-time
                            monitoring, analytics, and automation to optimize
                            manufacturing and eliminate inefficiencies.
                        </p>
                        <div class="masking-text">
                            <h1>Our Solution</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="container">
    <div class="m2-afmc-bottom-section">
        <!-- First Row -->
        <div class="m2-afmc-item active" id="bottom-1">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes check {
                            0% {
                                stroke-dashoffset: 1000;
                            }
                            100% {
                                stroke-dashoffset: 0;
                            }
                        }
                        @keyframes slide {
                            0% {
                                transform: translateY(-20px);
                                opacity: 0;
                            }
                            100% {
                                transform: translateY(0);
                                opacity: 1;
                            }
                        }
                        .checkmark {
                            stroke-dasharray: 1000;
                            animation: check 2s linear infinite;
                        }
                        .order {
                            animation: slide 2s infinite alternate;
                        }
                    </style>
                    <rect
                        x="20"
                        y="20"
                        width="60"
                        height="60"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <g class="order">
                        <line
                            x1="30"
                            y1="40"
                            x2="70"
                            y2="40"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="30"
                            y1="50"
                            x2="70"
                            y2="50"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                        <line
                            x1="30"
                            y1="60"
                            x2="50"
                            y2="60"
                            stroke="#1d9395"
                            stroke-width="2"
                        />
                    </g>
                    <path
                        class="checkmark"
                        d="M65 55 L75 65 L85 45"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Work Order Management</strong>
                <p>
                    Streamline the creation, tracking, and execution of work
                    orders for seamless operations.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-2">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes pulse {
                            0% {
                                transform: scale(1);
                                opacity: 0.5;
                            }
                            50% {
                                transform: scale(1.1);
                                opacity: 1;
                            }
                            100% {
                                transform: scale(1);
                                opacity: 0.5;
                            }
                        }
                        @keyframes graph {
                            0% {
                                transform: scaleY(0.3);
                            }
                            50% {
                                transform: scaleY(1);
                            }
                            100% {
                                transform: scaleY(0.6);
                            }
                        }
                        @keyframes blink {
                            0% {
                                opacity: 0.3;
                            }
                            50% {
                                opacity: 1;
                            }
                            100% {
                                opacity: 0.3;
                            }
                        }
                        .monitor {
                            animation: pulse 2s infinite;
                        }
                        .graph-bar {
                            animation: graph 1.5s infinite alternate;
                            transform-origin: bottom;
                        }
                        .status-light {
                            animation: blink 1s infinite;
                        }
                    </style>

                    <!-- Monitor Frame -->
                    <rect
                        class="monitor"
                        x="10"
                        y="10"
                        width="80"
                        height="60"
                        rx="2"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />

                    <!-- Graph Bars -->
                    <rect
                        class="graph-bar"
                        x="20"
                        y="30"
                        width="8"
                        height="30"
                        fill="#1d9395"
                        style="animation-delay: 0.1s"
                    />
                    <rect
                        class="graph-bar"
                        x="35"
                        y="30"
                        width="8"
                        height="30"
                        fill="#1d9395"
                        style="animation-delay: 0.2s"
                    />
                    <rect
                        class="graph-bar"
                        x="50"
                        y="30"
                        width="8"
                        height="30"
                        fill="#1d9395"
                        style="animation-delay: 0.3s"
                    />
                    <rect
                        class="graph-bar"
                        x="65"
                        y="30"
                        width="8"
                        height="30"
                        fill="#1d9395"
                        style="animation-delay: 0.4s"
                    />

                    <!-- Status Indicators -->
                    <circle
                        class="status-light"
                        cx="20"
                        y="80"
                        r="3"
                        fill="#1d9395"
                    />
                    <circle
                        class="status-light"
                        cx="35"
                        y="80"
                        r="3"
                        fill="#1d9395"
                        style="animation-delay: 0.3s"
                    />
                    <circle
                        class="status-light"
                        cx="50"
                        y="80"
                        r="3"
                        fill="#1d9395"
                        style="animation-delay: 0.6s"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Production Monitoring </strong>
                <p>
                    Monitor production processes in real time to improve
                    efficiency and control.
                </p>
            </div>
        </div>

        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes grow {
                            from {
                                transform: scaleY(0);
                            }
                            to {
                                transform: scaleY(1);
                            }
                        }
                        @keyframes pulse {
                            0% {
                                transform: scale(1);
                            }
                            50% {
                                transform: scale(1.1);
                            }
                            100% {
                                transform: scale(1);
                            }
                        }
                        .bar {
                            transform-origin: bottom;
                            animation: grow 1s ease-out;
                        }
                        .insight {
                            animation: pulse 2s infinite;
                        }
                    </style>

                    <!-- Chart Bars -->
                    <rect
                        class="bar"
                        x="20"
                        y="40"
                        width="10"
                        height="40"
                        fill="#1d9395"
                    />
                    <rect
                        class="bar"
                        x="40"
                        y="30"
                        width="10"
                        height="50"
                        fill="#1d9395"
                    />
                    <rect
                        class="bar"
                        x="60"
                        y="20"
                        width="10"
                        height="60"
                        fill="#1d9395"
                    />

                    <!-- Insight Indicator -->
                    <circle
                        class="insight"
                        cx="75"
                        cy="25"
                        r="8"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Performance Insights</strong>
                <p>
                    Analyze key performance metrics to identify bottlenecks and
                    optimize production.
                </p>
            </div>
        </div>
        <div class="m2-afmc-item" id="bottom-3">
            <div class="m2-afmc-bottom-item-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                    <style>
                        @keyframes flash {
                            0% {
                                opacity: 1;
                            }
                            50% {
                                opacity: 0.3;
                            }
                            100% {
                                opacity: 1;
                            }
                        }
                        @keyframes countdown {
                            from {
                                stroke-dashoffset: 0;
                            }
                            to {
                                stroke-dashoffset: 250;
                            }
                        }
                        .warning {
                            animation: flash 1s infinite;
                        }
                        .timer {
                            stroke-dasharray: 250;
                            animation: countdown 3s linear infinite;
                        }
                    </style>

                    <!-- Clock Face -->
                    <circle
                        cx="50"
                        cy="50"
                        r="35"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <circle
                        class="timer"
                        cx="50"
                        cy="50"
                        r="30"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="4"
                    />

                    <!-- Warning Symbol -->
                    <path
                        class="warning"
                        d="M45,30 L55,30 L50,45 Z"
                        fill="#1d9395"
                    />
                </svg>
            </div>
            <div class="m2-afmc-bottom-item-copy">
                <strong>Downtime Management</strong>
                <p>
                    Minimize production disruptions by identifying and
                    addressing downtime issues in real time.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- Second Row -->
<div class="m2-afmc-bottom-section">
    <div class="m2-afmc-item" id="bottom-4">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes rotate {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                    @keyframes progress {
                        from {
                            stroke-dashoffset: 0;
                        }
                        to {
                            stroke-dashoffset: -300;
                        }
                    }
                    .gear {
                        animation: rotate 4s linear infinite;
                    }
                    .metric {
                        stroke-dasharray: 10;
                        animation: progress 3s linear infinite;
                    }
                </style>

                <!-- Circular Progress -->
                <circle
                    cx="50"
                    cy="50"
                    r="30"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <circle
                    class="metric"
                    cx="50"
                    cy="50"
                    r="30"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="4"
                />

                <!-- Rotating Gear -->
                <g class="gear" transform="translate(50,50)">
                    <circle
                        r="15"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path d="M0,-15 L3,-10 L-3,-10 Z" fill="#1d9395" />
                    <path d="M0,15 L3,10 L-3,10 Z" fill="#1d9395" />
                </g>
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong
                >F<span style="text-transform: none">actoOEE</span>(Overall
                Equipment Effectiveness)
            </strong>
            <p>
                Track OEE in real time to improve machine utilization and
                productivity.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-5">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes check {
                        from {
                            stroke-dashoffset: 1000;
                        }
                        to {
                            stroke-dashoffset: 0;
                        }
                    }
                    @keyframes scan {
                        0% {
                            transform: translateY(0);
                        }
                        100% {
                            transform: translateY(40px);
                        }
                    }
                    .checkmark {
                        stroke-dasharray: 1000;
                        animation: check 2s linear infinite;
                    }
                    .scanner {
                        animation: scan 1.5s linear infinite alternate;
                    }
                </style>

                <!-- Product Frame -->
                <rect
                    x="20"
                    y="20"
                    width="60"
                    height="60"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Scanning Line -->
                <line
                    class="scanner"
                    x1="20"
                    y1="30"
                    x2="80"
                    y2="30"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Checkmark -->
                <path
                    class="checkmark"
                    d="M35,50 L45,60 L65,40"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong
                >F<span style="text-transform: none">actoQual</span> (Quality
                Control)
            </strong>
            <p>
                Implement IoT-powered quality control systems for early defect
                detection and improved product quality.
            </p>
        </div>
    </div>

    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes plotPoints {
                        0% {
                            transform: scale(0);
                            opacity: 0;
                        }
                        50% {
                            transform: scale(1.2);
                            opacity: 0.7;
                        }
                        100% {
                            transform: scale(1);
                            opacity: 1;
                        }
                    }
                    @keyframes drawLine {
                        from {
                            stroke-dashoffset: 1000;
                        }
                        to {
                            stroke-dashoffset: 0;
                        }
                    }
                    @keyframes dashMove {
                        from {
                            stroke-dashoffset: 0;
                        }
                        to {
                            stroke-dashoffset: -20;
                        }
                    }
                    .point {
                        animation: plotPoints 0.5s ease-out forwards;
                    }
                    .control-line {
                        stroke-dasharray: 1000;
                        animation: drawLine 3s linear forwards;
                    }
                    .limit-line {
                        stroke-dasharray: 5;
                        animation: dashMove 1s linear infinite;
                    }
                    .point:nth-child(2) {
                        animation-delay: 0.2s;
                    }
                    .point:nth-child(3) {
                        animation-delay: 0.4s;
                    }
                    .point:nth-child(4) {
                        animation-delay: 0.6s;
                    }
                    .point:nth-child(5) {
                        animation-delay: 0.8s;
                    }
                    .point:nth-child(6) {
                        animation-delay: 1s;
                    }
                </style>

                <!-- Grid Background -->
                <pattern
                    id="grid"
                    width="10"
                    height="10"
                    patternUnits="userSpaceOnUse"
                >
                    <path
                        d="M 10 0 L 0 0 0 10"
                        fill="none"
                        stroke="#1d939522"
                        stroke-width="0.5"
                    />
                </pattern>
                <rect x="10" y="10" width="80" height="80" fill="url(#grid)" />

                <!-- Axes -->
                <line
                    x1="10"
                    y1="90"
                    x2="90"
                    y2="90"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="10"
                    y1="10"
                    x2="10"
                    y2="90"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Control Limits -->
                <line
                    class="limit-line"
                    x1="10"
                    y1="30"
                    x2="90"
                    y2="30"
                    stroke="#1d9395"
                    stroke-width="1"
                    stroke-dasharray="4"
                />
                <line
                    class="limit-line"
                    x1="10"
                    y1="70"
                    x2="90"
                    y2="70"
                    stroke="#1d9395"
                    stroke-width="1"
                    stroke-dasharray="4"
                />

                <!-- Mean Line -->
                <line
                    class="limit-line"
                    x1="10"
                    y1="50"
                    x2="90"
                    y2="50"
                    stroke="#1d9395"
                    stroke-width="1"
                    stroke-dasharray="2"
                />

                <!-- Control Line -->
                <path
                    class="control-line"
                    d="M10,50 Q25,40 40,55 T70,45 T90,50"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Data Points -->
                <circle class="point" cx="10" cy="50" r="3" fill="#1d9395" />
                <circle class="point" cx="25" cy="40" r="3" fill="#1d9395" />
                <circle class="point" cx="40" cy="55" r="3" fill="#1d9395" />
                <circle class="point" cx="55" cy="45" r="3" fill="#1d9395" />
                <circle class="point" cx="70" cy="45" r="3" fill="#1d9395" />
                <circle class="point" cx="90" cy="50" r="3" fill="#1d9395" />

                <!-- Labels -->
                <text x="5" y="25" fill="#1d9395" font-size="4">UCL</text>
                <text x="5" y="55" fill="#1d9395" font-size="4">X̄</text>
                <text x="5" y="75" fill="#1d9395" font-size="4">LCL</text>
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong
                >F<span style="text-transform: none">actoSpc</span> (Statistical
                Process Control)
            </strong>
            <p>
                Use statistical techniques to monitor processes, identify
                variations, and ensure consistent quality.
            </p>
        </div>
    </div>
    <div class="m2-afmc-item" id="bottom-6">
        <div class="m2-afmc-bottom-item-icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <style>
                    @keyframes rotate {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }
                    @keyframes move {
                        from {
                            transform: translateX(-10px);
                        }
                        to {
                            transform: translateX(10px);
                        }
                    }
                    @keyframes blink {
                        0% {
                            opacity: 0.4;
                        }
                        50% {
                            opacity: 1;
                        }
                        100% {
                            opacity: 0.4;
                        }
                    }
                    .gear {
                        transform-origin: center;
                        animation: rotate 4s linear infinite;
                    }
                    .gear-reverse {
                        transform-origin: center;
                        animation: rotate 4s linear infinite reverse;
                    }
                    .item {
                        animation: move 2s ease-in-out infinite alternate;
                    }
                    .indicator {
                        animation: blink 1.5s infinite;
                    }
                </style>

                <!-- Main Frame -->
                <rect
                    x="10"
                    y="10"
                    width="80"
                    height="80"
                    fill="none"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Conveyor Belt -->
                <line
                    x1="20"
                    y1="60"
                    x2="80"
                    y2="60"
                    stroke="#1d9395"
                    stroke-width="4"
                />

                <!-- Moving Item on Belt -->
                <rect
                    class="item"
                    x="40"
                    y="50"
                    width="15"
                    height="15"
                    fill="#1d9395"
                />

                <!-- Gears -->
                <g class="gear" transform="translate(25,25)">
                    <circle
                        cx="0"
                        cy="0"
                        r="12"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path d="M0,-12 L2,-8 L-2,-8 Z" fill="#1d9395" />
                    <path d="M0,12 L2,8 L-2,8 Z" fill="#1d9395" />
                    <path d="M12,0 L8,2 L8,-2 Z" fill="#1d9395" />
                    <path d="M-12,0 L-8,2 L-8,-2 Z" fill="#1d9395" />
                </g>

                <g class="gear-reverse" transform="translate(75,25)">
                    <circle
                        cx="0"
                        cy="0"
                        r="12"
                        fill="none"
                        stroke="#1d9395"
                        stroke-width="2"
                    />
                    <path d="M0,-12 L2,-8 L-2,-8 Z" fill="#1d9395" />
                    <path d="M0,12 L2,8 L-2,8 Z" fill="#1d9395" />
                    <path d="M12,0 L8,2 L8,-2 Z" fill="#1d9395" />
                    <path d="M-12,0 L-8,2 L-8,-2 Z" fill="#1d9395" />
                </g>

                <!-- Planning Elements -->
                <line
                    x1="20"
                    y1="80"
                    x2="40"
                    y2="80"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="45"
                    y1="80"
                    x2="65"
                    y2="80"
                    stroke="#1d9395"
                    stroke-width="2"
                />
                <line
                    x1="70"
                    y1="80"
                    x2="90"
                    y2="80"
                    stroke="#1d9395"
                    stroke-width="2"
                />

                <!-- Status Indicators -->
                <circle
                    class="indicator"
                    cx="20"
                    cy="90"
                    r="3"
                    fill="#1d9395"
                />
                <circle
                    class="indicator"
                    cx="50"
                    cy="90"
                    r="3"
                    fill="#1d9395"
                    style="animation-delay: 0.5s"
                />
                <circle
                    class="indicator"
                    cx="80"
                    cy="90"
                    r="3"
                    fill="#1d9395"
                    style="animation-delay: 1s"
                />
            </svg>
        </div>
        <div class="m2-afmc-bottom-item-copy">
            <strong
                >F<span style="text-transform: none">actoMpcp</span>
                (Manufacturing Process Control and Planning)
            </strong>
            <p>
                Monitor and control production to ensure efficient operations,
                consistent quality, and regulatory compliance.
            </p>
        </div>
    </div>
</div>

<!-- Fifth Section -->
<!-- First Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-1">
        <img
            src="assets/img/OptebizImage/FactoMES Dashboard 3.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Real-Time OEE Tracking</div>
        <div class="m2-afmc-copy-text">
            <p>
                Maximize productivity and minimize downtime with real-time
                Overall Equipment Effectiveness (OEE) tracking.
            </p>
            <p>
                FactoMES provides a comprehensive view of your equipment's
                performance, enabling you to:
            </p>
            <ul>
                <li>Identify inefficiencies and bottlenecks in real-time</li>
                <li>Optimize production schedules and resource allocation</li>
                <li>
                    Improve maintenance planning and reduce unplanned downtime
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- Second Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-4">
        <img
            src="assets/img/OptebizImage/two_FactoMES Predictive Maintenance.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Predictive Maintenance</div>
        <div class="m2-afmc-copy-text">
            <p>
                Stay ahead of equipment failures and reduce unplanned downtime
                with predictive maintenance.
            </p>
            <p>
                FactoMES uses advanced analytics and machine learning algorithms
                to anticipate equipment failures, enabling you to:
            </p>
            <ul>
                <li>
                    Schedule maintenance proactively and minimize disruption
                </li>
                <li>Extend equipment lifespan and reduce maintenance costs</li>
                <li>Improve overall equipment reliability and performance</li>
            </ul>
        </div>
    </div>
</div>

<!-- Third Section -->
<div class="m2-afmc-top-section">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/FactoMES Dashboard 1.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Statistical Process Control (SPC)</div>
        <div class="m2-afmc-copy-text">
            <p>
                Ensure consistent quality and reduce variability with
                Statistical Process Control (SPC).
            </p>
            <p>
                FactoMES applies advanced statistical techniques to monitor
                processes, identify variations, and alert operators to take
                corrective action. This enables you to:
            </p>
            <ul>
                <li>Monitor process performance in real-time</li>
                <li>Identify and address quality control issues promptly</li>
                <li>Improve process capability and reduce waste</li>
            </ul>
        </div>
    </div>
</div>
<!-- Fourth Section -->
<div class="m2-afmc-top-section picture-right">
    <div class="m2-afmc-picture animated slideInRight faster active" id="top-5">
        <img
            src="assets/img/OptebizImage/four_FactoMES MPCP.png"
            alt="LQ img dash"
            style="max-width: 100%; height: auto"
        />
    </div>

    <div class="m2-afmc-copy">
        <div class="m2-afmc-copy-title">Inventory Optimization</div>
        <div class="m2-afmc-copy-text">
            <p>
                Optimize inventory levels and reduce waste with real-time
                tracking and management.
            </p>
            <p>
                FactoMES provides a comprehensive view of your inventory,
                enabling you to:
            </p>
            <ul>
                <li>Track inventory levels in real-time</li>
                <li>Set alerts for low or high inventory levels</li>
                <li>
                    Optimize inventory replenishment and reduce stockouts or
                    overstocking
                </li>
            </ul>
        </div>
    </div>
</div>

<!-- last section -->
<section class="mb-5 mt-5">
    <div class="container">
        <div class="cs_cta cs_style_1 text-center position-relative">
            <div class="cs_cta_in">
                <div class="cs_section_heading cs_style_1">
                    <h2 class="cs_section_title cs_text_white">
                        Transform Your Manufacturing Process Today!
                    </h2>
                    <p class="cs_section_subtitle mb-0">
                        Discover how FactoMES can help you automate process and
                        operations control and improve manufacturing pipelines.
                        Contact us to learn more.
                    </p>
                </div>
                <div class="cs_btn_group text-center">
                    <a href="/BookDemo" class="cs_btn cs_bg_white"
                        >Book A Demo</a
                    >
                    <a href="/TryItNow" class="cs_btn cs_bg_accent"
                        >Try It Now</a
                    >
                </div>
            </div>
            <div class="cs_cta_shape1">
                <img
                    src="assets/img/OptebizImage/Ellipse_small.svg"
                    alt="Image"
                />
            </div>
            <div class="cs_cta_shape2">
                <img src="assets/img/OptebizImage/stroke.svg" alt="Image" />
            </div>
        </div>
    </div>
</section>
