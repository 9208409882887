import { Component } from "@angular/core";

@Component({
    selector: "app-data-integration",
    templateUrl: "./data-integration.component.html",
    styleUrls: ["./data-integration.component.scss"],
})
export class DataIntegrationComponent {
    constructor() {}

    ngOnInit(): void {}

    pageTitleArea: pageTitle[] = [
        {
            title: "Facto CloudConnect",
            subTitle: "Data Integration & Connector Configuration",
        },
    ];
    servicesDetailsImage: DetailsImage[] = [
        {
            img: "assets/img/OptebizImage/Dataintegration1.jpg",
        },
    ];
    servicesDetailsDesc: DetailsDesc[] = [
        {
            subTitle: "Data Integration & Connector Configuration",
            title1: "Data Integration & Connector Configuration",
            paragraphText1:
                "Data integration is a process that involves combining and unifying data from different sources to provide a unified view or representation. It allows organizations to access, manipulate, and manage data from various systems or applications in a cohesive manner.",
            paragraphText2:
                "Connector configuration, in the context of data integration, refers to the setup and customization of connectors. Connectors are software components that facilitate the interaction between different systems, applications, or databases. They play a key role in data integration by establishing a bridge between disparate data sources and the target system.",
            title2: "Important Facts",
            title3: "Application Areas",
            title4: "Technologies That We Use",
            img1: "assets/img/OptebizImage/Kafka_To_Elasticsearch_Sink_Connector.png",
            img2: "assets/img/OptebizImage/Kafka-Connect_JSON-diagram-revised.png",
        },
    ];
    servicesDetailsDescImportantFacts: ImportantFacts[] = [
        {
            title: "Data Sources",
        },
        {
            title: "ETL Processes",
        },
        {
            title: "Data Warehouse or Target System",
        },
        {
            title: "Connection Details",
        },
        {
            title: "Connector Types",
        },
        {
            title: "Integration Platforms",
        },
    ];
    servicesDetailsDescApplication: Application[] = [
        {
            title: "Manufacturing",
            icon: "flaticon-factory",
        },
        {
            title: "Healthcare",
            icon: "flaticon-hospital",
        },
        {
            title: "Automobile",
            icon: "flaticon-tracking",
        },
        {
            title: "Banking",
            icon: "flaticon-investment",
        },
        {
            title: "Real Estate",
            icon: "flaticon-house",
        },
        {
            title: "Logistics",
            icon: "flaticon-order",
        },
    ];
    servicesDetailsDescTechnologiesFeatures: TechnologiesFeatures[] = [
        {
            title: "JavaScript",
        },
        {
            title: "Python",
        },
        {
            title: "Java",
        },
        {
            title: "C/CPP",
        },
        {
            title: "PHP",
        },
        {
            title: "Swift",
        },
        {
            title: "C# (C- Sharp)",
        },
        {
            title: "Ruby",
        },
        {
            title: "SQL",
        },
    ];

    sidebarServicesList: ServicesList[] = [
        {
            title: "Integration Service ",
            link: "integration-service",
        },
        {
            title: "Resilient Architecture",
            link: "resilient-architecture",
        },
        {
            title: "Trigger-Based",
            link: "trigger-based",
        },
        {
            title: "Transaction Progress",
            link: "transaction-progress",
        },
        {
            title: "Dynamic Tag Selection",
            link: "dynamic-tag-selection",
        },
    ];
    sidebarDownloadFile: DownloadFile[] = [
        {
            title: "PDF Download",
            icon: "bx bxs-file-pdf",
            link: "services-details",
        },
        {
            title: "Services Details.txt",
            icon: "bx bxs-file-txt",
            link: "services-details",
        },
    ];
    sidebarContactInfo: ContactInfo[] = [
        {
            icon: "bx bx-user-pin",
            title: "Phone",
            subTitle: "+14199133878",
        },
        {
            icon: "bx bx-map",
            title: "Location",
            subTitle: "LeanQubit Inc. 2600 Dorr Street Toledo, OH 43606",
        },
        {
            icon: "bx bx-envelope",
            title: "Email",
            subTitle: "sales@leanqubit.ai",
        },
    ];
}
class pageTitle {
    title: string;
    subTitle: string;
}
class DetailsImage {
    img: string;
}
class DetailsDesc {
    subTitle: string;
    title1: string;
    title2: string;
    title3: string;
    title4: string;
    paragraphText1: string;
    paragraphText2: string;
    img1: string;
    img2: string;
}
class ImportantFacts {
    title: string;
}
class Application {
    title: string;
    icon: string;
}
class TechnologiesFeatures {
    title: string;
}

class ServicesList {
    title: string;
    link: string;
}
class DownloadFile {
    title: string;
    icon: string;
    link: string;
}
class ContactInfo {
    icon: string;
    title: string;
    subTitle: string;
}
