import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormArray } from '@angular/forms';
import emailjs from '@emailjs/browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-book-demo-page',
  templateUrl: './book-demo-page.component.html',
  styleUrls: ['./book-demo-page.component.scss']
})
export class BookDemoPageComponent implements OnInit {
  contactForm: FormGroup;
  submitted = false;
  showFormError = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private router: Router
  ) {
    emailjs.init("_2CXtzL4SmhBGEjgD"); // Your EmailJS public key
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  private initializeForm(): void {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      company: ['', [Validators.required]],
      additionalNotes: ['']
    });
  }

  // Get form controls for easy access in template
  get f() {
    return this.contactForm.controls;
  }

  isFieldInvalid(fieldName: string): boolean {
    const field = this.contactForm.get(fieldName);
    return field ? (field.invalid && (field.dirty || field.touched || this.submitted)) : false;
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    this.showFormError = false;

    if (this.contactForm.invalid) {
      this.showFormError = true;
      Object.keys(this.contactForm.controls).forEach(key => {
        const control = this.contactForm.get(key);
        if (control) {
          control.markAsTouched();
        }
      });
      return;
    }

    try {
      const formValues = this.contactForm.value;

      // Prepare template parameters based on your EmailJS template
      const templateParams = {
        from_first_name: formValues.firstName,
        from_last_name: formValues.lastName,
        email: formValues.email,
        phone: formValues.phone || 'Not provided',
        company: formValues.company || 'Not provided',
        message: formValues.additionalNotes || 'No additional notes provided'
      };

      // Send email using EmailJS
      const response = await emailjs.send(
        'service_zkklyxk', // Your EmailJS service ID
        'template_3xo3lvj', // Your EmailJS template ID
        templateParams
      );

      if (response.status === 200) {
        this.toastr.success('Your message has been sent successfully!', 'Success');
        console.log('Email sent successfully!');
        this.onReset();
        this.router.navigate(['/ThankYou']);
      }
    } catch (error) {
      console.error('Failed to send email:', error);
      this.toastr.error('Failed to send message. Please try again.', 'Error');
    }
  }

  onReset(): void {
    this.submitted = false;
    this.contactForm.reset();
  }
}